import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import OrderContext from 'contexts/OrderContext';
import Commerce from 'types/models/Commerce';
import UseChangeCommerce from 'types/hooks/useChangeCommerce';

export default (): UseChangeCommerce => {
  const { setCurrentCommerce } = React.useContext(OrderContext);

  const storeCurrentCommerce = async (commerce: Commerce): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.currentCommerce, JSON.stringify(commerce));
      setCurrentCommerce(commerce);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentCommerce = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.currentCommerce);
      if (setCurrentCommerce) setCurrentCommerce(null);
    } catch (error) {
      console.log(error);
    }
  };

  return { storeCurrentCommerce, deleteCurrentCommerce };
};
