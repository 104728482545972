import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardInformationList, CardInformationProps } from '@double_point/rn-components';
import { getNextEvent } from 'services/eventServices';
import Event from 'types/models/Event';
import EventImageType from 'types/enums/EventImageType';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import AppContext from 'contexts/AppContext';
import useSelectEvent from 'hooks/useSelectEvent';
import { greenColor } from 'styles/global';
import NextEventProps from 'types/components/NextEventProps';
import App from 'constants/App';

export default ({ route }: NextEventProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, setIsLoading } = React.useContext(AppContext);
  const { setSelectedEvent } = useSelectEvent();
  const [itemsNextEvent, setItemsNextEvent] = React.useState<CardInformationProps[]>([]);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;

  React.useEffect(() => {
    const loadNextEvent = async () => {
      setIsLoading(true);
      if (currentVenue) {
        if (route) {
          // si recibe la prop de route verifica que sea igual al venueActual
          const params = route.params;
          if (params) {
            const { venueCode } = params;
            if (venueCode !== currentVenue.code) return;
          }
        }
        const result = await getNextEvent(currentVenue.id);
        if (result) {
          setItemsNextEvent([parseEventToCardItem(result)]);
        } else {
          setItemsNextEvent([]);
        }
        setIsLoading(false);
      }
    };
    loadNextEvent();
  }, [currentVenue]);

  const parseEventToCardItem = (event: Event): CardInformationProps => {
    return {
      id: event.id,
      date: event.eventStart,
      image: event.images.find((image) => image.eventImageType === EventImageType.banner)?.path ?? undefined,
      title: event.name,
      showDate: true,
      showTime: true,
      onClick: () => setSelectedEvent(event),
      dateLanguage: currentVenue ? currentVenue.language : '',
      isForArticleListing: false,
      venueLogoPath: venueLogoImage,
    };
  };

  const renderContent = React.useMemo(() => {
    return itemsNextEvent.length ? (
      <CardInformationList
        title={t('next-event')}
        titleColor={currentVenue?.appColor ?? greenColor}
        items={itemsNextEvent}
        seeMoreText={t('see-more')}
        dateLanguage={currentVenue ? currentVenue.language : ''}
      />
    ) : null;
  }, [itemsNextEvent]);

  return <>{renderContent}</>;
};
