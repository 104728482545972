import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  deliveryTypeBtn: {
    backgroundColor: '#F3F3F3',
    borderRadius: 21,
  },
  deliveryTypeBtnText: {
    padding: 5,
    fontSize: 16,
    marginLeft: 5,
    textTransform: 'uppercase',
  },
  deliveryTypeBtnIcon: {
    fontSize: 20,
    paddingTop: 2,
  },
  selectedDeliveryType: {
    color: '#F3F3F3',
  },
  pickUpBtn: {
    flex: 1,
  },
  toChairBtn: {
    marginLeft: 10,
    flex: 2,
  },
});
