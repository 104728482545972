import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 10,
    backgroundColor: '#fff',
  },
  creatingPaymentIntent: {
    position: 'absolute',
    alignSelf: 'center',
  },
  creatingPaymentIntentText: {
    fontSize: 22,
    fontWeight: '500',
    textAlign: 'center',
  },
  paymentIntentCreated: {
    marginTop: 50,
  },
  paymentIntentCreatedText: {
    fontSize: 19,
    fontWeight: '500',
    textAlign: 'center',
    color: '#434343',
  },
  paymentInstructions: {
    marginTop: 40,
    fontSize: 22,
    fontWeight: '600',
    padding: 10,
  },
  mpPointImage: {
    width: 350,
    height: 200,
    alignSelf: 'center',
    marginTop: 40,
  },
});
