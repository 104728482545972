import React from 'react';
import { Button } from 'react-native-elements';
import useIcon from 'hooks/useIcon';
import DeliveryTypeBtnProps from 'types/components/DeliveryTypeBtnProps';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/deliveryTypeBtn';
import { greenColor } from 'styles/global';

export default ({ deliveryType, onPress }: DeliveryTypeBtnProps): JSX.Element => {
  const Icon = useIcon();
  const { currentVenue } = React.useContext(AppContext);

  return (
    <Button
      title={deliveryType.title}
      containerStyle={[{ outlineStyle: 'none' }]}
      buttonStyle={[
        styles.deliveryTypeBtn,
        deliveryType.icon === 'smile' ? styles.pickUpBtn : styles.toChairBtn,
        deliveryType.selected && {
          ...styles.selectedDeliveryType,
          backgroundColor: currentVenue?.appColor ?? greenColor,
        },
      ]}
      titleStyle={[
        [styles.deliveryTypeBtnText, { color: currentVenue?.appColor ?? greenColor }],
        deliveryType.selected && [
          styles.selectedDeliveryType,
          { backgroundColor: currentVenue?.appColor ?? greenColor },
        ],
      ]}
      type="clear"
      icon={
        <Icon
          name={deliveryType.icon}
          style={[
            [styles.deliveryTypeBtnIcon, { color: currentVenue?.appColor ?? greenColor }],
            deliveryType.selected && [
              styles.selectedDeliveryType,
              { backgroundColor: currentVenue?.appColor ?? greenColor },
            ],
          ]}
        />
      }
      onPress={onPress}
    />
  );
};
