import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  textCloverContainer: {
    alignSelf: 'center',
    marginTop: 40,
    textAlign: 'center',
  },
  cloverTitleText: {
    fontWeight: '500',
    fontSize: 48,
    lineHeight: 20,
    textTransform: 'capitalize',
    letterSpacing: 0.1,
    marginLeft: 30,
    marginBottom: 100,
    marginTop: 100,
    color: '#000000',
  },
  cloverInstructions: {
    marginTop: 15,
    fontSize: 20,
    fontWeight: '400',
  },
  pinpadImage: {
    width: 221,
    height: 451,
    alignSelf: 'center',
    marginTop: 40,
  },
  responseImage: {
    width: 441.58,
    height: 440,
  },
  buttonContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 125,
  },
  button: {
    justifyContent: 'center',
    width: 603,
    height: 106,
    borderRadius: 100,
  },
});
