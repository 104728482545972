import LocalDeliveryField from 'types/models/LocalDeliveryField';
import LocalDeliveryArea from 'types/models/LocalDeliveryArea';

export const parseLocalDeliveryAreas = (localDeliveryAreas: any): LocalDeliveryArea[] => {
  return localDeliveryAreas.map(
    (localDeliveryArea: any): LocalDeliveryArea => ({
      id: localDeliveryArea.id,
      description: localDeliveryArea.description,
      fields: parseLocalDeliveryFields(localDeliveryArea.fields),
    }),
  );
};

const parseLocalDeliveryFields = (localDeliveryFields: any): LocalDeliveryField[] => {
  return localDeliveryFields.map(
    (localDeliveryField: any): LocalDeliveryField => ({
      id: localDeliveryField.id,
      description: localDeliveryField.description,
      placeholder: localDeliveryField.placeholder,
      isRequired: localDeliveryField.is_required === 1,
      isNumber: localDeliveryField.is_number === 1,
      maxNumberValue: parseFloat(localDeliveryField.max_number_value),
      minNumberValue: parseFloat(localDeliveryField.min_number_value),
      order: localDeliveryField.order,
    }),
  );
};
