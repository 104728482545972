import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardInformationList, CardInformationProps } from '@double_point/rn-components';
import { getNextEvents } from 'services/eventServices';
import PaginationData from 'types/models/PaginationData';
import Event from 'types/models/Event';
import EventImageType from 'types/enums/EventImageType';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import AppContext from 'contexts/AppContext';
import useSelectEvent from 'hooks/useSelectEvent';
import { greenColor } from 'styles/global';
import App from 'constants/App';

export default (): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, setIsLoading } = React.useContext(AppContext);
  const { setSelectedEvent } = useSelectEvent();
  const [nextEvents, setNextEvents] = React.useState<PaginationData<Event> | null>();
  const [itemsNextEvents, setItemsNextEvents] = React.useState<CardInformationProps[]>([]);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;

  React.useEffect(() => {
    const fetchEvents = async () => {
      if (currentVenue) {
        await loadEvents(currentVenue.id);
      }
    };
    fetchEvents();
  }, [currentVenue]);

  const loadEvents = async (venueId: string, page?: number) => {
    if (!page) {
      setItemsNextEvents([]);
    }
    setIsLoading(true);
    const paginationData = await getNextEvents(venueId, page);
    if (!paginationData) return;
    if (paginationData.data.length > 0) {
      setNextEvents(paginationData);
      const newItems = parseNextEventsToCardItems(paginationData.data);
      const items = page ? [...itemsNextEvents, ...newItems] : newItems;
      setItemsNextEvents(items);
    }
    setIsLoading(false);
  };

  const parseNextEventsToCardItems = (events: Event[]): CardInformationProps[] => {
    return events.map((event: Event) => parseEventToCardItem(event));
  };

  const parseEventToCardItem = (event: Event): CardInformationProps => {
    return {
      id: event.id,
      date: event.eventStart,
      image: event.images.find((image) => image.eventImageType === EventImageType.banner)?.path ?? undefined,
      title: event.name,
      showDate: true,
      showTime: true,
      onClick: () => setSelectedEvent(event),
      dateLanguage: currentVenue ? currentVenue.language : '',
      isForArticleListing: false,
      venueLogoPath: venueLogoImage,
    };
  };

  if (!currentVenue) return <></>;
  return (
    <>
      {itemsNextEvents.length ? (
        <CardInformationList
          title={t('next-events')}
          titleColor={currentVenue?.appColor ?? greenColor}
          items={itemsNextEvents}
          onClickMore={() => {
            if (nextEvents) {
              const page = nextEvents.currentPage;
              loadEvents(currentVenue.id, page + 1);
            }
          }}
          seeMoreText={t('see-more')}
          dateLanguage={currentVenue.language}
        />
      ) : null}
    </>
  );
};
