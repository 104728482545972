import React from 'react';
import { Text, View, Image, Platform } from 'react-native';
import { RadioButton } from 'react-native-paper';
import PaymentMethodItemProps from 'types/components/PaymentMethodItemProps';
import PaymentProviderTypes from 'types/enums/PaymentProviderTypes';
import useIcon from 'hooks/useIcon';
import { styles } from 'styles/components/paymentMethodItem';
import { greenColor } from 'styles/global';
import AppContext from 'contexts/AppContext';
import MercadoPago from 'assets/images/icon-mercado-pago.png';
import OrderContext from 'contexts/OrderContext';

export default ({
  title,
  iconName,
  radioValue,
  checked,
  setChecked,
  description,
  onPressAddCard,
  onDeleteCard,
  isForTotemDisplay,
}: PaymentMethodItemProps): JSX.Element => {
  const Icon = useIcon();
  const { currentVenue, pairedWithClover } = React.useContext(AppContext);
  const { currentCommerce } = React.useContext(OrderContext);

  const showMPLogo = (): boolean => {
    const venueActivePaymentProvider = currentVenue?.paymentConfig?.activePaymentProvider;
    const commerceActivePaymentProvider = currentCommerce?.paymentConfig?.activePaymentProvider;
    if (iconName === 'money-bill') return false;
    if (iconName === 'coin-dollar') return false;
    // Si el venue o el comercio tienen configuración de MercadoPago activo, debe retornar true
    if (
      venueActivePaymentProvider &&
      currentVenue?.paymentConfig?.priorityOverCommerce &&
      venueActivePaymentProvider !== PaymentProviderTypes.mercadoPago &&
      venueActivePaymentProvider !== PaymentProviderTypes.MercadoPagoCheckout
    ) {
      return false;
    }

    if (
      commerceActivePaymentProvider &&
      commerceActivePaymentProvider !== PaymentProviderTypes.mercadoPago &&
      commerceActivePaymentProvider !== PaymentProviderTypes.MercadoPagoCheckout
    ) {
      if (iconName === 'credit-card') {
        return false;
      }
    }

    if (
      commerceActivePaymentProvider &&
      !currentCommerce?.paymentConfig?.mercadoPagoConfigId &&
      !currentCommerce.paymentConfig?.mercadoPagoCheckoutConfig
    ) {
      return false;
    }

    if (pairedWithClover) {
      return false;
    }

    return true;
  };

  return (
    <View style={[styles.paymentMethod, isForTotemDisplay && { marginTop: 50, marginBottom: 50 }]}>
      <View style={[styles.paymentMethodColumnWeb, isForTotemDisplay && { height: 150 }]}>
        {showMPLogo() ? (
          <Image style={[styles.mercadoPagoIcon]} source={MercadoPago} />
        ) : (
          <Icon
            name={iconName}
            style={[
              styles.cashPaymentMethodIcon,
              isForTotemDisplay && { fontSize: 56, marginTop: 50 },
              { color: currentVenue?.appColor ?? greenColor },
            ]}
          />
        )}
      </View>
      <View
        style={[
          Platform.OS === 'web' ? styles.paymentMethodColumnWeb : styles.paymentMethodColumnMobile,
          styles.paymentMethodInfo,
          isForTotemDisplay && { height: 150 },
        ]}
      >
        <View style={{ width: '100%' }}>
          <View style={styles.cashPaymentMethodInfo}>
            <Text
              style={[
                styles.paymentMethodName,
                isForTotemDisplay && { fontSize: 36, lineHeight: 24, fontWeight: '400', letterSpacing: 0.15 },
              ]}
            >
              {title}
            </Text>
          </View>
          {description && (
            <View style={styles.cashPaymentMethodInfo}>
              <Text style={styles.paymentMethodDescription}>{description}</Text>
            </View>
          )}
        </View>
      </View>
      <View style={[styles.paymentMethodColumnWeb, isForTotemDisplay && { height: 150 }]}>
        {onDeleteCard && <Icon name="trash-alt" style={styles.trashIcon} onPress={() => onDeleteCard()} />}
        {!radioValue && onPressAddCard && (
          <Icon
            name="plus-circle"
            style={[styles.addCardIcon, { color: currentVenue?.appColor ?? greenColor }]}
            onPress={() => onPressAddCard()}
          />
        )}
        {radioValue && setChecked && (
          <View
            style={[
              styles.radioOption,
              isForTotemDisplay && { width: 200, height: 200, marginTop: 65 },
              Platform.OS !== 'ios' && { borderColor: currentVenue?.appColor ?? greenColor },
            ]}
          >
            <RadioButton
              color={currentVenue?.appColor ?? greenColor}
              value={radioValue}
              status={checked === radioValue ? 'checked' : 'unchecked'}
              onPress={() => setChecked(radioValue)}
            />
          </View>
        )}
      </View>
    </View>
  );
};
