import React from 'react';
import { Modal, View, Image, Pressable, TouchableOpacity, Text, Platform } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import { Icon as RNIcon } from 'react-native-elements';
import { WithLocalSvg } from 'react-native-svg';
import OrderModalResponseProps from 'types/components/OrderModalResponseProps';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import ShoppingBarSuccess from 'assets/images/shopping-bar-success.svg';
import ShoppingBarCardError from 'assets/images/shopping-bar-card-error.svg';
import { styles } from 'styles/components/orderResponseModal';

export default ({
  success,
  message,
  orderNumber,
  verificationCode,
  onClose,
  navigation,
  isOrderPayWithCloverDevice,
  redirectToPayment,
}: OrderModalResponseProps): JSX.Element => {
  const { currentVenue, cloverConnected, pairedWithClover } = React.useContext(AppContext);
  const { currentCommerce } = React.useContext(OrderContext);

  const redirectToOrdersScreen = () => {
    onClose();
    navigation.dispatch({
      ...CommonActions.reset({
        index: 1,
        routes: [
          {
            name: 'Home',
            state: {
              routes: [
                {
                  name: 'HomeScreen',
                },
              ],
            },
          },
          {
            name: 'Orders',
            state: {
              routes: [
                {
                  name: 'OrdersScreen',
                  params: Platform.OS === 'web' ? { venueCode: currentVenue?.code } : undefined,
                },
              ],
            },
          },
        ],
      }),
    });
  };

  const returnToRetryScreen = () => {
    onClose();
    if (redirectToPayment) {
      redirectToPayment();
      return;
    }
    navigation.reset({
      index: 1,
      routes: [
        { name: cloverConnected ? 'CommerceScreen' : 'HomeScreen' },
        {
          name: 'ShoppingCartScreen',
          params:
            Platform.OS === 'web'
              ? {
                  venueCode: currentVenue?.code,
                  commerceCode: currentCommerce?.code,
                }
              : undefined,
        },
      ],
    });
  };

  return (
    <Modal animationType="slide" transparent={true} visible={true} style={styles.modal}>
      <View style={styles.container}>
        <View style={styles.responseDataContainer}>
          <View style={styles.closeButton}>
            <Pressable
              onPress={() => {
                if (success) {
                  onClose();
                } else {
                  returnToRetryScreen();
                }
              }}
              style={styles.closeOpacity}
            >
              <RNIcon
                name="close"
                style={[styles.closeImage, pairedWithClover && { height: 100, width: 100 }]}
                size={pairedWithClover ? 100 : 25}
              />
            </Pressable>
          </View>
          <View style={[styles.modalImageContainer, { backgroundColor: success ? '#00A80E' : '#E50303' }]}>
            {Platform.OS === 'web' ? (
              <Image
                source={success ? ShoppingBarSuccess : ShoppingBarCardError}
                style={[styles.modalImage, { width: success ? 50 : 60 }, success && { height: 62 }]}
              />
            ) : (
              <WithLocalSvg
                asset={success ? ShoppingBarSuccess : ShoppingBarCardError}
                style={[styles.modalImage, { width: success ? 50 : 60 }, success && { height: 62 }]}
              />
            )}
          </View>
          <View style={styles.generalTextContainer}>
            <Text
              style={[
                styles.title,
                pairedWithClover && { fontSize: 36, lineHeight: 40 },
                { color: success ? '#00A80E' : '#E50303' },
              ]}
            >
              {success ? '¡Compra Realizada con exito!' : 'Hubo un inconveniente con el pago'}
            </Text>
            {success ? (
              <View style={styles.successDataContainer}>
                <Text style={[styles.orderNumberTitle, pairedWithClover && { fontSize: 48, lineHeight: 40 }]}>
                  Tu Número de pedido es el{' '}
                </Text>
                <Text style={[styles.orderNumberText, pairedWithClover && { fontSize: 48, lineHeight: 40 }]}>
                  {orderNumber}
                </Text>
                <Text
                  style={[styles.emailMessage, pairedWithClover && { fontSize: 36, lineHeight: 40 }]}
                >{`Recibirás el comprobante al mail ${`${
                  message ? 'con la información de envio de tu pedido' : 'para retirar tu pedido'
                }`}`}</Text>
                <Text
                  style={[styles.verificationCode, pairedWithClover && { fontSize: 36, lineHeight: 40 }]}
                >{`Codigo de Verificación: ${verificationCode}`}</Text>
                <Text
                  style={[styles.deliveryMessage, pairedWithClover && { fontSize: 36, lineHeight: 40 }]}
                >{`¡Recordá presentarlo ${`${message ? 'al recibir tu pedido!' : 'en local!'}`}`}</Text>
              </View>
            ) : (
              <View style={styles.errorContainer}>
                <Text style={[styles.errorTitle, pairedWithClover && { fontSize: 36, lineHeight: 40 }]}>
                  {message ? message : <Text>Tuvimos un inconveniente con el pago de la tarjeta.</Text>}
                </Text>
                <Text style={[styles.errorAdvice, pairedWithClover && { fontSize: 36, lineHeight: 40 }]}>
                  Por favor volvé a realizarlo
                </Text>
              </View>
            )}
            {!success && (
              <TouchableOpacity
                style={[
                  styles.retryButtonContainer,
                  { backgroundColor: currentVenue?.appColor ?? '#E50303' },
                  { outlineStyle: 'none' },
                ]}
                onPress={() => {
                  returnToRetryScreen();
                }}
              >
                <View style={styles.retryTextContainer}>
                  <Text style={styles.retryText}>Volver a Intentar</Text>
                </View>
              </TouchableOpacity>
            )}
          </View>
          {!isOrderPayWithCloverDevice && (
            <View style={styles.goToOrdersContainer}>
              <Text style={styles.goToOrdersText} onPress={redirectToOrdersScreen}>
                Ir a Mis Pedidos
              </Text>
            </View>
          )}
        </View>
      </View>
    </Modal>
  );
};
