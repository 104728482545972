import React from 'react';
import { Image, View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import UserPartnerCardProps from 'types/components/UserPartnerCardProps';
import { styles } from 'styles/components/userPartnerCard';
import { greenColor } from 'styles/global';

export default ({ venue, userProfile }: UserPartnerCardProps) => {
  const { t } = useTranslation();

  return (
    <View style={[styles.container, { backgroundColor: venue?.appColor ?? greenColor }]}>
      <View style={styles.imagesContainer}>
        <View style={styles.imageContainer}>
          <Image source={{ uri: userProfile?.partnerProfile?.partnerImageUrl }} style={styles.image} />
        </View>
        <View style={styles.imageContainer}>
          <Image source={{ uri: userProfile?.partnerProfile?.partnerQrUrl }} style={styles.image} />
        </View>
      </View>
      <View style={styles.partnerDataContainer}>
        <Text style={styles.partnerDataText}>{`${t('partner')}: ${userProfile.name}`}</Text>
        <Text style={styles.partnerDataText}>{`${t('partner-identificator-arg')}: ${userProfile.dni}`}</Text>
        <Text style={styles.partnerDataText}>{`${t('partner-last-membership-period-paid')}: ${
          userProfile.partnerProfile?.lastMembershipPeriodPaid
        }`}</Text>
      </View>
      <View style={styles.footerContainer}>
        <Text style={styles.footerTitle}>{t('partner')}</Text>
      </View>
    </View>
  );
};
