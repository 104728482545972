import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Image, Text } from 'react-native';
import { styles } from 'styles/screens/mapScreen';
import { greenColor } from 'styles/global';
import AppContext from 'contexts/AppContext';
import VenueImageTypes from 'types/enums/VenueImageTypes';

export default ({}): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue } = React.useContext(AppContext);
  const mapImage = currentVenue?.images.find((image) => image.venueImageId === VenueImageTypes.VenueMap)?.path;

  return (
    <View style={styles.container}>
      {mapImage && (
        <>
          <Text style={[styles.title, { color: currentVenue?.appColor ?? greenColor }]}>
            {`${t('map-for')} ${currentVenue?.name}`}
          </Text>
          <View style={styles.mapContainer}>
            {mapImage && <Image source={{ uri: mapImage }} style={styles.imageMap} resizeMode="contain" />}
          </View>
        </>
      )}
    </View>
  );
};
