import React from 'react';
import { View, Platform } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import useIcon from 'hooks/useIcon';
import AppContext from 'contexts/AppContext';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import ShoppingCartModalForTotemProps from 'types/components/ShoppingCartModalForTotemProps';
import { styles } from 'styles/components/shoppingCartModalForTotem';
import { greenColor } from 'styles/global';

export default ({ product, shoppingCart, navigation }: ShoppingCartModalForTotemProps): JSX.Element => {
  const Icon = useIcon();
  const { t } = useTranslation();
  const { setModalizeData, currentVenue, pairedWithClover } = React.useContext(AppContext);
  const { deleteCurrentShoppingCart } = useChangeShoppingCart();
  const [productTotal, setProductTotal] = React.useState<number>(0);

  React.useEffect(() => {
    if (shoppingCart) {
      const detailItem = shoppingCart.detail.find((item) => item.product.id === product.id);
      if (detailItem) {
        setProductTotal(detailItem.product.price * detailItem.quantity);
      }
    }
  }, [shoppingCart]);

  return (
    <View style={{ height: 796 }}>
      <View style={{ flexDirection: 'row', alignSelf: 'center', justifyContent: 'center', marginVertical: 50 }}>
        <Icon
          name="shopping-cart"
          style={{ color: currentVenue?.appColor ?? greenColor, fontSize: 40, marginRight: 25 }}
        />
        <Card.Title style={[styles.headerText, { color: currentVenue?.appColor ?? greenColor }]}>
          {t('product-added-to-cart')}
        </Card.Title>
      </View>
      <Card.Divider />
      <View style={styles.buttonsContainer}>
        <Button
          title={`${t('continue-shopping')}`}
          containerStyle={[
            styles.button,
            { outlineStyle: 'none' },
            { borderColor: currentVenue?.appColor ?? greenColor, borderWidth: 4 },
          ]}
          titleStyle={[
            {
              color: currentVenue?.appColor ?? greenColor,
              fontWeight: '500',
              fontSize: 32,
              lineHeight: 16,
              textAlign: 'center',
            },
          ]}
          type="clear"
          onPress={() => {
            setModalizeData(null);
            navigation.reset({
              index: 1,
              routes: [
                { name: 'HomeScreen' },
                {
                  name: 'CommerceScreen',
                  params:
                    Platform.OS === 'web'
                      ? {
                          venueCode: currentVenue?.code,
                          commerceCode: shoppingCart?.commerce.code,
                        }
                      : undefined,
                },
              ],
            });
          }}
        ></Button>
        <Button
          title={`${t('go-to-shopping-cart')}               $${productTotal}`}
          type="clear"
          containerStyle={[
            styles.button,
            { backgroundColor: currentVenue?.appColor ?? greenColor },
            { outlineStyle: 'none' },
          ]}
          titleStyle={[
            styles.confirmPaymentBtnText,
            { fontWeight: '500', fontSize: 32, lineHeight: 16, textAlign: 'center' },
          ]}
          onPress={async () => {
            setModalizeData(null);
            navigation.navigate(
              'ShoppingCartScreen',
              Platform.OS === 'web'
                ? {
                    venueCode: currentVenue?.code,
                    commerceCode: shoppingCart?.commerce.code,
                  }
                : undefined,
            );
          }}
          disabledTitleStyle={styles.confirmPaymentBtnText}
          disabledStyle={styles.buttonDisabled}
        ></Button>
      </View>
      <View style={{ width: '100%', alignItems: 'flex-start', marginTop: 65 }}>
        <Button
          title={t('cancel-order')}
          type="clear"
          containerStyle={[styles.button, { marginLeft: 50 }, { outlineStyle: 'none' }]}
          titleStyle={[
            styles.confirmPaymentBtnText,
            { color: currentVenue?.appColor ?? greenColor },
            pairedWithClover && { fontWeight: '500', fontSize: 32, lineHeight: 16, textAlign: 'center' },
          ]}
          onPress={async () => {
            setModalizeData(null);
            await deleteCurrentShoppingCart();
            navigation.navigate(
              'CommerceScreen',
              Platform.OS === 'web'
                ? {
                    venueCode: currentVenue?.code,
                    commerceCode: shoppingCart?.commerce.code,
                  }
                : undefined,
            );
          }}
          disabledTitleStyle={styles.confirmPaymentBtnText}
          disabledStyle={styles.buttonDisabled}
        ></Button>
      </View>
    </View>
  );
};
