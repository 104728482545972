import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 10,
    alignContent: 'center',
    alignSelf: 'center',
  },
  tabBar: {
    borderBottomColor: '#f4f4f4',
    borderBottomWidth: 1,
  },
  tabContainer: {
    borderBottomColor: '#090909',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  activeItem: {
    borderBottomWidth: 2,
  },
  tabText: {
    padding: 10,
    color: '#ffffff',
    fontSize: 18,
    fontWeight: '500',
  },
});
