import React from 'react';

export default (callback: () => void, delay: number | null): void => {
  const savedCallback = React.useRef<() => void>();

  React.useEffect(() => {
    savedCallback.current = callback;
  });

  React.useEffect(() => {
    function reconection() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay) {
      const id = setInterval(reconection, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
