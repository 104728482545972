import { StyleSheet } from 'react-native';

export const grayTextColor = '#C4C4C4';

export const styles = StyleSheet.create({
  containerCreditForm: {
    marginBottom: 10,
  },
  creditCard: {
    marginTop: 20,
    alignSelf: 'center',
    width: 340,
    height: 205,
    borderRadius: 22,
    overflow: 'hidden',
  },
  triangle: {
    marginTop: 40,
    backgroundColor: '#A1A1AA55',
    width: 400,
    height: 200,
    borderRightColor: '#A1A1AA55',
    borderTopColor: '#A1A1AA55',
    transform: [{ rotate: '45deg' }],
    marginLeft: -110,
  },
  secondTriangle: {
    position: 'absolute',
    marginLeft: 80,
    width: 300,
    height: 320,
  },
  containerForm: {
    flex: 1,
    marginBottom: 10,
  },
  textInput: {
    marginTop: 10,
    height: 40,
    width: 250,
    backgroundColor: 'white',
    borderColor: 'gray',
    borderBottomWidth: StyleSheet.hairlineWidth,
    alignSelf: 'center',
  },
  disabledInput: {
    color: '#C4C4C4',
  },
  columnExpirationCvv: {
    width: 110,
    flexDirection: 'column',
  },
  cardHolder: {
    position: 'absolute',
    width: 235,
    height: 20,
    top: 100,
    left: 20,
  },
  cardHolderText: {
    color: '#FFF',
    textTransform: 'uppercase',
  },
  cardNumber: {
    position: 'absolute',
    width: 240,
    height: 30,
    top: 125,
    left: 20,
  },
  cardNumberText: {
    letterSpacing: 5,
    color: '#FFF',
  },
  expirationCvvContainer: {
    width: 250,
    height: 80,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  expiredDate: {
    position: 'absolute',
    width: 100,
    height: 30,
    top: 160,
    left: 20,
  },
  expiredDateText: {
    letterSpacing: 3,
    color: '#FFF',
  },
  cvv: {
    position: 'absolute',
    width: 90,
    height: 30,
    top: 160,
    left: 100,
  },
  cvvText: {
    color: '#FFF',
  },
  chip: {
    position: 'absolute',
    width: 60,
    height: 30,
    top: 155,
    left: 260,
  },
  chipImage: {
    width: 42,
    height: 30,
  },
  invalidFieldMessage: {
    width: 250,
    marginTop: 5,
    alignSelf: 'center',
    color: '#F00',
  },
  confirmBtn: {
    width: 280,
    alignSelf: 'center',
    marginBottom: 15,
    marginTop: 10,
    borderRadius: 15,
    fontFamily: 'Helvetica',
    height: 50,
  },
});
