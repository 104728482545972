import React from 'react';
import { Text, View } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import DeleteCardAlertProps from 'types/components/DeleteCardAlertProps';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/deleteCardAlert';
import { greenColor } from 'styles/global';

export default ({ onDeleteCard }: DeleteCardAlertProps): JSX.Element => {
  const { t } = useTranslation();
  const { setModalizeData, currentVenue } = React.useContext(AppContext);

  return (
    <>
      <Card.Title style={styles.legendText}> {t('delete-card-title')} </Card.Title>
      <Card.Divider style={[styles.titleDivider, { borderBottomColor: currentVenue?.appColor ?? greenColor }]} />
      <View>
        <Text style={styles.message}>{t('delete-card-alert')}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={t('delete-card-text')}
          type="clear"
          containerStyle={[{ outlineStyle: 'none' }]}
          buttonStyle={[styles.confirmDeleteCardBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
          titleStyle={styles.confirmDeleteCardBtnText}
          onPress={() => {
            onDeleteCard();
          }}
        ></Button>
        <Button
          title={t('delete-card-cancel')}
          titleStyle={{ color: currentVenue?.appColor ?? greenColor }}
          containerStyle={[styles.cancelBtn, { outlineStyle: 'none' }]}
          type="clear"
          onPress={() => setModalizeData(null)}
        ></Button>
      </View>
    </>
  );
};
