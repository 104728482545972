import React from 'react';
import { getVenues } from 'services/venuesServices';
import Venue from 'types/models/Venue';
import UseVenues from 'types/hooks/UseVenues';
import AppContext from 'contexts/AppContext';

export default (): UseVenues => {
  const { setIsLoading } = React.useContext(AppContext);
  const [venues, setVenues] = React.useState<Venue[]>([]);

  const fetchVenues = React.useCallback(async () => {
    setIsLoading(true);
    setVenues(await getVenues());
    setIsLoading(false);
  }, []);

  return { venues, fetchVenues };
};
