import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    width: '90%',
    zIndex: 3,
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#00000059',
    alignItems: 'center',
    justifyContent: 'center',
  },
  responseDataContainer: {
    width: '90%',
    height: '80%',
    backgroundColor: '#fff',
    alignSelf: 'center',
    borderRadius: 30,
  },
  closeButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: 18,
    marginRight: 20,
  },
  closeImage: {
    height: 25,
    width: 25,
  },
  modalImageContainer: {
    position: 'absolute',
    top: -40,
    zIndex: 100,
    borderRadius: 45,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 90,
    height: 90,
  },
  modalImage: {
    height: 50,
    border: 0,
  },
  generalTextContainer: {
    flexDirection: 'column',
    marginTop: 50,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 15,
  },
  title: {
    marginBottom: 20,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.25,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontStyle: 'normal',
  },
  successDataContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  orderNumberTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#000000',
  },
  orderNumberText: {
    fontSize: 34,
    fontWeight: '700',
    textAlign: 'center',
    lineHeight: 40,
    fontStyle: 'normal',
    color: '#00A80E',
    marginVertical: 20,
  },
  emailMessage: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#000000',
    marginBottom: 20,
  },
  verificationCode: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#000000',
    marginBottom: 20,
  },
  deliveryMessage: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#000000',
  },
  errorContainer: {
    alignItems: 'center',
    marginBottom: 45,
  },
  errorTitle: {
    marginBottom: 30,
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#000000',
  },
  errorAdvice: {
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: 19,
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#000000',
  },
  retryButtonContainer: {
    width: '60%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
  },
  retryTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  retryText: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    fontStyle: 'normal',
  },
  goToOrdersContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
  },
  goToOrdersText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 14,
    textAlign: 'center',
    fontStyle: 'normal',
    marginTop: 20,
  },
  closeOpacity: {
    margin: 0,
    outlineStyle: 'none',
  },
});
