import { StyleSheet, Dimensions } from 'react-native';

const screenHeight = Dimensions.get('screen').height;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
  },
  title: {
    fontSize: 25,
    letterSpacing: 1.25,
    fontWeight: 'bold',
  },
  mapContainer: {
    width: '100%',
    height: screenHeight * 0.5,
    overflow: 'hidden',
  },
  imageMap: {
    width: '100%',
    height: screenHeight * 0.5,
  },
});
