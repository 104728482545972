import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import UseLocalOrders from 'types/hooks/useLocalOrders';
import Order from 'types/models/Order';
import AppContext from 'contexts/AppContext';

export default (): UseLocalOrders => {
  const { currentVenue } = React.useContext(AppContext);

  const storeLocalOrder = async (order: Order): Promise<void> => {
    try {
      let localOrders: Order[] | null = await getLocalOrders();
      if (!order) return;
      if (currentVenue) order.venue = currentVenue;
      if (localOrders) {
        localOrders.push(order);
      } else {
        localOrders = [order];
      }
      await AsyncStorage.setItem(constantsStorage.guestOrders, JSON.stringify(localOrders));
    } catch (error) {
      console.log(error);
    }
  };

  const updateOrders = async (localOrders: Order[]): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.guestOrders, JSON.stringify(localOrders));
    } catch (error) {
      console.log(error);
    }
  };

  const getLocalOrders = async (): Promise<Order[] | null> => {
    try {
      const orders = await AsyncStorage.getItem(constantsStorage.guestOrders);
      return orders ? JSON.parse(orders) : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const deleteLocalOrders = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.guestOrders);
    } catch (error) {
      console.log(error);
    }
  };

  const updateLocalOrderStatus = async (status: number, order: Order) => {
    const localOrders = await getLocalOrders();
    if (!localOrders || localOrders.length < 1) return;
    const localOrder = localOrders.find((localOrder) => localOrder.id === order.id);
    if (localOrder) {
      localOrder.orderStatusId = status;
      await updateOrders(localOrders);
    }
  };

  return { storeLocalOrder, updateOrders, getLocalOrders, deleteLocalOrders, updateLocalOrderStatus };
};
