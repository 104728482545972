import React from 'react';
import { Text, View } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Loading, SuccessModal } from '@double_point/rn-components';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/deleteCardAlert';
import constantsStorage from 'constants/Storage';
import { greenColor } from 'styles/global';
import { pairMPPoint } from 'services/paymentsService';
import UnpairMPPointModalProps from 'types/components/UnpairMPPointModalProps';

export default ({
  selectedMPPointId,
  currentCommerce,
  setCurrentPairedMPPoint,
}: UnpairMPPointModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { setModalizeData, currentVenue } = React.useContext(AppContext);
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const modalMessageContent = (message: string, isSuccess: boolean): JSX.Element => (
    <SuccessModal
      isSuccess={isSuccess}
      title={'Emparejar posnet'}
      mainColor={currentVenue?.appColor ?? greenColor}
      message={message}
      buttonText={'volver'}
      onConfirm={() => {
        setModalizeData(null);
        navigation.navigate('CommerceScreen');
      }}
    />
  );

  const openModalMessage = (message: string, success: boolean) => {
    setModalizeData({
      content: modalMessageContent(message, success),
      closeOnOverlayTap: false,
    });
  };

  return (
    <>
      <Card.Title style={styles.legendText}> {t('mercado-pago-point-unpair-posnet-title')} </Card.Title>
      <Card.Divider style={[styles.titleDivider, { borderBottomColor: currentVenue?.appColor ?? greenColor }]} />

      {isLoading && <Loading />}
      <View>
        <Text style={styles.message}>{t('Está seguro de que desea desemparejar el posnet?')}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={t('mercado-pago-point-unpair-posnet-text')}
          type="clear"
          containerStyle={[{ outlineStyle: 'none' }]}
          buttonStyle={[styles.confirmDeleteCardBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
          titleStyle={styles.confirmDeleteCardBtnText}
          onPress={async () => {
            if (!currentCommerce || !currentCommerce.paymentConfig?.mercadoPagoPointConfigId) return;
            setIsLoading(true);
            const mpPoint = await pairMPPoint(
              currentCommerce.paymentConfig.mercadoPagoPointConfigId,
              selectedMPPointId,
              false,
            );
            if (mpPoint) {
              try {
                await AsyncStorage.removeItem(constantsStorage.currentPairedMPPoint);
              } catch (error) {
                console.log(error);
              }
              setCurrentPairedMPPoint(null);
              const message = 'Posnet desemparejado correctamente';
              openModalMessage(message, true);
            } else {
              const message = 'Hubo un error al intentar desemparejar el posnet. Intente más tarde';
              openModalMessage(message, false);
            }
            setIsLoading(false);
          }}
        ></Button>
        <Button
          title={t('mercado-pago-point-unpair-posnet-cancel-text')}
          titleStyle={{ color: currentVenue?.appColor ?? greenColor }}
          containerStyle={[styles.cancelBtn, { outlineStyle: 'none' }]}
          type="clear"
          onPress={() => setModalizeData(null)}
        ></Button>
      </View>
    </>
  );
};
