import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  legendText: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 5,
    fontSize: 18,
    color: '#89909B',
    opacity: 0.8,
  },
  btn: {
    marginTop: 20,
    marginBottom: 20,
    width: 350,
    height: 50,
    borderRadius: 12,
    alignSelf: 'center',
  },
  disabledBtn: {
    backgroundColor: '#89909b',
  },
  btnText: {
    color: '#FFF',
    fontSize: 18,
  },
  loginLaterBtn: {
    width: 300,
    fontSize: 18,
    marginBottom: 20,
    alignSelf: 'center',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  textUnderline: {
    textDecorationLine: 'underline',
  },
  phoneInputContainer: {
    marginHorizontal: 16,
  },
  inputPhoneLabel: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#89909b',
  },
  inputPhoneRowContainer: {
    flexDirection: 'row',
  },
  touchableCountryCodeContainer: {
    borderRadius: 10,
    borderWidth: 1,
    width: '22%',
    height: 40,
    backgroundColor: '#fff',
    alignContent: 'center',
    marginTop: 10,
  },
  countryCodeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  countryCodeText: {
    fontSize: 20,
  },
  iconDownCountryCode: {
    marginTop: 15,
  },
  phoneInput: {
    borderColor: '#89909b',
    borderWidth: 1,
    marginTop: 10,
    marginLeft: 10,
    backgroundColor: '#FFF',
    height: 40,
    fontSize: 18,
    borderRadius: 10,
    padding: 10,
    width: '100%',
  },
  phoneInputInvalid: {
    color: '#F00',
    fontSize: 16,
    marginTop: 5,
  },
  phoneErrorLogin: {
    color: '#DC3545',
    fontSize: 15,
    marginTop: 10,
    paddingLeft: 20,
    textAlign: 'center',
  },
});
