import React from 'react';
import { Image, View, Text } from 'react-native';
import { Icon as RNIcon } from 'react-native-elements';
import ProductItemProps from 'types/components/ProductItemProps';
import ProductImageTypes from 'types/enums/ProductImageTypes';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/productItem';
import { greenColor } from 'styles/global';
import { productIsEmpty } from 'utils/products';
import { Platform } from 'react-native';

export default ({
  product,
  initialQuantity,
  showControlQuantity,
  clothingSize,
  onChangeQuantity,
  onDeleteProduct,
  addToCart,
}: ProductItemProps): JSX.Element => {
  const { currentVenue, pairedWithClover } = React.useContext(AppContext);
  const [productQuantity, setQuantity] = React.useState<number>(initialQuantity ?? 1);
  const roundOffPrice = Math.floor(product.price);

  React.useEffect(() => {
    if (initialQuantity) setQuantity(initialQuantity);
  }, [initialQuantity]);

  const onClickAdd = (clothingSize?: number) => {
    const newQuantity = productQuantity + 1;
    setQuantity(newQuantity);
    if (onChangeQuantity) onChangeQuantity(product.id, newQuantity, clothingSize);
  };

  const onClickRemove = (clothingSize?: number) => {
    if (productQuantity > 1) {
      const newQuantity = productQuantity - 1;
      setQuantity(newQuantity);
      if (onChangeQuantity) onChangeQuantity(product.id, newQuantity, clothingSize);
    }
  };

  const renderAddButton = (): JSX.Element => {
    return (
      <View>
        <RNIcon
          name="add-circle-outline"
          color={currentVenue?.appColor ?? greenColor}
          size={pairedWithClover ? 50 : 22}
          onPress={() => {
            if (addToCart) {
              addToCart(product);
            }
          }}
        />
      </View>
    );
  };

  const renderControlQuantity = () => {
    return (
      <View
        style={[
          styles.controlQuantityContainerNew,
          pairedWithClover && { width: 210, height: 81, borderRadius: 100, alignItems: 'center' },
          { borderColor: currentVenue?.appColor ?? greenColor },
        ]}
      >
        <RNIcon
          name={productQuantity === 1 ? 'delete-outline' : 'remove'}
          color={currentVenue?.appColor ?? greenColor}
          size={pairedWithClover ? 50 : 22}
          onPress={() => {
            // Si el cantidad de unidades es igual a 1 lo que hacemos es eliminar todo el
            // producto del carrito, si es mayor a 1 simplemente restamos 1
            if (productQuantity === 1 && onDeleteProduct) {
              onDeleteProduct(product.id, clothingSize?.id);
            } else {
              onClickRemove();
            }
          }}
        />
        <Text
          style={[
            styles.productQuantityText,
            pairedWithClover && { lineHeight: 56, fontSize: 48, marginTop: 10 },
            { color: currentVenue?.appColor ?? greenColor },
          ]}
        >
          {productQuantity}
        </Text>
        <RNIcon
          name="add"
          color={currentVenue?.appColor ?? greenColor}
          size={pairedWithClover ? 50 : 22}
          onPress={() => onClickAdd(clothingSize?.id)}
        />
      </View>
    );
  };

  return (
    <View
      style={[
        styles.productItem,
        Platform.OS === 'android' && productIsEmpty(product) && { shadowColor: 'rgba(0, 0, 0, 0.05)' },
        pairedWithClover && { width: 979, height: 303, borderRadius: 40, padding: 0 },
      ]}
    >
      <View style={[pairedWithClover ? styles.productImageContainerForTotem : styles.productImageContainer]}>
        <Image
          style={[pairedWithClover ? styles.productImageForTotem : styles.productImageNew]}
          source={{
            uri:
              product.images.find((image) => image.productImageTypeId === ProductImageTypes.logo)?.path ??
              'https://via.placeholder.com/100',
          }}
        />
      </View>
      <View style={[styles.productInfoContainer, pairedWithClover && { width: 585 }]}>
        <View style={[styles.productNameDescription, pairedWithClover && { height: 150, marginLeft: 25, top: 22 }]}>
          <Text
            style={[
              styles.productName,
              pairedWithClover
                ? { fontWeight: '500', fontSize: 32, lineHeight: 40, letterSpacing: 0.25, color: '#000000' }
                : { color: currentVenue?.appColor ?? greenColor },
            ]}
            numberOfLines={2}
          >
            {product.name}
          </Text>
          <Text
            numberOfLines={1}
            style={[
              pairedWithClover
                ? { fontSize: 20, lineHeight: 40, fontWeight: '400', letterSpacing: 0.25, marginTop: 10 }
                : { marginTop: 5 },
            ]}
          >
            {product.description}
          </Text>
          {product.isClothing && clothingSize ? <Text numberOfLines={1}>Talle: {clothingSize.description}</Text> : null}
        </View>
        <View style={styles.priceQuantityContainer}>
          <View style={[styles.priceContainer, pairedWithClover && { marginLeft: 25 }]}>
            <Text
              style={[
                styles.priceText,
                pairedWithClover && { fontSize: 32, lineHeight: 20, letterSpacing: 0.25, fontWeight: '700' },
                { color: currentVenue?.appColor },
              ]}
            >
              {`$${roundOffPrice}`}
            </Text>
          </View>
          <View style={[styles.addToCartControlQuantity, pairedWithClover && { marginTop: 45, marginRight: 15 }]}>
            {initialQuantity === 0 && renderAddButton()}
            {initialQuantity !== 0 && showControlQuantity && product.price !== 0 && renderControlQuantity()}
          </View>
        </View>
      </View>
    </View>
  );
};
