import { useEffect, useState } from 'react';

export default function (
  cb: () => void,
  delay: number,
): {
  tick: number;
} {
  const [tick, setTick] = useState<number>(0);

  useEffect(() => {
    const ref = setTimeout(() => {
      setTick((t) => ++t);
      cb();
    }, delay);

    return () => clearTimeout(ref);
  }, []);

  return {
    tick,
  };
}
