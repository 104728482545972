import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    padding: 10,
    flex: 1,
  },
  tabBar: {
    backgroundColor: '#fff',
    borderBottomColor: '#f4f4f4',
    borderBottomWidth: 1,
  },
  tabBarTotem: {
    backgroundColor: '#fff',
    borderBottomColor: '#f4f4f4',
    borderBottomWidth: 1,
    width: 983,
    height: 107,
    marginHorizontal: 150,
    marginTop: 100,
  },
  tabContainer: {
    borderBottomColor: '#090909',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  tabText: {
    padding: 10,
    color: '#9e9e9e',
    fontSize: 18,
    fontWeight: '500',
  },
  separator: {
    height: 0.5,
    width: '96%',
    alignSelf: 'flex-end',
    backgroundColor: '#eaeaea',
  },
  sectionHeaderContainer: {
    backgroundColor: '#f6f6f6',
    borderTopColor: '#f4f4f4',
    borderTopWidth: 1,
    borderBottomColor: '#f4f4f4',
    borderBottomWidth: 1,
  },
  sectionHeaderText: {
    color: '#89909B',
    fontSize: 15,
    fontWeight: 'normal',
    fontStyle: 'normal',
    paddingTop: 25,
    paddingBottom: 5,
    paddingHorizontal: 15,
    textTransform: 'uppercase',
  },
  activeItem: {
    borderBottomWidth: 2,
  },
  noStockMessage: {
    fontSize: 18,
    color: '#ff5050',
    textAlign: 'center',
    marginBottom: 5,
  },
  productCategoryLogo: {
    height: 45,
    width: 45,
    alignSelf: 'center',
  },
});
