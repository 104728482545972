import React from 'react';
import { View, Text, Image, Platform } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useTranslation } from 'react-i18next';
import OrderDescription from 'types/models/OrderDescription';
import OrderItemProps from 'types/components/OrderItemProps';
import CommerceImageTypes from 'types/enums/CommerceImageTypes';
import AppContext from 'contexts/AppContext';
import { translateOrderDescription } from 'services/ordersService';
import { styles } from 'styles/components/orderItem';
import { greenColor } from 'styles/global';
import { getcolorStatusDescription } from 'utils/orders';

export default ({ order, onClickDetail }: OrderItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue } = React.useContext(AppContext);
  const imageUri =
    order.commerce.images.find((image) => image.commerceImageTypeId === CommerceImageTypes.logo)?.path ??
    'https://via.placeholder.com/100';

  const orderDescriptions: OrderDescription = {
    orderCreated: t('pending-payment'),
    orderPaymentAccepted: t('payment-accepted'),
    orderPreparing: t('preparing-order'),
    orderToDeliver: t('order-ready-to-deliver'),
    orderDelivering: t('delivering-order'),
    orderDelivered: t('order-delivered'),
  };

  return (
    <View style={styles.productInfo}>
      <View style={[styles.productInfoColumn]}>
        <Image
          style={styles.productImage}
          source={{
            uri: imageUri,
          }}
        />
      </View>
      <View style={[styles.productInfoColumn, styles.nameColumn]}>
        <View style={{ flex: 1 }}>
          <Text
            style={{
              flex: 1,
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: 14,
              lineHeight: 24,
            }}
          >
            {`${t('order-detail-number')} ${order.id}`}
          </Text>
          <Text
            style={[
              { borderColor: 'red', flex: 1, fontWeight: '500', textTransform: 'uppercase' },
              { color: getcolorStatusDescription(order.orderStatusId) },
            ]}
          >
            {translateOrderDescription(order.orderStatusId, orderDescriptions)}
          </Text>
        </View>
        <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
          <View style={{ flex: 2.5 }}>
            <Text
              style={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: 12,
                lineHeight: 20,
              }}
            >{`${order.commerce.name}`}</Text>
            <Text
              style={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: 14,
                lineHeight: 20,
              }}
            >{` $${order.totalAmount}`}</Text>
          </View>
          <View style={{ flex: 2, justifyContent: 'center', alignItems: 'center' }}>
            <TouchableOpacity
              style={[
                {
                  minWidth: 50,
                  maxWidth: 100,
                  height: 32,
                  borderRadius: 15,
                  ...(Platform.OS === 'web' ? { padding: 10 } : { paddingHorizontal: 10 }),
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                { backgroundColor: currentVenue?.appColor ?? greenColor },
                { outlineStyle: 'none' },
              ]}
              onPress={() => onClickDetail(order.id)}
            >
              <Text
                style={{
                  color: '#FFF',

                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: 14,
                  lineHeight: 20,
                }}
              >
                Ir a pedido
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {/* <View>
          <Text style={[styles.productName, { color: currentVenue?.appColor ?? greenColor }]}>
            {`${t('order-detail-number')} ${order.id}`}
          </Text>
        </View>
        <View style={styles.statusContainer}>
          <Text style={styles.statusText}>{translateOrderDescription(order.orderStatusId, orderDescriptions)}</Text>
        </View>
        <View style={{ display: "flex", flexDirection: "row", }}>
          <View style={{ justifyContent: "center", alignItems: "center", flex: 2 }}>
            {order.commerce && (
              <View style={styles.productPriceContainer}>
                <Text style={[styles.productPrice, { color: textPriceColor }]}>
                  {`${order.commerce.name}`}
                </Text>
              </View>
            )}
            <View style={styles.productPriceContainer}>
              <Text style={[styles.productPrice, { color: textPriceColor }]}>
                {` $${Number(order.totalAmount).toFixed(2)}`}
              </Text>
            </View>
          </View>
          <View style={{ justifyContent: "center", alignItems: "center", flex: 1.5 }}>
            <TouchableOpacity style={[{
              minWidth: 50, maxWidth: 100, height: 32, borderRadius: 15, padding: 10, justifyContent: "center", alignItems: "center"
            }, { backgroundColor: currentVenue?.appColor ?? greenColor }]}>
              <Text style={{
                color: "#FFF",
                
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: 14,
                lineHeight: 20,
              }}>Ir a pedido</Text>
            </TouchableOpacity>
          </View> 
      </View>*/}
        {/* <View style={styles.buttonsContainer}>
          <Pressable style={styles.detailButton} onPress={() => onClickDetail(order.id)}>
            <Icon name="document-info" style={[styles.detailInfo, { color: currentVenue?.appColor ?? greenColor }]} />
            <Text style={[styles.detailButtonText, { color: currentVenue?.appColor ?? greenColor }]}>
              {t('detail')}
            </Text>
          </Pressable>
        </View> */}
      </View>
    </View>
  );
};
