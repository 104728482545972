import React from 'react';
import { View, Text, Image, TextInput, TouchableOpacity } from 'react-native';
import { Button } from 'react-native-elements';
import { SuccessModal } from '@double_point/rn-components';
import { useTranslation } from 'react-i18next';
import useIcon from 'hooks/useIcon';
import useLocalOrders from 'hooks/useLocalOrders';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import DiscountImageTypes from 'types/enums/DiscountImageTypes';
import DiscountScreenProps from 'types/screens/DiscountScreenProps';
import { storeDiscountOrder } from 'services/discountServices';
import App from 'constants/App';
import { styles } from 'styles/screens/discountScreen';
import { greenColor } from 'styles/global';
import regexConstants from 'constants/Regex';

export default ({ navigation }: DiscountScreenProps): JSX.Element => {
  const Icon = useIcon();
  const { t } = useTranslation();
  const { storeLocalOrder } = useLocalOrders();
  const { currentAuthData, setIsLoading, setModalizeData, currentVenue } = React.useContext(AppContext);
  const { currentDiscount } = React.useContext(OrderContext);
  const [userEmail, setUserEmail] = React.useState<string>(currentAuthData?.user.email ?? '');
  const [userPhone, setUserPhone] = React.useState<string>(currentAuthData?.user.phone ?? '');
  const discountImage =
    currentDiscount?.images.find((image) => (image.discountImageTypeId = DiscountImageTypes.banner))?.path ??
    App.defaultImage;

  const buttonDisabled = (): boolean => {
    if (!currentAuthData?.user.email && !regexConstants.email.test(userEmail)) return true;
    if (!currentAuthData?.user.phone && !regexConstants.phone.test(userPhone)) return true;
    return false;
  };

  if (!currentDiscount) return <></>;
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity
          style={styles.arrowleft}
          onPress={() => {
            navigation.reset({
              index: 0,
              routes: [{ name: 'HomeScreen' }],
            });
          }}
        >
          <Icon name="arrow-left" style={[styles.arrowleft, { color: currentVenue?.appColor ?? greenColor }]} />
        </TouchableOpacity>
        <Text style={[styles.textMenu, { color: currentVenue?.appColor ?? greenColor }]}>
          {`Descuento de ${currentDiscount.amount}%`}
        </Text>
      </View>
      <View style={styles.contentContainer}>
        <Image source={{ uri: discountImage }} style={styles.discountImage}></Image>
        <View style={styles.descriptionContainer}>
          <Text style={[styles.subTitleDescription, { color: currentVenue?.appColor ?? greenColor }]}>
            {currentDiscount?.description}
          </Text>
          <Text style={styles.subTitleDiscount}>Ingresa tu datos para poder obtener tu descuento:</Text>
        </View>
        <View style={styles.separator}></View>
        <View>
          <Text style={styles.subTitleDiscountPhone}>
            {t('enter-phone-number')} <Text style={styles.importantInputs}>*</Text>
          </Text>
          <TextInput
            keyboardType="number-pad"
            value={userPhone}
            onChangeText={setUserPhone}
            editable={!currentAuthData || !currentAuthData.user.email ? true : false}
            placeholder={t('example-phone-number')}
            style={[styles.phoneInput, { outlineStyle: 'none' }]}
            placeholderTextColor="#C4C4C4"
          />
          <Text style={styles.subTitleDiscountPhone}>
            {t('guest-email-shopping-label')} <Text style={styles.importantInputs}>*</Text>
          </Text>
          <TextInput
            keyboardType="email-address"
            value={userEmail}
            onChangeText={setUserEmail}
            editable={!currentAuthData || !currentAuthData.user.phone ? true : false}
            placeholder={t('guest-email-input-placeholder')}
            placeholderTextColor="#C4C4C4"
            style={[styles.emailAddressInput, { outlineStyle: 'none' }]}
          />
        </View>
      </View>
      <Button
        title={'Obtener Descuento'}
        type="clear"
        containerStyle={[styles.confirmPaymentBtnContainer, { outlineStyle: 'none' }]}
        buttonStyle={[styles.confirmPaymentBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
        titleStyle={styles.confirmPaymentBtnText}
        disabledStyle={[styles.confirmPaymentBtn, styles.confirmPaymentBtnDisabled]}
        disabledTitleStyle={styles.confirmPaymentBtnText}
        disabled={buttonDisabled()}
        onPress={async () => {
          if (!currentDiscount || !currentVenue) return;
          setIsLoading(true);
          const orderDiscount = await storeDiscountOrder(
            currentDiscount?.commerceId ?? '',
            currentVenue?.id,
            currentDiscount?.id,
            userPhone,
            currentVenue.localDateTimeIdentifier,
            !currentAuthData || !currentAuthData.user.email ? userEmail : '',
          );
          if (orderDiscount) {
            if (!orderDiscount.buyer) await storeLocalOrder(orderDiscount);
            setIsLoading(false);
            const successMessage = `${t('order-detail-number')} ${orderDiscount.id}. ${t(
              'order-detail-validation-code',
            )} ${orderDiscount.verificationCode}. ${t('order-detail-with-discount')} ${orderDiscount.commerce.name}`;
            setModalizeData({
              content: (
                <SuccessModal
                  isSuccess={true}
                  title={t('confirm-order-title')}
                  mainColor={currentVenue?.appColor ?? greenColor}
                  message={successMessage}
                  buttonText={t('return-to-home-after-discount')}
                  onConfirm={() => {
                    setModalizeData(null);
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'HomeScreen' }],
                    });
                  }}
                />
              ),
              closeOnOverlayTap: false,
            });
          } else {
            setIsLoading(false);
            const errorMessage = `${t('error-no-received-order-discount')}`;
            setModalizeData({
              content: (
                <SuccessModal
                  isSuccess={false}
                  title={t('order-confirm-error-title')}
                  mainColor={currentVenue?.appColor ?? greenColor}
                  message={errorMessage}
                  buttonText={t('try-to-get-discount-again')}
                  onConfirm={() => {
                    setModalizeData(null);
                  }}
                />
              ),
              closeOnOverlayTap: true,
            });
          }
        }}
      />
    </View>
  );
};
