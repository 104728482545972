import React from 'react';
import { Text, View, Image, TextInput } from 'react-native';
import { Button } from 'react-native-elements';
import { Formik } from 'formik';
import { SuccessModal } from '@double_point/rn-components';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import { calculateTotalAmount } from 'utils/orders';
import validationSchema from 'forms/schemas/creditCardForm';
import ChipCard from 'assets/images/chip.png';
import { storeMPPayment, storeMPPaymentPreviousCard } from 'services/paymentsService';
import PaymentDataMP from 'types/models/PaymentDataMP';
import { styles, grayTextColor } from 'styles/components/addCardForm';
import { greenColor } from 'styles/global';

export default (): JSX.Element => {
  const { currentVenue } = React.useContext(AppContext);
  const { selectedPaymentConfig, currentShoppingCart, currentOrder, selectedCard, setResultPayment } =
    React.useContext(OrderContext);
  const { setIsLoading, currentAuthData, setModalizeData } = React.useContext(AppContext);

  const initialValues = {
    holderName: selectedCard?.ccOwner ?? undefined,
    cardNumber: selectedCard ? `•••• •••• •••• ${selectedCard.ccLastFourDigits}` : undefined,
    expiration: selectedCard?.ccExpYear ? `${selectedCard.ccExpMonth}/${selectedCard.ccExpYear}` : undefined,
    cvv: undefined,
  };

  const getEmail = (): string | undefined => {
    if (currentAuthData?.user.email) return currentAuthData?.user.email;
    if (currentOrder?.guestEmail) return currentOrder.guestEmail;
    if (currentShoppingCart?.guestEmail) return currentShoppingCart.guestEmail;
  };

  const onSubmit = async (values: any) => {
    let success = false;

    const paymentData = getPaymentData(values);
    if (!paymentData) {
      return;
    }

    setIsLoading(true);
    const data = selectedCard ? await storeMPPaymentPreviousCard(paymentData) : await storeMPPayment(paymentData);
    console.log('data result', data);
    if (data) {
      success = true;
      setResultPayment({ success });
    } else {
      setModalizeData({
        content: (
          <SuccessModal
            isSuccess={success}
            title="Hemos tenido un inconveniento"
            mainColor={currentVenue?.appColor ?? greenColor}
            message="Hubo un error en el pago, intente más tarde"
            buttonText="Continuar"
            onConfirm={() => {
              setModalizeData(null);
            }}
          />
        ),
        closeOnOverlayTap: true,
      });
    }
    setIsLoading(false);
  };

  const getPaymentData = (values: any): PaymentDataMP | void => {
    const email = getEmail();
    if (
      !email ||
      !currentShoppingCart ||
      !currentOrder ||
      !selectedPaymentConfig ||
      !currentVenue ||
      !selectedPaymentConfig.mercadoPagoConfigId
    ) {
      return;
    }

    let paymentData: PaymentDataMP = {
      orderId: currentOrder.id,
      mpConfigId: selectedPaymentConfig.mercadoPagoConfigId,
      email,
      totalAmount: calculateTotalAmount(currentShoppingCart.detail),
    };

    if (selectedCard && selectedCard.ccId) {
      paymentData = {
        ...paymentData,
        creditCard: {
          ccId: parseInt(selectedCard.ccId),
          ccCode: values.cvv,
        },
      };
    } else {
      paymentData = {
        ...paymentData,
        creditCard: {
          ccNumber: values.cardNumber.replace(/ /g, ''),
          ccOwner: values.holderName,
          ccExpMonth: values.expiration.split('/')[0],
          ccExpYear: `20${values.expiration.split('/')[1]}`,
          ccCode: values.cvv,
        },
      };
    }

    return paymentData;
  };

  const expirationDatePattern = (value: string): string => {
    return value
      .replace(
        /[^0-9]/g,
        '', // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1', // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0', // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
        '$1/$2', // To handle 113 > 11/3
      );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={(values) => onSubmit(values)}>
      {({ handleSubmit, values, setFieldValue, errors, touched, isValid }) => (
        <View style={styles.containerCreditForm}>
          <View style={[styles.creditCard, { backgroundColor: currentVenue?.appColor ?? greenColor }]}>
            <View style={styles.triangle}></View>
            <View style={[styles.triangle, styles.secondTriangle]}></View>
            <View style={styles.cardHolder}>
              <Text style={styles.cardHolderText}>{values.holderName ?? 'Nombre y apellido'}</Text>
            </View>
            <View style={styles.cardNumber}>
              <Text style={styles.cardNumberText}>{values.cardNumber ?? '•••• •••• •••• ••••'}</Text>
            </View>
            <View style={styles.expiredDate}>
              <Text style={styles.expiredDateText}>{values.expiration ?? '10/24'}</Text>
            </View>
            <View style={styles.cvv}>
              <Text style={styles.cvvText}>CVV: {values.cvv}</Text>
            </View>
            <View style={styles.chip}>
              <Image style={styles.chipImage} source={ChipCard} />
            </View>
          </View>

          <TextInput
            keyboardType="numeric"
            placeholder="1111 2222 3333 4444"
            placeholderTextColor={grayTextColor}
            style={[styles.textInput, selectedCard && styles.disabledInput, { outlineStyle: 'none' }]}
            onChangeText={(value: string) =>
              setFieldValue(
                'cardNumber',
                value
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(.{4})/g, '$1 ')
                  .trim(),
              )
            }
            maxLength={19}
            value={values.cardNumber}
            editable={!selectedCard}
          />
          {errors.cardNumber && touched.cardNumber && (
            <Text style={styles.invalidFieldMessage}>*{errors.cardNumber}</Text>
          )}
          <TextInput
            placeholder="Nombre"
            placeholderTextColor={grayTextColor}
            style={[styles.textInput, selectedCard && styles.disabledInput, { outlineStyle: 'none' }]}
            onChangeText={(value) => setFieldValue('holderName', value.toUpperCase())}
            maxLength={26}
            value={values.holderName}
            editable={!selectedCard}
          />
          {errors.holderName && touched.holderName && (
            <Text style={styles.invalidFieldMessage}>*{errors.holderName}</Text>
          )}
          <View style={styles.expirationCvvContainer}>
            <TextInput
              style={[
                styles.textInput,
                styles.columnExpirationCvv,
                selectedCard && styles.disabledInput,
                { outlineStyle: 'none' },
              ]}
              keyboardType="numeric"
              placeholder="10/24"
              placeholderTextColor={grayTextColor}
              maxLength={5}
              onChangeText={(value: string) => setFieldValue('expiration', expirationDatePattern(value))}
              value={values.expiration}
              editable={!selectedCard}
            />
            <TextInput
              style={[styles.textInput, styles.columnExpirationCvv, { outlineStyle: 'none' }]}
              keyboardType="numeric"
              placeholder="cvv"
              placeholderTextColor={grayTextColor}
              maxLength={4}
              onChangeText={(value: string) => setFieldValue('cvv', value)}
              value={values.cvv}
            />
          </View>
          {errors.expiration && touched.expiration && (
            <Text style={styles.invalidFieldMessage}>*{errors.expiration}</Text>
          )}

          {errors.cvv && touched.cvv && <Text style={styles.invalidFieldMessage}>*{errors.cvv}</Text>}

          <Button
            onPress={() => handleSubmit()}
            disabled={!isValid}
            containerStyle={[{ outlineStyle: 'none' }]}
            buttonStyle={[styles.confirmBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
            title="Confirmar"
          />
        </View>
      )}
    </Formik>
  );
};
