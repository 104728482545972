enum VenueImageTypes {
  logo = 1,
  map = 2,
  Ticket = 3,
  VenueMap = 4,
  Sparx = 5,
  InstalationAppBanner = 6,
  banner = 7,
  TotemBackground = 8,
  TotemLowerBanner = 9,
  LogoForTinting = 12,
}

export default VenueImageTypes;
