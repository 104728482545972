import React from 'react';
import { ScrollView, Text, View, TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { ItemsCarousel, CardInformation, CarouselItemProps } from '@double_point/rn-components';
import EventImageTypes from 'types/enums/EventImageType';
import EventDetailProps from 'types/components/EventDetailProps';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import { styles } from 'styles/components/eventDetail';
import { greenColor } from 'styles/global';
import AppContext from 'contexts/AppContext';
import RedirectToTicket from './RedirectToTicket';
import LeavingModal from './LeavingModal';
import App from 'constants/App';

export default ({ event, onCloseButton }: EventDetailProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, setModalizeData } = React.useContext(AppContext);
  const bannerEvent = event.images.find((image) => image.eventImageType === EventImageTypes.banner);
  const detailImages = event.images.filter((image) => image.eventImageType === EventImageTypes.detail);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const venueLogoForTinting =
    currentVenue?.images.find((image) => image.venueImageId === VenueImageTypes.LogoForTinting)?.path ??
    App.defaultImage;
  const parseDetailImageToCarouselItem = (): CarouselItemProps[] => {
    return detailImages.map((detailImage, index) => ({
      id: index,
      image: detailImage.path,
    }));
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerTextTitle}>{t('events')}</Text>
        <TouchableOpacity
          style={[styles.headerCloseButton, { outlineStyle: 'none' }]}
          activeOpacity={0.75}
          onPress={() => {
            setModalizeData(null);
            if (onCloseButton) onCloseButton();
          }}
          hitSlop={styles.touchableHitSlop}
        >
          <Entypo name="cross" style={{ color: '#000000', fontSize: 30, height: '100%', width: '100%' }} />
        </TouchableOpacity>
      </View>
      <CardInformation
        id={event.id}
        image={bannerEvent?.path}
        title={event.name}
        date={event.eventStart}
        showDate={true}
        showTime={true}
        dateLanguage={currentVenue ? currentVenue.language : ''}
        isForArticleListing={false}
        venueLogoPath={venueLogoImage}
      />

      {event.detail ? (
        <>
          <View style={styles.descriptionTextTitleContainer}>
            <Text style={[styles.descriptionTextTitle, { color: currentVenue?.appColor ?? greenColor }]}>
              {t('description')}
            </Text>
          </View>

          <View
            style={[
              styles.descriptionContainer,
              (detailImages.length > 0 || event.detail) && styles.descriptionContainerBorder,
            ]}
          >
            <Text style={styles.description}>{event.detail.description}</Text>
          </View>
        </>
      ) : null}
      {detailImages.length ? (
        <View>
          <ItemsCarousel
            title={t('photos')}
            items={parseDetailImageToCarouselItem()}
            itemsSize={{ height: 135, width: 135 }}
            textColor={currentVenue?.appColor ?? greenColor}
            seeMoreText={t('see-more')}
          />
        </View>
      ) : null}
      {currentVenue?.ticketLink ? (
        <RedirectToTicket
          url={currentVenue.ticketLink}
          onPress={() => {
            setModalizeData({
              content: (
                <LeavingModal
                  url={currentVenue.ticketLink ?? ''}
                  showSecurityMessage={true}
                  venueLogoForTinting={venueLogoForTinting}
                />
              ),
              closeOnOverlayTap: false,
            });
          }}
        />
      ) : null}
    </ScrollView>
  );
};
