import React from 'react';
import { Text, TouchableOpacity, View, Platform } from 'react-native';
import { Icon as RNIcon } from 'react-native-elements';
import CommerceHeaderProps from 'types/components/CommerceHeaderProps';
import OrderContext from 'contexts/OrderContext';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/commerceHeader';
import { greenColor } from 'styles/global';

export default ({
  showBackButton,
  commerceName,
  commerceCode,
  onBack,
  navigation,
}: CommerceHeaderProps): JSX.Element => {
  const { currentVenue } = React.useContext(AppContext);
  const { currentShoppingCart } = React.useContext(OrderContext);

  return (
    <View style={styles.container}>
      {showBackButton && (
        <TouchableOpacity style={[styles.iconContainer, { outlineStyle: 'none' }]} onPress={onBack}>
          <RNIcon name="arrow-back" style={styles.icon} size={30} />
        </TouchableOpacity>
      )}
      <View style={styles.commerceNameContainer}>
        <Text style={styles.commerceNameText} numberOfLines={3} lineBreakMode={'tail'}>
          {commerceName}
        </Text>
      </View>
      <TouchableOpacity
        style={[styles.iconContainer, { outlineStyle: 'none' }]}
        onPress={() => {
          navigation.navigate(
            'ShoppingCartScreen',
            Platform.OS === 'web' && currentVenue && currentShoppingCart
              ? {
                  venueCode: currentVenue.code,
                  commerceCode: commerceCode,
                }
              : undefined,
          );
        }}
      >
        <View>
          <RNIcon name="shopping-cart" style={styles.icon} size={30} />
          {currentShoppingCart && currentShoppingCart.detail.length > 0 && (
            <View
              style={[
                styles.shoppingCartLength,
                {
                  backgroundColor: currentVenue?.appColor ?? greenColor,
                  borderColor: currentVenue?.appColor ?? greenColor,
                },
              ]}
            >
              <Text style={styles.shoppingCartLengthNumber}>
                {currentShoppingCart.detail.length > 9 ? '9+' : currentShoppingCart?.detail.length}
              </Text>
            </View>
          )}
        </View>
      </TouchableOpacity>
    </View>
  );
};
