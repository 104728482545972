import React from 'react';
import IzipayFormProps from 'types/components/IzipayFormProps';
import OrderContext from 'contexts/OrderContext';
import { storeIzipayPayment } from 'services/paymentsService';
import { SDK_IZIPAY } from '@env';

export default ({
  izipayPaymentData,
  orderId,
  token,
  setIsLoading,
  setResultPayment,
}: IzipayFormProps): JSX.Element => {
  const { setIzipayTransactionId } = React.useContext(OrderContext);

  const callbackResponsePayment = (response: any) => {
    window.parent.postMessage(JSON.stringify(response));
  };

  const html = `<html>
  <head>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  <title>Checkout Izipay</title>
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <script src="${SDK_IZIPAY}"></script>
  </head>
  <body id="root" onload="loadForm()">
    <div id="iframe-payment-izipay"></div>
  </body>
  <script>
  function loadForm() {
    let izipayInstance;
    try {
      izipayInstance = new Izipay({ config: ${JSON.stringify(izipayPaymentData)} });
    } catch (error) {
      console.log(error.message, error.Errors, error.date);
    }
    
    if (izipayInstance) {
      try {
        izipayInstance.LoadForm({
            authorization: "${token}",
            keyRSA: "RSA",
            callbackResponse: ${callbackResponsePayment}
          });
      } catch (error) {
        console.log(error.message, error.Errors, error.date);
      }
    }
  }
  </script>
</body>
  </html>`;

  React.useEffect(() => {
    const onMessage = async (event: any) => {
      // identify correctness of message from iframe
      if (typeof event.data === 'string') {
        const responseData = JSON.parse(event.data);
        const success = responseData.code === '00' ? true : false;
        if (success) {
          setIzipayTransactionId(null);
          await storeIzipayPayment(responseData, orderId);
        }
        setResultPayment({ success, response: responseData.message });
      }
    };

    window.addEventListener('message', onMessage);

    return () => window.removeEventListener('message', onMessage);
  }, []);

  // Se usa css instile porque es un html element
  return (
    <iframe
      style={{ border: 0, borderWidth: 0 }}
      srcDoc={html}
      height="100%"
      width="100%"
      onLoad={() => {
        setIsLoading(false);
      }}
    />
  );
};
