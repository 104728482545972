import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  addProductContainer: {
    width: 603,
    height: 106,
    alignSelf: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
  },
  addQuantityBtn: {
    borderRadius: 100,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  quantityAndPriceContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityText: {
    paddingTop: 2,
    width: 23,
    height: 23,
    borderWidth: 1,
    borderRadius: 11,
    textAlign: 'center',
    fontSize: 12,
    borderColor: '#FFF',
    color: '#FFF',
  },
  addToCartContainer: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addToCartAndPriceText: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: '500',
  },
});
