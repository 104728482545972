import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  bannerHeaderImage: {
    overflow: 'hidden',
    alignSelf: 'center',
    width: '100%',
    height: 240,
  },
});
