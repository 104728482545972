import axios from 'axios';
import axiosInstance from './axios';
import AuthData from 'types/models/AuthData';
import User from 'types/models/User';
import UserPartnerProfile from 'types/models/UserPartnerProfile';
import App from 'constants/App';
import { BASE_URL } from '@env';

export const getAuthData = async (token: string): Promise<User | null> => {
  try {
    const { data } = await axios.get(`${BASE_URL}auth`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getGoogleUserData = async (accessToken: string): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/google',
      data: {
        accessToken,
        appName: App.appName,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getFacebookUserData = async (accessToken: string): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/facebook',
      data: {
        accessToken,
        appName: App.appName,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const loginEmail = async (email: string): Promise<boolean> => {
  try {
    await axiosInstance({
      method: 'POST',
      url: '/login/email',
      data: {
        email,
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const verifyEmailCode = async (email: string, verificationCode: string): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/email/confirm',
      data: {
        verificationCode,
        email,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const loginWithPhone = async (phoneNumber: string, venueId: string): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/phone',
      data: {
        phoneNumber,
        venueId,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const loginPartner = async (partner: string, entityIdentificator: number): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/partner',
      data: {
        partner,
        entityIdentificator,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const loginApple = async (
  appleUserUniqueId: string,
  appleUserEmail?: string | null,
): Promise<AuthData | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: '/login/apple',
      data: {
        appleUserUniqueId,
        appleUserEmail,
      },
    });
    return parseAuthData(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getUser = async (userId: string, venueId: string): Promise<User | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `users/${userId}/${venueId}/get-user`,
    });
    return parseUser(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseAuthData = (data: any): AuthData => {
  return {
    token: data.token,
    user: parseUser(data.user),
  };
};

const parseUser = (data: any): User => {
  return {
    id: data.id,
    email: data.email,
    name: data.name,
    roleId: data.role_id,
    googleId: data.googleId,
    facebookId: data.googleId,
    updatedAt: data.updated_at,
    accountBalance: data.account_balance ?? undefined,
    isPartner: data.is_partner,
    dni: data.dni ?? undefined,
    partnerProfile: data.is_partner ? parseUserPartnerProfile(data.partner_profile) : undefined,
    phone: data.phone ?? undefined,
  };
};

const parseUserPartnerProfile = (data: any): UserPartnerProfile => {
  return {
    id: data.id,
    partnerImageUrl: data.partner_image_url,
    partnerQrUrl: data.partner_qr_url,
    lastMembershipPeriodPaid: data.last_membership_period_paid,
  };
};
