import React from 'react';
import { View, Platform } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import ProductItem from './ProductItem';
import ShoppingCartModalProps from 'types/components/ShoppingCartModal';
import { styles } from 'styles/components/shoppingCartModal';
import { greenColor } from 'styles/global';

export default ({ commerce, product, shoppingCart, navigation }: ShoppingCartModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { setModalizeData, currentVenue } = React.useContext(AppContext);
  const [quantity, setQuantity] = React.useState<number>(1);

  const onChangeQuantity = (id: string, quantity: number) => {
    if (!shoppingCart) return;
    const detailItem = shoppingCart.detail.find((item) => item.product.id === product.id);
    if (detailItem) {
      detailItem.quantity = quantity;
    }
  };

  React.useEffect(() => {
    if (shoppingCart) {
      const detailItem = shoppingCart.detail.find((item) => item.product.id === product.id);
      if (detailItem) {
        setQuantity(detailItem.quantity);
      } else {
        setQuantity(1);
      }
    }
  }, [shoppingCart]);

  return (
    <>
      <Card.Title style={styles.headerText}>{t('added-to-cart')}</Card.Title>
      <Card.Divider />
      <View style={styles.productItemContainer}>
        <ProductItem
          product={product}
          textPriceColor="#89909b"
          initialQuantity={quantity}
          onChangeQuantity={onChangeQuantity}
        ></ProductItem>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={t('proceed-to-payment')}
          type="clear"
          containerStyle={[
            styles.button,
            { backgroundColor: currentVenue?.appColor ?? greenColor },
            { outlineStyle: 'none' },
          ]}
          titleStyle={styles.confirmPaymentBtnText}
          onPress={async () => {
            setModalizeData(null);
            navigation.navigate(
              'ShoppingCartScreen',
              Platform.OS === 'web'
                ? {
                    venueCode: currentVenue?.code,
                    commerceCode: commerce.code,
                  }
                : undefined,
            );
          }}
          disabledTitleStyle={styles.confirmPaymentBtnText}
          disabledStyle={styles.buttonDisabled}
        ></Button>
        <Button
          title={`${t('continue-shopping-at')} ${commerce.name}`}
          containerStyle={[styles.button, { outlineStyle: 'none' }]}
          titleStyle={{ color: currentVenue?.appColor ?? greenColor }}
          type="clear"
          onPress={() => {
            setModalizeData(null);
            navigation.reset({
              index: 1,
              routes: [
                { name: 'HomeScreen' },
                {
                  name: 'CommerceScreen',
                  params:
                    Platform.OS === 'web'
                      ? {
                          venueCode: currentVenue?.code,
                          commerceCode: commerce.code,
                        }
                      : undefined,
                },
              ],
            });
          }}
        ></Button>
      </View>
    </>
  );
};
