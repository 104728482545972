import React from 'react';
import { Platform } from 'react-native';
import { CommonActions, useNavigation } from '@react-navigation/native';
import OrderContext from 'contexts/OrderContext';
import AppContext from 'contexts/AppContext';
import useLocalOrders from './useLocalOrders';
import UseChangePayment from 'types/hooks/UseChangePayment';
import ResultPayment from 'types/models/ResultPayment';
import OrderStatuses from 'types/enums/OrderStatuses';
import Order from 'types/models/Order';
import { saveBuyersBenefit } from 'services/ordersService';

export default (): UseChangePayment => {
  const { setCurrentShoppingCart, currentShoppingCart, currentOrder, resultPayment, currentBenefit, currentCommerce } =
    React.useContext(OrderContext);
  const { setSelectedOrder, setNewlyCreatedOrder, currentAuthData, cloverConnected, currentVenue } =
    React.useContext(AppContext);
  const { updateLocalOrderStatus } = useLocalOrders();
  const navigation = useNavigation();

  React.useEffect(() => {
    const saveBuyersBenefitByResultPayment = async () => {
      if (!currentBenefit || !currentOrder || !resultPayment?.success) return;
      const email = currentAuthData?.user.email ?? currentShoppingCart?.guestEmail;
      if (email) {
        await saveBuyersBenefit(email, currentBenefit.id, currentOrder.id);
      }
    };
    saveBuyersBenefitByResultPayment();
  }, [resultPayment]);

  const changeResultOrder = (
    order: Order,
    isCash: boolean,
    isCloverPayment?: boolean,
    mpCheckoutRedirectionVenueCode?: string,
  ): void => {
    if (!isCash) {
      order.orderStatusId = OrderStatuses.paymentAccepted;
      updateLocalOrderStatus(OrderStatuses.paymentAccepted, order);
    }
    setSelectedOrder(order);
    setNewlyCreatedOrder(true);
    if (!isCloverPayment) {
      redirectToOrdersDetailScreen(order.id, mpCheckoutRedirectionVenueCode);
    }
  };

  const changeResultPayment = (
    resultPayment: ResultPayment,
    isCloverPayment?: boolean,
    mpCheckoutRedirectionVenueCode?: string,
  ): void => {
    if (!currentShoppingCart || !currentOrder) return;
    if (resultPayment) {
      if (resultPayment.success) {
        setCurrentShoppingCart({ ...currentShoppingCart, paid: true });
        changeResultOrder(currentOrder, false, isCloverPayment, mpCheckoutRedirectionVenueCode);
      } else {
        navigation.reset({
          index: 1,
          routes: [
            { name: cloverConnected ? 'CommerceScreen' : 'HomeScreen' },
            {
              name: 'ShoppingCartScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: mpCheckoutRedirectionVenueCode ?? currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                    }
                  : undefined,
            },
          ],
        });
      }
    }
  };

  const redirectToOrdersDetailScreen = (orderNumber: number, mpCheckoutRedirectionVenueCode?: string) => {
    navigation.dispatch({
      ...CommonActions.reset({
        index: 1,
        routes: [
          {
            name: 'Home',
            state: {
              routes: [
                {
                  name: 'HomeScreen',
                },
              ],
            },
          },
          {
            name: 'Orders',
            state: {
              routes: [
                {
                  name: 'OrdersDetailScreen',
                  params: {
                    ...(Platform.OS === 'web' && {
                      venueCode: mpCheckoutRedirectionVenueCode ?? currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                      orderNumber: `${orderNumber}`,
                    }),
                    showModal: true,
                  },
                },
              ],
            },
          },
        ],
      }),
    });
  };

  return { changeResultOrder, changeResultPayment, redirectToOrdersDetailScreen };
};
