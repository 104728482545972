import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  paymentMethod: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 15,
    flex: 1,
  },
  paymentMethodColumnWeb: {
    height: 75,
    flex: 1,
    flexDirection: 'column',
  },
  paymentMethodColumnMobile: {
    marginLeft: 15,
    flex: 1,
    flexDirection: 'column',
  },
  paymentMethodInfo: {
    flex: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cashPaymentMethodLogo: {
    width: 70,
    height: 70,
    borderRadius: 100,
    backgroundColor: '#89909B',
    alignSelf: 'center',
  },
  cashPaymentMethodIcon: {
    fontSize: 18,
    alignSelf: 'center',
    color: '#FFF',
    marginTop: 25,
  },
  cashPaymentMethodInfo: {
    flexDirection: 'column',
    flex: 1,
  },
  paymentMethodName: {
    fontSize: 16,
  },
  paymentMethodExchange: {
    color: '#89909b',
    fontSize: 18,
  },
  paymentMethodDescription: {
    color: '#89909b',
    fontSize: 14,
  },
  radioOption: {
    width: 35,
    marginTop: 15,
  },
  addCardIcon: {
    marginTop: 22,
    fontSize: 35,
    alignSelf: 'center',
  },
  trashIcon: {
    color: '#ff5050',
    fontSize: 30,
  },
  mercadoPagoIcon: {
    height: 35,
    width: 45,
    alignSelf: 'center',
    marginTop: 18.5,
  },
});
