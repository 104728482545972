import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  productInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 15,
    backgroundColor: '#FFFFFF',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5,
    borderRadius: 10,
  },
  productInfoColumn: {
    flex: 1,
    flexDirection: 'column',
  },
  nameColumn: {
    flex: 2,
    paddingLeft: 10,
    paddingTop: 5,
  },
  productImage: {
    marginTop: 6,
    width: 100,
    height: 100,
    borderRadius: 10,
    alignSelf: 'center',
  },
  productName: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 24,
  },
  productPriceContainer: {
    top: 5,
    alignSelf: 'flex-start',
    bottom: 0,
  },
  productPrice: {
    fontSize: 17.5,
    color: '#89909b',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    top: 20,
  },
  detailButton: {
    borderRadius: 25,
    backgroundColor: '#F3F3F3',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  detailButtonText: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 16,
    fontSize: 14,
    letterSpacing: 1.25,
    alignItems: 'center',
  },
  cancelButton: {
    marginLeft: 8,
    borderRadius: 25,
    backgroundColor: '#F3F3F3',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
  cancelButtonText: {
    display: 'flex',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 14,
    lineHeight: 16,
    letterSpacing: 1.25,
    alignItems: 'center',
    color: '#FF5050',
  },
  detailInfo: {
    fontSize: 14,
    marginRight: 10,
  },
  statusContainer: {
    display: 'flex',
    alignSelf: 'flex-start',
    top: 5,
    bottom: 0,
  },
  statusText: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: 17.5,
    textAlign: 'left',
    color: '#000000',
    textTransform: 'uppercase',
  },
});
