import React from 'react';
import { Text, View, Platform } from 'react-native';
import { Button, Card } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/orderPendingMessage';
import OrderPendingMessageProps from 'types/components/OrderPendingMessageProps';
import { greenColor } from 'styles/global';

export default ({ onRestart, pendingShoppingCartCommerceCode }: OrderPendingMessageProps): JSX.Element => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { setModalizeData, currentVenue } = React.useContext(AppContext);

  return (
    <>
      <Card.Title style={styles.legendText}>{t('purchase pending payment-title')}</Card.Title>
      <Card.Divider />
      <View>
        <Text style={styles.message}>{t('purchase pending payment-message')}</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={t('finalize-purchase')}
          type="clear"
          containerStyle={[{ outlineStyle: 'none' }]}
          buttonStyle={[styles.confirmPaymentBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
          titleStyle={styles.confirmPaymentBtnText}
          onPress={() => {
            setModalizeData(null);
            navigation.navigate(
              'ShoppingCartScreen',
              Platform.OS === 'web'
                ? {
                    venueCode: currentVenue?.code,
                    commerceCode: pendingShoppingCartCommerceCode,
                  }
                : undefined,
            );
          }}
        ></Button>
        <Button
          title={t('make-a-new-purchase')}
          titleStyle={{ color: currentVenue?.appColor ?? greenColor }}
          containerStyle={[styles.newOrderBtn, { outlineStyle: 'none' }]}
          type="clear"
          onPress={() => {
            onRestart();
          }}
        ></Button>
      </View>
    </>
  );
};
