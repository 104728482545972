import React from 'react';
import { View, Text, TouchableOpacity, useWindowDimensions, Image, Platform } from 'react-native';
import { useNavigation, getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import * as AppleAuthentication from 'expo-apple-authentication';
import { Ionicons } from '@expo/vector-icons';
import {
  LoginModal,
  LoginWithEmailModal,
  SuccessModal,
  LoginModalTextProps,
  LoginWithEmailModalTextProps,
} from '@double_point/rn-components';
import {
  useGoogleAuth,
  useFacebookAuth,
  GoogleAuthRequestConfig,
  FacebookAuthRequestConfig,
} from '@double_point/rn-utils';
import VenueList from 'components/VenueList';
import LoginWithPhoneNumberModal from './LoginWithPhoneNumberModal';
import LoginPartnerModal from './LoginPartnerModal';
import useIcon from 'hooks/useIcon';
import useVenues from 'hooks/useVenues';
import useChangeAuthData from 'hooks/useChangeAuthData';
import ToggleHeaderContext from 'contexts/ToggleHeaderContext';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import {
  loginEmail,
  verifyEmailCode,
  getGoogleUserData,
  getFacebookUserData,
  loginWithPhone,
  loginPartner,
  loginApple,
} from 'services/authService';
import storageConstants from 'constants/Storage';
import App from 'constants/App';
import {
  GOOGLE_ENABLED,
  FACEBOOK_ENABLED,
  TERMS_AND_CONDITIONS,
  GOOGLE_EXPO_CLIENT_ID,
  GOOGLE_WEB_CLIENT_ID,
  FACEBOOK_WEB_CLIENT_ID,
  GOOGLE_ANDROID_CLIENT_ID,
  GOOGLE_IOS_CLIENT_ID,
} from '@env';
import { styles, containerTabsHeight } from 'styles/components/appHeader';
import { greenColor } from 'styles/global';
import VenueBannerHeader from './VenueBannerHeader';

export default (): JSX.Element => {
  const { t } = useTranslation();
  const Icon = useIcon();
  const navigation = useNavigation();
  const route = useRoute();
  const { headerIsMinimized, setHeaderIsMinimized } = React.useContext(ToggleHeaderContext);
  const {
    currentAuthData,
    currentVenue,
    showVenues,
    setShowVenues,
    setModalizeData,
    pairedWithClover,
    redirectionItems,
    wasVenueSelectedManually,
    currentRouteName,
  } = React.useContext(AppContext);
  const { currentShoppingCart } = React.useContext(OrderContext);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const venueBannerImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.banner)?.path ?? App.defaultImage;
  const [isAppleAuthAvailable, setIsAppleAuthAvailable] = React.useState<boolean>(false);
  const { storeCurrentAuthData, deleteCurrentAuthData } = useChangeAuthData();
  const { venues, fetchVenues } = useVenues();
  const { height } = useWindowDimensions();
  const routes = navigation.getState().routes;
  const currentRoute = routes[routes.length - 1];
  const nestedRoute = currentRoute.state?.routes[routes.length - 1];
  const deeplyNestedRoutes = nestedRoute?.state?.routes;
  const commerceScreenInRouteStack = deeplyNestedRoutes?.map((route) => route.name === 'CommerceScreen');
  const homeScreenInRouteStack = deeplyNestedRoutes?.map((route) => route.name === 'HomeScreen');
  const snsScreenInRouteStack = deeplyNestedRoutes?.map((route) => route.name === 'SNSScreen');
  const paymentScreensInRouteStack = deeplyNestedRoutes?.map((route) => {
    switch (route.name) {
      case 'PaymentFDScreen':
        return true;
      case 'AddCardScreen':
        return true;
      case 'PaymentMPPointScreen':
        return true;
      case 'PaymentCloverScreen':
        return true;
      default:
        return false;
    }
  });
  const findCommerceScreenInStack = commerceScreenInRouteStack?.find((stack) => stack === true);
  const findPaymentScreensInStack = paymentScreensInRouteStack?.find((stack) => stack === true);
  const findSNSScreenInStack = snsScreenInRouteStack?.find((stack) => stack === true);
  const homeScreenAsFirstScreen = homeScreenInRouteStack?.length === 1;
  const ordersStackFocused = getFocusedRouteNameFromRoute(route) === 'Orders';
  const eventsStackFocused = getFocusedRouteNameFromRoute(route) === 'Events';
  const articlesStackFocused = getFocusedRouteNameFromRoute(route) === 'Articles';
  const mapStackFocused = getFocusedRouteNameFromRoute(route) === 'Map';
  const params: any = route.params;
  const shouldInsertParamsOnShoppingScreenUrl =
    Platform.OS === 'web' && currentVenue && currentShoppingCart && currentShoppingCart.detail.length > 0;
  const headerIsBeingRenderedOnHomeScreen = currentRouteName === 'HomeScreen';
  // Login Config
  const googleAuthRequestConfig: GoogleAuthRequestConfig = {
    webClientId: GOOGLE_WEB_CLIENT_ID,
    expoClientId: GOOGLE_EXPO_CLIENT_ID,
    androidClientId: GOOGLE_ANDROID_CLIENT_ID,
    iosClientId: GOOGLE_IOS_CLIENT_ID,
  };

  const facebookAuthRequestConfig: FacebookAuthRequestConfig = {
    webClientId: FACEBOOK_WEB_CLIENT_ID,
    expoClientId: FACEBOOK_WEB_CLIENT_ID,
  };

  const { googleSignInAuthResult, googlePromptAsync } = useGoogleAuth(googleAuthRequestConfig);
  const { facebookSignInAuthResult, facebookPromptAsync } = useFacebookAuth(facebookAuthRequestConfig);

  const onGoogleResponse = async (accessToken: string): Promise<void> => {
    const authData = await getGoogleUserData(accessToken);
    if (authData) {
      await storeCurrentAuthData(authData);
      setModalizeData(null);
    }
  };

  const onFacebookResponse = async (accessToken: string): Promise<void> => {
    const authData = await getFacebookUserData(accessToken);
    if (authData) {
      await storeCurrentAuthData(authData);
      setModalizeData(null);
    }
  };

  const onLoginWithAppleDevice = async (user: string, email: string | null) => {
    const getUserWithAppleLogin = await loginApple(user, email);
    if (getUserWithAppleLogin) {
      await storeCurrentAuthData(getUserWithAppleLogin);
      setModalizeData(null);
    }
  };

  React.useEffect(() => {
    if (!googleSignInAuthResult) return;
    onGoogleResponse(googleSignInAuthResult.authentication.accessToken);
  }, [googleSignInAuthResult]);

  React.useEffect(() => {
    if (!facebookSignInAuthResult) return;
    onFacebookResponse(facebookSignInAuthResult.authentication.accessToken);
  }, [facebookSignInAuthResult]);

  React.useEffect(() => {
    const getAppleAuthAvailability = async () => {
      const isAppleAuthAvailable = await AppleAuthentication.isAvailableAsync();
      if (isAppleAuthAvailable) {
        setIsAppleAuthAvailable(isAppleAuthAvailable);
      }
    };
    getAppleAuthAvailability();
  }, []);

  const loginModalTexts: LoginModalTextProps = {
    headerTitle: t('login-modal-header-title'),
    guestButton: t('guest-button'),
    emailButton: t('email-button'),
    phoneButton: t('login-with-cellphone'),
    partnerButton: t('partner-button'),
    facebookButton: t('facebook-button'),
    googleButton: t('google-button'),
    termsAndConditionsTitle: t('terms'),
  };

  const loginModalWithEmailTexts: LoginWithEmailModalTextProps = {
    headerEmailTitle: t('login-email-modal-header-title'),
    emailInputPlaceholder: t('login-email-input-placeholder'),
    invalidEmail: t('invalid-email-text'),
    successfullyForwardedEmail: t('successfullyf-orwarded-email'),
    verificationTitle: t('verification-title'),
    wrongCode: t('wrong-code'),
    confirmCode: t('confirm-code'),
    code: t('code'),
    email: t('email'),
    continueAsGuest: t('guest-button'),
    reSendCode: t('re-send-code'),
    changeEmail: t('change-email'),
  };

  const onAbort = (): void => {
    setModalizeData(null);
  };

  const sendEmail = async (email: string): Promise<boolean> => {
    const response = await loginEmail(email);
    if (response) {
      return true;
    } else {
      setModalizeData({
        content: (
          <SuccessModal
            isSuccess={false}
            title={t('error-login-email')}
            mainColor={currentVenue?.appColor ?? greenColor}
            message={t('constants.default-error-message')}
            buttonText={t('return-to-home')}
            onConfirm={() => {
              setModalizeData(null);
              navigation.navigate('Home');
            }}
          />
        ),
        closeOnOverlayTap: false,
      });
      return false;
    }
  };

  const sendPartnerDocument = async (partnerDocument: string): Promise<boolean> => {
    if (!currentVenue?.entityIdentificator) return false;
    const authData = await loginPartner(partnerDocument, currentVenue.entityIdentificator);
    if (authData) {
      await storeCurrentAuthData(authData);
      setModalizeData(null);
      return true;
    } else {
      return false;
    }
  };

  const sendValidation = async (email: string, verificationCode: string): Promise<boolean> => {
    const authData = await verifyEmailCode(email, verificationCode);
    if (authData) {
      await storeCurrentAuthData(authData);
      setModalizeData(null);
      return true;
    } else {
      return false;
    }
  };

  const sendPhoneNumber = async (phoneNumber: string): Promise<boolean> => {
    if (!currentVenue) return false;
    const authData = await loginWithPhone(phoneNumber, currentVenue.id);
    if (authData) {
      await storeCurrentAuthData(authData);
      setModalizeData(null);
      return true;
    } else {
      return false;
    }
  };

  const onLoginWithEmail = (): void => {
    setModalizeData({
      content: (
        <LoginWithEmailModal
          loginEmailStorageKey={storageConstants.loginEmail}
          sendEmail={sendEmail}
          sendValidation={sendValidation}
          onAbort={onAbort}
          loginEmailColor={currentVenue?.appColor ?? greenColor}
          localizedTexts={loginModalWithEmailTexts}
        />
      ),
      closeOnOverlayTap: false,
    });
  };

  const onLoginWithPhoneNumber = (): void => {
    setModalizeData({
      content: (
        <LoginWithPhoneNumberModal
          onAbort={onAbort}
          loginPhoneNumberColor={currentVenue?.appColor ?? greenColor}
          sendPhoneNumber={sendPhoneNumber}
        />
      ),
      closeOnOverlayTap: false,
    });
  };

  const onLoginWithPartnerNumber = (): void => {
    setModalizeData({
      content: (
        <LoginPartnerModal
          sendPartnerDocument={sendPartnerDocument}
          onAbort={onAbort}
          loginPartnerColor={currentVenue?.appColor ?? greenColor}
        />
      ),
      closeOnOverlayTap: false,
    });
  };

  // End Login Config

  React.useEffect(() => {
    if (showVenues) {
      fetchVenues();
    }
  }, [showVenues]);

  const onClick = () => {
    setShowVenues(!showVenues);
  };

  const headerDisabled = (): boolean => {
    if (
      findCommerceScreenInStack &&
      !pairedWithClover &&
      !currentAuthData &&
      redirectionItems &&
      !redirectionItems?.isForTotemDisplay
    ) {
      return true;
    }
    if (findCommerceScreenInStack && !pairedWithClover && !currentAuthData) return false;
    if (findCommerceScreenInStack && currentAuthData && pairedWithClover) return false;
    if (findCommerceScreenInStack && currentAuthData && !pairedWithClover) return false;
    if (!pairedWithClover) return false;
    if (!findCommerceScreenInStack) return false;
    return true;
  };

  const userWithSession = () => {
    if (!currentAuthData) return;
    return (
      <View style={styles.userWithSessionContainer}>
        <View style={styles.userLoginContainer}>
          <TouchableOpacity
            style={{ outlineStyle: 'none' }}
            onPress={async () => {
              await deleteCurrentAuthData();
            }}
          >
            <View style={[styles.contentButton__button]}>
              <Icon name="user-times" style={styles.contentButton__icon} size={Platform.OS === 'web' ? 22 : 20} />
              {/*<Text numberOfLines={1} style={styles.contentButton__text}>
                  {t('logout')}
            </Text>*/}
            </View>
          </TouchableOpacity>
          {headerIsBeingRenderedOnHomeScreen && (
            <Icon
              name="shopping-cart"
              style={styles.shoppingCartIcon}
              onPress={() => {
                navigation.navigate(
                  'ShoppingCartScreen',
                  shouldInsertParamsOnShoppingScreenUrl
                    ? {
                        venueCode: currentVenue.code,
                        commerceCode: currentShoppingCart.commerce.code,
                      }
                    : undefined,
                );
              }}
            />
          )}
        </View>
      </View>
    );
  };

  const userWithoutSession = () => {
    return (
      <View style={styles.userWithoutSessionContainer}>
        {/*currentVenue?.paymentConfig?.balanceEnabled && (
          <Text
            style={[
              styles.accountBalanceLoginMessage,
              {
                color: headerIsMinimized === false ? 'white' : currentVenue?.appColor ?? greenColor,
              },
            ]}
          >
            {t('account-balance-login-message')}
          </Text>
          )*/}
        <View style={styles.loginBtnColumn}>
          <TouchableOpacity
            style={{ outlineStyle: 'none' }}
            onPress={() => {
              setModalizeData({
                content: (
                  <>
                    <LoginModal
                      onAbort={onAbort}
                      googlePromptAsync={googlePromptAsync}
                      facebookPromptAsync={facebookPromptAsync}
                      onLoginWithEmail={onLoginWithEmail}
                      onLoginWithPhoneNumber={onLoginWithPhoneNumber}
                      onLoginWithPartnerNumber={onLoginWithPartnerNumber}
                      onLoginWithAppleDevice={onLoginWithAppleDevice}
                      partnerLoginButtonColor={
                        currentVenue?.useDifferentIconSet ? undefined : currentVenue?.appColor ?? greenColor
                      }
                      venueIcon={venueLogoImage}
                      guestEnabled={true}
                      emailEnabled={true}
                      venueMainColor={currentVenue?.useDifferentIconSet ? currentVenue?.appColor : undefined}
                      venueSecondaryColor={currentVenue?.appSecondaryColor}
                      useDifferentIconSet={currentVenue?.useDifferentIconSet}
                      phoneEnabled={currentVenue?.paymentConfig?.balanceEnabled ?? false}
                      partnerEnabled={currentVenue?.entityIdentificator ? true : false}
                      facebookEnabled={FACEBOOK_ENABLED === 'true'}
                      googleEnabled={GOOGLE_ENABLED === 'true'}
                      appleLoginEnabled={isAppleAuthAvailable}
                      termsAndConditionsLink={TERMS_AND_CONDITIONS}
                      localizedTexts={loginModalTexts}
                    />
                  </>
                ),
                closeOnOverlayTap: true,
              });
            }}
          >
            <View
              style={[
                styles.contentButton__button,
                currentVenue?.useDifferentIconSet && { backgroundColor: currentVenue.appSecondaryColor },
              ]}
            >
              {currentVenue?.useDifferentIconSet ? (
                <Ionicons
                  name="person-circle"
                  size={Platform.OS === 'web' ? 32 : 30}
                  style={[styles.profileIconForDifferentSet, { color: currentVenue.appColor }]}
                />
              ) : (
                <Icon name="user-check" style={styles.contentButton__icon} size={Platform.OS === 'web' ? 22 : 20} />
              )}
              {/*<Text style={styles.contentButton__text}>{t('login')}</Text>*/}
            </View>
          </TouchableOpacity>
          {headerIsBeingRenderedOnHomeScreen && (
            <>
              {currentVenue?.useDifferentIconSet ? (
                <View
                  style={[
                    styles.containerForShoppingCartOfDifferentSet,
                    { backgroundColor: currentVenue.appSecondaryColor },
                  ]}
                >
                  <Icon
                    name={'shopping-cart1'}
                    style={[styles.shoppingCartIconForDifferentSet, { color: currentVenue?.appColor }]}
                    onPress={() => {
                      navigation.navigate(
                        'ShoppingCartScreen',
                        shouldInsertParamsOnShoppingScreenUrl
                          ? {
                              venueCode: currentVenue.code,
                              commerceCode: currentShoppingCart.commerce.code,
                            }
                          : undefined,
                      );
                    }}
                  />
                </View>
              ) : (
                <Icon
                  name={'shopping-cart'}
                  style={[styles.shoppingCartIcon]}
                  onPress={() => {
                    navigation.navigate(
                      'ShoppingCartScreen',
                      shouldInsertParamsOnShoppingScreenUrl
                        ? {
                            venueCode: currentVenue.code,
                            commerceCode: currentShoppingCart.commerce.code,
                          }
                        : undefined,
                    );
                  }}
                />
              )}
            </>
          )}
        </View>
      </View>
    );
  };

  if (headerDisabled()) {
    return <></>;
  }

  return (
    <>
      {currentVenue &&
      !ordersStackFocused &&
      !eventsStackFocused &&
      !articlesStackFocused &&
      !mapStackFocused &&
      !findPaymentScreensInStack &&
      !findSNSScreenInStack ? (
        <>
          {showVenues && (
            <View style={{ backgroundColor: currentVenue?.appColor ?? greenColor, height: height }}>
              <VenueList venues={venues} />
            </View>
          )}
          <VenueBannerHeader venueBanner={venueBannerImage}>
            <View style={styles.containerVenueSelectionHome}>
              <View style={styles.venueLogoContainer}>
                <Image source={{ uri: venueLogoImage }} style={styles.logoHeaderImage}></Image>
              </View>
              {!redirectionItems && (
                <View style={styles.venueSelectedContainer}>
                  {(wasVenueSelectedManually || params === undefined) && (
                    <Text style={styles.shake}>{t('header_selected_venue')}</Text>
                  )}
                  <TouchableOpacity
                    onPress={() => {
                      if (wasVenueSelectedManually || params === undefined) {
                        onClick();
                      }
                    }}
                    style={[styles.touchableVenue, { outlineStyle: 'none' }]}
                  >
                    {(wasVenueSelectedManually || params === undefined) && (
                      <Icon name="angle-down" style={styles.currentVenue__icon} />
                    )}
                    <Text
                      style={[
                        styles.currentVenue__name,
                        !wasVenueSelectedManually && {
                          fontSize: params && 20,
                          marginTop: params && 20,
                          fontWeight: params && 'bold',
                        },
                      ]}
                    >
                      {currentVenue?.name || t('header_selected_venue')}
                    </Text>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          </VenueBannerHeader>
          {currentAuthData ? userWithSession() : userWithoutSession()}
        </>
      ) : (
        <View
          style={[
            styles.container,
            showVenues && !headerIsMinimized && { height: height - containerTabsHeight },
            (!currentVenue || findSNSScreenInStack) && { borderBottomStartRadius: 0, borderBottomEndRadius: 0 },
            headerIsMinimized && styles.headerMinimizedHeight,
            { backgroundColor: currentVenue?.appColor ?? greenColor },
          ]}
        >
          {((!findCommerceScreenInStack && !findPaymentScreensInStack) ||
            (homeScreenAsFirstScreen && !currentVenue)) && (
            <View style={styles.headerMinimized}>
              <View style={styles.selectVenue}>
                <View style={styles.positionCenter}>
                  <Text style={styles.shake}>{t('header_selected_venue')}</Text>
                  <TouchableOpacity
                    onPress={() => {
                      if (
                        !params &&
                        ordersStackFocused &&
                        eventsStackFocused &&
                        articlesStackFocused &&
                        mapStackFocused
                      )
                        return;
                      onClick();
                      if (
                        !params &&
                        ordersStackFocused &&
                        eventsStackFocused &&
                        articlesStackFocused &&
                        mapStackFocused
                      ) {
                        setHeaderIsMinimized(false);
                      }
                    }}
                    style={[styles.touchableVenue, { outlineStyle: 'none' }]}
                  >
                    {!params &&
                      !ordersStackFocused &&
                      eventsStackFocused &&
                      articlesStackFocused &&
                      mapStackFocused && <Icon name="angle-down" style={styles.currentVenue__icon} />}
                    <Text style={styles.currentVenue__name}>{currentVenue?.name || t('header_selected_venue')}</Text>
                  </TouchableOpacity>
                </View>
              </View>
              {!params && ordersStackFocused && eventsStackFocused && articlesStackFocused && mapStackFocused && (
                <View style={styles.toggleHeaderBtn}>
                  <TouchableOpacity
                    style={{ outlineStyle: 'none' }}
                    onPress={() => {
                      setHeaderIsMinimized(!headerIsMinimized);
                      setShowVenues(false);
                    }}
                  >
                    <View style={styles.roomService}>
                      <Icon name={headerIsMinimized ? 'angle-down' : 'angle-up'} style={styles.roomService__icon} />
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </View>
          )}

          {!headerIsMinimized && (currentAuthData ? userWithSession() : userWithoutSession())}
        </View>
      )}
    </>
  );
};
