import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  cardTitle: {
    fontSize: 18,
    fontWeight: '400',
    marginTop: 5,
    marginBottom: 5,
  },
  caption: {
    fontSize: 19,
    marginbottom: 20,
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  messageContainer: {
    padding: 10,
  },
  message: {
    fontSize: 20,
    color: '#424242',
    textAlign: 'center',
  },
  buttonsContainer: {
    marginTop: 10,
    width: '100%',
    alignItems: 'center',
  },
  button: {
    justifyContent: 'center',
    width: '90%',
    height: 56,
    borderRadius: 12,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  buttonDisabled: {
    backgroundColor: '#FFFFFF66',
  },
});
