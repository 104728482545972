enum MPPointPaymentIntentStates {
  Open = 'OPEN',
  OnTerminal = 'ON_TERMINAL',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED',
  Canceled = 'CANCELED',
  Abandoned = 'ABANDONED',
  Error = 'ERROR',
  Finished = 'FINISHED',
}

export default MPPointPaymentIntentStates;
