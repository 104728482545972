enum PaymentProviderTypes {
  firstData = 1,
  mercadoPago = 2,
  paypal = 3,
  mercadoPagoPoint = 4,
  clover = 5,
  izipay = 6,
  MercadoPagoCheckout = 7,
}

export default PaymentProviderTypes;
