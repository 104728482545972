/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import App from 'constants/App';
import Venue from 'types/models/Venue';
import VenueImage from 'types/models/VenueImage';
import { VenuePaymentConfig } from 'types/models/PaymentConfig';
import DeliveryConfig from 'types/models/DeliveryConfig';
import VenueSection from 'types/models/VenueSection';
import { TwitterPost, InstagramPost } from '@double_point/rn-components';
import PaymentProviderTypes from 'types/enums/PaymentProviderTypes';
import { parseLocalDeliveryAreas } from './localDeliveryAreasServices';
import { parseEvents } from './eventServices';
import { parseArticles } from './articleServices';
import { parseCommerces } from './commercesService';
import { parseBanner } from './bannerServices';

export const getVenues = async (): Promise<Venue[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `apps/${App.appName}/venues/get/current-venues`,
    });
    return parseVenues(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getVenue = async (venueId: string): Promise<Venue | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/apps`,
    });
    return parseVenue(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseVenues = (venues: []): Venue[] => {
  return venues.map((venue: any): Venue => parseVenue(venue));
};

export const parseVenue = (venue: any): Venue => {
  return {
    id: venue.id,
    name: venue.name,
    code: venue.code,
    enabled: venue.enabled,
    useDifferentIconSet: venue.use_different_icon_set === 1,
    updatedAt: venue.updated_at,
    images: parseVenueImages(venue.images),
    categories: [],
    commerces: venue.commerces ? parseCommerces(venue.commerces) : [],
    appColor: venue.app_color ?? undefined,
    appSecondaryColor: venue.app_secondary_color ?? undefined,
    language: venue.language,
    order: venue.order_priority,
    localDateTimeIdentifier: venue.local_timezone_identifier,
    events: venue.events ? parseEvents(venue.events) : undefined,
    articles: venue.articles ? parseArticles(venue.articles) : undefined,
    sections: parseSections(venue.sections),
    paymentConfig: venue.payment_config ? parsePaymentConfig(venue) : undefined,
    deliveryConfig: venue.delivery_config ? parseDeliveryConfig(venue) : undefined,
    entityIdentificator: venue.entity_identificator ?? undefined,
    sparxDescription: venue.sparx_description,
    sparxLink: venue.sparx_link,
    ticketDescription: venue.ticket_description,
    ticketImageDescription: venue.ticket_image_description,
    ticketLink: venue.ticket_link,
    chatbotLink: venue.chatbot_link,
    wallioUrl: venue.wallio_url,
    wallioTitle: venue.wallio_title,
    bettingTitle: venue.betting_widget_title ?? undefined,
    bettingUrl: venue.betting_widget_url ?? undefined,
  };
};

const parsePaymentConfig = (venue: any): VenuePaymentConfig => {
  const paymentConfig = venue.payment_config;
  const parsedPaymentConfig: VenuePaymentConfig = {
    priorityOverCommerce: paymentConfig.priority_over_commerce === 1,
    cashEnabled: paymentConfig.cash_enabled === 1,
    balanceEnabled: paymentConfig.balance_enabled === 1,
  };
  if (paymentConfig.active_payment_provider_id) {
    switch (paymentConfig.active_payment_provider_id) {
      case PaymentProviderTypes.firstData:
        if (venue.payment_provider_config) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          const paymentProviderConfig = venue.payment_provider_config;
          parsedPaymentConfig.firstDataConfig = {
            id: paymentConfig.first_data_config_id,
            fdStore: paymentProviderConfig.fd_store,
            timeZone: paymentProviderConfig.time_zone,
            currency: paymentProviderConfig.currency,
          };
        }
        break;
      case PaymentProviderTypes.mercadoPago:
        if (paymentConfig.mp_config_id) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          parsedPaymentConfig.mercadoPagoConfigId = venue.payment_config.mp_config_id;
        }
        break;
      case PaymentProviderTypes.izipay:
        if (venue.payment_provider_config) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          const paymentProviderConfig = venue.payment_provider_config;
          parsedPaymentConfig.izipayConfig = {
            id: paymentConfig.izipay_config_id,
            commerceCode: paymentProviderConfig.commerce_code,
            publicKey: paymentProviderConfig.public_key,
          };
        }
        break;
      case PaymentProviderTypes.MercadoPagoCheckout:
        if (venue.payment_provider_config) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          const paymentProviderConfig = venue.payment_provider_config;
          parsedPaymentConfig.mercadoPagoCheckoutConfig = {
            id: paymentConfig.mp_checkout_config_id,
            publicKey: paymentProviderConfig.public_key,
          };
        }
        break;
      default:
        break;
    }
  }
  return parsedPaymentConfig;
};

export const parseVenueImages = (images: any): VenueImage[] => {
  return images.map(
    (image: any): VenueImage => ({
      venueId: image.pivot.venue_id,
      venueImageId: image.pivot.venue_image_type_id,
      path: image.path,
    }),
  );
};

const parseDeliveryConfig = (venue: any): DeliveryConfig => {
  const venueDeliveryConfig = venue.delivery_config;
  const parsedVenueDeliveryConfig: DeliveryConfig = {
    id: venueDeliveryConfig.id,
    deliveryEnabled: venueDeliveryConfig.delivery_enabled === 1,
    pickUpEnabled: venueDeliveryConfig.pick_up_enabled === 1,
    isLocalDelivery: venueDeliveryConfig.is_local_delivery === 1,
    areas: venueDeliveryConfig.areas ? parseLocalDeliveryAreas(venueDeliveryConfig.areas) : [],
    priorityOverCommerce: venueDeliveryConfig.priority_over_commerce === 1,
  };
  return parsedVenueDeliveryConfig;
};

export const getTwitterFeed = async (venueId: string): Promise<TwitterPost[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/twitter-search`,
    });
    return parseTwitterPosts(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getInstagramFeed = async (venueId: string): Promise<InstagramPost[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/ig-feed`,
    });
    return parseInstagramPosts(data.data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

const parseTwitterPosts = (posts: any): TwitterPost[] => {
  return posts.map((post: any) => parseTwitterPost(post));
};

const parseTwitterPost = (post: any) => {
  return {
    id: post.id,
    text: post.text,
    author: {
      name: post.author.name,
      username: post.author.username,
      profileImageUrl: post.author.profile_image_url,
    },
    media: post.media ? parseTwitterMedia(post.media) : undefined,
    publicMetrics: {
      replyCount: post.public_metrics.reply_count,
      retweetCount: post.public_metrics.retweet_count,
      quoteCount: post.public_metrics.quote_count,
      likeCount: post.public_metrics.like_count,
    },
    createdAt: post.created_at,
  };
};

const parseTwitterMedia = (media: any[]) => {
  return media.map((mediaData: any) => {
    return {
      type: mediaData.type,
      url: mediaData.url,
      previewImageUrl: mediaData.preview_image_url,
    };
  });
};

const parseInstagramPosts = (posts: any): InstagramPost[] => {
  return posts.map((post: any) => parseInstagramPost(post));
};

const parseInstagramPost = (post: any): InstagramPost => {
  return {
    id: post.id,
    mediaType: post.media_type,
    mediaUrl: post.media_url,
    permalink: post.permalink,
    timestamp: post.timestamp,
    username: post.username,
    caption: post.caption ?? undefined,
    thumbnailUrl: post.thumbnail_url ?? undefined,
  };
};

const parseSections = (sections: any): VenueSection<any>[] => {
  return sections.map(
    (section: any): VenueSection<any> => ({
      type: section.id,
      order: section.order,
      banner: section.banner ? parseBanner(section.banner) : undefined,
    }),
  );
};
