import React from 'react';
import { Ionicons } from '@expo/vector-icons';
import * as Font from 'expo-font';

export default function useCachedResources(): boolean {
  const [isLoadingComplete, setLoadingComplete] = React.useState<boolean>(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          'circular-std': require('assets/fonts/Circular-Std-Book.ttf'),
          'billetera-fan': require('assets/icomoon/billetera-fan.ttf'),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
