import { StyleSheet, Dimensions } from 'react-native';

const screenHeight = Dimensions.get('screen').height;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    marginBottom: 15,
    maxHeight: screenHeight - screenHeight * 0.1,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
    marginBottom: 15,
  },
  headerTextTitleContainer: {
    flexDirection: 'column',
    width: '70%',
  },
  headerTextTitle: {
    fontSize: 18,
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 20,
    color: '#001639',
    letterSpacing: 1.25,
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 5,
  },
  detailPhotosContainer: {
    marginHorizontal: 25,
  },
  descriptionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 15,
    marginRight: 15,
    marginTop: 20,
  },
  descriptionTitle: {
    fontSize: 13,
    letterSpacing: 1.25,
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 30,
    marginTop: 20,
    borderBottomColor: '#89909B',
  },
  description: {
    fontSize: 16,
    letterSpacing: 1.25,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 16,
    textAlign: 'center',
  },
  bodyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginHorizontal: 25,
    marginTop: 25,
    marginBottom: 20,
  },
  body: {
    fontSize: 16,
    letterSpacing: 1.25,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 16,
    textAlign: 'center',
  },
  headerCloseButton: {
    position: 'absolute',
    top: 3,
    right: 10,
    zIndex: 9000,
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    borderRadius: 4,
  },
  touchableHitSlop: {
    top: 15,
    right: 15,
    bottom: 15,
    left: 15,
  },
});
