import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    shadowOpacity: 0.15,
    shadowOffset: {
      height: 3,
      width: 1,
    },
    ...(Platform.OS === 'web' ? { outlineStyle: 'none' } : {}),
  },
  contentContainer: {
    borderRadius: 10,
    alignSelf: 'center',
    marginTop: 20,
  },
  imageBackgroundDimensions: {
    borderRadius: 10,
    overflow: 'hidden',
    height: 150,
    width: 340,
  },
  internalSectionContainer: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    bottom: 0,
    flex: 1,
    width: '100%',
  },
  internalCommerceLogoContainer: {
    marginBottom: 28,
    marginLeft: 15,
    position: 'absolute',
    flexDirection: 'column',
    bottom: 30,
    alignSelf: 'flex-start',
  },
  commerceLogo: {
    borderRadius: 5,
    height: 39,
    width: 39,
  },
  lowerCommerceBar: {
    marginTop: 10,
    flexDirection: 'row',
    flex: 1,
    marginLeft: 15,
    marginRight: 15,
    marginBottom: 3,
  },
  commerceTitle: {
    flex: Platform.OS !== 'web' ? 2 : 1,
    marginTop: 15,
    fontSize: 14,
    fontWeight: 'bold',
  },
  commerceAddress: {
    flex: Platform.OS !== 'web' ? 2 : 1,
    marginTop: 15,
    marginLeft: 'auto',
    fontWeight: '400',
    fontSize: 14,
  },
});
