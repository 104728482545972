import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    padding: 0,
    marginTop: 10,
    flexDirection: 'row',
  },
  arrowleft: {
    marginLeft: 10,
    fontSize: 20,
    margin: 0,
  },
  textMenu: {
    lineHeight: 23,
    fontWeight: 'bold',
    fontSize: 16,
    fontStyle: 'normal',
    marginLeft: 10,
    maxWidth: 220,
  },
  iconsContainer: {
    flexDirection: 'row',
    position: 'absolute',
    right: 0,
    marginRight: 15,
  },
  containerForPosnet: {
    alignSelf: 'center',
    flexDirection: 'row',
    marginTop: 10,
  },
  whatsappIcon: {
    marginRight: 10,
    fontSize: 22,
    color: '#25d366',
    fontWeight: 'bold',
    lineHeight: 23,
  },
  supportAgentIcon: {
    marginRight: 10,
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: 23,
  },
  shoppingCart: {
    fontSize: 22,
    fontWeight: 'bold',
    lineHeight: 23,
  },
  content: {
    marginTop: 5,
    marginHorizontal: 10,
  },
  headerText: {
    paddingTop: 10,
    fontSize: 16,
    lineHeight: 24,
    color: '#89909B',
    opacity: 0.8,
  },
  venueLogo: {
    width: 22,
    height: 22,
    borderRadius: 5,
    alignSelf: 'center',
    marginRight: 10,
    lineHeight: 23,
  },
  unpairPosnetMessage: {
    marginRight: 15,
    color: '#F00',
    fontSize: 16,
    fontWeight: 'bold',
  },
  pairPosnetMessage: {
    marginRight: 15,
    fontSize: 16,
    fontWeight: 'bold',
  },
  newHeader: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 50,
  },
  headerIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  commerceNameContainer: {
    flex: 4,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  headerIcon: {
    fontSize: 50,
    fontWeight: 'bold',
  },
  commerceNameText: {
    fontWeight: 'bold',
    fontSize: 21,
  },
  shoppingCartLength: {
    position: 'absolute',
    top: 2,
    right: 9,
    orderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    borderRadius: 20,
  },
  shoppingCartLengthNumber: { textAlign: 'center', color: '#FFF' },
});
