import React from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import OrderContext from 'contexts/OrderContext';
import WebViewIzipayFormProps from 'types/components/WebViewIzipayProps';
import { SDK_IZIPAY } from '@env';
import { storeIzipayPayment } from 'services/paymentsService';

export default ({
  platform,
  izipayPaymentData,
  orderId,
  token,
  setIsLoading,
  setResultPayment,
}: WebViewIzipayFormProps): JSX.Element => {
  const webViewRef = React.useRef<any>();
  const { setIzipayTransactionId } = React.useContext(OrderContext);

  const reloadWebViewOnCrash = () => {
    webViewRef.current.reload();
  };

  const html = `<html>
      <head>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Checkout Izipay</title>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <script src="${SDK_IZIPAY}"></script>
      </head>
      <body id="root" onload="loadForm()">
        <div id="iframe-payment-izipay"></div>
      </body>
      <script>
      function loadForm() {

        let izipayInstance;
        try {
          izipayInstance = new Izipay({ config: ${JSON.stringify(izipayPaymentData)} });
        } catch (error) {
          window.ReactNativeWebView.postMessage(JSON.stringify({ message: error.message, error: error.Errors, date: error.date}));
        }
        
        if (izipayInstance) {
          try {
            izipayInstance.LoadForm({
                authorization: "${token}",
                keyRSA: "RSA",
                callbackResponse: function (response) {
                  window.ReactNativeWebView.postMessage(JSON.stringify(response));
                }
              });
              window.ReactNativeWebView.postMessage(JSON.stringify(document.getElementById("root").innerHTML));
          } catch (error) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ message: error.message, error: error.Errors, date: error.date}));
          }
        }
      }
      </script>
    </body>
      </html>`;

  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
      }}
    >
      <WebView
        androidLayerType={'hardware'}
        ref={webViewRef}
        source={{
          html: html,
        }}
        mixedContentMode={'always'}
        onMessage={async (event) => {
          if (typeof event.nativeEvent.data === 'string') {
            const responseData = JSON.parse(event.nativeEvent.data);
            const success = responseData.code === '00' ? true : false;
            if (success) {
              setIzipayTransactionId(null);
              await storeIzipayPayment(responseData, orderId);
            }
            setResultPayment({ success, response: responseData.message });
          }
        }}
        automaticallyAdjustContentInsets={true}
        androidHardwareAccelerationDisabled={true}
        scalesPageToFit={false}
        bounces={true}
        originWhitelist={['*']}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        onLoad={() => {
          setIsLoading(false);
        }}
        onError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          alert(`WebView error: ${nativeEvent}`);
        }}
        {...(platform === 'ios' ? { onContentProcessDidTerminate: reloadWebViewOnCrash } : {})}
        style={{ height: '100%', width: '100%', border: 0, borderWidth: 0 }}
      />
    </View>
  );
};
