import React from 'react';
import { ScrollView, View, TouchableOpacity, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Image } from 'react-native-elements';
import { Icon } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import {
  AdvertisingBannerList,
  AdvertisingBannerProps,
  AdvertisingBanner,
  AdvertisingBannerCarousel,
} from '@double_point/rn-components';
import useChangeVenue from 'hooks/useChangeVenue';
import useVenues from 'hooks/useVenues';
import useRedirectHomeParams from 'hooks/useRedirectHomeParams';
import AppContext from 'contexts/AppContext';
import useChangeAuthData from 'hooks/useChangeAuthData';
import useRedirectParams from 'hooks/useRedirectParams';
import OrderContext from 'contexts/OrderContext';
import HomeScreenProps from 'types/screens/HomeScreenProps';
//--------------Se mantendrá comentada la lógica de categorías hasta que sea requerido------------------
// import Category from 'types/models/Category';
// import CategoryImageTypes from 'types/enums/CategoryImageTypes';
import Commerce from 'types/models/Commerce';
import Product from 'types/models/Product';
//import PaginationData from 'types/models/PaginationData';
import Discount from 'types/models/Discount';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import ProductImageTypes from 'types/enums/ProductImageTypes';
import DiscountImageTypes from 'types/enums/DiscountImageTypes';
import BannerImageType from 'types/enums/BannerImageType';
import VenueSectionType from 'types/enums/VenueSectionType';
import Venue from 'types/models/Venue';
import VenueSection from 'types/models/VenueSection';
//--------------Se mantendrá comentada la lógica de categorías hasta que sea requerido------------------
//import { getCommercesByVenue } from 'services/commercesService';
import { getPromotionsByVenue, getProductsByCommerce } from 'services/productsService';
import { parsePromotionsToCardItems } from 'services/promotionsServices';
import { parseDiscountsToCardItems } from 'services/discountServices';
import { styles } from 'styles/screens/homeScreen';
import { grayColor, greenColor } from 'styles/global';
import { getCommerceByCodes, getCompleteCommerce } from 'services/commercesService';
import { getAuthData } from 'services/authService';
import { getUserBalance } from 'services/ordersService';
import { getVenue } from 'services/venuesServices';
import { notAutoReloadScreenData } from 'utils/shoppingCart';
import App from 'constants/App';
import Storage from 'constants/Storage';
import VenueList from 'components/VenueList';
import CommerceList from 'components/CommerceList';
import AppHeader from 'components/AppHeader';
import BalanceExhibitor from 'components/BalanceExhibitor';
import UserPartnerCard from 'components/UserPartnerCard';
import AppSection from 'components/AppSection';
import LeavingModal from 'components/LeavingModal';
import ChatBotModal from 'components/ChatBotModal';
import BettingWidget from 'components/BettingWidget';
//import WelcomeModal from 'components/WelcomeModal';
import PwaInstructionsModal from 'components/PwaInstructionsModal';
import LunaParkLogo from 'assets/images/luna_park_logo.png';
import DiabloSoyIcon from 'assets/images/DiabloSoyIcon.png';
import CommerceImageTypes from 'types/enums/CommerceImageTypes';
import { WEB_HOST } from '@env';

export default ({ navigation, route }: HomeScreenProps): JSX.Element => {
  const {
    currentVenue,
    setCurrentVenue,
    currentAuthData,
    userBalance,
    setUserBalance,
    setModalizeData,
    redirectionHomeItems,
    showVenues,
    venueLoad,
    setVenueLoad,
    setIsLoading,
    checkedForDifferentVenueCode,
    setCheckedForDifferentVenueCode,
  } = React.useContext(AppContext);
  const { setCurrentCommerce, currentOrder, currentShoppingCart, currentCommerce, setCurrentDiscount } =
    React.useContext(OrderContext);
  const { t } = useTranslation();
  const { storeCurrentAuthData } = useChangeAuthData();
  const { venues, fetchVenues } = useVenues();
  const { storeCurrentVenue } = useChangeVenue();
  const { storeRedirectHomeParams, deleteRedirectHomeParams } = useRedirectHomeParams();
  const { deleteRedirectCommerceParams } = useRedirectParams();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
  //const [showWelcomeModal, setShowWelcomeModal] = React.useState<boolean>(true);
  const [showPwaInstructionsModal, setShowPwaInstructionsModal] = React.useState<boolean>(false);
  const [commerces, setCommerces] = React.useState<Commerce[] | []>([]);
  //const [promotions, setPromotions] = React.useState<PaginationData<Product> | null>();
  const [promotionItems, setPromotionItems] = React.useState<AdvertisingBannerProps[]>([]);
  const [discountItems, setDiscountItems] = React.useState<AdvertisingBannerProps[]>([]);
  const [commerceTypes, setCommerceTypes] = React.useState<string[]>([]);
  const [activeCommerceTypeTab, setActiveCommerceTypeTab] = React.useState<string>(commerceTypes[0]);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const venueLogoForTinting =
    currentVenue?.images.find((image) => image.venueImageId === VenueImageTypes.LogoForTinting)?.path ??
    App.defaultImage;
  const downloadBanner =
    currentVenue?.images.find((image) => image.venueImageId === VenueImageTypes.InstalationAppBanner)?.path ??
    App.defaultImage;
  const filteredCommercesByType = commerces.filter((commerce) => commerce.type.description === activeCommerceTypeTab);
  const venueHasChatbotLink = currentVenue?.chatbotLink && currentVenue.chatbotLink.length > 0 ? true : false;
  const params = route.params;

  const loadProductFromCommerceByCodes = async (): Promise<void> => {
    const params = route.params;
    if (params) {
      const { token, venueCode, commerceCode } = params;
      if (!(venueCode && commerceCode)) return;
      if (token) {
        const user = await getAuthData(token);
        if (user) storeCurrentAuthData({ token, user });
      }
      const response = await getCommerceByCodes(venueCode, commerceCode);
      if (response) {
        setCurrentVenue(response.venue);
        setCurrentCommerce(response.commerce);
        navigation.navigate('CommerceScreen', {
          venueCode: venueCode,
          commerceCode: commerceCode,
        });
      }
    }
  };

  const parsePromotionToCardItem = (promotion: Product, logoImage: string): AdvertisingBannerProps => {
    return {
      id: promotion.id,
      image:
        promotion.images.find((image) => image.productImageTypeId === ProductImageTypes.banner)?.path ??
        App.defaultImage,
      color: promotion.promotionBannerBackgroundColor ?? '',
      title: promotion.name,
      colorTitle: promotion.promotionBannerColorTitle ?? '',
      description: promotion.description,
      price: Math.floor(promotion.price),
      logo: logoImage,
      onPress: () => {
        if (promotion.commerce) {
          setCurrentCommerce(promotion.commerce);
        }
        navigation.navigate('CommerceScreen', {
          venueCode: currentVenue?.code,
          commerceCode: promotion.commerce?.code,
          productId: promotion.id,
        });
      },
      isForTotemDisplay: false,
    };
  };

  const parseDiscountToCardItem = (discount: Discount): AdvertisingBannerProps => {
    return {
      id: discount.id,
      image:
        discount.images.find((image) => image.discountImageTypeId === DiscountImageTypes.banner)?.path ??
        App.defaultImage,
      color: currentVenue?.appColor ?? greenColor,
      title: '',
      logo: discount.commerceLogoPath,
      colorTitle: '#000000',
      description: discount.description,
      onPress: () => {
        setCurrentDiscount(discount);
        navigation.navigate('DiscountScreen');
      },
      isForTotemDisplay: false,
    };
  };

  const loadPromotions = React.useCallback(async (venueId: string, logoImage: string, page?: number) => {
    if (!page) {
      setPromotionItems([]);
    }
    const paginationData = await getPromotionsByVenue(venueId, page);
    if (!paginationData) return;
    if (paginationData.data.length > 0) {
      //setPromotions(paginationData);
      setPromotionItems((oldItems) =>
        oldItems.concat(parsePromotionsToCardItems(paginationData.data, parsePromotionToCardItem, logoImage)),
      );
    }
  }, []);

  const loadVenueByCode = async (venueCode?: string, token?: string): Promise<void> => {
    if (currentVenue && currentVenue.code === venueCode) return;
    if (venueCode) {
      const venue = venues.find((venue) => venue.code === venueCode);
      if (venue) {
        await storeCurrentVenue(venue, false);
        setCheckedForDifferentVenueCode(true);
      }
    }
    if (token) {
      const user = await getAuthData(token);
      if (user) storeCurrentAuthData({ token, user });
    }
    if (redirectionHomeItems) {
      if (redirectionHomeItems.venueCode !== venueCode) {
        await deleteRedirectHomeParams();
      } else {
        return;
      }
    }
    await storeRedirectHomeParams(params);
  };

  const deletePosibleParams = async (): Promise<void> => {
    if (currentOrder && currentShoppingCart) return;
    if (currentShoppingCart && currentShoppingCart?.detail.length < 1) return;
    if (currentCommerce) return;
    await deleteRedirectCommerceParams();
  };

  const fetchUserBalance = async (): Promise<void> => {
    if (
      currentAuthData &&
      currentVenue?.paymentConfig?.balanceEnabled &&
      currentVenue.paymentConfig.priorityOverCommerce
    ) {
      const userBalance = await getUserBalance(currentAuthData.user.id, currentVenue.id);
      if (userBalance) {
        setUserBalance(userBalance);
      }
    } else {
      setUserBalance(null);
    }
  };

  const sortLocalDeliveryFields = (a: Discount, b: Discount): number => {
    if (a.priority < b.priority) return -1;
    if (a.priority > b.priority) return 1;
    return 0;
  };

  const sortVenueSections = (a: VenueSection<VenueSectionType>, b: VenueSection<VenueSectionType>): number => {
    if (a.order < b.order) return -1;
    if (a.order > b.order) return 1;
    return 0;
  };

  const getOnlyCommercesWithActiveProducts = (completeCommerces: Commerce[]) => {
    const filterCommercesWithoutProducts = completeCommerces.filter((commerce) => commerce.products.length > 0);
    if (filterCommercesWithoutProducts.length > 0) {
      setCommerces(filterCommercesWithoutProducts);
    } else {
      setCommerces([]);
    }
  };

  const getAllCommercesDiscounts = (commerces: Commerce[]) => {
    if (!commerces.length) return;
    const venueCommerces = commerces;
    const discounts: Discount[] = [];
    venueCommerces.map((commerce) => {
      const commerceLogo =
        commerce.images.find((commerce) => commerce.commerceImageTypeId === CommerceImageTypes.logo)?.path ??
        App.defaultImage;
      if (commerce.discounts) {
        commerce.discounts.forEach((discount) => {
          discount.commerceLogoPath = commerceLogo;
          discounts.push(discount);
        });
      }
    });
    if (discounts.length > 0) {
      discounts.sort(sortLocalDeliveryFields);
      setDiscountItems(parseDiscountsToCardItems(discounts, parseDiscountToCardItem));
    } else {
      setDiscountItems([]);
    }
  };

  /*const modalShown = async () => {
    const wasModalShown = await AsyncStorage.getItem(Storage.welcomeModalShown);
    if (wasModalShown && wasModalShown === 'true') {
      setShowWelcomeModal(false);
    } else {
      await AsyncStorage.setItem(Storage.welcomeModalShown, 'true');
    }
  };*/

  const checkForCommercesChanges = async (commerces: Commerce[]): Promise<Commerce[]> => {
    const currentVenueCommerces: Commerce[] = commerces;
    let anyCommerceChanged = false;
    const addingUpdatedCommerces = commerces.map(async (commerce) => {
      const remoteCommerce = await getCompleteCommerce(commerce.id);
      if (remoteCommerce) {
        if (commerce.updatedAt !== remoteCommerce.updatedAt) {
          if (!anyCommerceChanged) anyCommerceChanged = true;
          const productsByCommerce = await getProductsByCommerce(remoteCommerce.id);
          if (productsByCommerce.length > 0) {
            remoteCommerce.products = productsByCommerce;
          }
          const findIndexOfCommerce = currentVenueCommerces.findIndex((item) => item.id === remoteCommerce.id);
          currentVenueCommerces.splice(findIndexOfCommerce, 1, remoteCommerce);
        }
      }
    });
    await Promise.all(addingUpdatedCommerces);
    if (anyCommerceChanged && currentVenue) {
      currentVenue.commerces = currentVenueCommerces;
      await AsyncStorage.setItem(Storage.currentVenue, JSON.stringify(currentVenue));
      setCurrentVenue(currentVenue);
    }
    return currentVenueCommerces;
  };

  const loadRemainingPropertiesOfVenue = async (
    commerces: Commerce[],
    venue: Venue,
    venueLogoPath: string,
  ): Promise<void> => {
    setIsLoading(true);
    const updatedCommerces = await checkForCommercesChanges(commerces);
    venue.sections.sort(sortVenueSections);
    loadPromotions(venue.id, venueLogoPath);
    getOnlyCommercesWithActiveProducts(updatedCommerces);
    getAllCommercesDiscounts(updatedCommerces);
  };

  const fetchRemoteVenue = async (currentVenue: Venue) => {
    if (await notAutoReloadScreenData(currentCommerce?.code ?? undefined)) return;
    const remoteVenue = await getVenue(currentVenue.id);
    if (remoteVenue) {
      if (currentVenue.updatedAt !== remoteVenue.updatedAt) {
        setIsLoading(true);
        await storeCurrentVenue(remoteVenue, true);
        setVenueLoad(true);
      }
    }
  };

  React.useEffect(() => {
    //modalShown();
    deletePosibleParams();
    loadProductFromCommerceByCodes();
    setVenueLoad(true);
  }, []);

  React.useEffect(() => {
    if (params) {
      const { venueCode, token } = params;
      setIsLoading(true);
      if (venues && venues.length > 0) {
        loadVenueByCode(venueCode, token).then(() => {
          setVenueLoad(true);
        });
      }
    }

    return () => {
      setVenueLoad(false);
    };
  }, [venues]);

  React.useEffect(() => {
    if (currentVenue) fetchRemoteVenue(currentVenue);
  }, [currentVenue]);

  React.useEffect(() => {
    if (!currentVenue || (showVenues && currentVenue)) {
      fetchVenues();
    }
  }, [showVenues, currentVenue]);

  React.useEffect(() => {
    if (venueLoad) {
      if (currentVenue) {
        if (params && params.venueCode !== currentVenue.code) return;
        if (!route.params) {
          navigation.setParams({ venueCode: currentVenue.code });
        }
        if (Platform.OS === 'web') {
          const manifestDetails = {
            name: 'BilleteraFan',
            short_name: 'BilleteraFan',
            start_url: `${WEB_HOST}home/${currentVenue.code}`,
            display: 'minimal-ui',
            display_override: ['minimal-ui'],
            orientation: 'portrait',
            background_color: '#ffffff',
            description: 'Toda la oferta gastronómica de tu evento, facil y rápido.',
            icons: [
              {
                src: `${venueLogoImage}`,
                sizes: '512x512',
                type: 'image/png',
                purpose: 'any',
              },
              {
                src: `${venueLogoImage}`,
                sizes: '192x192',
                type: 'image/png',
                purpose: 'maskable',
              },
            ],
            theme_color: `${currentVenue.appColor}`,
          };

          const stringManifest = JSON.stringify(manifestDetails);
          const blob = new Blob([stringManifest], { type: 'application/json' });
          const manifestURL = URL.createObjectURL(blob);
          const htmlManifest = document.querySelector('#manifest-placeholder');
          if (htmlManifest) {
            htmlManifest.setAttribute('href', manifestURL);
          }

          const linkForAppleIcon = document.querySelectorAll('[rel="apple-touch-icon"]')[0];
          if (linkForAppleIcon) {
            linkForAppleIcon.setAttribute('href', venueLogoImage);
            linkForAppleIcon.setAttribute('crossorigin', 'use-credentials');
          }
        }
        //setItemsCategories(parseCategoriesToCarouselItems(currentVenue.categories));
        loadRemainingPropertiesOfVenue(currentVenue.commerces, currentVenue, venueLogoImage);
        setVenueLoad(false);
      } else {
        setCheckedForDifferentVenueCode(true);
      }
      setInitialLoad(true);
    }

    return () => {
      setVenueLoad(false);
      setIsLoading(false);
    };
  }, [venueLoad, currentVenue]);

  React.useEffect(() => {
    fetchUserBalance();
  }, [currentAuthData]);

  React.useEffect(() => {
    const types: any[] = [];
    if (commerces.length > 0) {
      commerces.forEach((commerce: Commerce, index) => {
        if (!commerce.type) return;
        if (index === 0) {
          setActiveCommerceTypeTab(commerce.type.description);
        }
        const commerceType = commerce.type.description;
        const type = types.find((type) => type === commerceType);
        if (!type) {
          types.push(commerceType);
        }
      });
    }
    setCommerceTypes(types);
  }, [commerces]);

  const renderNoCurrentVenueContent = (): JSX.Element => {
    return (
      <ScrollView
        style={[
          styles.noCurrentVenueContainer,
          { backgroundColor: currentVenue ? currentVenue.appColor : 'rgba(0,0,0,0)' },
        ]}
      >
        <VenueList venues={venues} />
      </ScrollView>
    );
  };

  const tabCommerceItem = (typeItem: string, index: number): JSX.Element => {
    const isActive: any = activeCommerceTypeTab === typeItem;

    return (
      <TouchableOpacity
        style={{ margin: 0, outlineStyle: 'none' }}
        key={index}
        onPress={() => {
          setActiveCommerceTypeTab(typeItem);
        }}
      >
        <View
          style={[
            styles.tabContainer,
            isActive && [
              styles.activeTabItem,
              {
                color: currentVenue?.appColor ?? greenColor,
                borderBottomColor: currentVenue?.appColor ?? greenColor,
              },
            ],
          ]}
        >
          <Text
            style={[
              styles.tabText,
              { color: activeCommerceTypeTab === typeItem ? currentVenue?.appColor ?? greenColor : '#9e9e9e' },
            ]}
          >
            {typeItem}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const getChatbotIcon = (): JSX.Element | undefined => {
    if (!currentVenue) return undefined;

    if (currentVenue.id === '63d51601-4175-4d49-8ac8-699b38a8f1e8') {
      // LunaParkId
      return <Image source={LunaParkLogo} style={{ height: 40, width: 40 }} />;
    }

    if (currentVenue.id === 'b04149b9-349c-4486-99f3-cf22fa3037a6') {
      //IndependienteId
      return <Image source={DiabloSoyIcon} style={{ height: 40, width: 40 }} />;
    }

    return <Icon name="support-agent" size={30} color={currentVenue?.appColor ?? '#c33e1d'} />;
  };

  const renderSwitchVenueContentBySectionType = (
    venueSection: VenueSection<VenueSectionType>,
    key: number,
  ): JSX.Element => {
    if (!currentVenue) return <></>;
    switch (venueSection.type) {
      case VenueSectionType.Promotion:
        return (
          <View key={key}>
            {promotionItems.length > 0 ? (
              <AppSection title={t('promotions')}>
                <View style={styles.promotionsContent}>
                  <AdvertisingBannerCarousel
                    dotVenueColor={currentVenue.appColor}
                    colorTitle={grayColor}
                    items={promotionItems}
                    itemsCustomStyles={{
                      marginTop: 10,
                      height: 100,
                      width: 260,
                      widthRectangleItems: 100,
                      fontSizeTitle: 12,
                      fontSizeDescription: 8,
                      triangleBorderRightWidth: 130,
                    }}
                    isForTotemDisplay={false}
                  />
                </View>
              </AppSection>
            ) : null}
          </View>
        );
      case VenueSectionType.Commerce:
        return (
          <View key={key}>
            <View style={styles.scrollTypesContainer}>
              <ScrollView horizontal={true}>
                {commerceTypes.length > 0 && (
                  <>{commerceTypes.map((commerceType, index) => tabCommerceItem(commerceType, index))}</>
                )}
              </ScrollView>
            </View>
            {filteredCommercesByType.length > 0 && (
              <CommerceList commerces={filteredCommercesByType} navigation={navigation} />
            )}
          </View>
        );
      case VenueSectionType.Banner:
        return (
          <View key={key}>
            {venueSection.banner !== undefined ? (
              <AppSection title={`${venueSection.banner.title}`}>
                <AdvertisingBanner
                  id={currentVenue.id}
                  image={
                    venueSection?.banner?.images.find((image) => image.bannerImageType === BannerImageType.Front)
                      ?.path ?? 'https://via.placeholder.com/700x300'
                  }
                  color={currentVenue.appColor ?? greenColor}
                  colorTitle="#fff"
                  description={venueSection.banner.description}
                  onPress={() => {
                    setModalizeData({
                      content: (
                        <LeavingModal
                          url={venueSection.banner?.url ?? ''}
                          showSecurityMessage={true}
                          venueLogoForTinting={venueLogoForTinting}
                        />
                      ),
                      closeOnOverlayTap: false,
                    });
                  }}
                  isForTotemDisplay={false}
                />
              </AppSection>
            ) : null}
          </View>
        );
      case VenueSectionType.Discount:
        return (
          <View key={key}>
            {discountItems.length > 0 ? (
              <View style={styles.discountsContainer}>
                <AdvertisingBannerList
                  title={t('discounts')}
                  colorTitle={grayColor}
                  items={discountItems}
                  seeMoreText={''}
                />
              </View>
            ) : null}
          </View>
        );
      case VenueSectionType.Download:
        return (
          <View key={key}>
            {Platform.OS === 'web' ? (
              <AppSection title={t('download')}>
                <TouchableOpacity
                  onPress={() => {
                    setShowPwaInstructionsModal(true);
                  }}
                >
                  <View style={styles.downloadBannerContainer}>
                    <Image source={{ uri: downloadBanner }} style={styles.downloadImageBanner} />
                  </View>
                </TouchableOpacity>
              </AppSection>
            ) : null}
          </View>
        );
      case VenueSectionType.Betting:
        return (
          <View key={key}>
            {currentVenue.bettingUrl && currentVenue.bettingTitle && (
              <AppSection title={currentVenue.bettingTitle}>
                <BettingWidget url={currentVenue.bettingUrl} />
              </AppSection>
            )}
          </View>
        );
      default:
        return <></>;
    }
  };

  const renderCurrentVenueContent = (): JSX.Element => {
    if (!currentVenue) return <></>;
    return (
      <>
        {/*<WelcomeModal
          visible={showWelcomeModal}
          onClose={() => setShowWelcomeModal(false)}
          onPwaInstructionsVisible={() => setShowPwaInstructionsModal(true)}
        />*/}
        <PwaInstructionsModal visible={showPwaInstructionsModal} onClose={() => setShowPwaInstructionsModal(false)} />
        <ScrollView style={styles.container} contentContainerStyle={{ flexGrow: 1 }}>
          <AppHeader />
          <View style={styles.homeDataContainer}>
            {currentVenue.paymentConfig?.balanceEnabled && userBalance && <BalanceExhibitor balance={userBalance} />}
            {currentVenue && currentAuthData?.user.partnerProfile && (
              <UserPartnerCard venue={currentVenue} userProfile={currentAuthData?.user} />
            )}
            {currentVenue.sections?.map((section, index) => renderSwitchVenueContentBySectionType(section, index))}
            {venueHasChatbotLink ? (
              <ChatBotModal
                isVisible={isModalOpen}
                setIsChatBotModalVisible={setIsModalOpen}
                chatbotLink={currentVenue?.chatbotLink ?? ''}
                navigation={navigation}
              />
            ) : null}
          </View>
        </ScrollView>
        {venueHasChatbotLink ? (
          <View style={styles.chatbotContainer}>
            <Text style={styles.chatbotLabel}>¿En qué podemos ayudarte?</Text>
            <TouchableOpacity
              style={[
                styles.supportAgentIconBtn,
                { backgroundColor: currentVenue.appColor ?? '#fff' },
                { outlineStyle: 'none' },
              ]}
              onPress={() => setIsModalOpen(true)}
            >
              {getChatbotIcon()}
            </TouchableOpacity>
          </View>
        ) : null}
      </>
    );
  };

  if (!initialLoad) return <></>;
  if (initialLoad && !currentVenue && checkedForDifferentVenueCode && params && params.venueCode) return <></>;
  if (initialLoad && currentVenue && checkedForDifferentVenueCode && params && params.venueCode !== currentVenue.code)
    return <></>;
  if (initialLoad && currentVenue && !checkedForDifferentVenueCode) return <></>;
  return (
    <>
      {(!currentVenue || (showVenues && currentVenue)) && renderNoCurrentVenueContent()}
      {currentVenue && !showVenues && <>{renderCurrentVenueContent()}</>}
    </>
  );
};
