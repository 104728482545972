import React from 'react';
import { View, ImageBackground } from 'react-native';
import VenueBannerHeaderProps from 'types/components/VenueBannerHeaderProps';
import { styles } from 'styles/components/venueBannerHeader';

export default ({ venueBanner, children }: VenueBannerHeaderProps): JSX.Element => {
  return (
    <View style={{ height: 160 }}>
      <ImageBackground source={{ uri: venueBanner }} style={styles.bannerHeaderImage}>
        {children}
      </ImageBackground>
    </View>
  );
};
