import React from 'react';
import { View, Text, TextInput, TouchableOpacity } from 'react-native';
import { Card, Button } from 'react-native-elements';
import { CountryPicker } from 'react-native-country-codes-picker';
import { t } from 'i18next';
import LoginWithPhoneNumberModalProps from '../types/components/LoginWithPhoneNumberModalProps';
import { styles } from '../styles/components/loginWithPhoneNumberModal';
import { grayColor } from 'styles/global';
import AppContext from 'contexts/AppContext';
import regexConstants from 'constants/Regex';
import useIcon from 'hooks/useIcon';

export default ({ sendPhoneNumber, onAbort, loginPhoneNumberColor }: LoginWithPhoneNumberModalProps): JSX.Element => {
  const { setIsLoading, currentVenue } = React.useContext(AppContext);
  const Icon = useIcon();
  const [showCountryPicker, setShowCountryPicker] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState('+54');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [showErrorWithLoginPhoneNumber, setShowErrorWithLoginPhoneNumber] = React.useState<boolean>(false);

  // Input partner form
  const inputPhoneNumber = () => {
    return (
      <>
        <Text style={styles.inputPhoneLabel}>{`${t('phone-number-modal-input-title')}`}</Text>
        <View style={styles.inputPhoneRowContainer}>
          <TouchableOpacity
            onPress={() => setShowCountryPicker(true)}
            style={[styles.touchableCountryCodeContainer, { borderColor: grayColor }, { outlineStyle: 'none' }]}
          >
            <View style={styles.countryCodeContainer}>
              {countryCode ? (
                <Text style={styles.countryCodeText}>{countryCode}</Text>
              ) : (
                <Icon name="angle-down" style={styles.iconDownCountryCode} />
              )}
            </View>
          </TouchableOpacity>
          <TextInput
            keyboardType="number-pad"
            value={phoneNumber}
            onChangeText={setPhoneNumber}
            style={[styles.phoneInput, { outlineStyle: 'none' }]}
            placeholder={t('phone-number-modal-input-placeholder')}
            placeholderTextColor="#C4C4C4"
          />
        </View>
        <CountryPicker
          show={showCountryPicker}
          lang={currentVenue?.language ?? 'en'}
          inputPlaceholder={t('phone-number-modal-country-code-selector-placeholder')}
          pickerButtonOnPress={(item) => {
            setCountryCode(item.dial_code);
            setShowCountryPicker(false);
          }}
          style={{
            modal: {
              height: '80%',
            },
          }}
          onBackdropPress={() => setShowCountryPicker(false)}
        />
        {phoneNumber !== '' && !regexConstants.numbers.test(phoneNumber) && (
          <View>
            <Text style={styles.phoneInputInvalid}>{`${t('phone-number-modal-wrong-number-characters')}`}</Text>
          </View>
        )}
        {phoneNumber !== '' && !regexConstants.phone.test(phoneNumber) && (
          <View>
            <Text style={styles.phoneInputInvalid}>{`${t('phone-number-modal-wrong-number-format')}`}</Text>
          </View>
        )}
        {!countryCode && phoneNumber !== '' && (
          <View>
            <Text style={styles.phoneInputInvalid}>{`${t('phone-number-modal-country-code-required')}`}</Text>
          </View>
        )}
        {showErrorWithLoginPhoneNumber && (
          <Text style={styles.phoneErrorLogin}>{`${t('phone-number-modal-error-on-login')}`}</Text>
        )}
      </>
    );
  };

  const onSendPhoneNumber = async (): Promise<void> => {
    setIsLoading(true);
    const joiningCountryCodeAndPhoneNumber = countryCode + phoneNumber;
    const formattedPhoneNumber = joiningCountryCodeAndPhoneNumber.replace('+', '');
    const result = await sendPhoneNumber(formattedPhoneNumber);
    if (result) {
      setPhoneNumber('');
      setShowErrorWithLoginPhoneNumber(false);
    } else {
      setPhoneNumber('');
      setShowErrorWithLoginPhoneNumber(true);
    }
    setIsLoading(false);
  };

  const isButtonDisabled = (): boolean => {
    return (
      !phoneNumber ||
      !regexConstants.numbers.test(phoneNumber) ||
      !regexConstants.phone.test(phoneNumber) ||
      !countryCode
    );
  };

  return (
    <>
      <Card.Title style={styles.legendText}>{`${t('phone-number-modal-login-title')}`}</Card.Title>
      <Card.Divider />
      <View style={styles.phoneInputContainer}>{inputPhoneNumber()}</View>
      <Button
        title={t('confirm-code')}
        type="clear"
        containerStyle={[{ outlineStyle: 'none' }]}
        buttonStyle={[styles.btn, { backgroundColor: loginPhoneNumberColor }]}
        titleStyle={[styles.btnText]}
        disabled={isButtonDisabled()}
        disabledStyle={styles.disabledBtn}
        disabledTitleStyle={styles.btnText}
        onPress={async () => {
          setIsLoading(true);
          await onSendPhoneNumber();
          setIsLoading(false);
        }}
      />
      <View>
        <Button
          title={t('guest-button')}
          titleStyle={{ color: loginPhoneNumberColor }}
          containerStyle={[styles.loginLaterBtn, { outlineStyle: 'none' }]}
          type="clear"
          onPress={() => onAbort()}
        />
      </View>
    </>
  );
};
