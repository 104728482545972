/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import ShoppingCart from 'types/models/ShoppingCart';
import Product from 'types/models/Product';
import DetailItem from 'types/models/DetailItem';
import Order, { Payment } from 'types/models/Order';
import Benefit from 'types/models/Benefit';
import PaginationData from 'types/models/PaginationData';
import OrderDetail from 'types/models/OrderDetail';
import OrderDescription from 'types/models/OrderDescription';
import BenefitImage from 'types/models/BenefitImage';
import OrderStatuses from 'types/enums/OrderStatuses';
import { parseOrderDetailProduct, parseProducts, parseProductsForStockCheck } from './productsService';
import { parseCommerce } from './commercesService';
import { parseVenue } from './venuesServices';
import { parseProduct } from './productsService';

export const getOrders = async (page?: number, venueId?: string): Promise<PaginationData<Order> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `users/orders?page=${page ?? 1}&venueId=${venueId ?? ''}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseOrders(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
      lastPage: paginationData.last_page,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchLocalOrdersRemote = async (ids: number[], page?: number): Promise<PaginationData<Order> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'post',
      url: `orders/fetch-local-orders?page=${page ?? 1}`,
      data: {
        orderIds: ids,
      },
    });
    return {
      currentPage: paginationData.current_page,
      data: parseOrders(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
      lastPage: paginationData.last_page,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getOrder = async (id: number): Promise<Order | null> => {
  try {
    const { data: order } = await axiosInstance({
      method: 'get',
      url: `orders/${id}`,
    });
    return parseOrder(order);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const checkProductsStock = async (detailItems: DetailItem[]): Promise<Product[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'check-products-stock',
      data: {
        detailItems,
      },
    });
    return parseProductsForStockCheck(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getBenefitByUser = async (email: string, commerceId: string): Promise<Benefit | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'benefits/get/by-buyer-data',
      data: {
        email,
        commerceId,
      },
    });
    return parseBenefit(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const saveBuyersBenefit = async (
  beneficiaryEmail: string,
  benefitId: string,
  orderId: number,
): Promise<void> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'orders/save-buyer-benefit',
      data: {
        beneficiaryEmail,
        benefitId,
        orderId,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const storeOrder = async (
  order: ShoppingCart,
  venueId: string,
  isCash: boolean,
  isBalance: boolean,
  isForTotem: boolean,
  localTimeZone: string,
  eventId?: string,
  benefitId?: string,
  beneficiaryEmail?: string,
): Promise<Order | null> => {
  try {
    const { commerce, ...orderWithoutCommerceData } = order;
    const commerceId = commerce.id;
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'orders',
      data: {
        commerceId,
        ...orderWithoutCommerceData,
        venueId,
        isCash,
        isBalance,
        isForTotem,
        localTimeZone,
        benefitId,
        beneficiaryEmail,
        eventId,
      },
    });
    return parseOrder(data);
  } catch (error: any) {
    console.log(error.error);
    return null;
  }
};

export const getUserBalance = async (userId: string, venueId: string): Promise<number | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `users/${userId}/${venueId}/balance`,
    });
    return data;
  } catch (error: any) {
    console.log(error.error);
    return null;
  }
};

export const getSuggestedProducts = async (
  commerceId: string,
  currentOrderTotalAmount: number,
  ids: string[],
): Promise<Product[] | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'post',
      url: `commerces/${commerceId}/suggested-products`,
      data: {
        totalAmount: currentOrderTotalAmount,
        ids,
      },
    });
    return parseProducts(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};
export const parseOrders = (orders: []): Order[] => {
  return orders.map((order: any): Order => parseOrder(order));
};

export const parseOrder = (order: any): Order => ({
  id: order.id,
  totalAmount: parseFloat(order.total_amount),
  verificationCode: order.verification_code,
  orderStatusId: order.order_status_id,
  commerce: parseCommerce(order.commerce),
  phone: order.phone,
  identification: order.identification,
  isCash: order.is_cash === 1,
  isDiscount: order.is_discount === 1,
  createdAt: order.created_at,
  updatedAt: order.updated_at,
  localDateTime: order.local_datetime,
  buyer: order.buyer ?? '',
  delivery: order.delivery ?? '',
  guestEmail: order.guest_email ?? '',
  details: order.order_detail ? parseDetailsOrder(order.order_detail) : undefined,
  venue: order.venue ? parseVenue(order.venue) : undefined,
  payment: order.payment ? parsePayment(order.payment) : undefined,
});

const parsePayment = (payment: any): Payment => ({
  id: payment.id,
  createdAt: payment.created_at,
  isCash: Boolean(payment.is_cash),
  isBalance: Boolean(payment.is_balance),
});

export const translateOrderDescription = (orderStatusId: number, orderDescription: OrderDescription): string => {
  switch (orderStatusId) {
    case OrderStatuses.created:
      return orderDescription.orderCreated;
    case OrderStatuses.paymentAccepted:
      return orderDescription.orderPaymentAccepted;
    case OrderStatuses.preparing:
      return orderDescription.orderPreparing;
    case OrderStatuses.toDeliver:
      return orderDescription.orderToDeliver;
    case OrderStatuses.delivering:
      return orderDescription.orderDelivering;
    case OrderStatuses.delivered:
      return orderDescription.orderDelivered;
    default:
      return 'Unknown';
  }
};

const parseDetailsOrder = (details: []): OrderDetail[] => {
  return details.map((detail: any): OrderDetail => parseDetails(detail));
};

const parseDetails = (detail: any): OrderDetail => ({
  id: detail.id,
  product: parseOrderDetailProduct(detail.product),
  price: detail.price,
  quantity: detail.quantity,
  totalAmount: detail.total_amount,
  comments: detail.comments,
  estimatedPreparationTime: detail.estimated_preparation_time ?? undefined,
});

const parseBenefitImages = (images: any): BenefitImage[] => {
  return images.map(
    (item: any): BenefitImage => ({
      benefitId: item.pivot.benefit_id,
      benefitImageTypeId: item.pivot.benefit_image_type_id,
      path: item.path,
    }),
  );
};

const parseBenefit = (benefit: any): Benefit => ({
  id: benefit.id,
  totalAmountOrdersMin: benefit.total_amount_orders_min,
  totalAmountOrdersMax: benefit.total_amount_orders_max,
  totalCantOrdersMin: benefit.total_cant_orders_min,
  totalCantOrdersMax: benefit.total_cant_orders_max,
  discountAmount: benefit.discount_amount ?? undefined,
  benefitType: benefit.type_id,
  benefitDescription: benefit.benefit_description,
  product: benefit.product ? parseProduct(benefit.product) : undefined,
  images: benefit.images ? parseBenefitImages(benefit.images) : undefined,
});
