import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import OrderContext from 'contexts/OrderContext';
import Order from 'types/models/Order';
import UseChangeOrder from 'types/hooks/useChangeOrder';
import useChangeShoppingCart from './useChangeShoppingCart';

export default (): UseChangeOrder => {
  const { setCurrentOrder, setCurrentShoppingCart, setIzipayTransactionId } = React.useContext(OrderContext);
  const { deleteCurrentShoppingCart } = useChangeShoppingCart();

  const storeCurrentOrder = async (order: Order): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.currentOrder, JSON.stringify(order));
      setCurrentOrder(order);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentOrder = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.currentOrder);
      await deleteCurrentShoppingCart();
      if (setCurrentOrder) setCurrentOrder(null);
      if (setCurrentShoppingCart) setCurrentShoppingCart(null);
      if (setIzipayTransactionId) setIzipayTransactionId(null);
    } catch (error) {
      console.log(error);
    }
  };

  return { storeCurrentOrder, deleteCurrentOrder };
};
