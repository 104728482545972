import React from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment-timezone';
import { getIzipaySessionToken } from 'services/paymentsService';
import PaymentIzipayScreenProps from 'types/screens/PaymentIzipayScreenProps';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import { WEB_HOST } from '@env';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangeOrder from 'hooks/useChangeOrder';
import useChangePayment from 'hooks/useChangePayment';
import IzipayForm from 'components/IzipayForm';
import WebViewIzipayForm from 'components/WebViewIzipayForm';
import App from 'constants/App';

export default ({ navigation }: PaymentIzipayScreenProps): JSX.Element => {
  const { setIsLoading, currentVenue, currentAuthData } = React.useContext(AppContext);
  const {
    currentShoppingCart,
    currentOrder,
    resultPayment,
    setResultPayment,
    selectedPaymentConfig,
    izipayTransactionId,
    setIzipayTransactionId,
  } = React.useContext(OrderContext);
  const [izipayPaymentData, setIzipayPaymentData] = React.useState<any>();
  const [izipayToken, setIzipayToken] = React.useState<string | null>(null);
  const { storeCurrentShoppingCart } = useChangeShoppingCart();
  const { storeCurrentOrder, deleteCurrentOrder } = useChangeOrder();
  const { changeResultPayment } = useChangePayment();
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;

  const setFormData = async () => {
    const izipayConfig = selectedPaymentConfig?.izipayConfig;

    if (
      !currentShoppingCart ||
      !currentShoppingCart.processed ||
      currentShoppingCart.paid ||
      !currentOrder ||
      !izipayConfig
    ) {
      if (currentShoppingCart?.paid) {
        await deleteCurrentOrder();
      }
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }, 500);
      return;
    }
    await storeCurrentShoppingCart(currentShoppingCart);
    await storeCurrentOrder(currentOrder);

    const date = moment().valueOf();

    const dataForTokenRequest = {
      izipayConfigId: izipayConfig.id,
      izipayConfigCommerceCode: izipayConfig.commerceCode,
      transactionId: izipayTransactionId ? `0${izipayTransactionId}` : '00000' + currentOrder.id,
      orderId: '0000' + currentOrder.id,
      orderAmount: Number(currentOrder.totalAmount).toFixed(2),
    };
    const token = await getIzipaySessionToken(dataForTokenRequest);
    setIzipayTransactionId(dataForTokenRequest.transactionId);
    if (token) {
      setIzipayToken(token);
      setIzipayPaymentData({
        transactionId: dataForTokenRequest.transactionId,
        action: 'pay',
        merchantCode: dataForTokenRequest.izipayConfigCommerceCode,
        order: {
          orderNumber: dataForTokenRequest.orderId,
          currency: 'PEN',
          amount: dataForTokenRequest.orderAmount,
          payMethod: 'CARD',
          processType: 'AT',
          dateTimeTransaction: `${date}000`,
          merchantBuyerId: dataForTokenRequest.izipayConfigCommerceCode,
        },
        card: {
          brand: '',
          pan: '',
        },
        billing: {
          firstName: '',
          lastName: '',
          email: `${currentOrder.guestEmail ?? currentAuthData?.user.email}`,
          phoneNumber: `${currentOrder.phone}`,
          street: 'Azcuenaga 24',
          city: 'CABA',
          state: 'BuenosAires',
          country: 'PE',
          postalCode: '10290',
          document: '12345678',
          documentType: 'DNI',
        },
        render: {
          typeForm: 'pop-up',
          container: '#iframe-payment-izipay',
          showButtonProcessForm: true,
        },
        urlRedirect: `${Platform.OS === 'web' ? window.location.origin : WEB_HOST}/izipay-payment`,
        redirectUrls: {
          onSuccess: `${Platform.OS === 'web' ? window.location.origin : WEB_HOST}/izipay-payment`,
          onError: `${Platform.OS === 'web' ? window.location.origin : WEB_HOST}/izipay-payment`,
          onCancel: `${Platform.OS === 'web' ? window.location.origin : WEB_HOST}/izipay-payment`,
        },
        urlIPN: '',
        appearance: {
          styleInput: 'normal',
          logo: venueLogoImage,
          theme: 'red',
        },
      });
    } else {
      navigation.reset({
        index: 1,
        routes: [
          { name: 'HomeScreen' },
          {
            name: 'ShoppingCartScreen',
            params:
              Platform.OS === 'web'
                ? {
                    venueCode: currentVenue?.code,
                    commerceCode: currentShoppingCart.commerce.code,
                  }
                : undefined,
          },
        ],
      });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setFormData();
      return () => {
        setResultPayment(null);
      };
    }, []),
  );

  React.useEffect(() => {
    if (resultPayment) changeResultPayment(resultPayment);
  }, [resultPayment]);

  if (!selectedPaymentConfig?.izipayConfig || !currentOrder || !izipayPaymentData || !izipayToken) {
    return <></>;
  }

  return (
    <>
      {Platform.OS === 'web' ? (
        <IzipayForm
          setIsLoading={setIsLoading}
          orderId={currentOrder.id}
          token={izipayToken}
          setResultPayment={setResultPayment}
          izipayPaymentData={izipayPaymentData}
        />
      ) : (
        <WebViewIzipayForm
          platform={Platform.OS}
          setIsLoading={setIsLoading}
          orderId={currentOrder.id}
          token={izipayToken}
          setResultPayment={setResultPayment}
          izipayPaymentData={izipayPaymentData}
        />
      )}
    </>
  );
};
