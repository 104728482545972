import OrderStatuses from 'types/enums/OrderStatuses';
import DetailItem from 'types/models/DetailItem';

export const calculateTotalAmount = (detail: DetailItem[], discount?: number): number => {
  const prices = detail.map((item) => item.product.price * item.quantity);
  const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
  if (discount) {
    const totalWithDiscount = prices.reduce(reducer) - discount;
    if (totalWithDiscount <= 0) {
      return 0;
    } else {
      return totalWithDiscount;
    }
  } else {
    return prices.reduce(reducer);
  }
};

export const getTotalProducts = (detail: DetailItem[]) => {
  let acc = 0;
  for (const item of detail) acc += item.quantity;
  return acc;
};

export const getcolorStatusDescription = (orderStatusId: number) => {
  switch (orderStatusId) {
    case OrderStatuses.created:
      return '#EC1C24';
    case OrderStatuses.delivered:
      return '#00A80E';
    default:
      return '#F8A918';
  }
};
