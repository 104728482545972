import React from 'react';
import { ImageBackground, View, Text, Image, TouchableOpacity, Platform } from 'react-native';
import CommerceImageTypes from 'types/enums/CommerceImageTypes';
import CommerceItemProps from 'types/components/CommerceItemProps';
import AppContext from 'contexts/AppContext';
import useChangeCommerce from 'hooks/useChangeCommerce';
import useRedirectParams from 'hooks/useRedirectParams';
import { styles } from 'styles/components/commerceItem';

export default ({ commerce, navigation }: CommerceItemProps): JSX.Element => {
  const { storeCurrentCommerce } = useChangeCommerce();
  const { deleteRedirectCommerceParams } = useRedirectParams();
  const { currentVenue, redirectionItems } = React.useContext(AppContext);
  const commerceLogo = commerce.images.find((image) => image.commerceImageTypeId === CommerceImageTypes.logo);
  const commerceBanner = commerce.images.find((image) => image.commerceImageTypeId === CommerceImageTypes.banner);

  const onPressCommerce = async (id: string): Promise<void> => {
    const commerce = currentVenue?.commerces.find((commerce) => commerce.id === id);
    if (commerce) {
      if (redirectionItems) {
        await deleteRedirectCommerceParams();
      }
      await storeCurrentCommerce(commerce);
      navigation.navigate(
        'CommerceScreen',
        Platform.OS === 'web'
          ? {
              venueCode: currentVenue?.code,
              commerceCode: commerce.code,
            }
          : undefined,
      );
    }
  };

  return (
    <TouchableOpacity style={styles.container} onPress={() => onPressCommerce(commerce.id)}>
      <View style={styles.contentContainer}>
        <ImageBackground source={{ uri: commerceBanner?.path }} style={styles.imageBackgroundDimensions}>
          <View style={styles.internalSectionContainer}>
            {!commerce.hideLogoOnList && (
              <View style={styles.internalCommerceLogoContainer}>
                <Image source={{ uri: commerceLogo?.path }} style={styles.commerceLogo} />
              </View>
            )}
            <View style={styles.lowerCommerceBar}>
              <Text style={styles.commerceTitle}>{commerce.name}</Text>
              <Text style={styles.commerceAddress}>{commerce.address}</Text>
            </View>
          </View>
        </ImageBackground>
      </View>
    </TouchableOpacity>
  );
};
