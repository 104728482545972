import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import constantsStorage from 'constants/Storage';
import UseChangeVenue from 'types/hooks/UseChangeVenue';
import Venue from 'types/models/Venue';
import useChangeCommerce from './useChangeCommerce';
import useChangeOrder from './useChangeOrder';
import { getCommercesByVenue } from 'services/commercesService';
import { getProductsByCommerce } from 'services/productsService';
import { getCommerceCategoriesByVenue } from 'services/commerceCategoriesService';
import { getActiveEvents } from 'services/eventServices';
import AppContext from 'contexts/AppContext';

export default (): UseChangeVenue => {
  const { i18n } = useTranslation();
  const { deleteCurrentCommerce } = useChangeCommerce();
  const { deleteCurrentOrder } = useChangeOrder();
  const { currentVenue, setCurrentVenue, redirectionItems } = React.useContext(AppContext);
  const [selectedVenue, setSelectedVenue] = React.useState<Venue | null>(currentVenue);

  const storeCurrentVenue = async (venue: Venue, isForUpdate: boolean): Promise<void> => {
    try {
      venue.categories = await getCommerceCategoriesByVenue(venue.id);
      venue.commerces = await getCommercesByVenue(venue.id);
      venue.events = await getActiveEvents(venue.id, redirectionItems?.isForTotemDisplay?.length ? true : false);
      const addingProductsToCommerces = venue.commerces.map(async (commerce) => {
        const productsByCommerce = await getProductsByCommerce(commerce.id);
        if (productsByCommerce.length > 0) {
          commerce.products = productsByCommerce;
        }
      });
      await Promise.all(addingProductsToCommerces);
      i18n.changeLanguage(venue?.language);
      await AsyncStorage.setItem(constantsStorage.currentVenue, JSON.stringify(venue));
      setCurrentVenue(venue);
      if (!isForUpdate) await emptyData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentVenue = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.currentVenue);
      await emptyData();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentPairedMPPoint = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.currentPairedMPPoint);
    } catch (error) {
      console.log(error);
    }
  };

  const emptyData = async (): Promise<void> => {
    await deleteCurrentCommerce();
    await deleteCurrentOrder();
    await deleteCurrentPairedMPPoint();
  };

  return { storeCurrentVenue, deleteCurrentVenue, selectedVenue, setSelectedVenue };
};
