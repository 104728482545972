import React from 'react';
import { ScrollView, View, Platform, Text } from 'react-native';
import moment from 'moment-timezone';
import { Button } from 'react-native-elements';
import { Icon as RNIcon } from 'react-native-elements';
import { CommonActions, useFocusEffect, useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import ContentHeader from 'components/ContentHeader';
import OrderDetailList from 'components/OrderDetailList';
import OrderResponseModal from 'components/OrderResponseModal';
import useChangeOrder from 'hooks/useChangeOrder';
import useLocalOrders from 'hooks/useLocalOrders';
import useTimeout from 'hooks/useTimeout';
import OrderDetailScreenProps from 'types/screens/OrderDetailScreenProps';
import AppContext from 'contexts/AppContext';
import { greenColor } from 'styles/global';
import { printTicket } from 'utils/setupTicket';
import OrderContext from 'contexts/OrderContext';
import { getOrder, translateOrderDescription } from 'services/ordersService';
import ShoppingCart from 'types/models/ShoppingCart';
import OrderStatuses from 'types/enums/OrderStatuses';
import Order from 'types/models/Order';
import { styles } from 'styles/screens/orderDetailScreen';
import { getcolorStatusDescription } from 'utils/orders';
import OrderDescription from 'types/models/OrderDescription';

export default ({ navigation, route }: OrderDetailScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectedOrder,
    modalizeData,
    newlyCreatedOrder,
    currentVenue,
    redirectionItems,
    cloverConnected,
    pairedWithClover,
    setOrderModalResponseData,
  } = React.useContext(AppContext);
  const { currentOrder, setCurrentShoppingCart, setCurrentOrder } = React.useContext(OrderContext);
  const { isLoading, setIsLoading } = React.useContext(AppContext);
  const [orderWithDetails, setOrderWithDetails] = React.useState<Order | null>(null);
  const { deleteCurrentOrder, storeCurrentOrder } = useChangeOrder();
  const { updateLocalOrderStatus } = useLocalOrders();
  const redirectNavigator = useNavigation();
  cloverConnected?.showMessage(`Muchas gracias por su compra, su Nro de orden es ${selectedOrder?.id}.`);
  const params = route.params;
  const [showModal, setShowModal] = React.useState<boolean>(params?.showModal ?? false);

  const orderDescriptions: OrderDescription = {
    orderCreated: t('pending-payment'),
    orderPaymentAccepted: t('payment-accepted'),
    orderPreparing: t('preparing-order'),
    orderToDeliver: t('order-ready-to-deliver'),
    orderDelivering: t('delivering-order'),
    orderDelivered: t('order-delivered'),
  };

  const onBackToOrders = () => {
    deleteCurrentOrder();
    navigation.navigate('OrdersScreen');
  };

  const redirectToShoppingCart = (venueCode: string, commerceCode: string) => {
    navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Home',
            state: {
              routes: [
                {
                  name: 'ShoppingCartScreen',
                  params:
                    Platform.OS === 'web'
                      ? {
                          venueCode,
                          commerceCode,
                        }
                      : undefined,
                },
              ],
            },
          },
        ],
      }),
    });
  };

  const redirectToHome = () => {
    navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Home',
            state: {
              routes: [
                {
                  name: 'HomeScreen',
                },
              ],
            },
          },
        ],
      }),
    });
  };

  const fetchOrderDetails = async () => {
    if (!selectedOrder) return;
    const order = await getOrder(selectedOrder.id);
    if (order) {
      if (newlyCreatedOrder && currentOrder) {
        order.orderStatusId = currentOrder.orderStatusId;
      }
      setOrderWithDetails(order);
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setIsLoading(true);
      fetchOrderDetails();
      setIsLoading(false);

      return () => {
        const resetParams = () => {
          navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [{ name: 'OrdersScreen' }],
            }),
          );
        };
        resetParams();
      };
    }, []),
  );

  React.useEffect(() => {
    const orderDetailInterval = setInterval(() => {
      fetchOrderDetails();
    }, 30000);
    return () => {
      clearInterval(orderDetailInterval);
    };
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      if (!selectedOrder) {
        setTimeout(() => {
          redirectToHome();
        }, 500);
        return;
      }
      if (newlyCreatedOrder && showModal === true) {
        setOrderModalResponseData({
          content: (
            <OrderResponseModal
              message={selectedOrder.delivery ?? ''}
              success={true}
              orderNumber={selectedOrder.id}
              verificationCode={selectedOrder.verificationCode}
              onClose={() => {
                setShowModal(false);
                setOrderModalResponseData(null);
              }}
              navigation={navigation}
            />
          ),
        });

        if (pairedWithClover && Platform.OS === 'web') {
          printTicket({
            data: selectedOrder,
            orderNum: selectedOrder.id,
          });
        }

        deleteCurrentOrder();
      }
    }, [selectedOrder, newlyCreatedOrder]),
  );

  if (newlyCreatedOrder) {
    useTimeout(() => {
      // La redirección al home automáticamente se hace solo cuando está conectado a un clover
      if (pairedWithClover && Platform.OS === 'web') {
        redirectNavigator.reset({
          index: 0,
          routes: [
            {
              name: 'Home',
              state: {
                routes: [
                  {
                    name: 'CommerceScreen',
                    params: redirectionItems ?? undefined,
                  },
                ],
              },
            },
          ],
        });
        setOrderModalResponseData(null);
      }
    }, 5000);
  }

  React.useEffect(() => {
    const saveOrders = async () => {
      if (!orderWithDetails) return;
      updateLocalOrderStatus(orderWithDetails.orderStatusId, orderWithDetails);
    };
    saveOrders();
  }, [orderWithDetails]);

  const onContinuePayment = async () => {
    if (!selectedOrder || !orderWithDetails || !orderWithDetails.details) return;
    const shoppingCart: ShoppingCart = {
      commerce: {
        id: orderWithDetails.commerce.id,
        code: orderWithDetails.commerce.code,
        name: orderWithDetails.commerce.name,
      },
      detail: orderWithDetails.details,
      paid: false,
      processed: true,
      phone: orderWithDetails.phone,
      isCash: orderWithDetails.isCash,
      guestEmail: orderWithDetails.guestEmail ?? '',
      delivery: orderWithDetails.delivery ?? '',
      identification: orderWithDetails.identification ?? 0,
    };
    const newOrder: Order = {
      id: orderWithDetails.id,
      totalAmount: orderWithDetails.totalAmount,
      verificationCode: orderWithDetails.verificationCode,
      orderStatusId: orderWithDetails.orderStatusId,
      commerce: orderWithDetails.commerce,
      phone: orderWithDetails.phone,
      identification: orderWithDetails.identification,
      isCash: orderWithDetails.isCash,
      isDiscount: orderWithDetails.isDiscount,
      createdAt: orderWithDetails.createdAt,
      updatedAt: orderWithDetails.updatedAt,
      localDateTime: orderWithDetails.localDateTime,
      buyer: orderWithDetails.buyer ?? '',
      delivery: orderWithDetails.delivery ?? '',
      guestEmail: orderWithDetails.guestEmail ?? '',
      details: orderWithDetails.details,
      venue: selectedOrder.venue,
    };
    setCurrentShoppingCart(shoppingCart);
    setCurrentOrder(newOrder);
    await storeCurrentOrder(newOrder);
    redirectToShoppingCart(newOrder?.venue?.code ?? '', shoppingCart.commerce.code);
  };

  const renderContent = (): JSX.Element => {
    if (isLoading || !orderWithDetails || !orderWithDetails.details) return <></>;
    const { commerce } = orderWithDetails;
    return (
      <View>
        <View style={styles.orderStatusContainer}>
          <RNIcon
            name={'check-circle'}
            color={getcolorStatusDescription(selectedOrder?.orderStatusId ?? 1)}
            size={65}
          />
          <Text
            style={[
              styles.statusDescriptionText,
              { color: getcolorStatusDescription(selectedOrder?.orderStatusId ?? 1) },
            ]}
          >
            {translateOrderDescription(orderWithDetails.orderStatusId, orderDescriptions)}
          </Text>
        </View>
        <View style={styles.detailsContainer}>
          <Text style={styles.commerceNameText}>{commerce.name}</Text>
          <OrderDetailList detail={orderWithDetails.details} />
          <View style={styles.separator} />
          <View>
            <Text
              style={{
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: 16,
                lineHeight: 20,
              }}
            >
              Detalle de pedido
            </Text>
            <View style={{ padding: 10 }}>
              <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                <Text style={styles.detailPaymentText}>Realizado</Text>
                <Text style={styles.detailPaymentText}>
                  {moment(orderWithDetails.localDateTime).format('DD/MM/YY - HH:mm')}hs
                </Text>
              </View>
              {orderWithDetails.payment ? (
                <>
                  <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <Text style={styles.detailPaymentText}>Abonado</Text>
                    <Text style={styles.detailPaymentText}>
                      {moment(orderWithDetails.payment.createdAt).format('DD/MM/YY - HH:mm')}hs
                    </Text>
                  </View>

                  <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    <Text style={styles.detailPaymentText}>Método de pago</Text>
                    <Text style={styles.detailPaymentText}>
                      {orderWithDetails.payment.isBalance && 'Saldo en cuenta'}
                      {orderWithDetails.payment.isCash && 'Efectivo'}
                    </Text>
                  </View>
                </>
              ) : null}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const orderIsCash = orderWithDetails && orderWithDetails.isCash;
  const orderIsDiscount = orderWithDetails && orderWithDetails.isDiscount;

  return (
    <View style={styles.container}>
      <ScrollView style={styles.scrollviewStyle} scrollEnabled={!modalizeData}>
        <ContentHeader
          title={`${t('order-detail-title')} ${orderWithDetails?.id}`}
          hasOrders={true}
          onPreviousScreen={onBackToOrders}
        />
        {renderContent()}
      </ScrollView>
      {!newlyCreatedOrder &&
        !orderIsCash &&
        !orderIsDiscount &&
        orderWithDetails &&
        orderWithDetails.orderStatusId === OrderStatuses.created && (
          <Button
            title={t('finalize-purchase')}
            type="clear"
            containerStyle={[styles.confirmPaymentBtnContainer, { outlineStyle: 'none' }]}
            buttonStyle={[styles.confirmPaymentBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
            titleStyle={styles.confirmPaymentBtnText}
            onPress={() => {
              onContinuePayment();
            }}
          />
        )}
    </View>
  );
};
