import React from 'react';
import { Card, Button } from 'react-native-elements';
import { RadioButton } from 'react-native-paper';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Loading, SuccessModal } from '@double_point/rn-components';
import PairMPPointModalProps from 'types/components/PairMPPointModalProps';
import { pairMPPoint } from 'services/paymentsService';
import constantsStorage from 'constants/Storage';
import AppContext from 'contexts/AppContext';
import { greenColor } from 'styles/global';
import { styles } from 'styles/components/pairMPPointModal';

export default ({
  availableMPPoints,
  currentCommerce,
  setCurrentPairedMPPoint,
}: PairMPPointModalProps): JSX.Element => {
  const [selectedMPPointId, setSelectedMPPointId] = React.useState<string | null>(null);
  const navigation = useNavigation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { currentVenue, setModalizeData } = React.useContext(AppContext);

  const modalMessageContent = (message: string, isSuccess: boolean): JSX.Element => (
    <SuccessModal
      isSuccess={isSuccess}
      title={'Emparejar posnet'}
      mainColor={currentVenue?.appColor ?? greenColor}
      message={message}
      buttonText={'volver'}
      onConfirm={() => {
        setModalizeData(null);
        navigation.navigate('CommerceScreen');
      }}
    />
  );

  const openModalMessage = (message: string, success: boolean) => {
    setModalizeData({
      content: modalMessageContent(message, success),
      closeOnOverlayTap: false,
    });
  };

  return (
    <View>
      {isLoading && <Loading />}
      <Card.Title style={styles.cardTitle}>Enlazar posnet de Mercado Pago</Card.Title>
      <Card.Divider />
      <View style={styles.radioButtonGroupContainer}>
        <Text style={styles.caption}>Seleccione el posnet disponible para enlazar al sistema</Text>
        <View style={styles.radioGroup}>
          {availableMPPoints.map((mpPoint, index) => (
            <TouchableOpacity key={index} onPress={() => setSelectedMPPointId(mpPoint.id)}>
              <View key={index} style={styles.radioButtonOption}>
                <Text style={styles.radioButtonLabel}>{mpPoint.description}</Text>
                <RadioButton value={mpPoint.id} status={selectedMPPointId === mpPoint.id ? 'checked' : 'unchecked'} />
              </View>
            </TouchableOpacity>
          ))}
          <Button
            title={'Confirmar posnet'}
            type="clear"
            containerStyle={[styles.confirmMPPointBtnContainer, { outlineStyle: 'none' }]}
            buttonStyle={[styles.confirmMPPointBtn, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
            titleStyle={styles.confirmMPPointBtnText}
            disabled={!selectedMPPointId}
            disabledStyle={[styles.confirmMPPointBtn, styles.confirmMPPointBtnDisabled]}
            disabledTitleStyle={styles.confirmMPPointBtnText}
            onPress={async () => {
              if (!currentCommerce || !currentCommerce.paymentConfig?.mercadoPagoPointConfigId || !selectedMPPointId)
                return;
              setIsLoading(true);
              const mpPoint = await pairMPPoint(
                currentCommerce.paymentConfig?.mercadoPagoPointConfigId,
                selectedMPPointId,
                true,
              );
              if (mpPoint) {
                await AsyncStorage.setItem(constantsStorage.currentPairedMPPoint, JSON.stringify(mpPoint));
                setCurrentPairedMPPoint(mpPoint);
                const message = 'Posnet emparejado correctamente';
                openModalMessage(message, true);
              } else {
                const message = 'Hubo un error al intentar enlazar el posnet. Intente más tarde';
                openModalMessage(message, false);
              }
              setIsLoading(false);
            }}
          />
        </View>
      </View>
    </View>
  );
};
