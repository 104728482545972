import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import AppContext from 'contexts/AppContext';
import UseChangeAuthData from 'types/hooks/useChangeAuthData';
import AuthData from 'types/models/AuthData';

export default (): UseChangeAuthData => {
  const { setCurrentAuthData } = React.useContext(AppContext);

  const storeCurrentAuthData = async (authData: AuthData): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.authData, JSON.stringify(authData));
      setCurrentAuthData(authData);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentAuthData = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.authData);
      setCurrentAuthData(null);
    } catch (error) {
      console.log(error);
    }
  };

  return { storeCurrentAuthData, deleteCurrentAuthData };
};
