import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text, ScrollView, useWindowDimensions, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import PaymentMPPointScreenProps from 'types/screens/PaymentMPPointScreenProps';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import useChangeOrder from 'hooks/useChangeOrder';
import useChangePayment from 'hooks/useChangePayment';
import { styles } from 'styles/screens/paymentMPPointScreen';
import { storeMPPointPaymentIntent, getMPPointPaymentIntent } from 'services/paymentsService';
import MPPointPaymentIntentStates from 'types/enums/MPPointPaymentIntentStates';
import GreenButtonMPPoint from 'assets/images/green-button-mp-point.png';
import { greenColor } from 'styles/global';
import HandleMPPointPaymentError from 'components/HandleMPPointPaymentError';

export default ({ navigation }: PaymentMPPointScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, setIsLoading, setModalizeData } = React.useContext(AppContext);
  const { currentShoppingCart, currentOrder, resultPayment, setResultPayment, currentPairedMPPoint, currentCommerce } =
    React.useContext(OrderContext);
  const { deleteCurrentOrder, storeCurrentOrder } = useChangeOrder();
  const { changeResultPayment } = useChangePayment();
  const { height } = useWindowDimensions();

  useFocusEffect(
    React.useCallback(() => {
      handleMPPoitPaymentIntent();
    }, [currentPairedMPPoint]),
  );

  const handleMPPoitPaymentIntent = () => {
    if (!currentOrder || currentOrder.mpPointPaymentIntent || !currentCommerce?.paymentConfig?.mercadoPagoPointConfigId)
      return;
    setIsLoading(true);
    createMPPointPaymentIntent();
    setIsLoading(false);
  };

  const checkMPPointPaymentIntent = async (): Promise<void> => {
    if (!currentOrder || !currentOrder.mpPointPaymentIntent) return;
    const notSuccessfullyStates = [
      MPPointPaymentIntentStates.Open,
      MPPointPaymentIntentStates.OnTerminal,
      MPPointPaymentIntentStates.Processing,
    ];
    const successfullyStates = [MPPointPaymentIntentStates.Processed, MPPointPaymentIntentStates.Finished];
    const paymentIntent = await getMPPointPaymentIntent(currentOrder.mpPointPaymentIntent.id);
    if (paymentIntent) {
      if (notSuccessfullyStates.includes(paymentIntent.state)) {
        currentOrder.mpPointPaymentIntent = paymentIntent;
        await storeCurrentOrder({ ...currentOrder });
        setTimeout(() => {
          checkMPPointPaymentIntent();
        }, 1000);
      } else if (successfullyStates.includes(paymentIntent.state)) {
        currentOrder.mpPointPaymentIntent = paymentIntent;
        await storeCurrentOrder({ ...currentOrder });
        setResultPayment({ success: true });
      } else {
        setModalizeData({
          content: modalErrorMessageContent(),
          closeOnOverlayTap: true,
        });
      }
    } else {
      setModalizeData({
        content: modalErrorMessageContent(),
        closeOnOverlayTap: true,
      });
    }
  };

  const onRetryPayment = async () => {
    if (!currentOrder) return;
    currentOrder.mpPointPaymentIntent = undefined;
    await storeCurrentOrder({ ...currentOrder });
    setModalizeData(null);
    handleMPPoitPaymentIntent();
  };
  const onCancelOrder = async () => {
    await deleteCurrentOrder();
    setModalizeData(null);
    setTimeout(() => {
      navigation.reset({
        index: 0,
        routes: [{ name: 'CommerceScreen' }],
      });
    }, 500);
  };
  const modalErrorMessageContent = (): JSX.Element => (
    <HandleMPPointPaymentError onRetryPayment={onRetryPayment} onCancelOrder={onCancelOrder} />
  );

  const createMPPointPaymentIntent = async (): Promise<void> => {
    if (!currentOrder || !currentPairedMPPoint) return;
    const data = { orderId: currentOrder.id, mpPointId: currentPairedMPPoint.id };
    const paymentIntent = await storeMPPointPaymentIntent(data);
    if (paymentIntent) {
      currentOrder.mpPointPaymentIntent = paymentIntent;
      await storeCurrentOrder({ ...currentOrder });
      checkMPPointPaymentIntent();
    }
  };

  React.useEffect(() => {
    if (resultPayment) changeResultPayment(resultPayment);
  }, [resultPayment]);

  const setData = async () => {
    if (!currentShoppingCart || !currentVenue || !currentOrder) {
      if (currentShoppingCart?.paid) {
        await deleteCurrentOrder();
      }
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }, 500);
      return;
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setData();
      return () => {
        setResultPayment(null);
      };
    }, []),
  );

  return (
    <ScrollView style={styles.container}>
      <View>
        {!currentOrder?.mpPointPaymentIntent ? (
          <View style={[styles.creatingPaymentIntent, { top: height * 0.4 }]}>
            <Text style={styles.creatingPaymentIntentText}>Por favor, espere un momento</Text>
          </View>
        ) : (
          <View style={styles.paymentIntentCreated}>
            <Text style={styles.paymentIntentCreatedText}>{t('mercado-pago-point-can-make-payment')}</Text>
            <Text style={[styles.paymentInstructions, { color: currentVenue?.appColor ?? greenColor }]}>
              Presione el botón verde en el posnet y acerque la tarjeta para realizar el pago.
            </Text>
            <Image source={GreenButtonMPPoint} style={styles.mpPointImage} />
          </View>
        )}
      </View>
    </ScrollView>
  );
};
