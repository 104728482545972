import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  subTitle: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 20,
    color: '#000',
  },
});
