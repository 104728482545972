import React from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import RedirectButtonProps from 'types/components/RedirectButtonProps';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/redirectButton';
import { greenColor } from 'styles/global';

export default ({ onPressRedirect }: RedirectButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, redirectionItems } = React.useContext(AppContext);

  return (
    <View style={styles.redirectionBtnContainer}>
      <TouchableOpacity onPress={onPressRedirect} style={{ flex: 1 }}>
        <Text style={[styles.redirectionToCommerceText, { color: currentVenue?.appColor ?? greenColor }]}>{`${t(
          'return-to-commerce',
        )} ${redirectionItems?.commerceNameForRedirection}`}</Text>
      </TouchableOpacity>
    </View>
  );
};
