import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import AppSectionProps from 'types/components/AppSectionProps';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/appSection';
import { greenColor } from 'styles/global';

export default ({ title, onPressConfig, children }: AppSectionProps): JSX.Element => {
  const { currentVenue } = React.useContext(AppContext);

  return (
    <>
      <View style={styles.headerContainer}>
        <Text style={[styles.title, { color: currentVenue?.appColor ?? greenColor }]}>{title}</Text>
      </View>
      {children}
      {onPressConfig ? (
        <TouchableOpacity style={{ outlineStyle: 'none' }} onPress={onPressConfig.onPress}>
          <Text style={styles.onPressText}>{onPressConfig.text}</Text>
        </TouchableOpacity>
      ) : null}
    </>
  );
};
