import React from 'react';
import { ScrollView, View, Text } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import AddCardScreenProps from 'types/screens/AddCardScreenProps';
import useIcon from 'hooks/useIcon';
import useChangeOrder from 'hooks/useChangeOrder';
import useChangePayment from 'hooks/useChangePayment';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import AddCardForm from 'forms/AddCardForm';
import { styles } from 'styles/screens/addCardScreen';
import { greenColor } from 'styles/global';

export default ({ navigation }: AddCardScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const Icon = useIcon();
  const { currentVenue } = React.useContext(AppContext);
  const { currentShoppingCart, currentOrder, resultPayment, setResultPayment, selectedCard } =
    React.useContext(OrderContext);
  const { deleteCurrentOrder } = useChangeOrder();
  const { changeResultPayment } = useChangePayment();

  React.useEffect(() => {
    if (resultPayment) changeResultPayment(resultPayment);
  }, [resultPayment]);

  const setData = async () => {
    if (!currentShoppingCart || !currentVenue || !currentOrder) {
      if (currentShoppingCart?.paid) {
        await deleteCurrentOrder();
      }
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }, 500);
      return;
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setData();
      return () => {
        setResultPayment(null);
      };
    }, []),
  );

  return (
    <ScrollView style={styles.container}>
      <View style={styles.header}>
        <Icon
          name="arrow-left"
          style={[styles.header_icon, { color: currentVenue?.appColor ?? greenColor }]}
          onPress={() => navigation.goBack()}
        />
        <Text style={[styles.header_title, { color: currentVenue?.appColor ?? greenColor }]}>
          {selectedCard ? `${selectedCard.paymentMethod?.name} ${selectedCard.ccLastFourDigits}` : `${t('new-card')}`}
        </Text>
      </View>
      <AddCardForm />
    </ScrollView>
  );
};
