import React from 'react';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import moment from 'moment-timezone';
import FormFD from 'components/FormFD';
import WebViewFDForm from 'components/WebViewFDForm';
import { getHash } from 'services/paymentsService';
import PaymentFDScreenProps from 'types/screens/PaymentFDScreenProps';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangeOrder from 'hooks/useChangeOrder';
import useChangePayment from 'hooks/useChangePayment';
import { WEB_HOST } from '@env';

export default ({ navigation }: PaymentFDScreenProps): JSX.Element => {
  const { setIsLoading } = React.useContext(AppContext);
  const { currentShoppingCart, currentOrder, resultPayment, setResultPayment, selectedPaymentConfig } =
    React.useContext(OrderContext);
  const [paymentData, setPaymentData] = React.useState<any>();
  const { storeCurrentShoppingCart } = useChangeShoppingCart();
  const { storeCurrentOrder, deleteCurrentOrder } = useChangeOrder();
  const { changeResultPayment } = useChangePayment();

  const setFormData = async () => {
    const firstDataConfig = selectedPaymentConfig?.firstDataConfig;
    if (
      !currentShoppingCart ||
      !currentShoppingCart.processed ||
      currentShoppingCart.paid ||
      !currentOrder ||
      !firstDataConfig
    ) {
      if (currentShoppingCart?.paid) {
        await deleteCurrentOrder();
      }
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }, 500);
      return;
    }
    await storeCurrentShoppingCart(currentShoppingCart);
    await storeCurrentOrder(currentOrder);
    const date = moment();
    const dateTime = date.tz(firstDataConfig.timeZone).format('YYYY:MM:DD-HH:mm:ss');
    const paymentDataFD = {
      firstDataConfigId: firstDataConfig.id,
      dateTime,
      chargeTotal: Number(currentOrder.totalAmount).toFixed(2),
    };
    const hash = await getHash(paymentDataFD);
    if (hash) {
      setPaymentData({
        dateTime,
        hash,
        chargeTotal: paymentDataFD.chargeTotal,
      });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setFormData();
      return () => {
        setResultPayment(null);
      };
    }, []),
  );

  React.useEffect(() => {
    if (resultPayment) changeResultPayment(resultPayment);
  }, [resultPayment]);

  if (!selectedPaymentConfig?.firstDataConfig || !currentOrder || !paymentData) {
    return <></>;
  }
  const firstDataConfig = selectedPaymentConfig.firstDataConfig;
  return (
    <>
      {Platform.OS === 'web' ? (
        <FormFD
          webHost={`${window.location.origin}/`}
          orderId={currentOrder.id}
          timeZone={firstDataConfig.timeZone}
          fdStore={firstDataConfig.fdStore}
          currency={firstDataConfig.currency}
          dateTime={paymentData.dateTime}
          hash={paymentData.hash}
          chargeTotal={paymentData.chargeTotal}
          setIsLoading={setIsLoading}
          setResultPayment={setResultPayment}
        />
      ) : (
        <WebViewFDForm
          webHost={WEB_HOST}
          orderId={currentOrder.id}
          timeZone={firstDataConfig.timeZone}
          fdStore={firstDataConfig.fdStore}
          currency={firstDataConfig.currency}
          dateTime={paymentData.dateTime}
          hash={paymentData.hash}
          chargeTotal={paymentData.chargeTotal}
          setIsLoading={setIsLoading}
          setResultPayment={setResultPayment}
        />
      )}
    </>
  );
};
