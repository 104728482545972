import { Platform, StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  productInfo: {
    flexDirection: 'row',
    marginVertical: 15,
  },
  productImageColumn: {
    justifyContent: 'center',
  },
  productImage: {
    width: 95,
    height: 95,
    borderRadius: 12,
  },
  productBackgroundImage: {
    width: 95,
    height: 95,
    borderRadius: 12,
  },
  productDataColumn: {
    width: '70%',
    justifyContent: 'center',
    paddingLeft: 15,
  },
  productDataShortColumn: {
    width: '50%',
  },
  productName: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  productPrice: {
    fontSize: 18,
    color: '#89909b',
  },
  commerceName: {
    color: '#89909b',
  },
  trashIconContainer: {
    alignSelf: 'flex-end',
    marginTop: 20,
    marginRight: 5,
  },
  controlQuantityContainer: {
    flexDirection: 'row',
  },
  trashIcon: {
    color: '#ff5050',
    fontSize: 30,
  },
  iconControlQuantity: {
    fontSize: 25,
  },
  quantityNumber: {
    fontSize: 18,
  },
  benefitIconContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    marginLeft: 20,
  },
  benefitIcon: {
    fontSize: 25,
    marginRight: 25,
    textAlign: 'justify',
  },
  productPriceOnListContainer: {
    position: 'absolute',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    alignItems: 'center',
    right: 0,
    marginLeft: 20,
    top: 0,
    borderRadius: 6,
    backgroundColor: '#b7b7b7',
  },
  productPriceOnListText: {
    color: '#FFF',
    fontSize: 18,
    fontWeight: '500',
    textAlignVertical: 'center',
    textAlign: 'center',
    flex: 1,
    flexShrink: 1,
    marginHorizontal: 5,
  },
  productItem: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    marginTop: 15,
    borderRadius: 20,
    alignSelf: 'center',
    width: '90%',
    padding: 10,
    backgroundColor: '#FFFFFF',
    shadowColor: Platform.OS === 'android' ? 'rgba(0, 0, 0, 0.30)' : '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 10,
  },
  controlQuantityContainerNew: {
    borderWidth: 2,
    height: 30,
    paddingTop: 3,
    paddingLeft: 7,
    paddingRight: 7,
    width: 85,
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  productQuantityText: {
    marginTop: -3,
    fontWeight: '500',
    fontSize: 18,
  },
  productImageContainer: {
    width: '30%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  productImageContainerForTotem: {
    width: 394,
    height: 303,
  },
  productImageNew: {
    width: 90,
    height: 90,
  },
  productImageForTotem: {
    width: '100%',
    height: '100%',
    borderBottomRightRadius: 40,
    borderTopRightRadius: 40,
  },
  productInfoContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
  },
  productNameDescription: {
    paddingTop: 2,
    paddingLeft: 4,
  },
  priceQuantityContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  priceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  priceText: {
    padding: 5,
    fontWeight: '700',
    fontSize: 14,
    lineHeight: 20,
    letterSpacing: 0.25,
  },
  addToCartControlQuantity: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },
});
