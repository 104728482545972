import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  noCurrentVenueContainer: {
    flex: 1,
  },
  homeDataContainer: {
    borderTopLeftRadius: 30,
    backgroundColor: '#ffffff',
    borderTopRightRadius: 30,
  },
  promotionsContent: {
    marginHorizontal: 10,
  },
  image: {
    width: '100%',
    height: 200,
    justifyContent: 'center',
    alignItems: 'center',
    resizeMode: 'contain',
  },
  sponsorBannerContainer: {
    marginTop: 20,
    height: 130,
    width: 340,
    alignSelf: 'center',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'row',
  },
  sponsorImageBanner: {
    height: 130,
    width: 340,
    borderRadius: 15,
  },
  downloadBannerContainer: {
    marginTop: 20,
    height: 70,
    width: 340,
    alignSelf: 'center',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'row',
  },
  downloadImageBanner: {
    height: 70,
    width: 340,
    borderRadius: 15,
  },
  twitterFeedContainer: {
    width: 340,
  },
  discountsContainer: {
    marginBottom: 15,
  },
  chatbotContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
    position: 'absolute',
    bottom: 20,
    right: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chatbotLabel: {
    backgroundColor: '#fff',
    borderWidth: 1,
    padding: 6,
    marginRight: 5,
    borderRadius: 15,
    borderColor: 'rgba(0,0,0,0.2)',
  },
  supportAgentIconBtn: {
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.2)',
    alignItems: 'center',
    justifyContent: 'center',
    width: 70,
    height: 70,
    backgroundColor: '#fff',
    borderRadius: 100,
  },
  tabBarContainer: {
    borderBottomColor: '#f4f4f4',
    borderBottomWidth: 1,
  },
  tabContainer: {
    orderBottomColor: '#090909',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 5,
  },
  activeTabItem: {
    borderBottomWidth: 2,
  },
  tabText: {
    padding: 10,
    color: '#9e9e9e',
    fontSize: 18,
    fontWeight: '500',
  },
  scrollTypesContainer: {
    alignSelf: 'center',
    marginTop: 20,
    height: 50,
  },
});
