import Product from 'types/models/Product';
import { AdvertisingBannerProps } from '@double_point/rn-components';

export const parsePromotionsToCardItems = (
  promotions: Product[],
  parsePromotionItem: (promotion: Product, venueLogoImage: string) => AdvertisingBannerProps,
  venueLogoImage?: string,
): AdvertisingBannerProps[] => {
  return promotions.map((promotion: Product) => parsePromotionItem(promotion, venueLogoImage ?? ''));
};
