import React, { memo, useEffect, useState } from 'react';
import { Image, ImageBackground, Text, View } from 'react-native';
import AnimatedNumbers from 'react-native-animated-numbers';
import { getWidgetData } from 'services/widgetService';
import { styles } from 'styles/components/JugaBetWidget';
import BettingWidgetProps from 'types/components/BettingWidgetProps';
import type WidgetResponse from 'types/models/WidgetResponse';
import RaysBackground from 'assets/images/rays-background.png';
import JugabetLogo from 'assets/images/jugabet-logo.png';

export default ({ url }: BettingWidgetProps): JSX.Element => {
  const [widget, setWidget] = useState<WidgetResponse | null>(null);

  useEffect(() => {
    const fetch = () => {
      if (url) getWidgetData(url).then(setWidget);
    };

    fetch(); // for the 1st render
    const interval = setInterval(fetch, 1000 * 30); // 30s

    return () => clearInterval(interval);
  }, [url]);

  if (!widget) return <></>;

  return (
    <View style={styles.container}>
      <ImageBackground
        style={styles.image}
        source={{
          uri: RaysBackground,
          cache: 'force-cache',
        }}
      >
        <View style={styles.content}>
          <View style={styles.top}>
            <Image style={styles.shield} source={{ uri: widget.p1Icon }} />
            <Image
              style={styles.logo}
              source={{
                uri: JugabetLogo,
                cache: 'force-cache',
              }}
            />
            <Image style={styles.shield} source={{ uri: widget.p2Icon }} />
          </View>

          <View style={styles.bottom}>
            <View style={styles.box}>
              <AnimatedNumberCounter value={widget.coffP1} />
              <Text style={styles.competitor}>{widget.p1}</Text>
            </View>
            <View style={styles.box}>
              <AnimatedNumberCounter value={widget.coffDraw} />
              <Text style={styles.competitor}>X</Text>
            </View>
            <View style={styles.box}>
              <AnimatedNumberCounter value={widget.coffP2} />
              <Text style={styles.competitor}>{widget.p2}</Text>
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  );
};

const AnimatedNumberCounter = memo((props: { value: string }) => {
  return (
    <View style={styles.counter}>
      {props.value
        .split('')
        .map(Number)
        .map((n, i) => {
          if (isNaN(n))
            return (
              <Text key={i} style={styles.odd}>
                .
              </Text>
            );
          return (
            <AnimatedNumbers
              key={i}
              animateToNumber={n}
              animationDuration={1500}
              fontStyle={{
                color: '#fff',
                fontSize: 24,
                fontStyle: 'normal',
                fontWeight: '900',
              }}
            />
          );
        })}
    </View>
  );
});
