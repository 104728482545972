import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flex: 1,
    backgroundColor: '#FFF',
  },

  btnAddToCartContainer: {
    alignItems: 'center',
    marginBottom: 50,
  },
  btnAddToCart: {
    width: 300,
    height: 68,
    padding: 10,
    borderRadius: 12,
  },
  addToCartText: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 23,
    textAlign: 'left',
    color: '#FFF',
  },
  clothingSizeTypesContainer: {
    width: '90%',
    alignSelf: 'center',
  },
  sizeTypesContainer: {
    paddingBottom: 15,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  sizeType: {
    borderWidth: 1,
    borderColor: '#bfbfbf',
    borderRadius: 5,
    height: 35,
    minWidth: 35,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  commentsContainer: {
    marginTop: 30,
    paddingBottom: 15,
  },
  commentsTitle: {
    fontSize: 18,
    fontWeight: '500',
    lineHeight: 20,
    width: '90%',
    alignSelf: 'center',
  },
  textareaContainer: {
    marginTop: 10,
    backgroundColor: '#FFF',
    width: '90%',
    height: 120,
    alignSelf: 'center',
    borderRadius: 5,
    padding: 15,
    borderColor: '#8E8E8E',
    borderWidth: 1,
  },
  textareaStyle: {
    height: 130,
    fontSize: 13,
    fontWeight: '400',
    color: '#8E8E8E',
  },
  controlQuantityContainer: {
    borderWidth: 2,
    height: 40,
    paddingLeft: 7,
    paddingRight: 7,
    width: 85,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconControlQuantity: {
    fontSize: 30,
  },
  quantityNumber: {
    marginTop: -3,
    fontWeight: '500',
    fontSize: 18,
  },
  headerIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  newHeader: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 50,
  },
  headerIcon: {
    fontSize: 50,
    fontWeight: 'bold',
  },
  commerceNameContainer: {
    flex: 4,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  commerceNameText: {
    fontWeight: 'bold',
    fontSize: 21,
  },
  shoppingCartLength: {
    position: 'absolute',
    top: 2,
    right: 9,
    orderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    borderRadius: 20,
  },
  shoppingCartLengthNumber: { textAlign: 'center', color: '#FFF' },
  productContainer: {
    width: '85%',
    borderColor: '#f2f2f2',
    borderRightWidth: 1,
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderTopStartRadius: 15,
    borderTopEndRadius: 15,
    alignSelf: 'center',
    marginTop: 15,
  },
  productImageContainer: {
    height: 150,
    width: '100%',
  },
  productImage: {
    width: '100%',
    height: '100%',
  },
  productPromotionBannerImage: {
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    width: '100%',
    height: '100%',
  },
  venueLogoContainer: {
    width: 45,
    height: 45,
    position: 'absolute',
    borderRadius: 10,
    bottom: 5,
    left: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  venueLogo: {
    width: 40,
    height: 40,
  },
  productInfoContainer: {
    padding: 10,
  },
  productNameAndPriceContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  productNameText: {
    flex: 5,
    fontSize: 15,
    fontWeight: 'bold',
  },
  productPriceText: {
    flex: Platform.OS !== 'web' ? 2 : 1,
    fontSize: 15,
    fontWeight: 'bold',
  },
  quantityContainer: {
    width: '90%',
    height: 40,
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  controlQuantitySection: {
    width: '30%',
    flex: 1,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  addQuantityContainer: {
    width: '70%',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  addQuantityBtn: {
    width: '100%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
  },
  quantityAndPriceContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityText: {
    paddingTop: 2,
    width: 23,
    height: 23,
    borderWidth: 1,
    borderRadius: 11,
    textAlign: 'center',
    fontSize: 12,
    borderColor: '#FFF',
    color: '#FFF',
  },
  addToCartContainer: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addToCartAndPriceText: {
    color: '#FFF',
    fontSize: 14,
    fontWeight: '500',
  },
});
