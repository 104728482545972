import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  scrollviewStyle: {
    marginBottom: 60,
  },
  commerceContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderColor: '#000f6657',
    borderBottomWidth: 0.3,
  },
  commerceDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 109,
  },
  detailsContainer: {
    padding: 10,
    backgroundColor: 'white',
  },
  confirmPaymentBtnContainer: {
    position: 'absolute',
    bottom: 0,
    margin: 16,
    width: '90%',
    alignSelf: 'center',
    borderRadius: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  },
  confirmPaymentBtn: {
    height: 67,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  redirectionBtnContainer: {
    flexDirection: 'row',
    height: '8%',
    backgroundColor: '#0000000d',
    alignItems: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  redirectionToCommerceText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
  separator: {
    width: '100%',
    marginBottom: 15,
    marginTop: 15,
    opacity: 0.15,
    borderStyle: 'solid',
    borderColor: '#979797',
    borderWidth: 0.5,
  },
  detailPaymentText: {
    flex: 1,

    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 16,
  },
  statusDescriptionText: {
    textAlign: 'center',
    fontSize: 18,

    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 20,
    textTransform: 'uppercase',
  },
  orderStatusContainer: {
    height: 110,
    justifyContent: 'space-around',
  },
  commerceNameText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
  },
});
