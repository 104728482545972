import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL } from '@env';
import constantsStorage from 'constants/Storage';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const userData = await AsyncStorage.getItem(constantsStorage.authData);
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      config.headers.Authorization = parsedUserData.token ? `Bearer ${parsedUserData.token}` : '';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const authFailed = [401, 403].includes(error?.response?.status);
    if (authFailed) {
      await AsyncStorage.removeItem(constantsStorage.authData);
      // TODO: Navigate
    } else {
      return Promise.reject(error);
    }
  },
);

export default axiosInstance;
