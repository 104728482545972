import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  header: {
    marginTop: 15,
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 30,
  },
  header_icon: {
    fontSize: 20,
    marginTop: 4,
  },
  header_title: {
    fontSize: 20,
    marginLeft: 5,
  },
});
