import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  legendText: {
    marginTop: 10,
    fontSize: 18,
    lineHeight: 23,
    color: '#89909B',
    opacity: 0.8,
  },
  message: {
    padding: 15,
    color: '#89909b',
    fontSize: 18,
    textAlign: 'center',
  },
  buttonsContainer: {
    width: '100%',
    alignItems: 'center',
  },
  confirmPaymentBtn: {
    width: 350,
    height: 68,
    borderRadius: 12,
    marginBottom: 20,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  newOrderBtn: {
    width: 300,
    fontSize: 18,
    marginBottom: 20,
  },
});
