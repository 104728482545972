import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  cardTitle: {
    fontSize: 18,
    fontWeight: '400',
  },
  caption: {
    fontSize: 19,
    marginbottom: 20,
  },
  confirmMPPointBtnContainer: {
    margin: 16,
    width: '90%',
    alignSelf: 'center',
    bottom: 0,
    borderRadius: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  },
  radioGroup: {
    marginTop: 13,
  },
  confirmMPPointBtn: {
    height: 67,
  },
  confirmMPPointBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  confirmMPPointBtnDisabled: {
    backgroundColor: '#89909b',
  },
  radioButtonGroupContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  radioButtonOption: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  radioButtonLabel: {
    flex: 5,
    paddingTop: 5,
  },
});
