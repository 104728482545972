import React from 'react';
import { View, Text } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import AppContext from 'contexts/AppContext';
import BalanceExhibitorProps from 'types/components/BalanceExhibitorProps';
import { styles } from 'styles/components/balanceExhibitor';
import { greenColor } from 'styles/global';

export default ({ balance }: BalanceExhibitorProps): JSX.Element => {
  const { currentVenue } = React.useContext(AppContext);
  const navigation = useNavigation();
  const routes = navigation.getState().routes;
  const commerceScreenInRouteStack = routes?.map((route) => route.name === 'CommerceScreen');
  const findCommerceScreenInStack = commerceScreenInRouteStack?.find((stack) => stack === true);

  return (
    <View style={[styles.container, findCommerceScreenInStack && { alignSelf: 'flex-start', marginLeft: 50 }]}>
      <Text style={[styles.balanceText, { color: currentVenue?.appColor ?? greenColor }]}>{`Saldo: $${balance}`}</Text>
    </View>
  );
};
