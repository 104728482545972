import React from 'react';
import * as clover from 'remote-pay-cloud';
import { View, Text, Image } from 'react-native';
import { Button } from 'react-native-elements';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import PaymentCloverScreenProps from 'types/screens/PaymentCloverScreenProps';
import CloverPointPayment from 'types/models/CloverPointPayment';
import useChangePayment from 'hooks/useChangePayment';
import useChangeOrder from 'hooks/useChangeOrder';
import useCloverServices from 'hooks/useCloverServices';
import { storeCloverPointPayment } from 'services/paymentsService';
import { styles } from 'styles/screens/PaymentCloverScreen';
import { greenColor } from 'styles/global';
import NavBarForTotem from 'components/NavBarForTotem';
import OrderResponseModal from 'components/OrderResponseModal';
import PosnetLogo from 'assets/images/posnet-icon.svg';
import ErrorCloverLogo from 'assets/images/error-clover-icon.svg';
import SuccessCloverLogo from 'assets/images/success-clover-icon.svg';
import { printTicket } from 'utils/setupTicket';

export default ({ navigation }: PaymentCloverScreenProps) => {
  const { t } = useTranslation();
  const {
    pairedWithClover,
    currentVenue,
    setIsLoading,
    selectedOrder,
    setSelectedOrder,
    newlyCreatedOrder,
    setOrderModalResponseData,
    redirectionItems,
  } = React.useContext(AppContext);
  const {
    resultPayment,
    setResultPayment,
    currentShoppingCart,
    currentOrder,
    cloverPointPaymentResult,
    setCloverPointPaymentResult,
  } = React.useContext(OrderContext);
  const closeoutTimerForOrderModal = React.useRef<any>(null);
  const { changeResultPayment } = useChangePayment();
  const { deleteCurrentOrder } = useChangeOrder();
  const { cloverMethods } = useCloverServices();
  const setData = async () => {
    if (!currentShoppingCart || !currentVenue || !currentOrder) {
      if (currentShoppingCart?.paid) {
        await deleteCurrentOrder();
      }
      setTimeout(() => {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }, 500);
      return;
    }
  };

  const saveCloverPointPayment = async (payment: clover.payments.Payment) => {
    if (!currentOrder) return;
    const paymentRequest: CloverPointPayment = {
      id: payment.getId(),
      amount: Number.isSafeInteger(payment.getAmount())
        ? payment.getAmount() / 100
        : parseFloat(payment.getAmount() + '00'),
      orderId: currentOrder.id,
      state: payment.getResult(),
      paymentState: payment.getCardTransaction().getState(),
      externalPaymentId: payment.getExternalPaymentId(),
      paymentCardLabel: payment.getTransactionInfo().getCardTypeLabel(),
      paymentMethod: payment.getTender().getLabel(),
      cardBrand: payment.getCardTransaction().getCardType(),
      cardLastFourDigits: payment.getCardTransaction().getLast4(),
    };
    await storeCloverPointPayment(paymentRequest, payment);
    if (payment.getResult() === clover.payments.Result.SUCCESS) {
      setResultPayment({ success: true });

      if (pairedWithClover) {
        printTicket({
          data: currentOrder,
          orderNum: currentOrder.id,
        });
      }
    }
  };

  const resetNavigationStateToCommerceScreen = async () => {
    await deleteCurrentOrder();
    setCloverPointPaymentResult(null);
    setSelectedOrder(null);
    navigation.reset({
      index: 0,
      routes: [{ name: 'CommerceScreen', params: redirectionItems ?? undefined }],
    });
  };

  useFocusEffect(
    React.useCallback(() => {
      setData();
      return () => {
        setResultPayment(null);
      };
    }, []),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (pairedWithClover && !currentShoppingCart?.paid && !selectedOrder) {
        setIsLoading(true);
        cloverMethods().performSale();
      }
    }, [pairedWithClover, currentShoppingCart]),
  );

  React.useEffect(() => {
    if (cloverPointPaymentResult) {
      saveCloverPointPayment(cloverPointPaymentResult);
    }
  }, [cloverPointPaymentResult]);

  React.useEffect(() => {
    if (resultPayment) {
      setIsLoading(false);
      changeResultPayment(resultPayment, true);
    }
  }, [resultPayment]);

  React.useEffect(() => {
    if (newlyCreatedOrder && selectedOrder) {
      closeoutTimerForOrderModal.current = setInterval(async () => {
        setOrderModalResponseData(null);
        await resetNavigationStateToCommerceScreen();
      }, 5000);
      setOrderModalResponseData({
        content: (
          <OrderResponseModal
            message={selectedOrder.delivery ?? ''}
            success={true}
            orderNumber={selectedOrder.id}
            verificationCode={selectedOrder.verificationCode}
            onClose={() => setOrderModalResponseData(null)}
            navigation={navigation}
            isOrderPayWithCloverDevice={true}
          />
        ),
      });
      deleteCurrentOrder();
    }

    return () => {
      clearInterval(closeoutTimerForOrderModal.current);
    };
  }, [selectedOrder, newlyCreatedOrder]);

  return (
    <View style={{ flex: 1, backgroundColor: '#FFFFFF' }}>
      <Text style={styles.cloverTitleText}>{t('payment')}</Text>
      <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: 65 }}>
        <Image
          source={!resultPayment ? PosnetLogo : resultPayment?.success ? SuccessCloverLogo : ErrorCloverLogo}
          style={resultPayment ? styles.responseImage : styles.pinpadImage}
        />
        <Text
          style={{
            fontWeight: '400',
            lineHeight: 38,
            fontSize: 32,
            textAlign: 'center',
            color: '#000000',
            marginTop: 50,
          }}
        >
          {!resultPayment
            ? t('continue-payment-with-pinpad')
            : resultPayment?.success
            ? t('clover-success-payment')
            : t('clover-error-payment')}
        </Text>
      </View>
      {resultPayment && (
        <View style={styles.buttonContainer}>
          <Button
            title={`${t('new-order')}`}
            containerStyle={[
              styles.button,
              { outlineStyle: 'none' },
              { backgroundColor: currentVenue?.appColor ?? greenColor },
            ]}
            titleStyle={[
              {
                color: '#FFFFFF',
                fontWeight: '500',
                fontSize: 32,
                lineHeight: 16,
                textAlign: 'center',
              },
            ]}
            type="clear"
            onPress={async () => {
              await resetNavigationStateToCommerceScreen();
            }}
          ></Button>
        </View>
      )}
      <NavBarForTotem />
    </View>
  );
};
