import React from 'react';
import * as clover from 'remote-pay-cloud';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import useCloverServices from 'types/hooks/useCloverServices';
import CloverRegionalExtras from 'types/models/CloverRegionalExtras';

export default (): useCloverServices => {
  const { currentOrder, setResultPayment, setCloverPointPaymentResult } = React.useContext(OrderContext);
  const {
    setPairedWithClover,
    setCloverConnected,
    cloverConnected,
    currentPairedCloverPoint,
    currentCloverConfig,
    setCurrentCloverPairingCode,
    setSuccessfullyConnectedToCloverDevice,
  } = React.useContext(AppContext);
  let pendingSaleRequest: any = null;
  let cloverConnector: clover.remotepay.ICloverConnector | null = cloverConnected ?? null;
  const commerceCloverConnectionConfiguration = {
    applicationId: currentCloverConfig?.applicationId,
    accessToken: currentCloverConfig?.accessToken,
    cloverServer: currentCloverConfig?.cloverServer,
    merchantId: currentCloverConfig?.merchantId,
    deviceAddress: currentPairedCloverPoint?.deviceAddress,
    friendlyId: currentCloverConfig?.friendlyId,
    cloverPointModel: currentPairedCloverPoint?.model,
  };

  const onPairingCode = (pairingCode: string) => {
    console.log(`Pairing code is ${pairingCode}`);
    setCurrentCloverPairingCode(pairingCode);
  };

  const onPairingSuccess = (authToken: string) => {
    console.log(`Pairing succeeded, authToken is ${authToken}`);
    setCurrentCloverPairingCode(null);
    setSuccessfullyConnectedToCloverDevice(true);
  };

  const getDeviceConfigurationForPairedDevice = (connectionConfiguration: any): clover.CloverDeviceConfiguration => {
    const configBuilder: clover.WebSocketPairedCloverDeviceConfigurationBuilder =
      new clover.WebSocketPairedCloverDeviceConfigurationBuilder(
        connectionConfiguration.applicationId,
        connectionConfiguration.deviceAddress,
        'pos',
        connectionConfiguration.cloverPointModel,
        '',
        onPairingCode,
        onPairingSuccess,
      );
    configBuilder
      .setPosName('pos.name')
      .setHeartbeatInterval(-1)
      .setReconnectDelay(10000)
      .setHeartbeatDisconnectTimeout(20000);
    return configBuilder.build();
  };

  const checkPaymentStatus = () => {
    if (pendingSaleRequest) {
      console.log('entering check Payment Status');
      // If a message was lost etc. the retrieveDevice status request will ask the device to send the last message.
      // This can help the POS recover if the device is stuck on a challenge screen, etc.  The last message will
      // be sent independently of the retrieveDeviceStatus response and thus the retrieveDeviceStatus response
      // does not need to be checked.
      const retrieveDeviceStatusRequest = new clover.remotepay.RetrieveDeviceStatusRequest();
      retrieveDeviceStatusRequest.setSendLastMessage(true);
      if (!cloverConnector) return;
      cloverConnector.retrieveDeviceStatus(retrieveDeviceStatusRequest);
      // Retrieve the payment status.
      const retrievePaymentRequest = new clover.remotepay.RetrievePaymentRequest();
      retrievePaymentRequest.setExternalPaymentId(pendingSaleRequest.getExternalId());
      cloverConnector.retrievePayment(retrievePaymentRequest);
    }
  };

  const resetCloverDevice = (): void => {
    console.log('theres a pendingSale', pendingSaleRequest);
    if (pendingSaleRequest) {
      checkPaymentStatus();
    } else {
      if (cloverConnector) {
        cloverConnector.resetDevice();
      }
    }
  };

  function buildCloverConnectionListener() {
    return Object.assign({}, clover.remotepay.ICloverConnectorListener.prototype, {
      onSaleResponse: function (response: clover.remotepay.SaleResponse): void {
        console.log({ message: 'Payment response received', response: response });
        console.log('pending sale request on sale response', pendingSaleRequest);
        //const saleAmountForRequest = pendingSaleRequest?.getAmount();
        //if (!saleAmountForRequest) return;
        if (response.getSuccess()) {
          const payment = response.getPayment();
          console.log('clover payment', payment);
          // We are choosing to void the payment if it was not authorized for the full amount.
          console.log('response result payment', response.getResult());
          if (!response.getIsSale()) {
            console.log({ error: 'Response is not a sale!' });
          }
          if (response.getResult() === clover.remotepay.ResponseCode.SUCCESS) {
            if (setCloverPointPaymentResult) setCloverPointPaymentResult(payment);
          }
        } else {
          if (setCloverPointPaymentResult) setCloverPointPaymentResult(null);
          setResultPayment({ success: false });
          resetCloverDevice();
        }
      },

      //clover.remotepay.ResultStatus.SUCCESS

      onRetrievePaymentResponse: function (retrievePaymentResponse: clover.remotepay.RetrievePaymentResponse) {
        console.log({ message: 'onRetrievePaymentResponse', response: retrievePaymentResponse });
        if (pendingSaleRequest) {
          if (retrievePaymentResponse.getExternalPaymentId() === pendingSaleRequest.getExternalId()) {
            if (retrievePaymentResponse.getQueryStatus() === clover.remotepay.QueryStatus.FOUND) {
              const payment = retrievePaymentResponse.getPayment();
              console.log(
                'on retrieve payment response',
                payment.getResult(),
                payment.getResult() === clover.payments.Result.SUCCESS,
              );
              pendingSaleRequest = null;
            }
          }
        }
      },

      onResetDeviceResponse(retrievePaymentResponse: clover.remotepay.ResetDeviceResponse) {
        if (pendingSaleRequest) {
          console.log(retrievePaymentResponse);
          const retrievePaymentRequest = new clover.remotepay.RetrievePaymentRequest();
          retrievePaymentRequest.setExternalPaymentId(pendingSaleRequest.getExternalId());
          if (cloverConnector) {
            cloverConnector.retrievePayment(retrievePaymentRequest);
          }
        }
      },

      // See https://docs.clover.com/build/working-with-challenges/
      onConfirmPaymentRequest: function (request: clover.remotepay.ConfirmPaymentRequest): void {
        console.log({ message: 'Automatically accepting payment', request: request });
        if (cloverConnector) {
          cloverConnector.acceptPayment(request.getPayment());
        }
        // to reject a payment, pass the payment and the challenge that was the basis for the rejection
        // getCloverConnector().rejectPayment(request.getPayment(), request.getChallenges()[REJECTED_CHALLENGE_INDEX]);
      },

      onVerifySignatureRequest: function (request: clover.remotepay.VerifySignatureRequest): void {
        console.log({ message: 'Automatically accepting signature', request: request });
        if (cloverConnector) {
          cloverConnector.acceptSignature(request);
        }
        // to reject a signature, pass the request to verify
        // getCloverConnector().rejectSignature(request);
      },

      onDeviceReady: function (merchantInfo: clover.remotepay.MerchantInfo): void {
        if (!pendingSaleRequest) {
          setPairedWithClover(true);
          console.log({ message: 'Device Ready to process requests!', merchantInfo: merchantInfo });
        } else {
          // We have an unresolved sale.  The connection to the device was lost and the customer is in the
          // middle of or finished the payment with the POS disconnected.  Calling retrieveDeviceStatus
          // with setSendLastMessage will ask the Clover device to send us the last message it
          // sent which may allow us to proceed with the payment.
          const retrieveDeviceStatusRequest = new clover.remotepay.RetrieveDeviceStatusRequest();
          retrieveDeviceStatusRequest.setSendLastMessage(true);
          if (cloverConnector) {
            cloverConnector.retrieveDeviceStatus(retrieveDeviceStatusRequest);
          }
        }
      },

      onDeviceError: function (cloverDeviceErrorEvent: clover.remotepay.CloverDeviceErrorEvent): void {
        setPairedWithClover(false);
        console.log({ message: `An error has occurred: ${cloverDeviceErrorEvent.getMessage()}` });
      },

      onDeviceDisconnected: function (): void {
        setPairedWithClover(false);
        console.log({ message: 'You have been disconnected from the Clover device.' });
      },

      onDeviceActivityStart: function (deviceEvent: clover.remotepay.CloverDeviceEvent): void {
        console.log({ message: `Device Activity Start Message: ${deviceEvent.getMessage()}` });
      },

      onDeviceActivityEnd(deviceEvent: clover.remotepay.CloverDeviceEvent): void {
        console.log({ message: `Device Activity End Message: ${deviceEvent.getMessage()}` });
      },
    });
  }

  const disposeCloverConnection = () => {
    if (cloverConnector) {
      cloverConnector.dispose();
    }
  };

  const cloverMethods = () => {
    return {
      /**
       * Establishes a connection to the Clover device based on the configuration provided.
       */
      connect: function (connectionConfiguration: any = null): void {
        //
        //isTheCloverDeviceConnectedRef.current = true;
        // clean up any existing connections.
        disposeCloverConnection();
        if (!connectionConfiguration) {
          connectionConfiguration = commerceCloverConnectionConfiguration;
        }
        clover.DebugConfig.loggingEnabled = true;
        let cloverDeviceConnectionConfiguration: clover.CloverDeviceConfiguration | null = null;
        cloverDeviceConnectionConfiguration = getDeviceConfigurationForPairedDevice(connectionConfiguration);
        const builderConfiguration: any = {};
        builderConfiguration[clover.CloverConnectorFactoryBuilder.FACTORY_VERSION] =
          clover.CloverConnectorFactoryBuilder.VERSION_12;
        const cloverConnectorFactory: clover.CloverConnectorFactory =
          clover.CloverConnectorFactoryBuilder.createICloverConnectorFactory(builderConfiguration);
        cloverConnector = cloverConnectorFactory.createICloverConnector(cloverDeviceConnectionConfiguration);
        cloverConnector.addCloverConnectorListener(buildCloverConnectionListener());
        cloverConnector.initializeConnection();
        setPairedWithClover(true);
        setCloverConnected(cloverConnector);
        //isTheCloverDeviceConnectedRef.current = false;
      },

      /**
       * Performs a sale on your Clover device.
       */
      performSale: function (): void {
        if (!pendingSaleRequest) {
          pendingSaleRequest = new clover.remotepay.SaleRequest();
          pendingSaleRequest.setExternalId(clover.CloverID.getNewId());
          const formatOrderAmountForClover = currentOrder?.totalAmount + '00';
          const formatOrderAmountForClovertoNumber = parseInt(parseInt(formatOrderAmountForClover).toFixed(2));
          pendingSaleRequest.setAmount(formatOrderAmountForClovertoNumber);
          pendingSaleRequest.setSignatureEntryLocation(clover.payments.DataEntryLocation.NONE);
          pendingSaleRequest.setDisableReceiptSelection(true);
          pendingSaleRequest.setApproveOfflinePaymentWithoutPrompt(true);
          pendingSaleRequest.setDisableDuplicateChecking(true);
          pendingSaleRequest.setCardEntryMethods(clover.CardEntryMethods.ALL);
          const regionalExtras = new clover.payments.RegionalExtras();
          const regionalExtrasObjectForRegion: CloverRegionalExtras = {};
          regionalExtrasObjectForRegion[`${regionalExtras.getFISCALINVOICENUMBERKEY()}`] =
            regionalExtras.getSKIPFISCALINVOICENUMBERSCREENVALUE();
          regionalExtrasObjectForRegion[`${regionalExtras.getINSTALLMENTNUMBERKEY()}`] =
            regionalExtras.getINSTALLMENTNUMBERDEFAULTVALUE();
          pendingSaleRequest.setRegionalExtras(regionalExtrasObjectForRegion);
          console.log({ message: 'Sending sale', request: pendingSaleRequest });
          // Send the sale to the device.
          if (cloverConnector) {
            cloverConnector.sale(pendingSaleRequest);
          }
        } else {
          checkPaymentStatus();
        }
      },

      disposeCloverConnection: function () {
        if (cloverConnector) {
          setPairedWithClover(false);
          cloverConnector.dispose();
          setCloverConnected(null);
        }
      },
    };
  };

  return { cloverMethods, cloverListenerConnector: buildCloverConnectionListener() };
};
