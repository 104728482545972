import React from 'react';
import { View, Text, TextInput, ScrollView, TouchableOpacity, Image, Platform } from 'react-native';
import { Icon as RNIcon } from 'react-native-elements';
import { useFocusEffect } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangeOrder from 'hooks/useChangeOrder';
import ShoppingCartModal from 'components/ShoppingCartModal';
import OrderPendingMessage from 'components/OrderPendingMessage';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import ProductImageTypes from 'types/enums/ProductImageTypes';
import ProductDetailScreenProps from 'types/screens/ProductDetailScreenProps';
import ShoppingCart from 'types/models/ShoppingCart';
import { styles } from 'styles/screens/productDetailScreen';
import { greenColor } from 'styles/global';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import App from 'constants/App';
import CommerceHeader from 'components/CommerceHeader';
import DetailItem from 'types/models/DetailItem';
import { ClothingSizeTypeStock } from 'types/models/Product';

export default ({ navigation }: ProductDetailScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const { modalizeData, setModalizeData, currentVenue } = React.useContext(AppContext);
  const { currentCommerce, currentProduct, currentShoppingCart, setCurrentShoppingCart } =
    React.useContext(OrderContext);
  const { storeCurrentShoppingCart } = useChangeShoppingCart();
  const { deleteCurrentOrder } = useChangeOrder();
  const [productQuantity, setQuantity] = React.useState<number>(1);
  const [currentClothingSize, setCurrentClothingSize] = React.useState<{
    id: number | undefined;
    stock: number;
  }>({
    id: currentProduct?.clothingSizeStocks.filter(({ stock }) => stock !== 0)[0]?.id ?? undefined,
    stock: currentProduct?.clothingSizeStocks.filter(({ stock }) => stock !== 0)[0]?.stock ?? 0,
  });
  const productImage = currentProduct?.images.find(
    (image) => image.productImageTypeId === ProductImageTypes.logo,
  )?.path;
  const venueLogo =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const roundOffPrice = currentProduct?.price ? Math.floor(currentProduct?.price) : null;

  const groupArray = (arr: ClothingSizeTypeStock[], size: number) => {
    const newArray = [];
    for (let i = 0; i < arr.length; i += size) {
      newArray.push(arr.slice(i, i + size));
    }
    return newArray;
  };

  const sizeOptions = groupArray(currentProduct?.clothingSizeStocks ?? [], 4);

  const openModal = (shoppingCart: ShoppingCart) => {
    if (!currentCommerce || !currentProduct) {
      return;
    }
    setModalizeData({
      content: (
        <ShoppingCartModal
          commerce={currentCommerce}
          product={currentProduct}
          shoppingCart={shoppingCart}
          navigation={navigation}
        />
      ),
      closeOnOverlayTap: false,
    });
  };

  const modalOrderPending = (shoppingCartCommerceCode?: string): JSX.Element => (
    <OrderPendingMessage
      pendingShoppingCartCommerceCode={shoppingCartCommerceCode}
      onRestart={async () => {
        await deleteCurrentOrder();
        await addProductToShoppingCart(true);
      }}
    />
  );

  useFocusEffect(
    React.useCallback(() => {
      if (!currentCommerce) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'HomeScreen' }],
          });
        }, 500);
        return;
      }
      if (!currentProduct) {
        setTimeout(() => {
          navigation.reset({
            index: 1,
            routes: [{ name: 'HomeScreen' }, { name: 'CommerceScreen' }],
          });
        }, 500);
        return;
      }
    }, [currentCommerce, currentProduct]),
  );

  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          await storeCurrentShoppingCart(currentShoppingCart);
        }
      };
      onFocus();
    }, [currentShoppingCart]),
  );

  React.useEffect(() => {
    if (currentProduct && currentShoppingCart) {
      const detailItem = currentShoppingCart.detail.find((item) => item.product.id === currentProduct.id);
      if (detailItem) {
        setQuantity(detailItem.quantity);
      }
    }
  }, [currentShoppingCart]);

  const addProductToShoppingCart = async (restarting?: boolean) => {
    if (!currentCommerce || !currentProduct) return;

    const selectedClothingSize = currentProduct.clothingSizeStocks.find(({ id }) => id === currentClothingSize.id);
    let shoppingCart = !restarting ? currentShoppingCart : null;
    if (shoppingCart && shoppingCart.commerce.id === currentCommerce.id) {
      //Si currentShoppingCart fue seteado, busca el producto. Si no lo encuentra lo agrega.
      const detailItem = shoppingCart.detail.find((item) => {
        const itsSameProduct = item.product.id === currentProduct.id;
        if (item.product.isClothing) {
          // si es de tipo indumentaria debo buscar el producto que coincida en id y talle
          return itsSameProduct && item.clothingSize?.id === selectedClothingSize?.id;
        } else {
          // Si no es indumentaria entonces solo busco que coincida en el id
          return itsSameProduct;
        }
      });
      if (detailItem) {
        if (detailItem.product.isClothing) {
          if (detailItem.clothingSize?.id === currentClothingSize.id) {
            // Si es el mismo de talle entonces aumento la cantidad
            detailItem.quantity += productQuantity;
          } else {
            //  Si son de distinto talle entonces agrego un item nuevo
            const productItem: DetailItem = {
              product: currentProduct,
              quantity: productQuantity,
              clothingSize: selectedClothingSize,
            };
            shoppingCart.detail.push(productItem);
          }
        } else {
          // Si encuentro el producto y no es indumentaria entonces agrego eel producto
          detailItem.quantity += productQuantity;
        }
      } else {
        const productItem: DetailItem = { product: currentProduct, quantity: productQuantity };
        if (currentProduct.isClothing) {
          productItem.clothingSize = selectedClothingSize;
        }
        // Si no lo encuentra lo agrega.
        shoppingCart.detail.push(productItem);
      }
    } else {
      //Si currentShoppingCart no fue seteado o el comercio del carrito actual no coincide con el comercio del producto, se vacia el carrito
      const detailItem: DetailItem = {
        product: currentProduct,
        quantity: productQuantity,
      };
      if (currentProduct.isClothing) {
        detailItem.clothingSize = selectedClothingSize;
      }
      shoppingCart = {
        commerce: { id: currentCommerce.id, code: currentCommerce.code, name: currentCommerce.name },
        detail: [detailItem],
        processed: false,
        paid: false,
        phone: '',
        isCash: false,
      };
    }
    setCurrentShoppingCart({ ...shoppingCart });
    openModal(shoppingCart);
  };

  const calculateProductPriceAccordingToQuantity = (): number | undefined => {
    if (currentProduct) {
      return currentProduct.price * productQuantity;
    }
  };
  const onChangeQuantity = (id: string, quantity: number) => {
    if (!currentShoppingCart) return;
    const detailItem = currentShoppingCart.detail.find((item) => item.product.id === id);
    if (detailItem) {
      detailItem.quantity = quantity;
    }
    setCurrentShoppingCart({ ...currentShoppingCart });
  };

  const onClickAdd = () => {
    if (currentProduct?.isClothing && productQuantity === currentClothingSize.stock) {
      return;
    }
    const newQuantity = productQuantity + 1;
    setQuantity(newQuantity);
    if (currentProduct) onChangeQuantity(currentProduct.id, newQuantity);
  };

  const onClickRemove = () => {
    if (productQuantity > 1) {
      const newQuantity = productQuantity - 1;
      setQuantity(newQuantity);
      if (currentProduct) onChangeQuantity(currentProduct.id, newQuantity);
    }
  };
  const renderControlQuantity = () => {
    return (
      <View style={[styles.controlQuantityContainer, { borderColor: currentVenue?.appColor ?? greenColor }]}>
        <TouchableOpacity
          style={{ outlineStyle: 'none' }}
          onPress={() => {
            // Si el cantidad de unidades es igual a 1 lo que hacemos es eliminar todo el
            // producto del carrito, si es mayor a 1 simplemente restamos 1
            if (productQuantity === 1 && currentProduct) {
              onDeleteProduct(currentProduct.id);
            } else {
              onClickRemove();
            }
          }}
        >
          <RNIcon
            name={productQuantity === 1 ? 'delete' : 'remove'}
            color={currentVenue?.appColor ?? greenColor}
            size={22}
          />
        </TouchableOpacity>

        <Text style={[styles.quantityNumber, { color: currentVenue?.appColor ?? greenColor }]}>{productQuantity}</Text>
        <TouchableOpacity style={{ outlineStyle: 'none' }} onPress={onClickAdd}>
          <RNIcon name="add" color={currentVenue?.appColor ?? greenColor} size={22} />
        </TouchableOpacity>
      </View>
    );
  };

  const onDeleteProduct = (id: string) => {
    if (currentShoppingCart) {
      currentShoppingCart.detail = currentShoppingCart.detail.filter((item) => item.product.id !== id);
      setCurrentShoppingCart({ ...currentShoppingCart });
    }
  };

  const checkSize = (clothingSizeId: number) => {
    if (!currentProduct) return;
    const selectedClothingSize = currentProduct.clothingSizeStocks.find(({ id }) => id === clothingSizeId);
    if (selectedClothingSize) {
      setCurrentClothingSize({
        id: selectedClothingSize.id,
        stock: selectedClothingSize.stock,
      });

      setQuantity(1);
    }
  };

  return (
    <ScrollView style={styles.container} scrollEnabled={!modalizeData}>
      {currentCommerce !== null && (
        <CommerceHeader
          commerceName={currentCommerce.name}
          commerceCode={currentCommerce.code}
          showBackButton={true}
          onBack={() => {
            if (navigation.canGoBack()) {
              if (currentCommerce) {
                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      name: 'CommerceScreen',
                      params:
                        Platform.OS === 'web'
                          ? {
                              venueCode: currentVenue?.code,
                              commerceCode: currentCommerce.code,
                            }
                          : undefined,
                    },
                  ],
                });
              } else {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'HomeScreen' }],
                });
              }
            }
          }}
          navigation={navigation}
        />
      )}

      <View style={styles.productContainer}>
        <View style={styles.productImageContainer}>
          <Image
            resizeMode="cover"
            source={{
              uri: currentProduct?.images[1]
                ? currentProduct?.images[1].path
                : productImage
                ? productImage
                : 'https://via.placeholder.com/700/300',
            }}
            style={styles.productImage}
          />
          <View style={styles.venueLogoContainer}>
            <Image resizeMode="center" source={{ uri: venueLogo }} style={styles.venueLogo} />
          </View>
        </View>
        <View style={styles.productInfoContainer}>
          <View style={styles.productNameAndPriceContainer}>
            <Text style={styles.productNameText}>{currentProduct?.name}</Text>
            <Text style={[styles.productPriceText, { color: currentVenue?.appColor ?? greenColor }]}>
              {`$${roundOffPrice}`}
            </Text>
          </View>
          <Text>{currentProduct?.description}</Text>
        </View>
      </View>

      {currentProduct?.isClothing ? (
        <View style={styles.clothingSizeTypesContainer}>
          {sizeOptions.map((group, index) => (
            <View key={index} style={styles.sizeTypesContainer}>
              {group.map((clothingSize, index) => (
                <TouchableOpacity
                  key={index}
                  style={[
                    styles.sizeType,
                    currentClothingSize.id === clothingSize.id && { borderWidth: 2, borderColor: '#3483fa' },
                  ]}
                  onPress={() => checkSize(clothingSize.id)}
                >
                  <Text style={{ textAlign: 'center' }}>{clothingSize.description}</Text>
                </TouchableOpacity>
              ))}
            </View>
          ))}

          <Text style={{ textAlign: 'center' }}>
            {currentClothingSize.stock === 1
              ? `${t('last-unit-available')}`
              : `${currentClothingSize.stock} ${t('units-available')}`}
          </Text>
        </View>
      ) : null}

      <View style={styles.commentsContainer}>
        <Text style={styles.commentsTitle}>{t('additional-comments')}</Text>
        <View style={styles.textareaContainer}>
          <TextInput
            placeholder={t('order-notes')}
            multiline={true}
            numberOfLines={4}
            maxLength={100}
            style={[styles.textareaStyle, { outlineStyle: 'none' }]}
          />
          <TextInput />
        </View>
      </View>

      <View style={styles.quantityContainer}>
        <View style={styles.controlQuantitySection}>{renderControlQuantity()}</View>
        <View style={styles.addQuantityContainer}>
          <TouchableOpacity
            style={[
              styles.addQuantityBtn,
              { backgroundColor: currentVenue?.appColor ?? greenColor },
              { outlineStyle: 'none' },
            ]}
            onPress={async () => {
              if (currentShoppingCart && currentShoppingCart.processed && !currentShoppingCart.isCash) {
                setModalizeData({
                  content: modalOrderPending(Platform.OS === 'web' ? currentShoppingCart.commerce.code : undefined),
                  closeOnOverlayTap: false,
                });
              } else {
                await addProductToShoppingCart();
              }
            }}
            disabled={!currentCommerce?.enabled}
          >
            <View style={styles.quantityAndPriceContainer}>
              <Text style={styles.quantityText}>{productQuantity}</Text>
            </View>
            <View style={styles.addToCartContainer}>
              <Text style={styles.addToCartAndPriceText}>{t('add-to-cart')}</Text>
            </View>
            <View style={styles.quantityAndPriceContainer}>
              <Text style={styles.addToCartAndPriceText}>${calculateProductPriceAccordingToQuantity()}</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>
  );
};
