import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import { HomeScreenParams } from 'types/navigation/bottomTabNavigator';
import useRedirectHomeParams from 'types/hooks/useRedirectHomeParams';
import AppContext from 'contexts/AppContext';

export default (): useRedirectHomeParams => {
  const { setRedirectionHomeItems } = React.useContext(AppContext);

  const storeRedirectHomeParams = async (params: HomeScreenParams): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.homeRedirectParams, JSON.stringify(params));
      setRedirectionHomeItems(params);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRedirectHomeParams = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.homeRedirectParams);
      if (setRedirectionHomeItems) setRedirectionHomeItems(null);
    } catch (error) {
      console.log(error);
    }
  };

  return { storeRedirectHomeParams, deleteRedirectHomeParams };
};
