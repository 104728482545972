export default {
  authData: 'auth-data',
  currentVenue: 'current-venue',
  currentCommerce: 'current-commerce',
  currentShoppingCart: 'current-shopping-cart',
  currentOrder: 'current-order',
  guestOrders: 'guest-orders',
  loginEmail: 'login-email',
  currentPairedMPPoint: 'current-paired-mp-point',
  commerceRedirectParams: 'commerce-redirect-params',
  homeRedirectParams: 'home-redirect-params',
  currentPairedCloverPoint: 'current-paired-clover-point',
  currentCloverConfig: 'current-clover-config',
  welcomeModalShown: 'welcome-modal-shown',
};
