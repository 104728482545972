/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              HomeScreen: {
                path: 'home/:venueCode?/:commerceCode?',
              },
              DiscountScreen: {
                path: 'discount/:venueCode?',
              },
              CommerceScreen: {
                path: 'commerce/:venueCode?/:commerceCode?/:productId?',
              },
              ProductDetailsScreen: {
                path: 'product-details/:venueCode?/:commerceCode?/:productName?',
              },
              ShoppingCartScreen: {
                path: 'shopping-cart/:venueCode?/:commerceCode?',
              },
              PaymentFDScreen: {
                path: 'fd-payment/:venueCode?/:commerceCode?/:paymentMethod?',
              },
              PaymentCloverScreen: {
                path: 'clover-payment/:venueCode?/:commerceCode?/:orderNumber?',
              },
              PaymentResumeScreen: {
                path: 'payment-resume/:venueCode?/:commerceCode?/:paymentMethod?',
              },
              PaymentIzipayScreen: {
                path: 'izipay-payment/:venueCode?/:commerceCode?/:paymentMethod?',
              },
              AddCardScreen: {
                path: 'add-card/:venueCode?/:commerceCode?/:paymentMethod?',
              },
              PaymentMPCheckoutScreen: {
                path: 'mp-checkout-payment/:venueCode?/:commerceCode?/:paymentMethod?',
              },
              PaymentMPPointScreen: 'mp-point-payment',
            },
          },
          Articles: {
            screens: {
              SocialMediaScreen: {
                path: 'social-media/:venueCode?',
              },
              ArticlesScreen: {
                path: 'news/:venueCode?',
              },
            },
          },
          Orders: {
            screens: {
              OrdersScreen: {
                path: 'orders/:venueCode?',
              },
              OrdersDetailScreen: {
                path: 'orders-detail/:venueCode?/:commerceCode?/:orderNumber?/:showModal?',
              },
            },
          },
          Wallet: {
            screens: {
              WalletScreen: 'wallet',
            },
          },
        },
      },
      NotFound: 'home',
    },
  },
};
