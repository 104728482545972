import Banner from 'types/models/Banner';
import BannerImage from 'types/models/BannerImage';

const parseBannerImages = (images: any): BannerImage[] => {
  return images.map(
    (image: any): BannerImage => ({
      bannerImageType: image.pivot.banner_image_type_id,
      description: image.description,
      path: image.path,
    }),
  );
};

export const parseBanner = (banner: any): Banner => {
  return {
    id: banner.id,
    title: banner.title,
    url: banner.url,
    description: banner.description,
    images: parseBannerImages(banner.images),
  };
};
