import { StyleSheet, Platform } from 'react-native';

export const styles = StyleSheet.create({
  headerText: {
    paddingTop: 10,
    fontSize: 16,
    lineHeight: 24,
    color: '#89909B',
    opacity: 0.8,
  },
  productItemContainer: {
    padding: 16,
    ...(Platform.OS !== 'web'
      ? {
          shadowColor: '#000',
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
        }
      : {}),
  },
  buttonsContainer: {
    width: '100%',
    alignItems: 'center',
  },
  button: {
    justifyContent: 'center',
    width: '90%',
    height: 56,
    borderRadius: 12,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  buttonDisabled: {
    backgroundColor: '#FFFFFF66',
  },
});
