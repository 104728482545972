import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  header: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderColor: '#0000000d',
    borderBottomWidth: 1,
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  headerBackIcon: {
    fontSize: 20,
    paddingTop: 4,
  },
  headerTitleText: {
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 10,
    color: '#000f6657',
  },
  headerTitleTextWithOrders: {
    fontSize: 20,
    fontWeight: 'bold',
    marginLeft: 10,
  },
  headerCommentQuestionIcon: {
    fontSize: 28,
    paddingTop: 4,
    color: '#000f6657',
  },
  headerCommentQuestionIconWithOrders: {
    fontSize: 28,
    paddingTop: 4,
  },
});
