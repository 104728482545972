/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import React from 'react';
import { Platform, View, Dimensions, Image } from 'react-native';
import { createBottomTabNavigator, useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Modalize } from 'react-native-modalize';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import * as clover from 'remote-pay-cloud';
import { Feather, FontAwesome5 } from '@expo/vector-icons';
import { t } from 'i18next';

import useIcon from 'hooks/useIcon';
import useCloverServices from 'hooks/useCloverServices';
import useCloverInterval from 'hooks/useCloverInterval';
import HomeScreen from 'screens/HomeScreen';
import CommerceScreen from 'screens/CommerceScreen';
import ProductDetailsScreen from 'screens/ProductDetailScreen';
import ProductDetailTotemScreen from 'screens/ProductDetailTotemScreen';
import ShoppingCartScreen from 'screens/ShoppingCartScreen';
import ShoppingCartScreenForTotem from 'screens/ShoppingCartScreenForTotem';
import PaymentFDScreen from 'screens/PaymentFDScreen';
import PaymentMPPointScreen from 'screens/PaymentMPPointScreen';
import OrdersDetailScreen from 'screens/OrderDetailScreen';
import OrdersScreen from 'screens/OrdersScreen';
import ArticlesScreen from 'screens/ArticlesScreen';
import EventsScreen from 'screens/EventsScreen';
//import WalletScreen from 'screens/WalletScreen';
import AddCardScreen from 'screens/AddCardScreen';
import PaymentResumeScreen from 'screens/PaymentResumeScreen';
import PaymentCloverScreen from 'screens/PaymentCloverScreen';
import DiscountScreen from 'screens/DiscountScreen';
import MapScreen from 'screens/MapScreen';
import {
  ArticlesStackParamList,
  BottomTabParamList,
  EventsStackParamList,
  HomeStackParamList,
  MapStackParamList,
  OrdersStackParamList,
  //WalletStackParamList,
} from 'types/navigation/bottomTabNavigator';

//import AppHeader from 'components/AppHeader';
import RedirectButton from 'components/RedirectButton';
import AppHeader from 'components/AppHeader';
import AppContext from 'contexts/AppContext';
//import ToggleHeaderContext from 'contexts/ToggleHeaderContext';
import OrderContext from 'contexts/OrderContext';
import Product from 'types/models/Product';
import Commerce from 'types/models/Commerce';
import ShoppingCart from 'types/models/ShoppingCart';
import Order from 'types/models/Order';
import ResultPayment from 'types/models/ResultPayment';
import SelectedPayment from 'types/models/SelectedPayment';
import CreditCard from 'types/models/CreditCard';
import { PaymentConfig } from 'types/models/PaymentConfig';
import MPPoint from 'types/models/MPPoint';
import Benefit from 'types/models/Benefit';
import Discount from 'types/models/Discount';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import constantsStorage from 'constants/Storage';
import { greenColor } from 'styles/global';
import TwitterScreen from 'screens/TwitterScreen';
import PaymentIzipayScreen from 'screens/PaymentIzipayScreen';
import PaymentMPCheckoutScreen from 'screens/PaymentMPCheckoutScreen';

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator(): JSX.Element | null {
  const Icon = useIcon();
  const { t } = useTranslation();
  const redirectNavigator = useNavigation();
  //const route = useRoute();
  const { pairedWithClover, cloverConnected, setNewlyCreatedOrder, currentRouteName } = React.useContext(AppContext);
  const { cloverListenerConnector } = useCloverServices();
  const routes = redirectNavigator.getState().routes;
  const currentRoute = routes[routes.length - 1];
  const nestedRoute = currentRoute.state?.routes[routes.length - 1];
  const deeplyNestedRoutes = nestedRoute?.state?.routes;
  const commerceScreenInRouteStack = deeplyNestedRoutes?.map((route) => route.name === 'CommerceScreen');
  const homeScreenInRouteParamsInStack: HomeStackParamList['HomeScreen'] | undefined = deeplyNestedRoutes
    ? deeplyNestedRoutes.find((route) => route.name === 'HomeScreen')?.params
    : undefined;
  //const snsScreenInRouteStack = deeplyNestedRoutes?.map((route) => route.name === 'SNSScreen');
  /*const paymentScreensInRouteStack = deeplyNestedRoutes?.map((route) => {
    switch (route.name) {
      case 'PaymentFDScreen':
        return true;
      case 'AddCardScreen':
        return true;
      case 'PaymentMPPointScreen':
        return true;
      case 'PaymentCloverScreen':
        return true;
      default:
        return false;
    }
  });*/
  //const ordersStackFocused = getFocusedRouteNameFromRoute(route) === 'Orders';
  //const eventsStackFocused = getFocusedRouteNameFromRoute(route) === 'Events';
  //const articlesStackFocused = getFocusedRouteNameFromRoute(route) === 'Articles';
  //const mapStackFocused = getFocusedRouteNameFromRoute(route) === 'Map';
  const findCommerceScreenInStack = commerceScreenInRouteStack?.find((stack) => stack === true);
  //const findPaymentScreensInStack = paymentScreensInRouteStack?.find((stack) => stack === true);
  //const findSNSScreenInStack = snsScreenInRouteStack?.find((stack) => stack === true);
  const modalizeRef = React.useRef<Modalize>(null);
  const {
    modalizeData,
    setModalizeData,
    currentVenue,
    tabHeight,
    redirectionItems,
    newlyCreatedOrder,
    orderModalResponseData,
    showWelcomeView,
    checkedForDifferentVenueCode,
    venueLoad,
  } = React.useContext(AppContext);
  const mapImage = currentVenue?.images.find((image) => image.venueImageId === VenueImageTypes.VenueMap)?.path;
  const venueLogoForTinting = currentVenue?.images.find(
    (image) => image.venueImageId === VenueImageTypes.LogoForTinting,
  )?.path;
  const showAlternativeColorIfExists = checkedForDifferentVenueCode && currentVenue?.appSecondaryColor ? true : false;
  const showAlternativeIconsIfExists = checkedForDifferentVenueCode && currentVenue?.useDifferentIconSet ? true : false;
  //const [headerIsMinimized, setHeaderIsMinimized] = React.useState<boolean>(false);
  const [reconnecting, setReconnecting] = React.useState<boolean>(false);
  const { height } = Dimensions.get('screen');
  const minimunHeightForIphoneXFamily = 812;
  const isIphoneXOrAbove = Platform.OS === 'ios' && height >= minimunHeightForIphoneXFamily;
  React.useEffect(() => {
    if (modalizeRef.current) {
      if (modalizeData) {
        modalizeRef.current.open();
      } else {
        modalizeRef.current.close();
      }
    }
  }, [modalizeData]);

  const onPressRedirect = () => {
    setNewlyCreatedOrder(false);
    redirectNavigator.reset({
      index: 0,
      routes: [
        {
          name: 'Home',
          state: {
            routes: [
              {
                name: 'CommerceScreen',
                params: redirectionItems ?? undefined,
              },
            ],
          },
        },
      ],
    });
  };

  React.useEffect(() => {
    const reconectingCloverDevice = () => {
      if (!pairedWithClover && cloverConnected) {
        setReconnecting(true);
      } else {
        setReconnecting(false);
      }
    };
    reconectingCloverDevice();
  }, [pairedWithClover]);

  useCloverInterval(
    () => {
      if (cloverConnected) {
        cloverConnected.addCloverConnectorListener(cloverListenerConnector);
      }
    },
    reconnecting ? 3000 : null,
  );

  const showArticlesNavigator = (): boolean => {
    if (!currentVenue) return false;

    const showArticlesPage = currentVenue.articles && currentVenue.articles.length > 0;
    if (!currentVenue.wallioUrl && !showArticlesPage) return false;

    return true;
  };
  console.log({ homeScreenInRouteParamsInStack });
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: '#FFF' }}>
      {/*<ToggleHeaderContext.Provider value={{ headerIsMinimized, setHeaderIsMinimized }}>
        </ToggleHeaderContext.Provider>*/}
      {findCommerceScreenInStack && currentVenue && redirectionItems ? <AppHeader /> : <></>}
      <BottomTab.Navigator
        initialRouteName="Home"
        tabBarOptions={{
          activeTintColor: showAlternativeColorIfExists
            ? currentVenue?.appSecondaryColor
            : currentVenue?.appColor ?? greenColor,
          inactiveTintColor: showAlternativeColorIfExists ? '#ffffff' : undefined,
          tabStyle: {
            height: isIphoneXOrAbove ? '215%' : '90%',
            alignSelf: isIphoneXOrAbove ? 'flex-start' : 'center',
            justifyContent: isIphoneXOrAbove ? 'flex-start' : 'center',
          },
          style: {
            paddingTop: 3,
            bottom: 5,
            borderRadius: 15,
            height: 55,

            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 3.84,

            elevation: 5,
            width: '95%',
            alignSelf: 'center',
            ...(showAlternativeColorIfExists && { backgroundColor: currentVenue?.appColor }),
          },
        }}
        tabBar={
          (redirectionItems && newlyCreatedOrder) ||
          pairedWithClover ||
          (homeScreenInRouteParamsInStack &&
            homeScreenInRouteParamsInStack.venueCode !== currentVenue?.code &&
            !newlyCreatedOrder) ||
          venueLoad
            ? () => (
                <View
                  style={{ backgroundColor: 'transparent', flexDirection: 'row', marginTop: showWelcomeView ? 0 : 5 }}
                ></View>
              )
            : undefined
        }
      >
        <BottomTab.Screen
          name="Home"
          component={HomeNavigator}
          options={{
            tabBarLabel: t('tab-home'),
            tabBarIcon: ({ color }) => {
              return showAlternativeIconsIfExists ? (
                <Image source={{ uri: venueLogoForTinting }} style={{ height: 35, width: 35, tintColor: color }} />
              ) : (
                <Icon name="home-alt" style={{ color, fontSize: 30 }} />
              );
            },
          }}
        />
        {currentVenue && currentVenue.events && currentVenue.events.length > 0 && (
          <BottomTab.Screen
            name="Events"
            component={EventsNavigator}
            options={{
              tabBarLabel: t('tab-event'),
              tabBarIcon: ({ color }) => <Icon name={'calendar'} style={{ color, fontSize: 30 }} />,
            }}
          />
        )}
        {showArticlesNavigator() && (
          <BottomTab.Screen
            name="Articles"
            component={ArticlesNavigator}
            options={{
              tabBarLabel: t('tab-article'),
              tabBarIcon: ({ color }) => (
                <Icon name={showAlternativeIconsIfExists ? 'list2' : 'file-text'} style={{ color, fontSize: 30 }} />
              ),
            }}
          />
        )}
        {mapImage && (
          <BottomTab.Screen
            name="Map"
            component={MapNavigator}
            options={{
              tabBarLabel: t('map'),
              tabBarIcon: ({ color }) => {
                return showAlternativeIconsIfExists ? (
                  <FontAwesome5 name="map-marker-alt" style={{ color, fontSize: 30 }} />
                ) : (
                  <Feather name="map" style={{ color, fontSize: 30 }} />
                );
              },
            }}
          />
        )}
        <BottomTab.Screen
          name="Orders"
          component={OrdersNavigator}
          options={{
            tabBarLabel: t('orders'),
            tabBarIcon: ({ color }) => (
              <Icon
                name={showAlternativeIconsIfExists ? 'shopping-cart1' : 'shopping-bag'}
                style={{ color, fontSize: 30 }}
              />
            ),
          }}
        />
        {/* <BottomTab.Screen
                name="Wallet"
                component={WalletNavigator}
                options={{
                  tabBarIcon: ({ color }) => <Icon name="wallet" style={{ color, fontSize: 30 }} />,
                }}
              /> */}
      </BottomTab.Navigator>
      {redirectionItems &&
        newlyCreatedOrder &&
        !pairedWithClover &&
        currentRouteName === 'OrdersDetailScreen' &&
        Platform.OS == 'web' && (
          <View
            style={[{ backgroundColor: 'white', height: '8%' }, Platform.OS !== 'web' && { paddingBottom: tabHeight }]}
          >
            <RedirectButton onPressRedirect={onPressRedirect} />
          </View>
        )}
      {modalizeData && (
        <Modalize
          ref={modalizeRef}
          adjustToContentHeight={true}
          disableScrollIfPossible={true}
          tapGestureEnabled={false}
          panGestureEnabled={false}
          withHandle={false}
          panGestureComponentEnabled={false}
          closeOnOverlayTap={modalizeData.closeOnOverlayTap}
          onClose={() => {
            setModalizeData(null);
          }}
        >
          <View
            style={[
              { backgroundColor: 'white', borderTopLeftRadius: 45, borderTopRightRadius: 45 },
              Platform.OS !== 'web' && { paddingBottom: tabHeight },
            ]}
          >
            {modalizeData.content}
          </View>
        </Modalize>
      )}
      {orderModalResponseData && (
        <View
          style={{
            backgroundColor: '#ecf0f1',
          }}
        >
          {orderModalResponseData.content}
        </View>
      )}
    </SafeAreaView>
  );
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const HomeStack = createStackNavigator<HomeStackParamList>();
const EventsStack = createStackNavigator<EventsStackParamList>();
const ArticlesStack = createMaterialTopTabNavigator<ArticlesStackParamList>();
const MapStack = createStackNavigator<MapStackParamList>();
const OrdersStack = createStackNavigator<OrdersStackParamList>();
//const WalletStack = createStackNavigator<WalletStackParamList>();

const HomeNavigator = (): JSX.Element => {
  const { setTabHeight, redirectionHomeItems, pairedWithClover } = React.useContext(AppContext);
  const tabBarHeight = useBottomTabBarHeight();
  const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
  const [currentCommerce, setCurrentCommerce] = React.useState<Commerce | null>(null);
  const [currentProduct, setCurrentProduct] = React.useState<Product | null>(null);
  const [currentShoppingCart, setCurrentShoppingCart] = React.useState<ShoppingCart | null>(null);
  const [currentOrder, setCurrentOrder] = React.useState<Order | null>(null);
  const [currentDiscount, setCurrentDiscount] = React.useState<Discount | null>(null);
  const [resultPayment, setResultPayment] = React.useState<ResultPayment | null>(null);
  const [selectedPayment, setSelectedPayment] = React.useState<SelectedPayment | null>(null);
  const [selectedCard, setSelectedCard] = React.useState<CreditCard | null>(null);
  const [selectedPaymentConfig, setSelectedPaymentConfig] = React.useState<PaymentConfig | null>(null);
  const [currentPairedMPPoint, setCurrentPairedMPPoint] = React.useState<MPPoint | null>(null);
  const [currentBenefit, setCurrentBenefit] = React.useState<Benefit | null>(null);
  const [cloverPointPaymentResult, setCloverPointPaymentResult] = React.useState<clover.payments.Payment | null>(null);
  const [izipayTransactionId, setIzipayTransactionId] = React.useState<string | null>(null);

  const setStorageCommerce = async (): Promise<void> => {
    const currentCommerce = await AsyncStorage.getItem(constantsStorage.currentCommerce);
    if (currentCommerce) setCurrentCommerce(JSON.parse(currentCommerce));
  };

  const setStorageOrder = async (): Promise<void> => {
    const currentOrder = await AsyncStorage.getItem(constantsStorage.currentOrder);
    if (currentOrder) setCurrentOrder(JSON.parse(currentOrder));
  };

  const setStorageShoppingCart = async (): Promise<void> => {
    const currentShoppingCart = await AsyncStorage.getItem(constantsStorage.currentShoppingCart);
    if (currentShoppingCart) {
      const parsedShoppingCart: ShoppingCart = JSON.parse(currentShoppingCart);
      if (!parsedShoppingCart.paid) {
        await setStorageOrder();
        setCurrentShoppingCart(parsedShoppingCart);
      }
    }
  };

  const setStorageMPPoint = async (): Promise<void> => {
    const storeCurrentPairedMPPoint = await AsyncStorage.getItem(constantsStorage.currentPairedMPPoint);
    if (storeCurrentPairedMPPoint) setCurrentPairedMPPoint(JSON.parse(storeCurrentPairedMPPoint));
  };

  const loadInitalData = React.useCallback(async () => {
    if (!initialLoad) {
      await setStorageCommerce();
      await setStorageShoppingCart();
      await setStorageMPPoint();
      setInitialLoad(true);
    }
  }, [initialLoad]);

  React.useEffect(() => {
    loadInitalData();
  }, []);

  React.useEffect(() => {
    setTabHeight(tabBarHeight);
  }, [tabBarHeight]);

  return (
    <OrderContext.Provider
      value={{
        currentProduct,
        setCurrentProduct,
        currentCommerce,
        setCurrentCommerce,
        currentShoppingCart,
        setCurrentShoppingCart,
        currentOrder,
        setCurrentOrder,
        resultPayment,
        setResultPayment,
        selectedPayment,
        setSelectedPayment,
        selectedCard,
        setSelectedCard,
        selectedPaymentConfig,
        setSelectedPaymentConfig,
        currentPairedMPPoint,
        setCurrentPairedMPPoint,
        currentBenefit,
        setCurrentBenefit,
        cloverPointPaymentResult,
        setCloverPointPaymentResult,
        currentDiscount,
        setCurrentDiscount,
        izipayTransactionId,
        setIzipayTransactionId,
      }}
    >
      <HomeStack.Navigator>
        <HomeStack.Screen
          name="HomeScreen"
          component={HomeScreen}
          options={{ headerShown: false }}
          initialParams={redirectionHomeItems ?? undefined}
        />
        <HomeStack.Screen name="CommerceScreen" component={CommerceScreen} options={{ headerShown: false }} />
        <HomeStack.Screen
          name="ProductDetailsScreen"
          component={pairedWithClover ? ProductDetailTotemScreen : ProductDetailsScreen}
          options={{ headerShown: false }}
        />
        <HomeStack.Screen
          name="ShoppingCartScreen"
          component={pairedWithClover ? ShoppingCartScreenForTotem : ShoppingCartScreen}
          options={{ headerShown: false }}
        />
        <HomeStack.Screen name="PaymentResumeScreen" component={PaymentResumeScreen} options={{ headerShown: false }} />
        <HomeStack.Screen name="PaymentFDScreen" component={PaymentFDScreen} options={{ headerShown: false }} />
        <HomeStack.Screen name="AddCardScreen" component={AddCardScreen} options={{ headerShown: false }} />
        <HomeStack.Screen
          name="PaymentMPPointScreen"
          component={PaymentMPPointScreen}
          options={{ headerShown: false }}
        />
        <HomeStack.Screen name="PaymentCloverScreen" component={PaymentCloverScreen} options={{ headerShown: false }} />
        <HomeStack.Screen name="DiscountScreen" component={DiscountScreen} options={{ headerShown: false }} />
        <HomeStack.Screen name="PaymentIzipayScreen" component={PaymentIzipayScreen} options={{ headerShown: false }} />
        <HomeStack.Screen
          name="PaymentMPCheckoutScreen"
          component={PaymentMPCheckoutScreen}
          options={{ headerShown: false }}
        />
      </HomeStack.Navigator>
    </OrderContext.Provider>
  );
};

const OrdersNavigator = (): JSX.Element => {
  const { setTabHeight, currentVenue } = React.useContext(AppContext);
  const tabBarHeight = useBottomTabBarHeight();

  React.useEffect(() => {
    setTabHeight(tabBarHeight);
  }, [tabBarHeight]);

  return (
    <OrdersStack.Navigator>
      <OrdersStack.Screen
        name="OrdersScreen"
        component={OrdersScreen}
        options={{ headerShown: false }}
        initialParams={Platform.OS === 'web' && currentVenue ? { venueCode: currentVenue.code } : undefined}
      />
      <OrdersStack.Screen name="OrdersDetailScreen" component={OrdersDetailScreen} options={{ headerShown: false }} />
    </OrdersStack.Navigator>
  );
};

const EventsNavigator = (): JSX.Element => {
  const { setTabHeight } = React.useContext(AppContext);
  const tabBarHeight = useBottomTabBarHeight();

  React.useEffect(() => {
    setTabHeight(tabBarHeight);
  }, [tabBarHeight]);

  return (
    <EventsStack.Navigator>
      <EventsStack.Screen name="EventsScreen" component={EventsScreen} options={{ headerShown: false }} />
    </EventsStack.Navigator>
  );
};

const ArticlesNavigator = (): JSX.Element => {
  const { setTabHeight, currentVenue } = React.useContext(AppContext);
  const tabBarHeight = useBottomTabBarHeight();
  const showArticlesPage = currentVenue?.articles && currentVenue.articles.length > 0;

  React.useEffect(() => {
    setTabHeight(tabBarHeight);
  }, [tabBarHeight]);

  return (
    <ArticlesStack.Navigator
      tabBarOptions={{
        style: {
          borderWidth: 0,
          borderColor: 'transparent',
          elevation: 0, // para Android
          shadowColor: '#fff',
          shadowOpacity: 0,
          shadowRadius: 0,
          shadowOffset: {
            width: 0,
            height: 0, // para iOS
          },
        },
        indicatorStyle: {
          backgroundColor: currentVenue?.appColor,
        },
      }}
    >
      {currentVenue?.wallioUrl && (
        <ArticlesStack.Screen
          name="SocialMediaScreen"
          component={TwitterScreen}
          options={{
            title: t('social-media'),
          }}
          initialParams={Platform.OS === 'web' ? { venueCode: currentVenue.code } : undefined}
        />
      )}
      {showArticlesPage && (
        <ArticlesStack.Screen
          name="ArticlesScreen"
          component={ArticlesScreen}
          options={{ title: t('tab-article') }}
          initialParams={Platform.OS === 'web' ? { venueCode: currentVenue.code } : undefined}
        />
      )}
    </ArticlesStack.Navigator>
  );
};

const MapNavigator = (): JSX.Element => {
  const { setTabHeight } = React.useContext(AppContext);
  const tabBarHeight = useBottomTabBarHeight();

  React.useEffect(() => {
    setTabHeight(tabBarHeight);
  }, [tabBarHeight]);

  return (
    <MapStack.Navigator>
      <MapStack.Screen name="MapScreen" component={MapScreen} options={{ headerShown: false }} />
    </MapStack.Navigator>
  );
};
