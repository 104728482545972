import React from 'react';
import UseSelectEvent from 'types/hooks/UseSelectEvent';
import Event from 'types/models/Event';
import EventDetail from 'components/EventDetail';
import { getEvent } from 'services/eventServices';
import AppContext from 'contexts/AppContext';

export default (): UseSelectEvent => {
  const { setModalizeData } = React.useContext(AppContext);
  const [selectedEvent, setSelectedEvent] = React.useState<Event | null>(null);

  React.useEffect(() => {
    const loadEvents = async () => {
      if (selectedEvent) {
        const data = await getEvent(selectedEvent.id);
        if (data) {
          setModalizeData({
            content: React.createElement(EventDetail, {
              event: data,
              onCloseButton: () => setSelectedEvent(null),
            }),
            closeOnOverlayTap: true,
          });
        }
      }
    };
    loadEvents();
  }, [selectedEvent]);

  return { selectedEvent, setSelectedEvent };
};
