import React from 'react';
import { View, Text, TextInput } from 'react-native';
import { Card, Button } from 'react-native-elements';
import { t } from 'i18next';
import LoginPartnerModalProps from '../types/components/LoginPartnerModalProps';
import { styles } from '../styles/components/loginWithEmailModal';
import AppContext from 'contexts/AppContext';

export default ({ sendPartnerDocument, onAbort, loginPartnerColor }: LoginPartnerModalProps): JSX.Element => {
  const { setIsLoading } = React.useContext(AppContext);
  const [partnetDocument, setPartnerDocument] = React.useState<string>('');
  const [showInvalidPartnerMessage, setShowInvalidPartnerMessage] = React.useState<boolean>(false);

  const DNI_REGEX = /^[0-9]+$/;

  // Input partner form
  const inputPartner = () => {
    return (
      <>
        <Text style={styles.inputEmail__label}>{`${t('partner-modal-input-title')}`}</Text>
        <TextInput
          keyboardType="number-pad"
          value={partnetDocument}
          onChangeText={setPartnerDocument}
          style={[styles.inputEmail__input, { outlineStyle: 'none' }]}
          placeholder={t('partner-modal-input-placeholder')}
          placeholderTextColor="#C4C4C4"
        />
        {partnetDocument !== '' && !DNI_REGEX.test(partnetDocument) && (
          <View>
            <Text style={styles.inputEmail__invalid}>{`${t('wrong-partner-number-format')}`}</Text>
          </View>
        )}
        {showInvalidPartnerMessage && (
          <Text style={styles.verificationCode__invalidInput}>{`${t(
            'partner-modal-error-non-existent-partner',
          )}`}</Text>
        )}
      </>
    );
  };

  const onSendPartnerDocument = async () => {
    setIsLoading(true);
    const result = await sendPartnerDocument(partnetDocument);
    if (result) {
      setPartnerDocument('');
      setShowInvalidPartnerMessage(false);
    } else {
      setPartnerDocument('');
      setShowInvalidPartnerMessage(true);
    }
    setIsLoading(false);
  };

  const isButtonDisabled = (): boolean => {
    return !partnetDocument || !DNI_REGEX.test(partnetDocument);
  };

  return (
    <>
      <Card.Title style={styles.legendText}>{`${t('partner-modal-login-title')}`}</Card.Title>
      <Card.Divider />
      <View style={styles.inputEmail__container}>{inputPartner()}</View>
      <Button
        title={t('confirm-code')}
        type="clear"
        containerStyle={[{ outlineStyle: 'none' }]}
        buttonStyle={[styles.btn, { backgroundColor: loginPartnerColor }]}
        titleStyle={[styles.btnText]}
        disabled={isButtonDisabled()}
        disabledStyle={styles.disabledBtn}
        disabledTitleStyle={styles.btnText}
        onPress={async () => {
          setIsLoading(true);
          await onSendPartnerDocument();
          setIsLoading(false);
        }}
      />
      <View>
        <Button
          title={t('guest-button')}
          titleStyle={{ color: loginPartnerColor }}
          containerStyle={[styles.loginLaterBtn, { outlineStyle: 'none' }]}
          type="clear"
          onPress={() => onAbort()}
        />
      </View>
    </>
  );
};
