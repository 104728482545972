import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginHorizontal: 15,
    marginTop: 20,
  },
  title: {
    fontSize: 12,
    letterSpacing: 1.25,
    textTransform: 'uppercase',
  },
  onPressText: {
    fontSize: 12,
    textAlign: 'right',
    padding: 16,
    color: '#89909b',
    letterSpacing: 1.25,
  },
});
