import { StyleSheet, Platform } from 'react-native';

export const containerTabsHeight = 29;

export const styles = StyleSheet.create({
  container: {
    textAlign: 'center',
    borderBottomStartRadius: 15,
    borderBottomEndRadius: 15,
  },
  accountBalanceLoginMessage: {
    textAlign: 'center',
    paddingTop: 6,
    paddingLeft: 4,
    width: 184,
  },
  headerMinimized: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerMinimizedHeight: {
    height: 55,
  },
  userWithoutSessionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end',
    bottom: 85,
  },
  loginBtnColumn: {
    height: 50,
    flexDirection: 'row',
    marginRight: 10,
  },
  selectVenue: {
    height: 50,
    width: 50,
    alignSelf: 'center',
    flex: 1.6,
  },
  positionCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
  },
  toggleHeaderBtn: {
    position: 'absolute',
    height: 50,
    width: 50,
    right: 10,
  },
  touchableVenue: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  currentVenue__icon: {
    fontSize: 8,
    flexDirection: 'column',
    paddingTop: 5,
    color: '#ffffff',
  },
  currentVenue__name: {
    marginLeft: 10,
    color: '#ffffff',
    flexDirection: 'column',
    fontSize: 16,
  },
  shake: {
    marginTop: 10,
    fontSize: 8,
    color: '#ffffff',
    textAlign: 'center',
  },
  userWithSessionContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'flex-end',
    bottom: 85,
  },
  money: {
    fontSize: 44,
    color: '#FFFFFF',
  },
  userLoginContainer: {
    height: 50,
    flexDirection: 'row',
    paddingRight: 10,
  },
  roomService: {
    width: 44,
    height: 44,
    backgroundColor: '#ffffff26',
    borderRadius: 29,
    marginRight: 15,
    marginTop: 4,
    alignSelf: 'flex-end',
    justifyContent: 'center',
    alignItems: 'center',
  },
  roomService__icon: {
    fontSize: 10,
    color: '#FFF',
  },
  contentButtons: {
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    color: '#FFFFFF',
  },
  contentButton__button: {
    height: 45,
    width: 45,
    borderRadius: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#FFF',
    marginLeft: 10,
    paddingLeft: Platform.OS === 'web' ? 15 : 10,
    bottom: 10,
    alignSelf: 'center',
    backgroundColor: '#001639',
  },
  containerForShoppingCartOfDifferentSet: {
    height: 45,
    width: 45,
    borderRadius: 30,
    flexDirection: 'row',
    justifyContent: 'center',
    color: '#FFF',
    marginLeft: 10,
    bottom: 13,
    paddingLeft: Platform.OS === 'web' ? 15 : 10,
    alignSelf: 'center',
  },
  contentButton__icon: {
    flexDirection: 'row',
    alignSelf: 'center',
    color: '#FFF',
    marginRight: Platform.OS === 'web' ? 12 : 5,
    marginBottom: 5,
    marginTop: 2,
  },
  profileIconForDifferentSet: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginRight: Platform.OS === 'web' ? 13 : 6,
    marginBottom: 5,
    marginTop: 5,
  },
  contentButton__text: {
    color: '#FFF',
    marginLeft: 10,
    marginTop: 2,
    fontSize: 13,
  },
  containerVenueSelectionHome: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  venueLogoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  shoppingCartIcon: {
    height: 45,
    width: 45,
    fontSize: 22,
    borderRadius: 21,
    fontWeight: 'bold',
    lineHeight: 23,
    color: '#ffffff',
    paddingRight: 15,
    paddingLeft: 15,
  },
  shoppingCartIconForDifferentSet: {
    flexDirection: 'row',
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: 28,
    marginRight: Platform.OS === 'web' ? 13 : 6,
    marginBottom: 5,
    marginTop: 5,
  },
  logoHeaderImage: {
    height: 100,
    width: 100,
  },
  venueSelectedContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
  },
});
