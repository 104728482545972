import React from 'react';
import { FlatList, TouchableOpacity, View, Text } from 'react-native';
import { Button } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { CarouselItem, CarouselItemPlaceHolder } from '@double_point/rn-components';
import Venue from 'types/models/Venue';
import VenueListProps from 'types/components/VenueListProps';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import useChangeVenue from 'hooks/useChangeVenue';
import AppContext from 'contexts/AppContext';
import App from 'constants/App';
import { styles } from 'styles/components/venueList';
import { greenColor } from 'styles/global';

export default ({ venues }: VenueListProps): JSX.Element => {
  const { t } = useTranslation();
  const navigation = useNavigation();
  const { setIsLoading, setShowVenues, currentVenue, setWasVenueSelectedManually, setVenueLoad } =
    React.useContext(AppContext);
  const { storeCurrentVenue, deleteCurrentVenue, selectedVenue, setSelectedVenue } = useChangeVenue();

  const renderItem = ({ item }: any) => {
    return (
      <TouchableOpacity
        key={item.id}
        style={{ outlineStyle: 'none' }}
        onPress={() => {
          if (item.id !== -1) {
            // Si el venue que se selecciona es igual a venue que se había seleccionado antes
            // selectedVenue` se setea null
            item.id !== selectedVenue?.id ? setSelectedVenue(item) : setSelectedVenue(null);
          }
        }}
      >
        {item.id !== -1 ? (
          <CarouselItem
            id={item.id}
            image={
              item.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage
            }
            description={item.name}
            textColor={currentVenue ? '#FFF' : greenColor}
            isSelected={item.id === selectedVenue?.id}
          />
        ) : (
          <CarouselItemPlaceHolder />
        )}
      </TouchableOpacity>
    );
  };

  // Placeholders para completar 3 columns
  let data: any[];
  if (venues.length > 0 && venues.length < 3) {
    data = [...venues];
    while (data.length < 3) {
      data.push({ id: -1 });
    }
  } else {
    data = venues;
  }

  const renderList = () => {
    return (
      <>
        <View style={styles.flatlistContainer}>
          <FlatList
            data={data}
            renderItem={renderItem}
            keyExtractor={(item: Venue) => item.id}
            numColumns={3}
            columnWrapperStyle={{ justifyContent: 'space-around' }}
          />
        </View>

        <View style={styles.buttonsContainer}>
          <Button
            title={t('confirm-venue')}
            type="clear"
            buttonStyle={styles.confirmVenueBtn}
            titleStyle={[styles.confirmVenueBtnText, { color: currentVenue?.appColor ?? greenColor }]}
            onPress={async () => {
              if (selectedVenue) {
                setIsLoading(true);
                try {
                  navigation.setParams({ venueCode: selectedVenue.code });
                  setWasVenueSelectedManually(true);
                  await storeCurrentVenue(selectedVenue, false);
                  setVenueLoad(true);
                } catch (error) {
                  console.log(error);
                } finally {
                  setShowVenues(false);
                }
                setIsLoading(false);
              }
            }}
            disabled={!selectedVenue}
            disabledTitleStyle={[styles.confirmVenueBtnText, { color: currentVenue?.appColor ?? greenColor }]}
            disabledStyle={styles.btnDisabled}
          ></Button>
          <Button
            title={t('choose-venue-later')}
            titleStyle={{ fontSize: 16, color: currentVenue ? '#FFF' : greenColor }}
            containerStyle={styles.selectLaterBtn}
            type="clear"
            onPress={() => {
              deleteCurrentVenue().finally(() => {
                setShowVenues(false);
              });
            }}
          ></Button>
        </View>
      </>
    );
  };

  return <>{venues.length > 0 ? renderList() : <Text style={styles.noVenues}>{t('no-venues-available')}</Text>}</>;
};
