import { StyleSheet } from 'react-native';

export const containerTabsHeight = 29;

export const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: 340,
    marginTop: 20,
    borderRadius: 15,
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    flex: 1,
  },
  image: {
    marginTop: 20,
    height: 150,
    width: 150,
    alignSelf: 'center',
    borderRadius: 15,
  },
  partnerDataContainer: {
    marginTop: 10,
    marginLeft: 10,
  },
  partnerDataText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  footerContainer: {
    alignSelf: 'center',
    marginBottom: 10,
  },
  footerTitle: {
    fontSize: 26,
    fontWeight: 'bold',
  },
});
