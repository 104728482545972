import React from 'react';
import { Text, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import { Button } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import PaymentResumeScreenProps from 'types/screens/PaymentResumeScreenProps';
import OrderContext from 'contexts/OrderContext';
import useChangeOrder from 'hooks/useChangeOrder';
import PaymentMethodItem from 'components/PaymentMethodItem';
import NavBarForTotem from 'components/NavBarForTotem';
import { calculateTotalAmount } from 'utils/orders';
import { styles } from 'styles/screens/paymentResumeScreen';
import { greenColor } from 'styles/global';

export default ({ navigation }: PaymentResumeScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, redirectionItems } = React.useContext(AppContext);
  const { deleteCurrentOrder } = useChangeOrder();
  const { currentShoppingCart, currentOrder } = React.useContext(OrderContext);
  console.log(currentShoppingCart);
  console.log(currentOrder);
  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          if (currentShoppingCart.processed) {
            if (currentShoppingCart.paid) {
              await deleteCurrentOrder();
              setTimeout(() => {
                navigation.reset({
                  index: 1,
                  routes: [{ name: 'HomeScreen' }, { name: 'CommerceScreen', params: redirectionItems ?? undefined }],
                });
              }, 500);
            }
          }
        }
      };
      onFocus();
    }, []),
  );

  return (
    <View style={{ flex: 1, backgroundColor: '#FFFFFF' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 30, marginTop: 100 }}>
        <Text style={{ fontWeight: '500', fontSize: 48, lineHeight: 20, letterSpacing: 0.14 }}>
          {t('selected-payment-method')}
        </Text>
      </View>
      <View style={{ marginHorizontal: 30 }}>
        <PaymentMethodItem
          title={'Tarjeta de Débito/Crédito'}
          iconName={'credit-card'}
          radioValue={'clover'}
          checked={'clover'}
          setChecked={() => {
            return 'clover';
          }}
          backgroundColorPayment={currentVenue?.appColor ?? greenColor}
          isForTotemDisplay={true}
        />
        <View style={[styles.separator]}></View>
        <View style={{ marginVertical: 50 }}>
          <View style={{ marginBottom: 30 }}>
            <Text
              style={{
                fontWeight: '500',
                fontSize: 48,
                lineHeight: 20,
                letterSpacing: 0.1,
              }}
            >
              {t('order-resume')}
            </Text>
          </View>
          <View>
            {currentShoppingCart?.detail.map((item, index) => (
              <View key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
                <Text
                  style={{
                    flex: 5,
                    paddingLeft: 7,
                    fontSize: 36,
                    lineHeight: 16,
                    fontWeight: '400',
                  }}
                >
                  {item.product.name}
                </Text>
                <Text
                  style={{
                    flex: 1,
                    paddingLeft: 7,
                    fontSize: 36,
                    lineHeight: 16,
                    fontWeight: '400',
                    textAlign: 'right',
                  }}
                >
                  ${item.quantity * item.product.price}
                </Text>
              </View>
            ))}

            <View style={{ display: 'flex', flexDirection: 'row', marginTop: 50 }}>
              <Text
                style={{
                  paddingLeft: 7,
                  flex: 5,
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: 36,
                  lineHeight: 16,
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  flex: 1,
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: 36,
                  lineHeight: 16,
                  textAlign: 'right',
                }}
              >
                ${currentShoppingCart ? calculateTotalAmount(currentShoppingCart?.detail) : ''}
              </Text>
            </View>
          </View>
        </View>
        <View style={[styles.separator]}></View>
        <View style={styles.buttonsContainer}>
          <Button
            title={`${t('pay')}               $${currentOrder?.totalAmount}`}
            type="clear"
            containerStyle={[
              styles.button,
              { backgroundColor: currentVenue?.appColor ?? greenColor },
              { outlineStyle: 'none' },
            ]}
            titleStyle={[
              styles.confirmPaymentBtnText,
              { fontWeight: '500', fontSize: 32, lineHeight: 16, textAlign: 'center' },
            ]}
            onPress={async () => {
              navigation.reset({
                index: 1,
                routes: [
                  { name: 'CommerceScreen' },
                  {
                    name: 'PaymentCloverScreen',
                    params: {
                      venueCode: currentVenue?.code,
                      commerceCode: currentOrder?.commerce.code,
                      orderNumber: currentOrder?.id,
                    },
                  },
                ],
              });
            }}
            disabledTitleStyle={styles.confirmPaymentBtnText}
            disabledStyle={styles.buttonDisabled}
          ></Button>
          <Button
            title={t('cancel-order')}
            type="clear"
            containerStyle={[styles.button, { outlineStyle: 'none' }]}
            titleStyle={[
              styles.confirmPaymentBtnText,
              { color: currentVenue?.appColor ?? greenColor },
              { fontWeight: '500', fontSize: 32, lineHeight: 16, textAlign: 'center' },
            ]}
            onPress={async () => {
              await deleteCurrentOrder();
              navigation.reset({
                index: 1,
                routes: [{ name: 'HomeScreen' }, { name: 'CommerceScreen', params: redirectionItems ?? undefined }],
              });
            }}
            disabledTitleStyle={styles.confirmPaymentBtnText}
            disabledStyle={styles.buttonDisabled}
          ></Button>
        </View>
      </View>
      <NavBarForTotem />
    </View>
  );
};
