import React from 'react';
import { Card, Button } from 'react-native-elements';
import { View, Text } from 'react-native';
import { AntDesign } from '@expo/vector-icons';
import { Loading } from '@double_point/rn-components';
import AppContext from 'contexts/AppContext';
import { greenColor } from 'styles/global';
import { styles } from 'styles/components/handleMPPointPaymentError';
import HandleMPPointPaymentErrorProps from 'types/components/HandleMPPointPaymentErrorProps';

export default ({ onRetryPayment, onCancelOrder }: HandleMPPointPaymentErrorProps): JSX.Element => {
  const [isLoading] = React.useState<boolean>(false);
  const { currentVenue } = React.useContext(AppContext);

  return (
    <View>
      {isLoading && <Loading />}
      <Card.Title style={styles.cardTitle}>Pago con posnet</Card.Title>
      <Card.Divider />
      <View style={styles.iconsContainer}>
        <AntDesign name="exclamationcircle" size={30} color={currentVenue?.appColor ?? greenColor} />
      </View>
      <View style={styles.messageContainer}>
        <Text style={styles.message}>No se pudo realizar el pago</Text>
      </View>
      <View style={styles.buttonsContainer}>
        <Button
          title={'Reintentar'}
          type="clear"
          containerStyle={[
            styles.button,
            { backgroundColor: currentVenue?.appColor ?? greenColor },
            { outlineStyle: 'none' },
          ]}
          titleStyle={styles.confirmPaymentBtnText}
          onPress={onRetryPayment}
          disabledTitleStyle={styles.confirmPaymentBtnText}
          disabledStyle={styles.buttonDisabled}
        ></Button>
        <Button
          title={'Cancelar orden'}
          containerStyle={[styles.button, { outlineStyle: 'none' }]}
          titleStyle={{ color: currentVenue?.appColor ?? greenColor }}
          type="clear"
          onPress={onCancelOrder}
        ></Button>
      </View>
    </View>
  );
};
