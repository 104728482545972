import React from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-native';
import { Button } from 'react-native-elements';
import { Ionicons } from '@expo/vector-icons';
import RedirectToTicketProps from 'types/components/RedirectToTicketProps';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/components/redirectToTicket';
import { greenColor } from 'styles/global';

export default ({ url, onPress }: RedirectToTicketProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue } = React.useContext(AppContext);

  return (
    <View style={styles.container}>
      <Button
        title={t('buy-tickets')}
        containerStyle={[styles.buttonContainer, { outlineStyle: 'none' }]}
        buttonStyle={{ backgroundColor: currentVenue?.appColor ?? greenColor }}
        onPress={onPress}
      ></Button>
      <Text>
        <Ionicons name="ios-information-circle-outline" size={18} color={currentVenue?.appColor ?? greenColor} />
        {`${t('redirect-url')} ${url}`}
      </Text>
    </View>
  );
};
