/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import Category from 'types/models/Category';
import CategoryImage from 'types/models/CategoryImage';

export const getCommerceCategoriesByVenue = async (venueId: string): Promise<Category[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/commerce-categories`,
    });
    return parseCommerceCategories(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const parseCommerceCategories = (data: any): Category[] => {
  return data.map((category: any) => ({
    id: category.id,
    description: category.description,
    images: parseCommerceCategoryImage(category.images),
  }));
};

const parseCommerceCategoryImage = (images: any): CategoryImage[] => {
  return images.map(
    (item: any): CategoryImage => ({
      commerceCategoryId: item.id,
      categoryImageTypeId: item.pivot.category_image_type_id,
      path: item.path,
    }),
  );
};
