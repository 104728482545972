import React from 'react';
import { View } from 'react-native';
import CommerceItem from 'components/CommerceItem';
import CommercesListProps from 'types/components/CommercesListProps';
import { styles } from 'styles/components/commerceList';

export default ({ commerces, navigation }: CommercesListProps): JSX.Element => {
  return (
    <View style={styles.container}>
      {commerces.map((value, index) => (
        <CommerceItem key={index} commerce={value} navigation={navigation} />
      ))}
    </View>
  );
};
