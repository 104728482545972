import React from 'react';
import { Modal, View, Text, Pressable } from 'react-native';
import { Icon as RNIcon } from 'react-native-elements';
import { EvilIcons, Entypo } from '@expo/vector-icons';
import PwaInstructionsModalProps from 'types/components/PwaInstructionsModalProps';
import { styles } from 'styles/components/pwaInstructionsModal';

export default ({ visible, onClose }: PwaInstructionsModalProps): JSX.Element => {
  return (
    <Modal animationType="slide" transparent={true} visible={visible} style={styles.modal}>
      <View style={styles.container}>
        <View style={styles.pwaInstructionsDataContainer}>
          <View style={styles.closeButton}>
            <Pressable
              onPress={() => {
                if (visible) {
                  onClose();
                }
              }}
              style={styles.closeOpacity}
            >
              <RNIcon name="close" style={styles.closeImage} size={25} color={'#000000'} />
            </Pressable>
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>Instrucciones de Instalación</Text>
            <Text style={styles.infoText}>{'(Instalación sujeta a compatibilidad según dispositivo)'}</Text>
            <View style={styles.generalTextContainer}>
              <Text style={styles.infoText}>Navegador Safari: </Text>
              <Text style={styles.infoText}>
                Hace Tap en el icono
                <EvilIcons name="share-apple" size={30} color={'#000000'} /> y selecciona la opcion "Añadir a pantalla
                de inicio"
              </Text>
            </View>
            <View style={styles.generalTextContainer}>
              <Text style={styles.infoText}>Navegador Chrome: </Text>
              <Text style={styles.infoText}>
                Hace Tap en el icono
                <Entypo name="dots-three-vertical" size={14} color={'#000000'} /> y selecciona la opcion "Añadir a
                pantalla de inicio"
              </Text>
            </View>
          </View>
        </View>
      </View>
    </Modal>
  );
};
