import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    padding: 16,
  },
  buttonContainer: {
    borderRadius: 12,
  },
});
