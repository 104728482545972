import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  headerText: {
    marginTop: 5,
    fontSize: 32,
    fontWeight: '400',
    lineHeight: 20,
    letterSpacing: 0.25,
    color: '#89909B',
  },
  productItemContainer: {
    padding: 16,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  button: {
    justifyContent: 'center',
    width: 470,
    height: 106,
    borderRadius: 100,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  buttonDisabled: {
    backgroundColor: '#FFFFFF66',
  },
});
