import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    paddingLeft: 10,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 50,
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  icon: {
    fontSize: 50,
    fontWeight: 'bold',
  },
  commerceNameContainer: {
    flex: 4,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  commerceNameText: {
    fontWeight: 'bold',
    fontSize: 21,
  },
  shoppingCartLength: {
    position: 'absolute',
    top: -10,
    left: 15,
    orderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 25,
    height: 25,
    borderRadius: 20,
  },
  shoppingCartLengthNumber: { textAlign: 'center', color: '#FFF' },
});
