import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 150,
    width: '90%',
    zIndex: 3,
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#00000059',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pwaInstructionsDataContainer: {
    width: '90%',
    height: '70%',
    backgroundColor: '#fff',
    alignSelf: 'center',
    borderRadius: 30,
  },
  closeButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: 18,
    marginRight: 20,
  },
  closeImage: {
    height: 25,
    width: 25,
  },
  titleContainer: {
    flexDirection: 'column',
    marginTop: 25,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 15,
  },
  title: {
    color: '#089FD3',
    marginBottom: 10,
    fontWeight: '700',
    fontSize: 18,
    lineHeight: 20,
    letterSpacing: 0.25,
    textAlign: 'center',
    fontStyle: 'normal',
  },
  generalTextContainer: {
    alignItems: 'center',
    marginBottom: 10,
  },
  infoText: {
    fontWeight: '700',
    fontSize: 14,
    textAlign: 'center',
    lineHeight: 20,
    fontStyle: 'normal',
    color: '#000000',
    marginVertical: 5,
  },
  closeOpacity: {
    margin: 0,
    outlineStyle: 'none',
  },
});
