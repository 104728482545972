import React from 'react';
import { View, Text, ScrollView, TextInput, Image } from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import { SuccessModal } from '@double_point/rn-components';
import { Button } from 'react-native-elements';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import Product from 'types/models/Product';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangeOrder from 'hooks/useChangeOrder';
import useLocalOrders from 'hooks/useLocalOrders';
import useChangePayment from 'hooks/useChangePayment';
import OrderDetailList from 'components/OrderDetailList';
import GuestMailInput from 'components/GuestMailInput';
import OrderResponseModal from 'components/OrderResponseModal';
import NavBarForTotem from 'components/NavBarForTotem';
import ShoppingCartScreenProps from 'types/screens/ShoppingCartScreenProps';
import PaymentProviderTypes from 'types/enums/PaymentProviderTypes';
import BenefitType from 'types/enums/BenefitType';
import BenefitImageTypes from 'types/enums/BenefitImageTypes';
import Order from 'types/models/Order';
import { calculateTotalAmount } from 'utils/orders';
import regexConstants from 'constants/Regex';
import {
  storeOrder,
  checkProductsStock,
  getBenefitByUser,
  saveBuyersBenefit,
  getUserBalance,
} from 'services/ordersService';
import { styles } from 'styles/screens/shoppingCartScreenForTotem';
import { greenColor } from 'styles/global';
import ShoppingCartIcon from 'assets/images/cart-icon.svg';

export default ({ navigation }: ShoppingCartScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const [buttonWasPressed, setButtonWasPressed] = React.useState<boolean>(false);
  const {
    currentAuthData,
    currentVenue,
    setIsLoading,
    setModalizeData,
    setUserBalance,
    cloverConnected,
    setOrderModalResponseData,
    setCurrentAuthData,
    pairedWithClover,
    redirectionItems,
  } = React.useContext(AppContext);
  const {
    currentCommerce,
    currentShoppingCart,
    currentOrder,
    setCurrentOrder,
    setCurrentShoppingCart,
    resultPayment,
    selectedPayment,
    setSelectedPayment,
    currentBenefit,
    setCurrentBenefit,
    setResultPayment,
  } = React.useContext(OrderContext);
  const { storeCurrentShoppingCart, deleteCurrentShoppingCart } = useChangeShoppingCart();
  const { deleteCurrentOrder } = useChangeOrder();
  const { storeLocalOrder } = useLocalOrders();
  const { changeResultOrder } = useChangePayment();
  const [emailIntputValue, setEmailIntputValue] = React.useState<string>(
    (currentOrder?.guestEmail || currentShoppingCart?.firstUserEmail) ?? '',
  );
  const [identification, setIdentification] = React.useState<string>(
    currentShoppingCart?.identification?.toString() ?? '',
  );
  const maxIdentification = 45000000;
  const minIdentification = 91000000;
  const currentOrderTotalAmount =
    currentShoppingCart && currentShoppingCart.detail.length > 0
      ? calculateTotalAmount(currentShoppingCart.detail)
      : null;

  const onChangeQuantity = (id: string, quantity: number) => {
    if (!currentShoppingCart) return;
    const detailItem = currentShoppingCart.detail.find((item) => item.product.id === id);
    if (detailItem) {
      detailItem.quantity = quantity;
    }
    setCurrentShoppingCart({ ...currentShoppingCart });
  };

  const onDeleteProduct = (id: string) => {
    if (currentShoppingCart) {
      currentShoppingCart.detail = currentShoppingCart.detail.filter((item) => item.product.id !== id);
      setCurrentShoppingCart({ ...currentShoppingCart });
    }
  };

  const getEmailForOrderBenefit = (): string | undefined => {
    if (currentBenefit) {
      if (currentAuthData?.user.email) {
        return currentAuthData?.user.email;
      } else if (emailIntputValue && regexConstants.emailForBenefits.test(emailIntputValue)) {
        return emailIntputValue;
      }
    }
  };

  const isAllDataComplete = (): boolean => {
    if (someProductRequiresIdentification()) {
      if (!identification) return false;
      if (parseInt(identification) > maxIdentification && parseInt(identification) < minIdentification) return false;
    }

    if (emailIntputValue.length > 0 && !currentAuthData?.user.email && !regexConstants.email.test(emailIntputValue)) {
      return false;
    }

    return true;
  };

  const inputIsInvalid = (input: string): boolean => {
    switch (input) {
      case 'identification':
        if (someProductRequiresIdentification()) {
          if (!identification) return true;
          if (parseInt(identification) > maxIdentification && parseInt(identification) < minIdentification) return true;
        }
        break;
      case 'guestEmail':
        if (
          emailIntputValue.length > 0 &&
          !currentAuthData?.user.email &&
          !regexConstants.email.test(emailIntputValue)
        ) {
          return true;
        }
        break;
      default:
        break;
    }

    return false;
  };

  /*const buttonDisabled = (): boolean => {
    // El telefono es requerido
    if (selectedDelivery?.id !== 1 && !phone) return true;
    // Controla que sea un número de telefono con formato internacional válido.
    if (selectedDelivery?.id !== 1 && !regexConstants.phone.test(phone)) return true;
    // Si al menos un producto requiere DNI se controla que se haya ingresado el DNI y
    // que el mismo sea menor a maxIdentification o mayor a minIdentification
    if (someProductRequiresIdentification()) {
      if (!identification) return true;
      if (parseInt(identification) > maxIdentification && parseInt(identification) < minIdentification) return true;
    }
    // El tipo de pago es requerido
    if (!selectedPayment) return true;
    // La orden este procesada es requerido
    if (currentShoppingCart?.processed && currentShoppingCart?.isCash) return true;
    // La orden este paga es requerido
    if (currentShoppingCart?.paid) return true;
    // El email es requerido
    if (!currentAuthData?.user.email && !regexConstants.email.test(emailIntputValue)) return true;
    // El delivery es requerido
    if (selectedDelivery?.id === 2 && !currentShoppingCart?.delivery) return true;
    // Los datos para la orden son correctos
    return false;
  };*/

  const someProductRequiresIdentification = (): boolean | undefined => {
    return currentShoppingCart?.detail.some((detail) => detail.product?.category?.requiresIdentification);
  };

  /*const calculatePreparationTime = (): number | void => {
    if (!currentShoppingCart || !currentShoppingCart.detail) return;

    const filterEstimatedPreparationTimes = (): number[] => {
      const filteredEstimationTimes = currentShoppingCart?.detail.filter(
        (detailItem) => detailItem.product.estimatedPreparationTime,
      );
      return filteredEstimationTimes.map((detailItem) => {
        return Number(detailItem.product.estimatedPreparationTime);
      });
    };

    const maxPreparationTime =
      filterEstimatedPreparationTimes().length > 0 ? Math.max(...filterEstimatedPreparationTimes()) : 0;
    return maxPreparationTime;
  };*/

  const getBenefit = async () => {
    if (!currentCommerce) return;
    setIsLoading(true);
    const benefitByUser = await getBenefitByUser(emailIntputValue, currentCommerce.id);
    setIsLoading(false);
    if (benefitByUser) {
      if (benefitByUser.benefitType === BenefitType.ProductBenefit && benefitByUser.product) {
        benefitByUser.product.price = 0;
        if (currentShoppingCart && currentShoppingCart.detail.length > 0) {
          const productDetailItem = currentShoppingCart.detail.find((item) => {
            if (item.product.id === benefitByUser.product?.id && item.product.price === benefitByUser.product?.price) {
              return true;
            }
          });
          if (!productDetailItem) {
            currentShoppingCart?.detail.push({ product: benefitByUser.product, quantity: 1 });
            setCurrentShoppingCart({ ...currentShoppingCart });
          }
        }
      }
      setCurrentBenefit(benefitByUser);
      const benefitMessage = benefitByUser.benefitDescription;
      const benefitImageByUser = benefitByUser?.images?.find(
        (image) => image.benefitImageTypeId === BenefitImageTypes.banner,
      )?.path;
      setModalizeData({
        content: (
          <SuccessModal
            isSuccess={true}
            title={t('title-benefit-success')}
            mainColor={currentVenue?.appColor ?? greenColor}
            message={benefitMessage}
            buttonText={t('close-benefit-modal-button')}
            onConfirm={() => {
              setModalizeData(null);
            }}
            isBenefit={true}
            benefitImage={benefitImageByUser}
          />
        ),
        closeOnOverlayTap: true,
      });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          if (currentShoppingCart.processed) {
            if (currentShoppingCart.paid) {
              await deleteCurrentOrder();
              setTimeout(() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'CommerceScreen', params: redirectionItems ?? undefined }],
                });
              }, 500);
            }
          }
        }
      };
      onFocus();

      return () => {
        setButtonWasPressed(false);
      };
    }, []),
  );

  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          await storeCurrentShoppingCart(currentShoppingCart);
        }
      };
      onFocus();
    }, [currentShoppingCart]),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (currentAuthData && currentAuthData.user.email) {
        getBenefit();
      }
    }, []),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (pairedWithClover) {
        setSelectedPayment({
          isCash: false,
          isBalance: false,
          paymentProvider: PaymentProviderTypes.clover,
        });
      }
    }, []),
  );

  React.useEffect(() => {
    const getBalanceByCurrentUser = async (): Promise<void> => {
      if (
        currentAuthData &&
        currentVenue?.paymentConfig?.balanceEnabled &&
        currentVenue.paymentConfig.priorityOverCommerce
      ) {
        const userBalance = await getUserBalance(currentAuthData.user.id, currentVenue.id);
        if (userBalance) {
          setUserBalance(userBalance);
        }
      }
    };
    getBalanceByCurrentUser();
  }, [currentAuthData]);

  const modalMessageContent = (message: string): JSX.Element => (
    <OrderResponseModal
      success={false}
      message={message}
      onClose={() => setOrderModalResponseData(null)}
      navigation={navigation}
    />
  );

  const openErrorModal = (message: string) => {
    setOrderModalResponseData({
      content: modalMessageContent(message),
    });
  };

  const redirectPayment = () => {
    switch (selectedPayment?.paymentProvider) {
      case PaymentProviderTypes.firstData:
        navigation.reset({
          index: 1,
          routes: [{ name: 'HomeScreen' }, { name: 'PaymentFDScreen' }],
        });
        break;
      case PaymentProviderTypes.mercadoPago:
        navigation.reset({
          index: 1,
          routes: [{ name: 'HomeScreen' }, { name: 'AddCardScreen' }],
        });
        break;
      case PaymentProviderTypes.mercadoPagoPoint:
        navigation.reset({
          index: 1,
          routes: [{ name: 'HomeScreen' }, { name: 'PaymentMPPointScreen' }],
        });
        break;
      case PaymentProviderTypes.clover:
        navigation.reset({
          index: 1,
          routes: [
            { name: 'CommerceScreen', params: redirectionItems ?? undefined },
            {
              name: 'PaymentResumeScreen',
              params: {
                venueCode: currentVenue?.code,
                commerceCode: currentCommerce?.code,
                paymentMethod: 'clover',
              },
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  const handleProductsWithoutStock = (productsWithoutStock: Product[]) => {
    setIsLoading(false);
    let messageMaximumUnitsAvailable = '';
    productsWithoutStock.map((product, index) => {
      if (productsWithoutStock.length > 1 && index === productsWithoutStock.length - 1) {
        messageMaximumUnitsAvailable += ` y no hay suficiente de ${product.name}`;
      } else if (productsWithoutStock.length > 2 && index !== productsWithoutStock.length - 2) {
        messageMaximumUnitsAvailable += `No hay stock suficiente de ${product.name}, `;
      } else if (product.stock === 0) {
        messageMaximumUnitsAvailable += `No hay stock disponible de ${product.name}`;
      } else if (product.stock === 0 && productsWithoutStock.length > 1 && index === productsWithoutStock.length - 1) {
        messageMaximumUnitsAvailable += ` y no hay disponible de ${product.name}`;
      } else if (product.stock === 0 && productsWithoutStock.length > 2 && index !== productsWithoutStock.length - 2) {
        messageMaximumUnitsAvailable += `No hay stock disponible de ${product.name}, `;
      } else if (index > 0 && index !== productsWithoutStock.length - 1) {
        messageMaximumUnitsAvailable += ` no hay stock suficiente de ${product.name}`;
      } else if (product.stock === 0 && index > 0 && index !== productsWithoutStock.length - 1) {
        messageMaximumUnitsAvailable += ` no hay stock disponible de ${product.name}`;
      } else {
        messageMaximumUnitsAvailable += `No hay stock suficiente de ${product.name}`;
      }
    });
    messageMaximumUnitsAvailable += '.';
    openErrorModal(messageMaximumUnitsAvailable);
  };

  React.useEffect(() => {
    if (resultPayment) {
      if (!resultPayment.success) {
        if (cloverConnected) {
          setResultPayment(null);
        }
        if (resultPayment.response) {
          openErrorModal(resultPayment.response);
        }
      }
    }
  }, [resultPayment]);

  React.useEffect(() => {
    if (regexConstants.emailForBenefits.test(emailIntputValue)) {
      getBenefit();
    }
  }, [emailIntputValue]);

  return (
    <View style={styles.container}>
      <ScrollView>
        <View style={styles.content}>
          {currentShoppingCart && currentShoppingCart.detail.length > 0 ? (
            <>
              <OrderDetailList
                detail={currentShoppingCart.detail}
                showControlQuantity={!currentShoppingCart?.processed}
                onChangeQuantity={onChangeQuantity}
                onDeleteProduct={onDeleteProduct}
                isForTotemDisplay={true}
              />
              {currentBenefit && currentBenefit.benefitType === BenefitType.DiscountBenefit && (
                <>
                  <Text style={styles.dataOrder}>
                    {t('discount-benefit')}
                    <Text style={{ color: currentVenue?.appColor ?? greenColor }}>
                      {' '}
                      - ${currentBenefit.discountAmount}
                    </Text>
                  </Text>
                  <Text style={styles.dataOrder}>
                    {t('order-total')}
                    <Text style={{ color: currentVenue?.appColor ?? greenColor }}>
                      {' '}
                      {currentBenefit.discountAmount &&
                        `$${calculateTotalAmount(currentShoppingCart.detail, currentBenefit.discountAmount)}`}
                    </Text>
                  </Text>
                </>
              )}
              {/*!!calculatePreparationTime() && (
                <Text style={styles.dataOrder}>
                  {t('estimated-preparation-time-text')}
                  <Text
              */}

              {/*currentVenue?.deliveryConfig && (
                <>
                  <View style={styles.separator}></View>
                  <Text style={styles.subTitle}>
                    {currentCommerce?.type.description === 'Parking'
                      ? t('complete-vehicle-data')
                      : t('select-the-type-of-delivery')}
                  </Text>
                  {deliveryMethods}
                </>
              )*/}
              <>
                <Text style={styles.sectionTitle}>{'Email (opcional)'}</Text>
                {/* <Text style={styles.importantInputs}>{`${t('fields-required')} *`}</Text> */}
                {(!currentAuthData || !currentAuthData.user.email) && (
                  <GuestMailInput
                    label={t('guest-email-shopping-label')}
                    value={emailIntputValue}
                    onChangeValue={setEmailIntputValue}
                    editable={!currentShoppingCart.processed}
                    inputHasError={buttonWasPressed && inputIsInvalid('guestEmail')}
                    isForTotemDisplay={true}
                  />
                )}
                {someProductRequiresIdentification() && (
                  <>
                    <Text style={styles.subTitle}>
                      {t('enter-identification')} <Text style={styles.importantInputs}>*</Text>
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      value={identification}
                      onChangeText={setIdentification}
                      style={[
                        styles.phoneInput,
                        buttonWasPressed && inputIsInvalid('identification') && styles.requiredInput,
                        currentShoppingCart.processed && !currentShoppingCart.paid && styles.disabledPhoneInput,
                        { outlineStyle: 'none' },
                      ]}
                      placeholder={t('enter-identification-placeholder')}
                      placeholderTextColor={buttonWasPressed && inputIsInvalid('phone') ? '#f00' : '#C4C4C4'}
                      editable={!currentShoppingCart.processed}
                    />
                    <Text style={styles.invalidatedIdentification}>
                      {identification !== '' &&
                        parseInt(identification) > maxIdentification &&
                        parseInt(identification) < minIdentification &&
                        t('minimum-age')}
                    </Text>
                  </>
                )}
                {/*selectedDelivery?.id !== 1 && (
                  <View style={styles.inputPhoneContainer}>
                    <Text style={styles.subTitle}>
                      {t('enter-phone-number')} <Text style={styles.importantInputs}>*</Text>
                    </Text>
                    <Text style={styles.subTitle}>
                      {t('see-example')} <Text style={styles.importantInputs}>*</Text>
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      value={phone}
                      onChangeText={setPhone}
                      style={[
                        styles.phoneInput,
                        buttonWasPressed && inputIsInvalid('phone') && styles.requiredInput,
                        currentShoppingCart.processed && !currentShoppingCart.paid && styles.disabledPhoneInput,
                        { outlineStyle: 'none' },
                      ]}
                      placeholder={t('example-phone-number')}
                      placeholderTextColor={buttonWasPressed && inputIsInvalid('phone') ? '#f00' : '#C4C4C4'}
                      editable={!currentShoppingCart.processed}
                    />
                    <Text style={styles.phoneNumberContact}>{t('phone-number-contact')}</Text>
                  </View>
                )*/}
              </>
              {currentBenefit &&
                currentBenefit.benefitType === BenefitType.DiscountBenefit &&
                calculateTotalAmount(currentShoppingCart.detail, currentBenefit?.discountAmount) <= 0 && (
                  <Text style={styles.subTitle}>{t('discount-benefit-superior-to-order-total')}</Text>
                )}

              <>
                {/*<View style={[styles.separator]}></View>
                <View>
                  <Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: 16,
                      lineHeight: 20,
                    }}
                  >
                    {t('order-resume')}
                  </Text>
                  <View style={{ marginTop: 15 }}>
                    {currentShoppingCart.detail.map((item, index) => (
                      <View key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <Text
                          style={{
                            flex: 5,
                            paddingLeft: 7,
                          }}
                        >
                          {item.product.name}
                        </Text>
                        <Text style={{ flex: 1 }}>${item.quantity * item.product.price}</Text>
                      </View>
                    ))}

                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 18 }}>
                      <Text
                        style={{
                          paddingLeft: 7,
                          flex: 5,
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: 14,
                          lineHeight: 16,
                        }}
                      >
                        Total
                      </Text>
                      <Text
                        style={{
                          flex: 1,
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: 14,
                          lineHeight: 16,
                        }}
                      >
                        ${calculateTotalAmount(currentShoppingCart.detail)}
                      </Text>
                    </View>
                  </View>
                </View>*/}
              </>
              <View style={styles.buttonsContainerTotem}>
                <Button
                  title={`${t('continue-shopping')}`}
                  containerStyle={[
                    styles.button,
                    { outlineStyle: 'none' },
                    { borderColor: currentVenue?.appColor ?? greenColor, borderWidth: 4 },
                  ]}
                  titleStyle={[
                    {
                      color: currentVenue?.appColor ?? greenColor,
                      fontWeight: '500',
                      fontSize: 32,
                      lineHeight: 16,
                      textAlign: 'center',
                    },
                  ]}
                  type="clear"
                  onPress={() => {
                    setModalizeData(null);
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'CommerceScreen', params: redirectionItems ?? undefined }],
                    });
                  }}
                ></Button>
                <Button
                  title={`${t('go-to-payment-details')}               $${currentOrderTotalAmount}`}
                  type="clear"
                  containerStyle={[
                    styles.button,
                    { backgroundColor: currentVenue?.appColor ?? greenColor },
                    { outlineStyle: 'none' },
                  ]}
                  titleStyle={[
                    styles.confirmPaymentBtnTextTotem,
                    { fontWeight: '500', fontSize: 32, lineHeight: 16, textAlign: 'center' },
                  ]}
                  onPress={async () => {
                    setButtonWasPressed(true);
                    if (!isAllDataComplete()) {
                      return;
                    }

                    setIsLoading(true);
                    let redirectToPayment = false;
                    const productsWithoutStock = await checkProductsStock(currentShoppingCart.detail);
                    if (productsWithoutStock.length > 0) {
                      handleProductsWithoutStock(productsWithoutStock);
                    } else {
                      if (currentShoppingCart.processed && !currentShoppingCart.paid && currentOrder) {
                        // Reintentar el pago
                        redirectToPayment = true;
                        redirectPayment();
                        setIsLoading(false);
                      } else {
                        // Crear la orden
                        if (!currentVenue) return;
                        try {
                          const shoppingCart = currentShoppingCart;
                          if (emailIntputValue && currentAuthData && !currentAuthData.user.email) {
                            shoppingCart.firstUserEmail = emailIntputValue;
                          } else if (emailIntputValue) {
                            shoppingCart.guestEmail = emailIntputValue;
                          }
                          if (identification) shoppingCart.identification = parseInt(identification);
                          setCurrentShoppingCart(shoppingCart);
                          const isCash = selectedPayment?.isCash ?? false;
                          const isBalance = selectedPayment?.isBalance ?? false;
                          let order: Order | null;
                          if (currentBenefit) {
                            order = await storeOrder(
                              currentShoppingCart,
                              currentVenue.id,
                              isCash,
                              isBalance,
                              true,
                              currentVenue.localDateTimeIdentifier,
                              currentBenefit.id,
                              getEmailForOrderBenefit(),
                            );
                          } else {
                            order = await storeOrder(
                              currentShoppingCart,
                              currentVenue.id,
                              isCash,
                              isBalance,
                              true,
                              currentVenue.localDateTimeIdentifier,
                            );
                          }
                          if (order) {
                            if (!order.buyer) await storeLocalOrder(order);
                            redirectToPayment = true;
                            setCurrentShoppingCart({ ...currentShoppingCart, processed: true, isCash });
                            setCurrentOrder(order);
                            if (isCash) {
                              // Payment with cash
                              if (currentBenefit) {
                                const email = currentAuthData?.user.email ?? shoppingCart.guestEmail;
                                if (email) {
                                  await saveBuyersBenefit(email, currentBenefit.id, order.id);
                                }
                              }
                              changeResultOrder(order, true);
                            } else if (isBalance) {
                              // Payment with balance
                              if (currentBenefit) {
                                const email = currentAuthData?.user.email ?? shoppingCart.guestEmail;
                                if (email) {
                                  await saveBuyersBenefit(email, currentBenefit.id, order.id);
                                }
                              }
                              changeResultOrder(order, false);
                            } else {
                              // Payment with provider
                              redirectPayment();
                            }
                          }
                          if (shoppingCart.firstUserEmail) {
                            if (currentAuthData) {
                              const currentUser = { ...currentAuthData.user, email: shoppingCart.firstUserEmail };
                              const updatedUserData = { ...currentAuthData, user: currentUser };
                              setCurrentAuthData(updatedUserData);
                            }
                          }
                        } catch (error) {
                          console.log(error);
                        } finally {
                          setIsLoading(false);
                        }
                      }
                      if (!redirectToPayment) {
                        setIsLoading(false);
                        openErrorModal(t('error-no-received-order'));
                      }
                    }
                  }}
                  disabledTitleStyle={styles.confirmPaymentBtnTextTotem}
                  disabledStyle={styles.buttonDisabled}
                ></Button>
              </View>
              <View style={{ width: '100%', alignItems: 'flex-start', marginTop: 65, marginBottom: 100 }}>
                <Button
                  title={t('cancel-order')}
                  type="clear"
                  containerStyle={[styles.button, { marginLeft: 35 }, { outlineStyle: 'none' }]}
                  titleStyle={[
                    styles.confirmPaymentBtnTextTotem,
                    {
                      color: currentVenue?.appColor ?? greenColor,
                      fontWeight: '500',
                      fontSize: 32,
                      lineHeight: 16,
                      textAlign: 'center',
                    },
                  ]}
                  onPress={async () => {
                    setCurrentShoppingCart(null);
                    await deleteCurrentShoppingCart();
                    navigation.navigate('CommerceScreen');
                  }}
                  disabledTitleStyle={styles.confirmPaymentBtnTextTotem}
                  disabledStyle={styles.buttonDisabled}
                ></Button>
              </View>
            </>
          ) : (
            <>
              <Text
                style={{
                  fontWeight: '500',
                  fontSize: 48,
                  lineHeight: 20,
                  textTransform: 'capitalize',
                  letterSpacing: 0.1,
                  marginLeft: 30,
                  marginBottom: 100,
                  color: '#000000',
                }}
              >
                {t('products-shopping-cart')}
              </Text>
              <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Image source={ShoppingCartIcon} style={{ width: 437, height: 367 }} />
                <Text style={styles.noProducts}>{t('empty-shopping-cart')}</Text>
              </View>
              <View style={{ flexDirection: 'column', alignSelf: 'center', justifyContent: 'center', marginTop: 125 }}>
                <Button
                  title={`${t('continue-shopping')}`}
                  containerStyle={[
                    styles.emptyShoppingCartContinueButton,
                    { outlineStyle: 'none' },
                    { backgroundColor: currentVenue?.appColor ?? greenColor },
                  ]}
                  titleStyle={[
                    {
                      color: '#FFFFFF',
                      fontWeight: '500',
                      fontSize: 32,
                      lineHeight: 16,
                      textAlign: 'center',
                    },
                  ]}
                  type="clear"
                  onPress={() => {
                    setModalizeData(null);
                    navigation.reset({
                      index: 0,
                      routes: [{ name: 'CommerceScreen', params: redirectionItems ?? undefined }],
                    });
                  }}
                ></Button>
              </View>
            </>
          )}
        </View>
      </ScrollView>
      <NavBarForTotem />
    </View>
  );
};
