import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  infoContainer: {
    alignItems: 'center',
  },
  title: {
    textAlign: 'center',
    marginBottom: 16,
    color: '#89909B',
    fontSize: 18,
  },
  mainText: {
    textAlign: 'center',
    marginTop: 16,
    color: '#89909B',
    fontSize: 18,
  },
  subtitle: {
    textAlign: 'center',
    marginTop: 16,
    fontSize: 18,
  },
  buttonContainer: {
    marginTop: 16,
  },
  mainButtonContainer: {
    borderRadius: 12,
  },
  mainButton: {
    minHeight: 70,
  },
  secondaryButton: {
    fontWeight: 'bold',
  },
  venueLogo: {
    height: 60,
    width: 60,
  },
});
