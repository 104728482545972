import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import Article from 'types/models/Article';
import ArticleImage from 'types/models/ArticleImage';
import ArticleDetail from 'types/models/ArticleDetail';

export const getArticles = async (venueId: string, page?: number): Promise<PaginationData<Article> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `venues/${venueId}/articles?page=${page ?? 1}&perPage=${3}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseArticles(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
      lastPage: paginationData.last_page,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getArticle = async (articleId: string): Promise<Article | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/articles/${articleId}`,
    });
    return parseArticle(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseArticles = (articles: []): Article[] => {
  return articles.map((article: any): Article => parseArticle(article));
};

const parseArticle = (article: any): Article => {
  return {
    id: article.id,
    title: article.title,
    description: article.description,
    date: article.date,
    images: parseArticleImages(article.images),
    detail: article.detail ? parseArticleDetail(article.detail) : undefined,
  };
};

const parseArticleImages = (images: any): ArticleImage[] => {
  return images.map(
    (image: any): ArticleImage => ({
      articleImageType: image.pivot.article_image_type_id,
      description: image.description,
      path: image.path,
    }),
  );
};

const parseArticleDetail = (detail: any): ArticleDetail => {
  return {
    id: detail.id,
    body: detail.body,
  };
};
