import React from 'react';
import { Modal, View, Image, Pressable, Platform, Text, ScrollView } from 'react-native';
import { Icon as RNIcon } from 'react-native-elements';
import { WithLocalSvg } from 'react-native-svg';
import ShoppingBarSuccess from 'assets/images/shopping-bar-success.svg';
import { styles } from 'styles/components/suggestedProductsModal';
import OrderContext from 'contexts/OrderContext';
import Product from 'types/models/Product';
import ProductItem from './ProductItem';
import useSmallScreen from 'hooks/useSmallScreen';
import SuggestedProductsModalProps from 'types/components/SuggestedProductsModalProps';

export default ({ onClose, onChangeQuantity, onDeleteProduct, items }: SuggestedProductsModalProps): JSX.Element => {
  const isWeb = Platform.OS === 'web';
  const isSmallScreen = useSmallScreen();

  const { currentShoppingCart, setCurrentShoppingCart, currentCommerce } = React.useContext(OrderContext);
  const checkProductInShoppingCart = (product: Product): number => {
    if (!currentShoppingCart || currentShoppingCart.detail.length === 0) {
      return 0;
    } else {
      const productIndex = currentShoppingCart.detail.findIndex((item) => item.product.id === product.id);
      if (productIndex !== -1) {
        return currentShoppingCart.detail[productIndex].quantity;
      }
    }

    return 0;
  };

  const addProductToShoppingCart = async (productItem: Product): Promise<void> => {
    if (!currentCommerce) return;
    let shoppingCart = currentShoppingCart ?? null;
    if (shoppingCart && shoppingCart.commerce.id === currentCommerce.id) {
      //Si currentShoppingCart fue seteado, busca el producto. Si no lo encuentra lo agrega.
      const detailItem = shoppingCart.detail.find((item) => item.product.id === productItem.id);
      if (!detailItem) {
        shoppingCart.detail.push({ product: productItem, quantity: 1 });
      }
    } else {
      //Si currentShoppingCart no fue seteado o el comercio del carrito actual no coincide con el comercio del producto, se vacia el carrito
      shoppingCart = {
        commerce: { id: currentCommerce.id, code: currentCommerce.code, name: currentCommerce.name },
        detail: [{ product: productItem, quantity: 1 }],
        processed: false,
        paid: false,
        phone: '',
        isCash: false,
      };
    }
    setCurrentShoppingCart({ ...shoppingCart });
  };
  return (
    <Modal animationType="slide" transparent={true} visible={true} style={styles.modal}>
      <View style={styles.container}>
        <View
          style={[
            styles.responseDataContainer,
            !isWeb && isSmallScreen && { height: '60%' },
            isWeb && { height: '70%' },
            isWeb && isSmallScreen && { height: '75%' },
          ]}
        >
          <View style={styles.closeButton}>
            <Pressable onPress={onClose} style={styles.closeOpacity}>
              <RNIcon name="close" style={styles.closeImage} />
            </Pressable>
          </View>

          <View style={styles.modalImageContainer}>
            {Platform.OS === 'web' ? (
              <Image source={ShoppingBarSuccess} style={[styles.modalImage, { width: 50 }, { height: 62 }]} />
            ) : (
              <WithLocalSvg asset={ShoppingBarSuccess} style={[styles.modalImage, { width: 50 }, { height: 62 }]} />
            )}
          </View>
          <View style={Platform.OS === 'web' ? styles.content : styles.contentMobile}>
            <Text style={[styles.title]}>PRODUCTOS SUGERIDOS</Text>

            <ScrollView
              style={[
                styles.generalTextContainer,
                isSmallScreen && !isWeb && styles.generalTextContainerMobile,
                isWeb && isSmallScreen && styles.generalTextContainerSmallWeb,
              ]}
              contentContainerStyle={{ alignItems: 'center', justifyContent: 'center' }}
            >
              {items &&
                items.map((product: Product) => (
                  <>
                    {Platform.OS === 'web' ? (
                      <ProductItem
                        key={product.id}
                        product={product}
                        showControlQuantity={true}
                        initialQuantity={checkProductInShoppingCart(product)}
                        onChangeQuantity={onChangeQuantity}
                        onDeleteProduct={onDeleteProduct}
                        addToCart={addProductToShoppingCart}
                      />
                    ) : (
                      <View style={styles.productItemShadow}>
                        <ProductItem
                          key={product.id}
                          product={product}
                          showControlQuantity={true}
                          initialQuantity={checkProductInShoppingCart(product)}
                          onChangeQuantity={onChangeQuantity}
                          onDeleteProduct={onDeleteProduct}
                          addToCart={addProductToShoppingCart}
                        />
                      </View>
                    )}
                  </>
                ))}
            </ScrollView>
          </View>
          <View style={styles.goToOrdersContainer}>
            <Text style={styles.goToOrdersText} onPress={onClose}>
              Seguir con la compra
            </Text>
          </View>
        </View>
      </View>
    </Modal>
  );
};
