enum VenueSectionType {
  Promotion = 1,
  Commerce = 2,
  Sparx = 3,
  Ticket = 4,
  Discount = 5,
  Download = 6,
  Banner = 7,
  Betting = 8,
}

export default VenueSectionType;
