import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  redirectionBtnContainer: {
    flexDirection: 'row',
    height: '100%',
    backgroundColor: '#0000000d',
    alignItems: 'center',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  redirectionToCommerceText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 'bold',
  },
});
