import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  header: {
    padding: 0,
    marginTop: 15,
    flexDirection: 'row',
    marginBottom: 15,
  },
  separator: {
    width: '100%',
    marginBottom: 15,
    marginTop: 15,
    opacity: 0.15,
    borderStyle: 'solid',
    borderColor: '#000',
    borderWidth: 1,
  },
  descriptionContainer: {
    textAlign: 'center',
    marginTop: 15,
  },
  contentContainer: {
    marginTop: 15,
    alignSelf: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingBottom: 90,
  },
  discountImage: {
    width: 320,
    height: 130,
    alignSelf: 'center',
    borderRadius: 15,
  },
  arrowleft: {
    marginLeft: 10,
    fontSize: 20,
    margin: 0,
  },
  subTitleDescription: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  subTitleDiscount: {
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#89909b',
    marginTop: 15,
    marginBottom: 10,
  },
  subTitleDiscountPhone: {
    marginTop: 15,
    marginBottom: 5,
    fontSize: 14,
    color: '#000',
  },
  importantInputs: {
    fontSize: 14,
    color: '#F00',
    marginBottom: 10,
  },
  textMenu: {
    lineHeight: 23,
    fontWeight: 'bold',
    fontSize: 20,
    fontStyle: 'normal',
    marginLeft: 10,
    maxWidth: 220,
  },
  emailAddressInput: {
    borderColor: '#89909b',
    borderWidth: 1,
    marginTop: 10,
    backgroundColor: '#FFF',
    padding: 10,
    height: 54,
    fontSize: 14,
    borderRadius: 5,
  },
  phoneInput: {
    borderColor: '#89909b',
    borderWidth: 1,
    marginTop: 10,
    backgroundColor: '#FFF',
    padding: 10,
    height: 54,
    fontSize: 14,
    borderRadius: 5,
  },
  confirmPaymentBtnContainer: {
    position: 'absolute',
    margin: 16,
    width: '90%',
    alignSelf: 'center',
    bottom: 0,
    borderRadius: 12,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
  },
  confirmPaymentBtn: {
    height: 67,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  confirmPaymentBtnDisabled: {
    backgroundColor: '#89909b',
  },
});
