import axiosInstance from './axios';
import { AdvertisingBannerProps } from '@double_point/rn-components';
import Discount from 'types/models/Discount';
import Order from 'types/models/Order';
import DiscountImage from 'types/models/DiscountImage';
import { parseOrder } from './ordersService';

export const storeDiscountOrder = async (
  commerceId: string,
  venueId: string,
  discountId: string,
  phone: string,
  localTimeZone: string,
  guestEmail?: string,
): Promise<Order | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'orders/discounts',
      data: {
        commerceId,
        venueId,
        discountId,
        phone,
        guestEmail,
        localTimeZone,
      },
    });
    return parseOrder(data);
  } catch (error: any) {
    console.log(error);
    return null;
  }
};

export const parseDiscountsToCardItems = (
  discounts: Discount[],
  parseDiscountItem: (discount: Discount) => AdvertisingBannerProps,
): AdvertisingBannerProps[] => {
  return discounts.map((discount: Discount) => parseDiscountItem(discount));
};

const parseDiscountImages = (images: any): DiscountImage[] => {
  return images.map(
    (item: any): DiscountImage => ({
      discountId: item.pivot.discount_id,
      discountImageTypeId: item.pivot.discount_image_type_id,
      path: item.path,
    }),
  );
};

const parseDiscount = (discount: any): Discount => {
  return {
    id: discount.id,
    description: discount.description,
    enabled: discount.enabled,
    amount: discount.amount,
    priority: discount.priority,
    images: parseDiscountImages(discount.images),
    commerceId: discount.pivot.commerce_id,
    commerceLogoPath: discount.commerceLogoPath,
  };
};

export const parseDiscounts = (discounts: any): Discount[] => {
  return discounts.map((discount: any) => parseDiscount(discount));
};
