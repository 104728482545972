import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    width: '90%',
    zIndex: 3,
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#00000059',
    alignItems: 'center',
    justifyContent: 'center',
  },
  responseDataContainer: {
    width: '90%',
    height: '50%',
    backgroundColor: '#fff',
    alignSelf: 'center',
    borderRadius: 30,
  },
  closeButton: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    marginTop: 18,
    marginRight: 20,
  },
  closeImage: {
    height: 25,
    width: 25,
  },
  modalImageContainer: {
    position: 'absolute',
    top: -40,
    zIndex: 100,
    borderRadius: 45,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 90,
    height: 90,
    backgroundColor: '#00A80E',
  },
  modalImage: {
    height: 50,
    border: 0,
  },
  generalTextContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    marginHorizontal: 15,
    width: '100%',
    minHeight: 135,
    maxHeight: 360,
    paddingBottom: 5,
  },
  generalTextContainerMobile: {
    minHeight: 135,
    maxHeight: 480,
  },
  generalTextContainerSmallWeb: {
    minHeight: 130,
    maxHeight: 300,
  },
  content: {
    marginTop: 30,
  },
  contentMobile: {
    marginTop: 30,
    flex: 1,
  },
  title: {
    marginBottom: 10,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 20,
    letterSpacing: 0.25,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontStyle: 'normal',
    color: '#00A80E',
  },
  successDataContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },

  goToOrdersContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    marginTop: 15,
    marginBottom: 10,
    paddingVertical: 5,
  },
  goToOrdersText: {
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    textAlign: 'center',
    fontStyle: 'normal',
  },
  closeOpacity: {
    margin: 0,
    outlineStyle: 'none',
  },
  productItemShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});
