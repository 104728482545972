import React from 'react';
import UseSelectArticle from 'types/hooks/useSelectArticle';
import Article from 'types/models/Article';
import ArticleDetail from 'components/ArticleDetail';
import { getArticle } from 'services/articleServices';
import AppContext from 'contexts/AppContext';

export default (): UseSelectArticle => {
  const { setModalizeData } = React.useContext(AppContext);
  const [selectedArticle, setSelectedArticle] = React.useState<Article | null>(null);

  React.useEffect(() => {
    const loadArticles = async () => {
      if (selectedArticle) {
        const data = await getArticle(selectedArticle.id);
        if (data) {
          setModalizeData({
            content: React.createElement(ArticleDetail, {
              article: data,
              onCloseButton: () => setSelectedArticle(null),
            }),
            closeOnOverlayTap: true,
          });
        }
      }
    };
    loadArticles();
  }, [selectedArticle]);

  return { selectedArticle, setSelectedArticle };
};
