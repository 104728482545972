import React from 'react';
import { Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { SuccessModal } from '@double_point/rn-components';
import PaymentMethodItem from 'components/PaymentMethodItem';
import DeleteCardAlert from 'components/DeleteCardAlert';
import PaymentMethodListProps from 'types/components/PaymentMethodListProps';
import PaymentProviderTypes from 'types/enums/PaymentProviderTypes';
import MethodPaymentTypes from 'types/enums/MethodPaymentTypes';
import CreditCard from 'types/models/CreditCard';
import DeleteCreditCardRequest from 'types/models/DeleteCreditCardRequest';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import { getMPCards, deleteMPCard } from 'services/paymentsService';
import { greenColor, grayColor } from 'styles/global';
import { styles } from 'styles/components/paymentMethodList';

export default ({
  paymentConfig,
  setSelectedPayment,
  currentOrderTotalAmount,
  userBalance,
}: PaymentMethodListProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    currentAuthData,
    currentVenue,
    creditCards,
    setIsLoading,
    setCreditCards,
    setModalizeData,
    pairedWithClover,
  } = React.useContext(AppContext);
  const { setSelectedCard, setSelectedPaymentConfig, currentPairedMPPoint } = React.useContext(OrderContext);
  const [checked, setChecked] = React.useState<string | null>(null);

  const getOwnCards = async () => {
    if (!currentAuthData?.user.email || !currentVenue?.paymentConfig?.mercadoPagoConfigId) return;

    setIsLoading(true);
    const creditCards = await getMPCards(currentAuthData.user.email, currentVenue.paymentConfig.mercadoPagoConfigId);
    if (creditCards) setCreditCards(creditCards);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (currentAuthData) {
      getOwnCards();
    } else {
      setCreditCards(null);
    }
  }, [currentAuthData]);

  React.useEffect(() => {
    let checked: MethodPaymentTypes;
    if (paymentConfig.mercadoPagoPointConfigId && currentPairedMPPoint) {
      // Solo si es por MercadoPagoPoint solo tendrá esa opción seleccionada
      // sino, sigue el flujo normal
      checked = MethodPaymentTypes.mercadoPagoPoint;
      onSetChecked(checked);
      return;
    } else if (pairedWithClover) {
      checked = MethodPaymentTypes.clover;
      onSetChecked(checked);
      return;
    } else if (
      currentOrderTotalAmount &&
      userBalance &&
      currentOrderTotalAmount <= userBalance &&
      currentVenue?.paymentConfig?.balanceEnabled
    ) {
      checked = MethodPaymentTypes.balance;
      onSetChecked(checked);
    } else {
      checked = MethodPaymentTypes.cash;
    }
    setSelectedPaymentConfig(paymentConfig);
    if (!paymentConfig.cashEnabled) {
      if (paymentConfig.activePaymentProvider) {
        switch (paymentConfig.activePaymentProvider) {
          case PaymentProviderTypes.firstData:
          case PaymentProviderTypes.izipay:
          case PaymentProviderTypes.mercadoPago:
          case PaymentProviderTypes.MercadoPagoCheckout:
            checked = MethodPaymentTypes.newCard;
            break;
          default:
            break;
        }
        if (checked !== MethodPaymentTypes.cash) {
          onSetChecked(checked);
        }
      }
    } else {
      onSetChecked(checked);
    }
  }, []);

  const onSetChecked = (checked: string): void => {
    setChecked(checked);
    switch (checked) {
      case MethodPaymentTypes.cash:
        setSelectedPayment({ isCash: true });
        break;
      case MethodPaymentTypes.newCard:
        setSelectedPayment({
          isCash: false,
          paymentProvider: paymentConfig.activePaymentProvider,
        });
        break;
      case MethodPaymentTypes.mercadoPagoPoint:
        setSelectedPayment({
          isCash: false,
          paymentProvider: PaymentProviderTypes.mercadoPagoPoint,
        });
        break;
      case MethodPaymentTypes.clover:
        setSelectedPayment({
          isCash: false,
          paymentProvider: PaymentProviderTypes.clover,
        });
        break;
      case MethodPaymentTypes.balance:
        setSelectedPayment({
          isCash: false,
          isBalance: true,
        });
        break;
      default:
        //si no coincide con ninguno, se considera que se seleccionó una tarjeta del usuario
        setSelectedPayment({
          isCash: false,
          paymentProvider: paymentConfig.activePaymentProvider,
          mpCardId: checked,
        });
        const creditCard = creditCards?.find((creditCard: CreditCard) => creditCard.ccId === checked);
        if (creditCard) setSelectedCard(creditCard);
        break;
    }
  };

  const onDeleteCard = (card: CreditCard) => {
    setModalizeData({
      content: <DeleteCardAlert onDeleteCard={() => deleteCreditCard(card)} />,
      closeOnOverlayTap: true,
    });
  };

  const deleteCreditCard = async (card: CreditCard) => {
    if (!currentVenue || !currentAuthData || !currentVenue.paymentConfig?.mercadoPagoConfigId || !card.ccId) return;

    const paymentDataMP: DeleteCreditCardRequest = {
      mpConfigId: currentVenue.paymentConfig.mercadoPagoConfigId,
      email: currentAuthData.user.email,
      ccId: parseInt(card.ccId),
    };

    const data = await deleteMPCard(paymentDataMP);
    if (data) {
      setModalizeData(null);
      getOwnCards();
    } else {
      setModalizeData({
        content: (
          <SuccessModal
            isSuccess={false}
            title={t('error-delete-credit-card-title')}
            mainColor={currentVenue?.appColor ?? greenColor}
            message={t('error-delete-credit-card-message')}
            buttonText={t('continue')}
            onConfirm={() => {
              setModalizeData(null);
            }}
          />
        ),
        closeOnOverlayTap: true,
      });
    }
  };

  if (!checked) {
    return <></>;
  }

  let newCard = false;
  let newCardTitle = '';
  switch (paymentConfig.activePaymentProvider) {
    case PaymentProviderTypes.firstData:
      if (paymentConfig.firstDataConfig) {
        newCard = true;
        newCardTitle = t('new-card-title');
      }
      break;
    case PaymentProviderTypes.mercadoPago:
      newCard = true;
      newCardTitle = t('new-card');
      break;
    case PaymentProviderTypes.izipay:
      if (paymentConfig.izipayConfig) {
        newCard = true;
        newCardTitle = t('new-card-title');
      }
      break;
    case PaymentProviderTypes.MercadoPagoCheckout:
      if (paymentConfig.mercadoPagoCheckoutConfig) {
        newCard = true;
        newCardTitle = t('mercado-pago-app');
      }
    default:
      break;
  }

  return (
    <>
      <Text style={styles.subTitle}>{t('select-the-payment-method')}</Text>
      {/* Solamente en los totems se va a mostrar la opción de Mercado Pago Point*/}
      {paymentConfig.mercadoPagoPointConfigId && currentPairedMPPoint ? (
        <PaymentMethodItem
          title={t('mercado-pago-point')}
          backgroundColorPayment={'#fff'}
          iconName={MethodPaymentTypes.mercadoPagoPoint}
          radioValue={MethodPaymentTypes.mercadoPagoPoint}
          checked={checked}
          setChecked={onSetChecked}
        />
      ) : pairedWithClover ? (
        <PaymentMethodItem
          title={'Clover Posnet'}
          backgroundColorPayment={currentVenue?.appColor ?? greenColor}
          iconName={'credit-card'}
          radioValue={MethodPaymentTypes.clover}
          checked={checked}
          setChecked={onSetChecked}
        />
      ) : (
        <>
          {paymentConfig.cashEnabled && (
            <PaymentMethodItem
              title={t('cash-title')}
              backgroundColorPayment={grayColor}
              iconName={'money-bill'}
              radioValue={MethodPaymentTypes.cash}
              checked={checked}
              setChecked={onSetChecked}
              description={t('cash-only-on-pick-up')}
            />
          )}
          {newCard && creditCards && creditCards.length > 0 && (
            <>
              {creditCards.map((creditCard) => (
                <PaymentMethodItem
                  key={creditCard.ccId}
                  title={`${creditCard.paymentMethod?.name} ${creditCard.ccLastFourDigits}`}
                  backgroundColorPayment={currentVenue?.appColor ?? greenColor}
                  iconName={'credit-card'}
                  radioValue={creditCard.ccId}
                  description={creditCard.issuer?.name}
                  checked={checked}
                  setChecked={onSetChecked}
                  onDeleteCard={() => onDeleteCard(creditCard)}
                />
              ))}
            </>
          )}
          {newCard && (
            <PaymentMethodItem
              title={newCardTitle}
              backgroundColorPayment={currentVenue?.appColor ?? greenColor}
              iconName={'credit-card'}
              radioValue={MethodPaymentTypes.newCard}
              checked={checked}
              setChecked={onSetChecked}
            />
          )}
          {currentOrderTotalAmount &&
            userBalance &&
            currentOrderTotalAmount <= userBalance &&
            currentVenue?.paymentConfig?.balanceEnabled && (
              <PaymentMethodItem
                title={t('payment-with-account-balance')}
                backgroundColorPayment={currentVenue?.appColor ?? greenColor}
                iconName={'coin-dollar'}
                radioValue={MethodPaymentTypes.balance}
                checked={checked}
                setChecked={onSetChecked}
                description={`$${userBalance}`}
              />
            )}
        </>
      )}
    </>
  );
};
