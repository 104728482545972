import React from 'react';
import { View, Text, Linking, Image } from 'react-native';
import { Button } from 'react-native-elements';
import { Feather } from '@expo/vector-icons';
import { useTranslation } from 'react-i18next';
import { styles } from 'styles/components/leavingModal';
import { greenColor } from 'styles/global';
import LeavingModalProps from 'types/components/LeavingModalProps';
import AppContext from 'contexts/AppContext';
import App from 'constants/App';

export default ({
  url,
  message,
  showSecurityMessage,
  buttonText,
  venueLogoForTinting,
}: LeavingModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, setModalizeData } = React.useContext(AppContext);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t('redirect-alert')}</Text>
      <View style={styles.infoContainer}>
        {currentVenue?.useDifferentIconSet ? (
          <Image
            source={{ uri: venueLogoForTinting }}
            style={[styles.venueLogo, { tintColor: currentVenue?.appColor ?? greenColor }]}
          />
        ) : (
          <Feather name="smile" size={50} color={currentVenue?.appColor ?? greenColor} />
        )}
        <Text style={styles.mainText}>{message ?? `${t('leaving')} ${App.appName}`}</Text>
        {showSecurityMessage && <Text style={styles.subtitle}>{t('messsage-secure-site')}</Text>}
      </View>
      <View style={styles.buttonContainer}>
        <Button
          title={buttonText ?? `${t('go')}`}
          containerStyle={[styles.mainButtonContainer, { outlineStyle: 'none' }]}
          buttonStyle={[styles.mainButton, { backgroundColor: currentVenue?.appColor ?? greenColor }]}
          onPress={() => {
            setModalizeData(null);
            try {
              Linking.openURL(url);
            } catch (error) {
              alert(error);
            }
          }}
        ></Button>
        <Button
          title={t('return')}
          type="clear"
          containerStyle={[{ outlineStyle: 'none' }]}
          titleStyle={[styles.secondaryButton, { color: currentVenue?.appColor ?? greenColor }]}
          onPress={() => {
            setModalizeData(null);
          }}
        ></Button>
      </View>
    </View>
  );
};
