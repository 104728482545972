import Product from 'types/models/Product';

export const productIsEmpty = (product: Product): boolean => {
  if (!product.isClothing && !product.stock) {
    return true;
  }

  if (product.isClothing) {
    const allClothingSizeStoksAreEmpty = product.clothingSizeStocks.every(({ stock }) => stock === 0);
    return allClothingSizeStoksAreEmpty;
  }

  return false;
};
