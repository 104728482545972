import axiosInstance from './axios';
import PaginationData from 'types/models/PaginationData';
import Event from 'types/models/Event';
import EventImage from 'types/models/EventImage';
import EventDetail from 'types/models/EventDetail';

export const getNextEvent = async (venueId: string): Promise<Event | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/events/next-event`,
    });
    return data ? parseEvent(data) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getNextEvents = async (venueId: string, page?: number): Promise<PaginationData<Event> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/events/next-events?page=${page ?? 1}`,
    });
    if (!paginationData.data) return null;
    return {
      currentPage: paginationData.current_page,
      data: parseEvents(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
      lastPage: paginationData.last_page,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getEvent = async (eventId: string): Promise<Event | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/events/${eventId}`,
    });
    return parseEvent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getActiveEvents = async (venueId: string, isTotemConnected: boolean): Promise<Event[] | undefined> => {
  try {
    if (isTotemConnected) return undefined;
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/events/active-events`,
    });
    if (!data.length) return undefined;
    return parseEvents(data);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const parseEvents = (events: []): Event[] => {
  return events.map((event: any): Event => parseEvent(event));
};

const parseEvent = (event: any): Event => {
  return {
    id: event.id,
    name: event.name,
    eventStart: event.event_start,
    eventEnd: event.event_end,
    images: parseEventImages(event.images),
    detail: event.detail ? parseEventDetail(event.detail) : undefined,
  };
};

const parseEventImages = (images: any): EventImage[] => {
  return images.map(
    (image: any): EventImage => ({
      eventImageType: image.pivot.event_image_type_id,
      description: image.description,
      path: image.path,
    }),
  );
};

const parseEventDetail = (detail: any): EventDetail => {
  return {
    id: detail.id,
    description: detail.description,
  };
};
