import React from 'react';
import { View, TextInput } from 'react-native';
import { Text } from 'react-native-elements';
import { useTranslation } from 'react-i18next';
import GuestMailInputProps from 'types/components/GuestMailInputProps';
import regexConstants from 'constants/Regex';
import { styles } from 'styles/components/guestMailInput';

export default ({
  label,
  value,
  onChangeValue,
  editable = true,
  inputHasError,
  isForTotemDisplay,
  scrollContainerRef,
}: GuestMailInputProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View
      id={'email'}
      ref={(ref) => {
        if (ref) scrollContainerRef.current.email = ref;
      }}
    >
      <Text
        style={[
          styles.subTitle,
          isForTotemDisplay && { fontSize: 24, fontWeight: '400', lineHeight: 20, marginTop: 20 },
        ]}
      >
        {label} {isForTotemDisplay && <Text style={styles.importantInputs}>*</Text>}
      </Text>
      <TextInput
        keyboardType="email-address"
        value={value}
        onChangeText={onChangeValue}
        style={[
          styles.emailAddressInput,
          isForTotemDisplay && { fontSize: 24, fontWeight: '400', lineHeight: 20 },
          inputHasError && styles.inputHasError,
          !editable && styles.disabledEmailAddressInput,
          { outlineStyle: 'none' },
        ]}
        placeholder={t('guest-email-input-placeholder')}
        placeholderTextColor={inputHasError ? '#F00' : '#C4C4C4'}
        editable={editable}
      />
      <View>
        <Text style={styles.invalidatedEmail}>
          {value !== '' && !regexConstants.email.test(value) && t('guest-email-input-error')}
          <Text
            style={[
              styles.needsValidEmail,
              isForTotemDisplay && { fontSize: 24, fontWeight: '400', lineHeight: 20, marginTop: 20 },
            ]}
          >
            {t('needs-valid-email')}
          </Text>
        </Text>
      </View>
    </View>
  );
};
