import React from 'react';
import { ScrollView } from 'react-native';
import NextEvent from 'components/NextEvent';
import NextEvents from 'components/NextEvents';
import AppContext from 'contexts/AppContext';

export default ({}): JSX.Element => {
  const { currentVenue } = React.useContext(AppContext);

  if (!currentVenue) return <></>;
  return (
    <ScrollView>
      {currentVenue && (
        <>
          <NextEvent />
          <NextEvents />
        </>
      )}
    </ScrollView>
  );
};
