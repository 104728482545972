import React from 'react';
import { View, Text } from 'react-native';
import { styles } from 'styles/components/contentHeader';
import { greenColor } from 'styles/global';
import useIcon from 'hooks/useIcon';
import ContentHeaderProps from 'types/components/ContentHeaderProps';
import AppContext from 'contexts/AppContext';

export default ({ title, hasOrders, onPreviousScreen }: ContentHeaderProps): JSX.Element => {
  const Icon = useIcon();
  const { currentVenue } = React.useContext(AppContext);

  return (
    <View style={styles.header}>
      <View style={styles.headerTitle}>
        {onPreviousScreen && (
          <Icon
            name="arrow-left"
            style={[styles.headerBackIcon, { color: currentVenue?.appColor ?? greenColor }]}
            onPress={() => {
              onPreviousScreen();
            }}
          />
        )}
        <Text
          style={
            hasOrders
              ? [styles.headerTitleTextWithOrders, { color: currentVenue?.appColor ?? greenColor }]
              : styles.headerTitleText
          }
        >
          {title}
        </Text>
      </View>
    </View>
  );
};
