/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import * as clover from 'remote-pay-cloud';
import PaymentDataFD from 'types/models/PaymentDataFD';
import PaymentDataMP from 'types/models/PaymentDataMP';
import CreditCard from 'types/models/CreditCard';
import DeleteCreditCardRequest from 'types/models/DeleteCreditCardRequest';
import MPPointPaymentIntent from 'types/models/MPPointPaymentIntent';
import MPPoint from 'types/models/MPPoint';
import CloverPoint from 'types/models/CloverPoint';
import CloverPointPayment from 'types/models/CloverPointPayment';
import IzipayTokenDataRequest from 'types/models/IzipayTokenDataRequest';
import DetailItem from 'types/models/DetailItem';

export const getHash = async (paymentDataFD: PaymentDataFD): Promise<string | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/first-data/hash',
      data: {
        first_data_config_id: paymentDataFD.firstDataConfigId,
        date_time: paymentDataFD.dateTime,
        charge_total: paymentDataFD.chargeTotal,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeMPPayment = async (paymentDataMP: PaymentDataMP): Promise<any> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/mercado-pago',
      data: {
        ...paymentDataMP,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeMPPaymentPreviousCard = async (paymentDataMP: PaymentDataMP): Promise<any> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/mercado-pago/previous-card',
      data: {
        ...paymentDataMP,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMPCards = async (email: string, mpConfigId: string): Promise<CreditCard[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/mercado-pago/cards',
      data: {
        email,
        mpConfigId,
      },
    });

    return parsedMPCards(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const deleteMPCard = async (request: DeleteCreditCardRequest): Promise<any> => {
  try {
    const { data } = await axiosInstance({
      method: 'delete',
      url: `payments/mercado-pago/cards`,
      data: {
        ...request,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeMPPointPaymentIntent = async (body: {
  orderId: number;
  mpPointId: string;
}): Promise<MPPointPaymentIntent | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: `payments/mercado-pago-point/create-payment-intent`,
      data: body,
    });
    return parseMPPointPaymentIntent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMPPointPaymentIntent = async (mpPointPaymentIntent: string): Promise<MPPointPaymentIntent | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'GET',
      url: `payments/mercado-pago-point/check-payment-intent/${mpPointPaymentIntent}`,
    });
    return parseMPPointPaymentIntent(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAvailableMPPoints = async (mpPointConfig: string): Promise<MPPoint[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'GET',
      url: `config/${mpPointConfig}/available-mp-points`,
    });
    return parseMPPoints(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const pairMPPoint = async (
  mpPointConfig: string,
  mpPointId: string,
  isPaired: boolean,
): Promise<MPPoint | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'PUT',
      url: `config/${mpPointConfig}/pair-mp-point`,
      data: {
        mpPointId,
        isPaired,
      },
    });
    return parseMPPoint(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeCloverPointPayment = async (
  paymentDataCloverPoint: CloverPointPayment,
  rawPaymentData: clover.payments.Payment,
): Promise<any | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: `payments/clover-point/webhook`,
      data: {
        ...paymentDataCloverPoint,
        rawPaymentData,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAvailableCloverPoints = async (cloverPointConfig: string): Promise<CloverPoint[]> => {
  try {
    const { data } = await axiosInstance({
      method: 'GET',
      url: `clover-point/config/${cloverPointConfig}/available-clover-points`,
    });
    return parseCloverPoints(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getCurrentPairedPoint = async (cloverPointId: string): Promise<CloverPoint | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'GET',
      url: `clover-point/${cloverPointId}/current-paired-point`,
    });
    return parseCloverPoint(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const pairCloverPoint = async (
  cloverPointConfig: string,
  cloverPointId: string,
  isPaired: boolean,
): Promise<CloverPoint | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'PUT',
      url: `clover-point/config/${cloverPointConfig}/pair-clover-point`,
      data: {
        cloverPointId,
        isPaired,
      },
    });
    return parseCloverPoint(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getIzipaySessionToken = async (tokenDataRequest: IzipayTokenDataRequest): Promise<string | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/izipay/token',
      data: {
        izipayConfigId: tokenDataRequest.izipayConfigId,
        commerceCode: tokenDataRequest.izipayConfigCommerceCode,
        transactionId: tokenDataRequest.transactionId,
        orderNumber: tokenDataRequest.orderId,
        amount: tokenDataRequest.orderAmount,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const storeIzipayPayment = async (paymentRequest: any, orderId: number): Promise<any | null> => {
  console.log(paymentRequest);
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/izipay',
      data: {
        orderId: orderId,
        code: paymentRequest.code,
        message: paymentRequest.message,
        currency: paymentRequest.response.currency,
        amount: paymentRequest.response.amount,
        uniqueId: paymentRequest.response.uniqueId,
        orderNumber: paymentRequest.response.orderNumber,
        cardBrand: paymentRequest.response.card.brand,
        cardNumber: paymentRequest.response.card.pan,
        codeAuth: paymentRequest.response.codeAuth,
        signature: paymentRequest.response.signature,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const createPreference = async (
  idConfig: string,
  shoppingCartDetails: DetailItem[],
  venueCode: string,
  commerceCode: string,
  orderId: number,
): Promise<string | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'POST',
      url: 'payments/mercado-pago-checkout/create-preference',
      data: {
        mpCheckoutConfigId: idConfig,
        shoppingCartDetail: shoppingCartDetails,
        venueCode,
        commerceCode,
        orderId,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

const parseMPPoints = (data: any): MPPoint[] => {
  return data.map((item: any): MPPoint => parseMPPoint(item));
};

const parseMPPoint = (data: any): MPPoint => {
  return {
    id: data.id,
    description: data.description,
    operatingMode: data.operating_mode,
    isPaired: data.is_paired === 1,
  };
};

const parseCloverPoints = (data: any): CloverPoint[] => {
  return data.map((item: any): CloverPoint => parseCloverPoint(item));
};

const parseCloverPoint = (data: any): CloverPoint => {
  return {
    id: data.id,
    description: data.description,
    model: data.model,
    isPaired: data.is_paired === 1,
    deviceAddress: data.device_address,
  };
};

const parseMPPointPaymentIntent = (data: any): MPPointPaymentIntent => {
  return {
    id: data.id,
    paymentIntentId: data.payment_intent_id,
    amount: data.amount,
    state: data.state,
  };
};

const parsedMPCards = (data: any): CreditCard[] => {
  return data.map((item: any) => parsedMPCard(item));
};

const parsedMPCard = (data: any): CreditCard => {
  return {
    ccId: data.id,
    ccOwner: data.cardholder.name,
    ccExpMonth: data.expiration_month.toString(),
    ccExpYear: data.expiration_year.toString(),
    ccFirstSixDigits: data.first_six_digits,
    ccLastFourDigits: data.last_four_digits,
    paymentMethod: {
      id: data.payment_method.id,
      name: data.payment_method.name,
    },
    issuer: {
      id: data.issuer.id,
      name: data.issuer.name,
    },
  };
};
