import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFF',
  },
  content: {
    paddingHorizontal: 20,
    paddingBottom: 100,
  },
  header: {
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerTitle: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  headerBackIcon: {
    fontSize: 20,
    paddingTop: 4,
  },
  headerTitleText: {
    fontSize: 20,
    fontWeight: '600',
    marginLeft: 10,
  },
  headerCommentQuestionIcon: {
    fontSize: 28,
    paddingTop: 4,
  },
  headerCommentChatIcon: {
    height: 35,
    width: 35,
  },
  noProducts: {
    fontWeight: 'bold',
    fontSize: 21,
    marginTop: 20,
    padding: 40,
  },
  separator: {
    width: '100%',
    marginBottom: 15,
    marginTop: 15,
    opacity: 0.5,
    borderStyle: 'solid',
    borderColor: '#979797',
    borderWidth: 0.5,
  },
  requiredInput: {
    borderColor: '#F00',
  },
  inputPhoneContainer: {
    marginTop: 20,
  },
  disabledPhoneInput: {
    backgroundColor: '#89909b',
    color: '#FFF',
  },
  dataOrder: {
    fontSize: 18,
    color: '#89909b',
  },
  subTitle: {
    fontSize: 14,
    color: '#000',
  },
  importantInputs: {
    fontSize: 14,
    color: '#F00',
    marginBottom: 10,
  },
  phoneInput: {
    borderColor: '#89909b',
    borderWidth: 1,
    marginTop: 10,
    backgroundColor: '#FFF',
    padding: 10,
    height: 54,
    fontSize: 14,
    borderRadius: 5,
  },
  picker: {
    borderColor: '#89909b',
    borderWidth: 1,
    backgroundColor: '#FFF',
    padding: 10,
    height: 54,
    fontSize: 14,
    borderRadius: 5,
  },
  pickerIos: {
    fontSize: 14,
  },
  pickerContainer: {
    marginTop: 5,
    marginBottom: 6,
  },
  inputHasError: {
    borderColor: '#F00',
  },
  inputTextError: {
    color: '#F00',
  },
  confirmPaymentBtnContainer: {
    alignSelf: 'center',
    width: '80%',
    height: 40,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  confirmPaymentFloatingBtnContainer: {
    alignSelf: 'center',
    width: '95%',
    height: 50,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  confirmPaymentBtnTextContainer: {
    flex: 4,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  confirmPaymentBtnTotalTextContainer: {
    flex: 2,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  shoppingCartActionsText: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 16,
  },
  confirmPaymentBtnText: {
    textAlign: 'center',
    color: '#FFF',
  },
  confirmPaymentBtnDisabled: {
    backgroundColor: '#89909b',
  },
  invalidatedIdentification: {
    color: '#F00',
    fontSize: 16,
    marginTop: 5,
  },
  phoneNumberContact: {
    paddingLeft: 15,
    paddingRight: 15,
    fontSize: 12,
  },
  buttonsContainer: {
    marginTop: 25,
    width: 300,
    height: 120,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 10,
    lineHeight: 20,
  },
  returnAdviceContainer: {
    flexDirection: 'column',
    marginTop: 50,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  returnButtonContainer: {
    marginTop: 10,
    width: '60%',
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
  },
  returnTextContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  returnText: {
    color: '#FFFFFF',
    fontSize: 16,
    fontWeight: '500',
    lineHeight: 16,
    fontStyle: 'normal',
  },
  stickyBtnText: {
    fontSize: 16,
    fontWeight: '700',
  },
});
