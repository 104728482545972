import * as React from 'react';
import { Modal, View, useWindowDimensions, Platform } from 'react-native';
import { Icon } from 'react-native-elements';
import WebView from 'react-native-webview';
import { Loading } from '@double_point/rn-components';
import ChatBotModal from 'types/components/ChatBotModal';
import AppContext from 'contexts/AppContext';
import App from 'constants/App';

export default ({ isVisible, chatbotLink, setIsChatBotModalVisible, navigation }: ChatBotModal) => {
  const { currentVenue } = React.useContext(AppContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const webViewRef = React.useRef<any>();
  const { height } = useWindowDimensions();
  const routes = navigation.getState().routes;
  const currentRoute = routes[routes.length - 1];

  const htmlForWeb = `
            <html>
                <body>
            </body>
            <!-- START Botmaker Webchat-->
            <script>
            (function () {
              let js = document.createElement('script');
              js.type = 'text/javascript';
              js.async = 1;
              js.src = "${chatbotLink}";
              document.body.appendChild(js);
            })();
            </script> 
            <!-- END Botmaker Webchat-->
            </html>`;

  const injectedJavascriptForWebView = () => {
    webViewRef.current.injectJavaScript(`
    (function () {
          let js = document.createElement('script');
          js.type = 'text/javascript';
          js.async = 1;
          js.src = "${chatbotLink}";
          document.body.appendChild(js);
    })();
    `);
  };

  const injectedJavascriptAtTheStartOfTheWebView = () => {
    webViewRef.current.injectJavaScript(`
    (function () {
          let body = document.body;
          body.style.backgroundColor = '#ffffff';
    })();
    `);
  };

  if (!currentVenue) {
    return <></>;
  }

  return (
    <>
      <Modal visible={isVisible}>
        {isLoading && <Loading />}

        {!isLoading && (
          <View style={{ backgroundColor: '#F2F4F8', alignItems: 'flex-end', padding: 12, marginTop: 15 }}>
            <Icon name="close" size={30} color="#000" onPress={() => setIsChatBotModalVisible(!isVisible)} />
          </View>
        )}
        {Platform.OS === 'web' ? (
          <iframe
            id="test"
            style={{ border: 0, borderWidth: 0 }}
            srcDoc={htmlForWeb}
            height={height - 28}
            width="100%"
            onLoad={() => setIsLoading(false)}
          />
        ) : (
          <>
            <View style={{ flex: 1, flexDirection: 'column' }}>
              <WebView
                ref={webViewRef}
                originWhitelist={['*']}
                source={{ uri: App.backgroundWhiteImage }}
                onLoad={() => setIsLoading(false)}
                onLoadStart={injectedJavascriptAtTheStartOfTheWebView}
                onLoadEnd={injectedJavascriptForWebView}
                scalesPageToFit={true}
                style={{ border: 0, borderWidth: 0, width: '100%', height: height - 28, backgroundColor: '#ffffff' }}
                onError={(syntheticEvent) => {
                  const { nativeEvent } = syntheticEvent;
                  alert(`WebView error: ${nativeEvent}`);
                }}
                on
                onNavigationStateChange={(event) => {
                  if (event.url.includes(currentVenue?.code)) {
                    const getCommerceCodeFromInternalUrl = event.url.split('/').slice(-1).join('');
                    setIsChatBotModalVisible(!isVisible);
                    if (currentRoute.name === 'HomeScreen') {
                      navigation.navigate('CommerceScreen', {
                        venueCode: currentVenue?.code,
                        commerceCode: getCommerceCodeFromInternalUrl,
                        productId: undefined,
                      });
                    } else {
                      navigation.reset({
                        index: 1,
                        routes: [
                          { name: 'HomeScreen' },
                          {
                            name: 'CommerceScreen',
                            params: {
                              venueCode: currentVenue?.code,
                              commerceCode: getCommerceCodeFromInternalUrl,
                              productId: undefined,
                            },
                          },
                        ],
                      });
                    }
                  }
                }}
                javaScriptEnabled={true}
                domStorageEnabled={true}
              ></WebView>
            </View>
          </>
        )}
      </Modal>
    </>
  );
};
