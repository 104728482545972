import AsyncStorage from '@react-native-async-storage/async-storage';
import ShoppingCart from 'types/models/ShoppingCart';
import constantsStorage from 'constants/Storage';
import Commerce from 'types/models/Commerce';

export const notAutoReloadScreenData = async (
  currentCommerceCodeFromParams?: string,
  currentCommerce?: Commerce,
): Promise<boolean> => {
  const currentShoppingCart = await AsyncStorage.getItem(constantsStorage.currentShoppingCart);
  if (currentShoppingCart) {
    const parsedShoppingCart: ShoppingCart = JSON.parse(currentShoppingCart);
    if (
      parsedShoppingCart &&
      parsedShoppingCart.detail.length > 0 &&
      !parsedShoppingCart.paid &&
      !parsedShoppingCart.processed &&
      currentCommerceCodeFromParams
        ? parsedShoppingCart.commerce.code === currentCommerceCodeFromParams
        : parsedShoppingCart.commerce.code === currentCommerce?.code
    ) {
      return true;
    }
  }
  return false;
};
