import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  subTitle: {
    fontSize: 14,
    color: '#000',
  },
  importantInputs: {
    fontSize: 14,
    color: '#F00',
    marginBottom: 10,
  },
  emailAddressInput: {
    borderColor: '#89909b',
    borderWidth: 1,
    marginTop: 10,
    backgroundColor: '#FFF',
    height: 54,
    fontSize: 14,
    borderRadius: 5,
    padding: 10,
  },
  disabledEmailAddressInput: {
    backgroundColor: '#89909b',
    color: '#FFF',
  },
  invalidatedEmail: {
    color: '#F00',
    fontSize: 16,
    marginTop: 5,
  },
  needsValidEmail: {
    fontSize: 12,
    paddingLeft: 15,
    paddingRight: 15,
  },
  inputHasError: {
    borderColor: '#F00',
  },
});
