import React from 'react';
import { FlatList, Platform, useWindowDimensions } from 'react-native';
import OrderItem from 'components/OrderItem';
import OrdersListProps from 'types/components/OrdersListProps';
import Order from 'types/models/Order';
import AppContext from 'contexts/AppContext';

export default ({ ordersPaginationData, orders, onEndReached, navigation }: OrdersListProps): JSX.Element => {
  const { setSelectedOrder } = React.useContext(AppContext);
  const { height } = useWindowDimensions();
  const onClickDetail = (id: number) => {
    const order = orders.find((order) => order.id === id);
    if (order) {
      setSelectedOrder(order);
      navigation.navigate('OrdersDetailScreen', {
        ...(Platform.OS === 'web' && {
          venueCode: order.venue?.code,
          commerceCode: order.commerce.code,
          orderNumber: `${order.id}`,
        }),
        showModal: false,
      });
    }
  };

  const renderItem = ({ item }: { item: Order }): JSX.Element => {
    return <OrderItem key={item.id} order={item} onClickDetail={onClickDetail} />;
  };

  return (
    <FlatList
      data={orders}
      renderItem={renderItem}
      keyExtractor={(item) => item.id.toString()}
      initialNumToRender={ordersPaginationData.perPage}
      onEndReachedThreshold={0.5}
      onEndReached={onEndReached}
      style={{ height: height - 220 }}
    />
  );
};
