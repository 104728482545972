import React from 'react';
import { ScrollView, View, Text, useWindowDimensions, Platform } from 'react-native';
import AppContext from 'contexts/AppContext';
import { styles } from 'styles/screens/twitterScreen';
import WebView from 'react-native-webview';

export default (): JSX.Element => {
  const { currentVenue, tabHeight } = React.useContext(AppContext);
  const { height } = useWindowDimensions();
  const webViewRef = React.useRef<any>();

  const WallioSection = (): JSX.Element => {
    const htmlForWeb = `
    <html>
        <body>
    </body>
    <!-- START Botmaker Webchat-->
    <script async src="https://walls.io/js/wallsio-widget-1.2.js" data-wallurl="${
      currentVenue?.wallioUrl
    }"  data-title="${currentVenue?.wallioTitle}" data-width="100%" data-height="${
      Platform.OS === 'web' ? 800 : '80%'
    }" data-lazyload="1"></script>
    <!-- END Botmaker Webchat-->
    </html>`;

    if (Platform.OS === 'web') {
      return (
        <iframe id="test" style={{ border: 0, borderWidth: 0 }} srcDoc={htmlForWeb} height={height} width="100%" />
      );
    } else {
      return (
        <WebView
          ref={webViewRef}
          source={{ html: htmlForWeb }}
          originWhitelist={['*']}
          scalesPageToFit={true}
          style={{ border: 0, borderWidth: 0, width: '100%', height: height - tabHeight, backgroundColor: '#ffffff' }}
          onError={(syntheticEvent) => {
            const { nativeEvent } = syntheticEvent;
            alert(`WebView error: ${nativeEvent}`);
          }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
        ></WebView>
      );
    }
  };
  return (
    <ScrollView style={styles.twitterFeedScreenScrollContainer}>
      {currentVenue?.wallioUrl ? (
        <WallioSection />
      ) : (
        <View style={styles.noAvailablePublications}>
          <Text>Sin publicaciones disponibles</Text>
        </View>
      )}
      {/* <TwitterFeed data={twitterFeed} withImages /> */}
    </ScrollView>
  );
};
