import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  twitterFeedScreenScrollContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  twitterFeedScreenContainer: {
    width: 340,
  },
  noAvailablePublications: {
    marginTop: 25,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
