import React from 'react';
import { Text, View, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import ProductItem from 'components/ProductItem';
import OrderDetailListProps from 'types/components/OrderDetailListProps';
import { styles } from 'styles/components/orderDetailList';

export default ({
  detail,
  showControlQuantity,
  onChangeQuantity,
  onDeleteProduct,
  isForTotemDisplay,
  isModalCart,
}: OrderDetailListProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <View style={[styles.container, isModalCart && { width: '100%' }]}>
      <Text
        style={[
          styles.subTitle,
          isForTotemDisplay && {
            fontWeight: '500',
            fontSize: 48,
            lineHeight: 20,
            textTransform: 'capitalize',
            letterSpacing: 0.1,
            marginLeft: 30,
            marginBottom: 100,
            color: '#000000',
          },
        ]}
      >
        {isModalCart !== true &&
          `${t(isForTotemDisplay ? 'products-shopping-cart' : 'order-resume')} ${
            !isForTotemDisplay ? `(${detail.length})` : ''
          }`}
      </Text>
      {detail.map((detailItem, index) => (
        <>
          {Platform.OS === 'web' ? (
            <ProductItem
              product={detailItem.product}
              clothingSize={detailItem.clothingSize}
              textPriceColor={'#89909b'}
              initialQuantity={detailItem.quantity}
              showControlQuantity={showControlQuantity}
              onChangeQuantity={onChangeQuantity}
              onDeleteProduct={onDeleteProduct}
              key={index}
            />
          ) : (
            <View style={styles.productItemShadow}>
              <ProductItem
                product={detailItem.product}
                clothingSize={detailItem.clothingSize}
                textPriceColor={'#89909b'}
                initialQuantity={detailItem.quantity}
                showControlQuantity={showControlQuantity}
                onChangeQuantity={onChangeQuantity}
                onDeleteProduct={onDeleteProduct}
                key={index}
              />
            </View>
          )}
        </>
      ))}
    </View>
  );
};
