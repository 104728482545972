import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  shoppingCartLength: {
    position: 'absolute',
    top: 0,
    right: 15,
    orderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  shoppingCartLengthNumber: {
    fontSize: 36,
    textAlign: 'center',
    color: '#FFF',
  },
});
