import React from 'react';
import { useTranslation } from 'react-i18next';
import { ScrollView, Text, View, TouchableOpacity } from 'react-native';
import { Entypo } from '@expo/vector-icons';
import { ItemsCarousel, CardInformation, CarouselItemProps } from '@double_point/rn-components';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import ArticleImageTypes from 'types/enums/ArticleImageType';
import ArticleDetailProps from 'types/components/ArticleDetailProps';
import { styles } from 'styles/components/articleDetail';
import { greenColor } from 'styles/global';
import AppContext from 'contexts/AppContext';
import App from 'constants/App';

export default ({ article, onCloseButton }: ArticleDetailProps): JSX.Element => {
  const { t } = useTranslation();
  const { currentVenue, setModalizeData } = React.useContext(AppContext);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const bannerArticle = article.images.find((image) => image.articleImageType === ArticleImageTypes.banner);
  const detailImages = article.images.filter((image) => image.articleImageType === ArticleImageTypes.detail);

  const parseDetailImageToCarouselItem = (): CarouselItemProps[] => {
    return detailImages.map((detailImage, index) => ({
      id: index,
      image: detailImage.path,
    }));
  };

  return (
    <ScrollView style={styles.container}>
      <View style={styles.headerContainer}>
        <View style={styles.headerTextTitleContainer}>
          <Text style={styles.headerTextTitle}>{article.title}</Text>
        </View>
        <TouchableOpacity
          style={[styles.headerCloseButton, { outlineStyle: 'none' }]}
          activeOpacity={0.75}
          onPress={() => {
            setModalizeData(null);
            if (onCloseButton) onCloseButton();
          }}
          hitSlop={styles.touchableHitSlop}
        >
          <Entypo name="cross" style={{ color: '#000000', fontSize: 30, height: '100%', width: '100%' }} />
        </TouchableOpacity>
      </View>
      <CardInformation
        id={article.id}
        image={bannerArticle?.path}
        title={''}
        date={article.date}
        isForArticleListing={false}
        venueLogoPath={venueLogoImage}
        showDate={true}
        showTime={true}
        dateLanguage={currentVenue ? currentVenue?.language : ''}
      />
      {/*<View style={styles.descriptionTitleContainer}>
        <Text style={[styles.descriptionTitle, { color: currentVenue?.appColor ?? greenColor }]}>{article.title}</Text>
      </View>

      <View
        style={[
          styles.descriptionContainer,
          (detailImages.length > 0 || article.detail) && styles.descriptionContainerBorder,
        ]}
      >
        <Text style={styles.description}>{article.description}</Text>
      </View>*/}
      {detailImages.length > 0 && (
        <View style={styles.detailPhotosContainer}>
          <ItemsCarousel
            title={t('photos')}
            items={parseDetailImageToCarouselItem()}
            itemsSize={{ height: 135, width: 135 }}
            textColor={currentVenue?.appColor ?? greenColor}
            seeMoreText={t('see-more')}
          />
        </View>
      )}

      <View style={styles.descriptionContainer}>
        <Text style={styles.description}>{article.description}</Text>
      </View>

      {article.detail && (
        <View style={styles.bodyContainer}>
          <Text style={styles.body}>{article.detail.body}</Text>
        </View>
      )}
    </ScrollView>
  );
};
