import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#FFFFFF',
  },
  bagContainer: {
    marginTop: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bagText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: 24,
    fontSize: 20,
    paddingTop: 20,
  },
  image: {
    width: 71.33,
    height: 89.17,
    alignSelf: 'center',
  },
  buttonContainer: {
    top: 350,
    display: 'flex',
    position: 'absolute',
    width: 380,
    height: 88,
    justifyContent: 'space-between',
    alignSelf: 'center',
  },
  textAboveButton: {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    lineHeight: 21,
    opacity: 0.5,
  },
  button: {
    height: 67,
    borderRadius: 12,
    paddingVertical: 13,
    paddingHorizontal: 83,
    marginHorizontal: 17,
    marginVertical: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    justifyContent: 'center',
    alignItems: 'center',
  },
  filtersScrollContainer: {
    paddingVertical: 1,
  },
  filtersContainer: {
    padding: 15,
    borderColor: '#0000000d',
    borderBottomWidth: 1,
  },
  filterBtnTitle: {
    textTransform: 'uppercase',
    fontSize: 14,
    letterSpacing: 1.25,
    color: '#89909B',
  },
  filterBtnContainer: {
    height: 41,
    backgroundColor: '#FFF',
    borderRadius: 25,
    marginRight: 10,
    paddingLeft: 7,
    paddingRight: 7,
  },
  selectedFilterBtnTitle: {
    color: '#FFF',
  },
  textInsideButton: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: 23,
    fontSize: 20,
    color: '#FFFFFF',
  },
  ordersContainer: {
    padding: 10,
  },
});
