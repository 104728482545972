enum OrderStatuses {
  created = 1,
  paymentAccepted = 2,
  toDeliver = 3,
  preparing = 4,
  delivering = 5,
  delivered = 6,
}

export default OrderStatuses;
