import React from 'react';
import { ScrollView, View, Text, TouchableOpacity } from 'react-native';
import { Image, Button } from 'react-native-elements';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import ContentHeader from 'components/ContentHeader';
import OrdersList from 'components/OrdersList';
import AppContext from 'contexts/AppContext';
import OrdersScreenProps from 'types/screens/OrdersScreenProps';
import Order from 'types/models/Order';
import PaginationData from 'types/models/PaginationData';
import { fetchLocalOrdersRemote, getOrders } from 'services/ordersService';
import ShoppingBag from 'assets/images/shopping-bag.png';
import useLocalOrders from 'hooks/useLocalOrders';
import useIcon from 'hooks/useIcon';
import { styles } from 'styles/screens/ordersScreen';
import { greenColor } from 'styles/global';

export default ({ navigation }: OrdersScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const Icon = useIcon();
  type FilterType = { id: number; description: string };
  const filterTypes: FilterType[] = [
    { id: 1, description: t('order-filter-on-this-venue') }, // se filtra por venueId, solo las orders de backend
    { id: 2, description: t('record') }, // se filtran todas las orders, solo del backend
  ];
  const { isLoading, currentAuthData, currentVenue, setIsLoading } = React.useContext(AppContext);
  const { getLocalOrders } = useLocalOrders();
  const [ordersPaginationData, setOrdersPaginationData] = React.useState<PaginationData<Order> | null>(null);
  const [orders, setOrders] = React.useState<Order[]>([]);
  const [showSectionVenues, setShowSectionVenues] = React.useState<boolean>(currentVenue !== null);
  const [currentFilter, setCurrentFilter] = React.useState<FilterType>();
  const [filters, setFilters] = React.useState<FilterType[]>(filterTypes);

  const onBackButton = () => {
    navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Home',
            state: {
              routes: [
                {
                  name: 'HomeScreen',
                },
              ],
            },
          },
        ],
      }),
    });
  };

  const fetchOrders = async (page?: number, venueId?: string) => {
    setIsLoading(true);
    const response = await getOrders(page, venueId);
    setOrdersPaginationData(response);
    if (response) {
      if (response.currentPage === 1) {
        setOrders([...response.data]);
      } else {
        setOrders([...orders, ...response.data]);
      }
    }
    setIsLoading(false);
  };

  const fetchLocalOrders = async (page?: number) => {
    setIsLoading(true);
    const localOrders = await getLocalOrders();
    if (localOrders) {
      const orderIds = localOrders.map((item) => item.id);
      fetchRemoteOrders(orderIds, page);
    } else {
      setOrders([]);
      pageOrders([]);
    }

    setIsLoading(false);
  };

  const fetchRemoteOrders = async (ids: number[], page?: number) => {
    const response = await fetchLocalOrdersRemote(ids, page);
    setOrdersPaginationData(response);
    if (response) {
      if (response.currentPage === 1) {
        setOrders([...response.data]);
      } else {
        setOrders([...orders, ...response.data]);
      }
    }
  };

  const pageOrders = (orders: Order[]) => {
    const pagination: PaginationData<Order> = {
      currentPage: 1,
      lastPage: 1,
      perPage: orders.length,
      data: orders,
      total: 1,
    };
    setOrdersPaginationData(pagination);
  };

  React.useEffect(() => {
    const checkFilters = async () => {
      const guestFilter: FilterType = { id: 3, description: t('as-guest') }; // se filtran solo las del storage
      const localOrders = await getLocalOrders();

      if (currentAuthData) {
        const filters: FilterType[] = currentVenue ? [...filterTypes] : [...filterTypes.slice(1)];
        if (localOrders) filters.push(guestFilter);
        setFilters([...filters]);
        setCurrentFilter(currentVenue ? filterTypes[0] : filterTypes[1]);
      } else {
        setFilters([guestFilter]);
        setCurrentFilter(guestFilter);
      }
    };
    checkFilters();
  }, [currentAuthData, currentVenue]);

  /*React.useEffect(() => {
    getOrders();
  }, [currentVenue, currentFilter]);*/

  const getOrdersToShow = () => {
    if (!currentFilter) return;
    switch (currentFilter.id) {
      case 1:
        if (!currentVenue) return;
        setShowSectionVenues(true);
        fetchOrders(1, currentVenue.id);
        break;
      case 2:
        setShowSectionVenues(false);
        fetchOrders();
        break;
      case 3:
        fetchLocalOrders();
        break;
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      getOrdersToShow();
    }, [currentVenue, currentFilter]),
  );

  const renderContent = (): JSX.Element => {
    if (!ordersPaginationData) return <></>;
    return (
      <View style={styles.ordersContainer}>
        <OrdersList
          ordersPaginationData={ordersPaginationData}
          orders={orders}
          onEndReached={() => {
            if (ordersPaginationData.currentPage < ordersPaginationData.lastPage && currentFilter?.id !== 3) {
              if (currentVenue && showSectionVenues) {
                fetchOrders(ordersPaginationData.currentPage + 1, currentVenue.id);
              } else {
                fetchOrders(ordersPaginationData.currentPage + 1);
              }
            } else if (ordersPaginationData.currentPage < ordersPaginationData.lastPage && currentFilter?.id === 3) {
              fetchLocalOrders(ordersPaginationData?.currentPage + 1);
            }
          }}
          navigation={navigation}
        />
      </View>
    );
  };

  const renderEmpty = (): JSX.Element => {
    if (isLoading) return <></>;
    return (
      <View>
        <View style={styles.bagContainer}>
          {currentVenue?.useDifferentIconSet ? (
            <Icon name={'shopping-cart1'} style={[styles.image, { color: currentVenue?.appColor, fontSize: 85 }]} />
          ) : (
            <Image style={[styles.image, { tintColor: currentVenue?.appColor }]} source={ShoppingBag} />
          )}
          <Text style={[styles.bagText, { color: currentVenue?.appColor ?? greenColor }]}>{t('no-orders-title')}</Text>
        </View>
        <View style={styles.buttonContainer}>
          <Text style={[styles.textAboveButton, { color: currentVenue?.appColor ?? greenColor }]}>
            {t('text-to-menu')}
          </Text>
          <TouchableOpacity
            style={[
              styles.button,
              { backgroundColor: currentVenue?.appSecondaryColor ?? currentVenue?.appColor ?? greenColor },
              { outlineStyle: 'none' },
            ]}
            onPress={() => onBackButton()}
          >
            <Text style={styles.textInsideButton}>{t('order-now')}</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  if (!currentFilter) return <></>;
  return (
    <View style={styles.container}>
      <ContentHeader title={t('my-orders')} hasOrders={ordersPaginationData?.data ? true : false} />
      <View style={styles.filtersScrollContainer}>
        <ScrollView horizontal={true} showsHorizontalScrollIndicator={false} style={styles.filtersContainer}>
          {filters.map((filter) => (
            <Button
              key={filter.id}
              title={filter.description}
              titleStyle={[
                styles.filterBtnTitle,
                currentVenue
                  ? filter.id === currentFilter.id && styles.selectedFilterBtnTitle
                  : styles.selectedFilterBtnTitle,
              ]}
              containerStyle={[
                styles.filterBtnContainer,
                currentVenue
                  ? filter.id === currentFilter.id && { backgroundColor: currentVenue?.appColor ?? greenColor }
                  : { backgroundColor: greenColor },
                { outlineStyle: 'none' },
              ]}
              type="clear"
              onPress={() => {
                setCurrentFilter(filter);
              }}
            />
          ))}
        </ScrollView>
      </View>
      {ordersPaginationData && ordersPaginationData.data.length > 0 ? renderContent() : renderEmpty()}
    </View>
  );
};
