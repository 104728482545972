import React from 'react';
import { View } from 'react-native';
import WebView from 'react-native-webview';
import FormFDProps from 'types/components/FormFDProps';
import { FIRST_DATA_URL, BASE_URL } from '@env';
import { getOrder } from 'services/ordersService';
import OrderStatuses from 'types/enums/OrderStatuses';

export default ({
  webHost,
  orderId,
  timeZone,
  dateTime,
  hash,
  fdStore,
  currency,
  chargeTotal,
  setIsLoading,
  setResultPayment,
}: FormFDProps): JSX.Element => {
  const webViewRef = React.useRef<any>();
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isJavascriptInjected, setIsJavascriptInjected] = React.useState<boolean>(false);
  const submittingTimer = React.useRef<any>();

  React.useEffect(() => {
    if (!isSubmitting) return;
    const captureAppWebViewResponse = async (orderId: number) => {
      if (isSubmitting) {
        const currentOrder = await getOrder(orderId);
        if (currentOrder) {
          if (currentOrder.orderStatusId === OrderStatuses.paymentAccepted) {
            setResultPayment({ success: true, response: 'success' });
          } else {
            setResultPayment({ success: false, response: 'Hubo un problema con el pago, inténtelo nuevamente' });
          }
        }
      }
    };
    submittingTimer.current = setTimeout(() => captureAppWebViewResponse(orderId), 3000);

    return () => {
      clearInterval(submittingTimer.current);
    };
  }, [isSubmitting]);

  /*const html = `
    <body>
    <form action="${FIRST_DATA_URL}" method="post" target="_self">
      <input type="hidden" name="orderId" value="${orderId}"/>
      <input type="hidden" name="txntype" value="sale"/>
      <input type="hidden" name="timezone" value="${timeZone}"/>
      <input type="hidden" name="txndatetime" value="${dateTime}"/>
      <input type="hidden" name="hash_algorithm" value="SHA256"/>
      <input type="hidden" name="hash" value="${hash}"/>
      <input type="hidden" name="storename" value="${fdStore}" />
      <input type="hidden" name="mode" value="payonly"/>
      <input type="hidden" name="currency" value="${currency}"/>
      <input type="hidden" name="mobileMode" value="false"/>
      <input type="hidden" name="chargetotal" value="${chargeTotal}" />
      <input type="hidden" name="hostURI" value="${logoImage?.path}" />
      <input type="hidden" name="checkoutoption" value="simpleform" />
      <input type="hidden" name="responseSuccessURL" value="${logoImage?.path}" />
      <input type="hidden" name="responseFailURL" value="${logoImage?.path}" />
    </form>
    </body>`;*/

  const injectedJavascriptForWebView = () => {
    webViewRef.current.injectJavaScript(`
      (function () {
        document.forms[0].submit();
      })();
    `);
  };

  return (
    <View style={{ flex: 1 }}>
      <WebView
        ref={webViewRef}
        source={{
          uri: `${BASE_URL}payments/first-data-form`,
          method: 'post',
          body: `firstDataUrl=${FIRST_DATA_URL}&webHost=${webHost}&orderId=${orderId}&timeZone=${timeZone}&datetime=${dateTime}&hash=${hash}&fdStore=${fdStore}&currency=${currency}&chargeTotal=${chargeTotal}`,
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }}
        onNavigationStateChange={(value) => {
          if (value.url.includes('efan') && !value.loading && value.canGoBack && !value.canGoForward) {
            setIsSubmitting(true);
          }
        }}
        mixedContentMode="always"
        onMessage={(event) => {
          console.log(event.nativeEvent.data);
        }}
        automaticallyAdjustContentInsets={true}
        scalesPageToFit={false}
        bounces={true}
        originWhitelist={['*']}
        javaScriptEnabled={true}
        domStorageEnabled={true}
        onLoad={() => {
          setIsLoading(false);
        }}
        onLoadEnd={() => {
          if (!isJavascriptInjected) {
            injectedJavascriptForWebView();
            setIsJavascriptInjected(true);
          }
        }}
        onError={(syntheticEvent) => {
          const { nativeEvent } = syntheticEvent;
          alert(`WebView error: ${nativeEvent}`);
        }}
        style={{ height: '100%', width: '100%', border: 0, borderWidth: 0 }}
        onShouldStartLoadWithRequest={(event) => {
          if (event.url.includes('efan') && !event.loading && event.canGoBack && !event.canGoForward) {
            setIsSubmitting(true);
            return false;
          }
          return true;
        }}
      />
    </View>
  );
};
