import React from 'react';
import { View, ScrollView } from 'react-native';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { CardInformationList, CardInformationProps } from '@double_point/rn-components';
import AppContext from 'contexts/AppContext';
import PaginationData from 'types/models/PaginationData';
import Article from 'types/models/Article';
import Venue from 'types/models/Venue';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import ArticleImageTypes from 'types/enums/ArticleImageType';
import ArticlesScreenProps from 'types/screens/ArticlesScreenProps';
import { getArticles } from 'services/articleServices';
import { greenColor } from 'styles/global';
import { styles } from 'styles/screens/articlesScreen';
import useSelectArticle from 'hooks/useSelectArticle';
import App from 'constants/App';

export default ({ navigation }: ArticlesScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const { setSelectedArticle } = useSelectArticle();
  const { currentVenue } = React.useContext(AppContext);
  const [articles, setArticles] = React.useState<PaginationData<Article> | null>();
  const [articleItems, setArticleItems] = React.useState<CardInformationProps[]>([]);
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;

  const parseArticleToCardItem = (article: Article): CardInformationProps => {
    return {
      id: article.id,
      date: article.date,
      image: article.images.find((image) => image.articleImageType === ArticleImageTypes.banner)?.path ?? undefined,
      title: article.title,
      venueLogoPath: venueLogoImage,
      isForArticleListing: true,
      showDate: true,
      showTime: true,
      onClick: () => setSelectedArticle(article),
      dateLanguage: currentVenue ? currentVenue?.language : '',
    };
  };

  const parseArticlesToCardItems = (articles: Article[]): CardInformationProps[] => {
    return articles.map((article: Article) => parseArticleToCardItem(article));
  };

  const loadArticles = React.useCallback(
    async (venue: Venue, page?: number) => {
      if (!page) {
        await setArticleItems([]);
      }
      const paginationData = await getArticles(venue.id, page);
      if (!paginationData) return;
      if (paginationData.data.length > 0) {
        setArticles(paginationData);
        if (page) {
          setArticleItems((oldItems) => oldItems.concat(parseArticlesToCardItems(paginationData.data)));
        } else {
          setArticleItems(parseArticlesToCardItems(paginationData.data));
        }
      } else {
        return;
      }
    },
    [currentVenue],
  );

  useFocusEffect(
    React.useCallback(() => {
      if (!currentVenue) return;
      loadArticles(currentVenue);
    }, [currentVenue]),
  );

  if (!currentVenue) {
    navigation.dispatch({
      ...CommonActions.reset({
        index: 0,
        routes: [
          {
            name: 'Home',
            state: {
              routes: [
                {
                  name: 'HomeScreen',
                },
              ],
            },
          },
        ],
      }),
    });
    return <></>;
  }
  return (
    <ScrollView style={styles.container}>
      <View style={styles.internalContainerForCardList}>
        <CardInformationList
          title={''}
          titleColor={currentVenue?.appColor ?? greenColor}
          items={articleItems}
          onClickMore={() => {
            if (articles) {
              const page = articles.currentPage;
              loadArticles(currentVenue, page + 1);
            }
          }}
          seeMoreText={t('see-more')}
          dateLanguage={currentVenue.language}
        />
      </View>
    </ScrollView>
  );
};
