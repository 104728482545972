import print from 'print-js';
import moment from 'moment-timezone';
import { calculateTotalAmount, getTotalProducts } from './orders';
import type Order from 'types/models/Order';
import type OrderDetail from 'types/models/OrderDetail';

type Ticket = {
  total: number;
  price: number;
  title: string;
  subtitle: string;
};

type PrintTicket = {
  orderNum: number;
  data: Order;
};

export const setupTicket = (cart: Order, { total, price, title, subtitle }: Ticket) => {
  const now = moment();

  const date = now.format('DD/MM/YYYY');
  const time = now.format('HH:mm');

  const ticketSect = document.createElement('section');
  ticketSect.id = 'ticket';
  ticketSect.innerHTML = `
    <h6>${title}</h6>
    <header>
      <span>
        <p>Nro de Orden: ${subtitle}</p>
      </span>
      <p id="date">FECHA: ${date}</p>
      <p id="time">HORA: ${time}</p>
    </header>
    <div id="content">
      <div id="products">
        ${cart?.details
          ?.map(
            (item) => `<div class="product">
					<p>${item.product.name} (x${item.quantity})</p>
					<b>$${item.product.price * item.quantity}</b>
        </div>`,
          )
          .join('')}
      </div>
      <div id="total-sell">
        <p id="total-ticket-products">${total} ART.</p>
        <b id="total-ticket-price">TOTAL($) ${price}</b>
      </div>
    </div>
    <footer>
      <i>Gracias por su compra</i>
    </footer>
  `;

  return ticketSect;
};

export const ticketStyles = `
@media print{*{margin:0;padding:0;box-sizing:border-box}#ticket{width:7.95cm;min-height:6.90cm;padding:12px;padding-bottom:40px;font-family:monospace;position:relative}#ticket h6{text-align:center;text-transform:uppercase;font-size:12pt;font-weight:800;padding-top:16px;padding-bottom:16px}#ticket header>span{border-top:1px dashed black;border-bottom:1px dashed black;white-space:nowrap;font-weight:800;font-size:18pt;width:100%;text-align:center;display:block;padding-top:16px;padding-bottom:16px}#ticket header>p{font-size:10pt;font-weight:600}#ticket header #date{margin-top:16px;margin-bottom:4px}#ticket #content{padding-top:16px;padding-bottom:32px}#ticket #content #total-sell{width:100%;text-align:center;display:flex;align-items:center;justify-content:space-evenly;white-space:nowrap}#ticket #content #total-sell p{font-size:10pt;font-weight:600}#ticket #content #total-sell b{font-size:10pt}#ticket footer{width:100%;border-top:1px dashed black;position:absolute;text-align:center;bottom:8px;left:50%;transform:translateX(-50%);white-space:nowrap;padding-top:16px;padding-bottom:16px}#ticket footer i{font-size:10pt;font-weight:700}#ticket #content #products{display:flex;flex-direction:column;gap:6px}#ticket #content #products div{display:flex;align-items:center;justify-content:space-evenly}#ticket #content #products{margin-top:14px;margin-bottom:12px}#ticket #content #products .product b{font-weight:700;font-size:10pt;text-transform:uppercase}#ticket #content #products .product p{font-weight:700;font-size:10pt;text-transform:uppercase;width:50%}, b{font-weight:700;font-size:10pt;text-transform:uppercase}}
`;

export const printTicket = ({ data, orderNum }: PrintTicket) => {
  const ticket = setupTicket(data, {
    price: calculateTotalAmount(data?.details as OrderDetail[]),
    total: getTotalProducts(data?.details as OrderDetail[]),
    title: data.commerce.name,
    subtitle: `#${orderNum}`,
  });

  print({
    printable: ticket,
    type: 'html',
    header: '',
    documentTitle: '',
    style: ticketStyles,
    showModal: false,
  });
};
