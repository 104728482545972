import React from 'react';
import { View, Pressable, Text } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import ProductDetailTotemScreenProps from 'types/screens/ProductDetailTotemScreenProps';
import ShoppingCart from 'types/models/ShoppingCart';
import ProductItem from 'components/ProductItem';
import ShoppingCartModalForTotem from 'components/ShoppingCartModalForTotem';
import OrderPendingMessage from 'components/OrderPendingMessage';
import NavBarForTotem from 'components/NavBarForTotem';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangeOrder from 'hooks/useChangeOrder';
import { styles } from 'styles/screens/productDetailTotemScreen';
import { greenColor } from 'styles/global';

export default ({ navigation }: ProductDetailTotemScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const { storeCurrentShoppingCart } = useChangeShoppingCart();
  const { deleteCurrentOrder } = useChangeOrder();
  const { setModalizeData, currentVenue, pairedWithClover, redirectionItems } = React.useContext(AppContext);
  const [productQuantity, setQuantity] = React.useState<number>(1);
  const { currentCommerce, currentProduct, currentShoppingCart, setCurrentShoppingCart } =
    React.useContext(OrderContext);

  const onChangeQuantity = (id: string, quantity: number) => {
    setQuantity(quantity);
    if (currentShoppingCart) {
      const detailItem = currentShoppingCart.detail.find((item) => item.product.id === id);
      if (detailItem) {
        detailItem.quantity = quantity;
      }
      setCurrentShoppingCart({ ...currentShoppingCart });
    }
  };

  const openModal = (shoppingCart: ShoppingCart) => {
    if (!currentCommerce || !currentProduct) {
      return;
    }
    setModalizeData({
      content: (
        <ShoppingCartModalForTotem product={currentProduct} shoppingCart={shoppingCart} navigation={navigation} />
      ),
      closeOnOverlayTap: false,
    });
  };

  useFocusEffect(
    React.useCallback(() => {
      if (!currentCommerce) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'CommerceScreen', params: redirectionItems ?? undefined }],
          });
        }, 500);
        return;
      }
      if (!currentProduct) {
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'CommerceScreen', params: redirectionItems ?? undefined }],
          });
        }, 500);
        return;
      }
    }, [currentCommerce, currentProduct]),
  );

  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          await storeCurrentShoppingCart(currentShoppingCart);
        }
      };
      onFocus();
    }, [currentShoppingCart]),
  );

  React.useEffect(() => {
    if (currentProduct && currentShoppingCart) {
      const detailItem = currentShoppingCart.detail.find((item) => item.product.id === currentProduct.id);
      if (detailItem) {
        setQuantity(detailItem.quantity);
      }
    }
  }, [currentShoppingCart]);

  if (!currentCommerce || !currentProduct) {
    return <></>;
  }

  const calculateProductPriceAccordingToQuantity = (): number | undefined => {
    if (currentProduct) {
      return currentProduct.price * productQuantity;
    }
  };

  const addProductToShoppingCart = async (restarting?: boolean) => {
    if (!currentCommerce || !currentProduct) return;
    let shoppingCart = !restarting ? currentShoppingCart : null;
    if (shoppingCart && shoppingCart.commerce.id === currentCommerce.id) {
      //Si currentShoppingCart fue seteado, busca el producto. Si no lo encuentra lo agrega.
      const detailItem = shoppingCart.detail.find((item) => item.product.id === currentProduct.id);
      if (!detailItem) {
        shoppingCart.detail.push({ product: currentProduct, quantity: productQuantity });
      }
    } else {
      //Si currentShoppingCart no fue seteado o el comercio del carrito actual no coincide con el comercio del producto, se vacia el carrito
      shoppingCart = {
        commerce: { id: currentCommerce.id, code: currentCommerce.code, name: currentCommerce.name },
        detail: [{ product: currentProduct, quantity: productQuantity }],
        processed: false,
        paid: false,
        phone: '',
        isCash: false,
      };
    }
    setCurrentShoppingCart({ ...shoppingCart });
    openModal(shoppingCart);
  };

  const modalOrderPending = (shoppingCartCommerceCode: string): JSX.Element => (
    <OrderPendingMessage
      pendingShoppingCartCommerceCode={shoppingCartCommerceCode}
      onRestart={async () => {
        await deleteCurrentOrder();
        await addProductToShoppingCart(true);
      }}
    />
  );

  return (
    <View style={{ flex: 1, backgroundColor: '#FFFFFF' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'flex-start', marginLeft: 30, marginTop: 100 }}>
        <Text style={{ fontWeight: '500', fontSize: 48, lineHeight: 20, letterSpacing: 0.14 }}>{t('products')}</Text>
      </View>
      <View style={{ width: 979, alignSelf: 'center' }}>
        <View style={{ marginBottom: 150, marginTop: 50 }}>
          <ProductItem
            product={currentProduct}
            initialQuantity={productQuantity}
            showControlQuantity={true}
            onChangeQuantity={onChangeQuantity}
          />
        </View>
        <View style={styles.addProductContainer}>
          <Pressable
            style={[
              styles.addQuantityBtn,
              { backgroundColor: currentVenue?.appColor ?? greenColor },
              { outlineStyle: 'none' },
            ]}
            onPress={async () => {
              if (currentShoppingCart && currentShoppingCart.processed && !currentShoppingCart.isCash) {
                setModalizeData({
                  content: modalOrderPending(currentShoppingCart.commerce.code),
                  closeOnOverlayTap: false,
                });
              } else {
                await addProductToShoppingCart();
              }
            }}
            disabled={!currentCommerce?.enabled}
          >
            <View style={styles.quantityAndPriceContainer}>
              <Text
                style={[
                  styles.quantityText,
                  pairedWithClover && {
                    fontSize: 36,
                    borderWidth: 4,
                    width: 60,
                    height: 60,
                    borderRadius: 100,
                    marginTop: 5,
                  },
                ]}
              >
                {productQuantity}
              </Text>
            </View>
            <View
              style={[
                styles.addToCartContainer,
                pairedWithClover && { alignItems: 'flex-start', justifyContent: 'center' },
              ]}
            >
              <Text style={[styles.addToCartAndPriceText, pairedWithClover && { fontSize: 32, lineHeight: 16 }]}>
                {t('add-to-cart')}
              </Text>
            </View>
            <View style={styles.quantityAndPriceContainer}>
              <Text style={[styles.addToCartAndPriceText, pairedWithClover && { fontSize: 32, lineHeight: 38 }]}>
                ${calculateProductPriceAccordingToQuantity()}
              </Text>
            </View>
          </Pressable>
        </View>
      </View>
      {pairedWithClover && <NavBarForTotem />}
    </View>
  );
};
