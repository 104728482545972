import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: '90%',
    alignSelf: 'center',
    borderRadius: 12,
    marginTop: 20,
    paddingBottom: 5,
  },
  image: {
    flex: 1,
    width: '100%',
    borderRadius: 12,
    resizeMode: 'cover',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 14,
    paddingVertical: 14,
    paddingHorizontal: 19,
  },
  top: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 44,
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    columnGap: 5,
  },
  box: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    rowGap: 4,
    height: 'auto',
    width: 'auto',
    backgroundColor: '#000',
    borderRadius: 16,
    paddingTop: 11,
    paddingBottom: 11,
  },
  odd: {
    color: '#fff',
    fontSize: 24,
    fontStyle: 'normal',
    fontWeight: '900',
  },
  competitor: {
    color: '#fff',
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: '500',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  counter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    width: 132,
    height: 26,
  },
  shield: {
    // flex: 1,
    resizeMode: 'contain',
    width: 44,
    height: 44 + 16,
  },
});
