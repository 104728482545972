import React from 'react';
import { Platform, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';
import { createPreference } from 'services/paymentsService';
import AppContext from 'contexts/AppContext';
import useChangeOrder from 'hooks/useChangeOrder';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangePayment from 'hooks/useChangePayment';
import OrderContext from 'contexts/OrderContext';
import PaymentMPCheckoutScreenProps from 'types/screens/PaymentMPCheckoutScreenProps';

export default ({ navigation, route }: PaymentMPCheckoutScreenProps): JSX.Element => {
  const { setIsLoading, currentVenue } = React.useContext(AppContext);
  const { currentShoppingCart, selectedPaymentConfig, currentOrder, setResultPayment, resultPayment } =
    React.useContext(OrderContext);
  const [preferenceId, setPreferenceId] = React.useState<string | null>(null);
  const [paymentStatus, setPaymentStatus] = React.useState<string | null>(null);
  const { storeCurrentShoppingCart } = useChangeShoppingCart();
  const { storeCurrentOrder, deleteCurrentOrder } = useChangeOrder();
  const { changeResultPayment } = useChangePayment();
  const params = route.params;
  const posibleMpPaymentRedirectStatus = params?.status;

  const setMPPreferenceData = async () => {
    const mercadoPagoCheckoutConfig = selectedPaymentConfig?.mercadoPagoCheckoutConfig;
    if (posibleMpPaymentRedirectStatus) {
      setIsLoading(true);
      setPaymentStatus(posibleMpPaymentRedirectStatus);
    } else {
      if (
        !currentShoppingCart ||
        !currentShoppingCart.processed ||
        currentShoppingCart.paid ||
        !currentOrder ||
        !mercadoPagoCheckoutConfig ||
        !currentVenue
      ) {
        if (currentShoppingCart?.paid) {
          await deleteCurrentOrder();
        }
        setTimeout(() => {
          navigation.reset({
            index: 0,
            routes: [{ name: 'HomeScreen' }],
          });
        }, 500);
        return;
      }
      await storeCurrentShoppingCart(currentShoppingCart);
      await storeCurrentOrder(currentOrder);
      setIsLoading(true);
      const preference = await createPreference(
        mercadoPagoCheckoutConfig?.id,
        currentShoppingCart?.detail,
        currentVenue?.code,
        currentShoppingCart.commerce.code,
        currentOrder.id,
      );
      if (preference) {
        setPreferenceId(preference);
        initMercadoPago(mercadoPagoCheckoutConfig.publicKey);
      } else {
        setIsLoading(false);
        navigation.reset({
          index: 1,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'ShoppingCartScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: currentVenue?.code,
                      commerceCode: currentShoppingCart.commerce.code,
                    }
                  : undefined,
            },
          ],
        });
      }
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      setMPPreferenceData();
      return () => {
        if (resultPayment) {
          setResultPayment(null);
        }
      };
    }, []),
  );

  React.useEffect(() => {
    if (resultPayment) {
      changeResultPayment(resultPayment, false, params?.venueCode);
    }
  }, [resultPayment]);

  React.useEffect(() => {
    if (paymentStatus) {
      setResultPayment({ success: paymentStatus === 'approved' ? true : false });
      setIsLoading(false);
    }
  }, [paymentStatus]);

  return (
    <View style={{ flex: 1, justifyContent: 'center', alignSelf: 'center', marginHorizontal: 5 }}>
      {preferenceId && (
        <Wallet
          initialization={{ preferenceId: preferenceId, redirectMode: 'self' }}
          onReady={() => {
            setIsLoading(false);
          }}
        />
      )}
    </View>
  );
};
