import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import UseChangeShoppingCart from 'types/hooks/useChangeShoppingCart';
import ShoppingCart from 'types/models/ShoppingCart';

export default (): UseChangeShoppingCart => {
  const storeCurrentShoppingCart = async (shoppingCart: ShoppingCart): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.currentShoppingCart, JSON.stringify(shoppingCart));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteCurrentShoppingCart = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.currentShoppingCart);
    } catch (error) {
      console.log(error);
    }
  };

  return { storeCurrentShoppingCart, deleteCurrentShoppingCart };
};
