/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import { parseCommerceCategories } from 'services/commerceCategoriesService';
import { parseVenue } from 'services/venuesServices';
import Venue from 'types/models/Venue';
import Commerce from 'types/models/Commerce';
import CommerceImage from 'types/models/CommerceImage';
import DeliveryConfig from 'types/models/DeliveryConfig';
import { CommercePaymentConfig } from 'types/models/PaymentConfig';
import CommerceType from 'types/models/CommerceType';
import PaymentProviderTypes from 'types/enums/PaymentProviderTypes';
import { parseLocalDeliveryAreas } from './localDeliveryAreasServices';
import { parseDiscounts } from './discountServices';

export const getCommercesByVenue = async (venueId: string, categoryId?: string): Promise<Commerce[] | []> => {
  try {
    let url = `/venues/${venueId}/commerces?enabled=true`;
    if (categoryId) {
      url += `&categoryId=${categoryId}`;
    }
    const { data } = await axiosInstance({
      method: 'get',
      url,
    });
    return parseCommerces(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getCommerceByCodes = async (
  venueCode: string,
  commerceCode: string,
): Promise<{ venue: Venue; commerce: Commerce } | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/commerces/get/by-codes/${venueCode}/${commerceCode}`,
    });
    return {
      venue: parseVenue(data.venue),
      commerce: parseCommerce(data.commerce),
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getCompleteCommerce = async (commerceId: string): Promise<Commerce | null> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/commerces/get/complete-commerce/${commerceId}`,
    });
    return parseCommerce(data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const parseCommerces = (commerces: []): Commerce[] => {
  return commerces.map((commerce: any) => parseCommerce(commerce));
};

export const parseCommerce = (commerce: any): Commerce => {
  return {
    id: commerce.id,
    name: commerce.name,
    phone: commerce.phone,
    enabled: commerce.enabled,
    hideLogoOnList: commerce.hide_logo_on_list === 1,
    code: commerce.code,
    address: commerce.address,
    updatedAt: commerce.updated_at,
    images: parseCommerceImages(commerce.images),
    products: [],
    promotions: [],
    categories: commerce.categories ? parseCommerceCategories(commerce.categories) : [],
    type: parseCommerceType(commerce.type),
    editorId: commerce.editor_id,
    paymentConfig: commerce.payment_config ? parsePaymentConfig(commerce) : undefined,
    deliveryConfig: commerce.delivery_config ? parseDeliveryConfig(commerce) : undefined,
    discounts: commerce.discounts ? parseDiscounts(commerce.discounts) : undefined,
    order: commerce.order ?? undefined,
  };
};

const parsePaymentConfig = (commerce: any): CommercePaymentConfig => {
  const paymentConfig = commerce.payment_config;
  const parsedPaymentConfig: CommercePaymentConfig = {
    cashEnabled: paymentConfig.cash_enabled === 1,
    mercadoPagoPointConfigId: paymentConfig.mp_point_config_id ?? undefined,
    cloverPointConfigId: paymentConfig.clover_point_config_id ?? undefined,
  };
  if (parsedPaymentConfig.cloverPointConfigId) {
    parsedPaymentConfig.cloverPointConfig = {
      id: paymentConfig.clover_point_config.id,
      description: paymentConfig.clover_point_config.description,
      applicationId: paymentConfig.clover_point_config.application_id,
      accessToken: paymentConfig.clover_point_config.access_token,
      cloverServer: paymentConfig.clover_point_config.clover_server,
      merchantId: paymentConfig.clover_point_config.merchant_id,
      friendlyId: paymentConfig.clover_point_config.friendly_id,
    };
  }

  if (paymentConfig.active_payment_provider_id) {
    switch (paymentConfig.active_payment_provider_id) {
      case PaymentProviderTypes.firstData:
        if (commerce.payment_provider_config) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          const paymentProviderConfig = commerce.payment_provider_config;
          parsedPaymentConfig.firstDataConfig = {
            id: paymentConfig.first_data_config_id,
            fdStore: paymentProviderConfig.fd_store,
            timeZone: paymentProviderConfig.time_zone,
            currency: paymentProviderConfig.currency,
          };
        }
        break;
      case PaymentProviderTypes.mercadoPago:
        if (paymentConfig.mp_config_id) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          parsedPaymentConfig.mercadoPagoConfigId = commerce.payment_config.mp_config_id;
        }
      case PaymentProviderTypes.izipay:
        if (commerce.payment_provider_config) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          const paymentProviderConfig = commerce.payment_provider_config;
          parsedPaymentConfig.izipayConfig = {
            id: paymentConfig.izipay_config_id,
            commerceCode: paymentProviderConfig.commerce_code,
            publicKey: paymentProviderConfig.public_key,
          };
        }
        break;
      case PaymentProviderTypes.MercadoPagoCheckout:
        if (commerce.payment_provider_config) {
          parsedPaymentConfig.activePaymentProvider = paymentConfig.active_payment_provider_id;
          const paymentProviderConfig = commerce.payment_provider_config;
          parsedPaymentConfig.mercadoPagoCheckoutConfig = {
            id: paymentConfig.mp_checkout_config_id,
            publicKey: paymentProviderConfig.public_key,
          };
        }
      default:
        break;
    }
  }
  return parsedPaymentConfig;
};

const parseCommerceType = (type: any): CommerceType => {
  return {
    id: type.id,
    description: type.description,
  };
};

const parseCommerceImages = (images: any): CommerceImage[] => {
  return images.map(
    (item: any): CommerceImage => ({
      commerceId: item.pivot.commerce_id,
      commerceImageTypeId: item.pivot.commerce_image_type_id,
      path: item.path,
    }),
  );
};

const parseDeliveryConfig = (commerce: any): DeliveryConfig => {
  const commerceDeliveryConfig = commerce.delivery_config;
  const parsedCommerceDeliveryConfig: DeliveryConfig = {
    id: commerceDeliveryConfig.id,
    deliveryEnabled: commerceDeliveryConfig.delivery_enabled === 1,
    pickUpEnabled: commerceDeliveryConfig.pick_up_enabled === 1,
    isLocalDelivery: commerceDeliveryConfig.is_local_delivery === 1,
    areas: commerceDeliveryConfig.areas ? parseLocalDeliveryAreas(commerceDeliveryConfig.areas) : [],
  };
  return parsedCommerceDeliveryConfig;
};
