import React from 'react';
import { View, Text, Image, ImageBackground, Pressable, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { AdvertisingBannerProps, AdvertisingBannerCarousel, SuccessModal } from '@double_point/rn-components';
import CommerceScreenProps from 'types/screens/CommerceScreenProps';
import Product from 'types/models/Product';
import ProductImageTypes from 'types/enums/ProductImageTypes';
import MPPoint from 'types/models/MPPoint';
//import CloverPoint from 'types/models/CloverPoint';
import VenueImageTypes from 'types/enums/VenueImageTypes';
import constantsStorage from 'constants/Storage';
import ProductsList from 'components/ProductsList';
import PairMPPointModal from 'components/PairMPPointModal';
import UnpairMPPointModal from 'components/UnpairMPPointModal';
import BalanceExhibitor from 'components/BalanceExhibitor';
import NavBarForTotem from 'components/NavBarForTotem';
import useChangeVenue from 'hooks/useChangeVenue';
import useChangeCommerce from 'hooks/useChangeCommerce';
import useRedirectParams from 'hooks/useRedirectParams';
import useCloverServices from 'hooks/useCloverServices';
import OrderContext from 'contexts/OrderContext';
import AppContext from 'contexts/AppContext';
import { getCommerceByCodes } from 'services/commercesService';
import { getProductsByCommerce, getPromotionsByCommerce } from 'services/productsService';
import { parsePromotionsToCardItems } from 'services/promotionsServices';
import { getAvailableMPPoints } from 'services/paymentsService';
import { notAutoReloadScreenData } from 'utils/shoppingCart';
import { styles } from 'styles/screens/commerceScreen';
import { grayColor, greenColor, darkColor } from 'styles/global';
import App from 'constants/App';
import ChatBotModal from 'components/ChatBotModal';
import CommerceHeader from 'components/CommerceHeader';

export default ({ navigation, route }: CommerceScreenProps): JSX.Element => {
  const {
    setIsLoading,
    currentVenue,
    currentAuthData,
    setModalizeData,
    redirectionItems,
    pairedWithClover,
    cloverConnected,
    currentCloverConfig,
    currentPairedCloverPoint,
    currentCloverPairingCode,
    successfullyConnectedToCloverDevice,
    setSuccessfullyConnectedToCloverDevice,
    userBalance,
    showWelcomeView,
    setShowWelcomeView,
    previousRouteName,
    currentRouteName,
    setCheckedForDifferentVenueCode,
    setVenueLoad,
  } = React.useContext(AppContext);
  const { storeCurrentVenue } = useChangeVenue();
  const { storeCurrentCommerce } = useChangeCommerce();
  const { storeRedirectCommerceParams } = useRedirectParams();
  const { cloverMethods, cloverListenerConnector } = useCloverServices();
  const {
    currentCommerce,
    currentShoppingCart,
    setCurrentCommerce,
    setCurrentProduct,
    currentPairedMPPoint,
    setCurrentPairedMPPoint,
  } = React.useContext(OrderContext);
  const [availableMPPoints, setAvailableMPPoints] = React.useState<MPPoint[]>([]);
  //const [availableCloverPoints, setAvailableCloverPoints] = React.useState<CloverPoint[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const venueLogo =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const params = route.params;
  const rebuildCloverConnectorListener = () => {
    if (cloverConnected && params) {
      cloverConnected.addCloverConnectorListener(cloverListenerConnector);
      cloverConnected.showWelcomeScreen();
    }
  };
  const totemBackgroundImage =
    currentVenue?.images?.find((image) => image.venueImageId === VenueImageTypes.TotemBackground)?.path ??
    App.defaultImage;
  const totemLowerBanner =
    currentVenue?.images?.find((image) => image.venueImageId === VenueImageTypes.TotemLowerBanner)?.path ??
    App.defaultImage;
  const venueLogoImage =
    currentVenue?.images.find((image: any) => image.venueImageId === VenueImageTypes.logo)?.path ?? App.defaultImage;
  const fetchAvailableMPPoints = async (): Promise<void> => {
    if (!currentCommerce || !currentCommerce.paymentConfig?.mercadoPagoPointConfigId || !currentAuthData) return;
    const mPPoints = await getAvailableMPPoints(currentCommerce.paymentConfig.mercadoPagoPointConfigId);
    setAvailableMPPoints(mPPoints);
  };
  /*const fetchAvailableCloverPoints = async (): Promise<void> => {
    if (
      !currentCommerce ||
      !currentCommerce.paymentConfig?.cloverPointConfigId ||
      !currentAuthData ||
      currentAuthData.user.roleId !== UserRoleTypes.CommerceEditor ||
      currentCommerce.editorId !== currentAuthData.user.id
    )
      return;
    const cloverPoints = await getAvailableCloverPoints(currentCommerce.paymentConfig.cloverPointConfigId);
    setAvailableCloverPoints(cloverPoints);
  };*/

  const customBannerStyleProps = {
    marginTop: 10,
    height: 100,
    width: 260,
    widthRectangleItems: 100,
    fontSizeTitle: 12,
    fontSizeDescription: 8,
    triangleBorderRightWidth: 130,
  };

  const parsePromotionToCardItem = (promotion: Product): AdvertisingBannerProps => {
    return {
      id: promotion.id,
      image:
        promotion.images.find((image) => image.productImageTypeId === ProductImageTypes.banner)?.path ??
        App.defaultImage,
      color: promotion.promotionBannerBackgroundColor ?? '',
      title: pairedWithClover ? '' : promotion.name,
      price: Math.floor(promotion.price),
      logo: venueLogo,
      colorTitle: promotion.promotionBannerColorTitle ?? '',
      description: promotion.description,
      onPress: async () => {
        await setCurrentProduct(promotion);
        navigation.navigate(
          'ProductDetailsScreen',
          Platform.OS === 'web'
            ? {
                venueCode: currentVenue?.code,
                commerceCode: currentCommerce?.code,
                productName: promotion.name.replace(/ /g, '-'),
              }
            : undefined,
        );
      },
      isForTotemDisplay: pairedWithClover,
    };
  };

  const fetchProducts = async () => {
    if (!currentVenue) return;
    if (!currentCommerce) return;
    setIsLoading(true);
    const products = await getProductsByCommerce(currentCommerce.id);
    if (someProductsHasBeenUpdated(products)) {
      currentCommerce.products = products;
      setCurrentCommerce({ ...currentCommerce });
    }
    setIsLoading(false);
  };

  const someProductsHasBeenUpdated = (remoteProducts: Product[]): boolean => {
    if (!currentVenue) return false;
    if (!currentCommerce) return false;
    // Si el comercio no tiene productos o si la cantidad de productos locales es distinta a la cantidad de productos
    // remotos entonces retornamos true para que los productos se actualicen
    if (currentCommerce.products.length === 0) return true;
    if (currentCommerce.products.length !== remoteProducts.length) return true;

    // Si alguno de los productos del localStorage es distinto o no existía, entonces debemos actualizar el localStorage
    return currentCommerce.products.some((product) => {
      const findProduct = remoteProducts.find(({ id }) => id === product.id);
      if (findProduct) {
        return product.updatedAt !== findProduct.updatedAt;
      } else {
        return true;
      }
    });
  };

  const fetchPromotions = async () => {
    if (!currentVenue) return;
    if (!currentCommerce) return;
    if (!currentCommerce.promotions || currentCommerce.promotions.length === 0) {
      setIsLoading(true);
      const commercePromotions = await getPromotionsByCommerce(currentVenue?.id, currentCommerce.id);
      if (commercePromotions.length > 0) {
        currentCommerce.promotions = parsePromotionsToCardItems(commercePromotions, parsePromotionToCardItem);
        setCurrentCommerce({ ...currentCommerce });
      }
      setIsLoading(false);
    }
  };

  const loadCommerceByCodes = async (): Promise<boolean> => {
    if (await notAutoReloadScreenData(route.params?.commerceCode)) return true;
    const storeCurrentPairedMPPoint = await AsyncStorage.getItem(constantsStorage.currentPairedMPPoint);
    const params = route.params;
    if (params) {
      const { venueCode, commerceCode } = params;
      if (venueCode && commerceCode) {
        setCheckedForDifferentVenueCode(true);
        if (!currentVenue) {
          setVenueLoad(true);
          const response = await getCommerceByCodes(venueCode, commerceCode);
          if (response) {
            await storeCurrentVenue(response.venue, false);
            await storeCurrentCommerce(response.commerce);
            setVenueLoad(false);
            return true;
          }
        } else if (currentVenue && currentCommerce && commerceCode !== currentCommerce.code) {
          const response = await getCommerceByCodes(venueCode, commerceCode);
          if (response) {
            await storeCurrentCommerce(response.commerce);
            return true;
          }
        } else {
          setVenueLoad(false);
          return true;
        }
        if (storeCurrentPairedMPPoint) {
          await AsyncStorage.setItem(constantsStorage.currentPairedMPPoint, JSON.stringify(storeCurrentPairedMPPoint));
          setCurrentPairedMPPoint(JSON.parse(storeCurrentPairedMPPoint));
        }
      }
    }
    return false;
  };

  const reloadVenueCommerceData = async (): Promise<void> => {
    const shoppingCartHasItems = await notAutoReloadScreenData(currentCommerce?.code);
    if (shoppingCartHasItems) return;
    if (currentVenue && currentCommerce) {
      setIsLoading(true);
      const remoteVenueCommerce = await getCommerceByCodes(currentVenue.code, currentCommerce.code);
      if (remoteVenueCommerce) {
        setIsLoading(false);
        if (currentVenue.updatedAt !== remoteVenueCommerce?.venue.updatedAt) {
          setVenueLoad(true);
          await storeCurrentVenue(remoteVenueCommerce.venue, true);
          setVenueLoad(false);
        }
        if (currentCommerce.updatedAt !== remoteVenueCommerce?.commerce.updatedAt) {
          await storeCurrentCommerce(remoteVenueCommerce?.commerce);
        }
      }
    }
  };

  const loadProductById = (): void => {
    const params = route.params;
    if (params) {
      const { productId } = params;
      if (productId) loadProduct(productId);
    }
  };

  const setUniqueRedirectionItems = async (): Promise<void> => {
    const params = route.params;
    if (params) {
      if (!previousRouteName && !currentRouteName) {
        const paramsWithCommerceName = { ...params, commerceNameForRedirection: currentCommerce?.name };
        await storeRedirectCommerceParams({ ...paramsWithCommerceName });
      }
    }
  };

  const showPairMPPointBtns = (): boolean => {
    if (!currentAuthData || !currentCommerce) return false;
    if (currentAuthData.user.id !== currentCommerce.editorId) return false;
    if (!currentCommerce.paymentConfig?.mercadoPagoPointConfigId) return false;
    return true;
  };

  /*const showPairCloverPointBtns = (): boolean => {
    if (!currentAuthData || !currentCommerce) return false;
    if (currentAuthData.user.id !== currentCommerce.editorId) return false;
    if (!currentCommerce.paymentConfig?.cloverPointConfigId) return false;
    return true;
  };*/

  const loadProduct = (productId: string): void => {
    const product = currentCommerce?.products.find((product) => product.id === productId);
    if (product) {
      setCurrentProduct(product);
      navigation.reset({
        index: 1,
        routes: [
          { name: 'HomeScreen' },
          {
            name: 'ProductDetailsScreen',
            params:
              Platform.OS === 'web'
                ? {
                    venueCode: currentVenue?.code,
                    commerceCode: currentCommerce?.code,
                    productName: product.name.replace(' ', '-'),
                  }
                : undefined,
          },
        ],
      });
    }
  };

  const modalPairingCodeMessageContent = (message: string, isSuccess: boolean): JSX.Element => (
    <SuccessModal
      isSuccess={isSuccess}
      title={''}
      mainColor={currentVenue?.appColor ?? greenColor}
      message={message}
      buttonText={'OK'}
      onConfirm={() => {
        if (successfullyConnectedToCloverDevice) {
          setSuccessfullyConnectedToCloverDevice(false);
          setModalizeData(null);
        } else {
          setModalizeData(null);
        }
      }}
    />
  );

  useFocusEffect(
    React.useCallback(() => {
      let loadedByCodes = false;
      reloadVenueCommerceData();
      loadCommerceByCodes()
        .then((loadedByCodesResult) => {
          loadedByCodes = loadedByCodesResult;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          if (!loadedByCodes && !currentCommerce) {
            setTimeout(() => {
              navigation.reset({
                index: 0,
                routes: [{ name: 'HomeScreen' }],
              });
            }, 500);
            return;
          }
        });
    }, []),
  );

  React.useEffect(() => {
    if (!pairedWithClover && currentPairedCloverPoint && !cloverConnected) {
      cloverMethods().connect();
    }
    if (pairedWithClover && currentCloverConfig && currentPairedCloverPoint && !cloverConnected) {
      cloverMethods().connect();
    }
  }, [currentPairedCloverPoint]);

  useFocusEffect(
    React.useCallback(() => {
      fetchProducts();
      fetchPromotions();
      fetchAvailableMPPoints();
      //fetchAvailableCloverPoints();
      setUniqueRedirectionItems();
      rebuildCloverConnectorListener();
      loadProductById();
    }, [currentCommerce, currentAuthData]),
  );

  React.useEffect(() => {
    if (currentCloverPairingCode) {
      setModalizeData({
        content: modalPairingCodeMessageContent(
          `Ingrese el siguente codigo en su dispositivo clover: ${currentCloverPairingCode}`,
          true,
        ),
        closeOnOverlayTap: true,
      });
    }
  }, [currentCloverPairingCode]);

  React.useEffect(() => {
    if (successfullyConnectedToCloverDevice) {
      setModalizeData({
        content: modalPairingCodeMessageContent('¡Dispositivo Conectado!', true),
        closeOnOverlayTap: true,
      });
    }
  }, [successfullyConnectedToCloverDevice]);

  React.useEffect(() => {
    if (pairedWithClover) {
      if (currentShoppingCart) {
        setShowWelcomeView(false);
      } else {
        setShowWelcomeView(true);
      }
    }
  }, [pairedWithClover]);

  const renderCloverPointWelcomeView = (): JSX.Element => {
    return (
      <Pressable onPress={() => setShowWelcomeView(false)}>
        <ImageBackground
          style={{ height: '100%', width: '100%', overflow: 'hidden', alignSelf: 'center' }}
          source={{ uri: totemBackgroundImage }}
        >
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: 160,
              marginBottom: 110,
            }}
          >
            <Image source={{ uri: venueLogoImage }} style={{ width: 372, height: 386 }} />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: 50,
              marginBottom: 150,
            }}
          >
            <Text
              style={{
                fontWeight: '700',
                fontSize: 96,
                lineHeight: 124,
                letterSpacing: 0.25,
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Ordená desde aca!
            </Text>
            <Text
              style={{
                fontWeight: '400',
                fontSize: 96,
                lineHeight: 124,
                letterSpacing: 0.25,
                textAlign: 'center',
                color: '#FFFFFF',
              }}
            >
              Evitá hacer fila
            </Text>
            <View
              style={{
                backgroundColor: '#FFFFFF',
                borderRadius: 100,
                width: 766,
                height: 106,
                marginTop: 150,
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontWeight: '500',
                  fontSize: 48,
                  lineHeight: 16,
                  textAlign: 'center',
                  color: currentVenue?.appColor,
                }}
              >
                Tocá la pantalla para continuar
              </Text>
            </View>
          </View>
          <Image source={{ uri: totemLowerBanner }} style={{ width: 1080, height: 342, bottom: 0, marginTop: 180 }} />
        </ImageBackground>
      </Pressable>
    );
  };

  const renderNormalCommerceScreenView = (): JSX.Element => {
    return (
      <View style={styles.container}>
        {currentCommerce !== null && !pairedWithClover && (
          <CommerceHeader
            commerceName={currentCommerce.name}
            commerceCode={currentCommerce.code}
            showBackButton={!redirectionItems}
            onBack={() => {
              navigation.reset({
                index: 0,
                routes: [{ name: 'HomeScreen' }],
              });
            }}
            navigation={navigation}
          />
        )}
        <View style={styles.header}></View>
        {showPairMPPointBtns() ? (
          <View style={styles.containerForPosnet}>
            {currentPairedMPPoint ? (
              <Text
                style={styles.unpairPosnetMessage}
                onPress={async () => {
                  setModalizeData({
                    content: (
                      <UnpairMPPointModal
                        selectedMPPointId={currentPairedMPPoint.id}
                        currentCommerce={currentCommerce}
                        setCurrentPairedMPPoint={setCurrentPairedMPPoint}
                      />
                    ),
                    closeOnOverlayTap: true,
                  });
                }}
              >
                Desemparejar posnet
              </Text>
            ) : (
              <Text
                style={styles.pairPosnetMessage}
                onPress={async () => {
                  setModalizeData({
                    content: (
                      <PairMPPointModal
                        availableMPPoints={availableMPPoints}
                        currentCommerce={currentCommerce}
                        setCurrentPairedMPPoint={setCurrentPairedMPPoint}
                      />
                    ),
                    closeOnOverlayTap: true,
                  });
                }}
              >
                Enlazar Posnet
              </Text>
            )}
          </View>
        ) : null}
        {/*showPairCloverPointBtns() ? (
          <View style={styles.containerForPosnet}>
            {currentPairedCloverPoint ? (
              <Text
                style={styles.unpairPosnetMessage}
                onPress={async () => {
                  setModalizeData({
                    content: (
                      <UnPairCloverPointModal
                        selectedCloverPointId={currentPairedCloverPoint.id}
                        currentCommerce={currentCommerce}
                        setCurrentPairedCloverPoint={setCurrentPairedCloverPoint}
                      />
                    ),
                    closeOnOverlayTap: true,
                  });
                }}
              >
                Desemparejar posnet
              </Text>
            ) : (
              <Text
                style={styles.pairPosnetMessage}
                onPress={async () => {
                  setModalizeData({
                    content: (
                      <PairCloverPointModal
                        availableCloverPoints={availableCloverPoints}
                        currentCommerce={currentCommerce}
                        setCurrentPairedCloverPoint={setCurrentPairedCloverPoint}
                      />
                    ),
                    closeOnOverlayTap: true,
                  });
                }}
              >
                Enlazar Posnet
              </Text>
            )}
          </View>
              ) : null*/}
        {currentVenue?.paymentConfig?.balanceEnabled && userBalance && <BalanceExhibitor balance={userBalance} />}
        {currentCommerce && (
          <>
            {currentCommerce.promotions.length > 0 && (
              <View style={[styles.content, pairedWithClover && { marginTop: 100 }]}>
                <AdvertisingBannerCarousel
                  dotVenueColor={currentVenue?.appColor ?? darkColor}
                  colorTitle={grayColor}
                  items={currentCommerce.promotions}
                  itemsCustomStyles={customBannerStyleProps}
                  title={'Combos'}
                  isForTotemDisplay={pairedWithClover}
                />
              </View>
            )}
            <ProductsList commerce={currentCommerce} navigation={navigation} />
          </>
        )}
        {currentVenue?.chatbotLink ? (
          <ChatBotModal
            isVisible={isModalOpen}
            setIsChatBotModalVisible={setIsModalOpen}
            chatbotLink={currentVenue?.chatbotLink}
            navigation={navigation}
          />
        ) : null}
        {!showWelcomeView && pairedWithClover && <NavBarForTotem />}
      </View>
    );
  };

  return (
    <>
      {!showWelcomeView && renderNormalCommerceScreenView()}
      {showWelcomeView && renderCloverPointWelcomeView()}
    </>
  );
};
