/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axiosInstance from './axios';
import Product, { ClothingSizeTypeStock } from 'types/models/Product';
import ProductImage from 'types/models/ProductImage';
import ProductCategory from 'types/models/ProductCategory';
import PaginationData from 'types/models/PaginationData';
import { parseCommerce } from './commercesService';

export const getProductsByCommerce = async (commerceId: string): Promise<Product[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/commerces/${commerceId}/products/all`,
    });
    return parseProducts(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getPromotionsByVenue = async (venueId: string, page?: number): Promise<PaginationData<Product> | null> => {
  try {
    const { data: paginationData } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/promotions?page=${page ?? 1}&perPage=${3}`,
    });
    return {
      currentPage: paginationData.current_page,
      data: parseProducts(paginationData.data),
      perPage: paginationData.per_page,
      total: paginationData.total,
      lastPage: paginationData.last_page,
    };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPromotionsByCommerce = async (venueId: string, commerceId: string): Promise<Product[] | []> => {
  try {
    const { data } = await axiosInstance({
      method: 'get',
      url: `/venues/${venueId}/promotions?commerceId=${commerceId}`,
    });
    return parseProducts(data);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const parseProducts = (products: any[]): Product[] => {
  return products.map((product: any): Product => parseProduct(product));
};

export const parseProduct = (product: any): Product => {
  return {
    id: product.id,
    name: product.name,
    description: product.description,
    price: product.price,
    images: parseImages(product.images),
    isClothing: product.is_clothing === 1,
    clothingSizeStocks: product.clothing_sizes_stocks ? parseClothingSizeTypeStocks(product.clothing_sizes_stocks) : [],
    stock: product.stock,
    isSuggested: product.is_suggested,
    showInList: product.show_in_list,
    minimumAmount: product.minimum_amount,
    estimatedPreparationTime: product.estimated_preparation_time ?? undefined,
    promotionBannerBackgroundColor: product.promotion_banner_background_color ?? undefined,
    promotionBannerColorTitle: product.promotion_banner_color_title ?? undefined,
    category: parseCategory(product.category),
    commerce: product.commerce ? parseCommerce(product.commerce) : undefined,
    updatedAt: product.updated_at,
  };
};

export const parseImages = (data: any[]): ProductImage[] => {
  return data.map((item) => ({
    productId: item.pivot.product_id,
    productImageTypeId: item.pivot.product_image_type_id,
    path: item.path,
  }));
};

export const parseProductsForStockCheck = (products: any[]): Product[] => {
  return products.map((product: any): Product => parseProductForStockCheck(product));
};

const parseProductForStockCheck = (product: any): Product => {
  return {
    id: product.id,
    name: product.name,
    description: product.description,
    price: product.price,
    isClothing: product.is_clothing === 1,
    clothingSizeStocks: product.clothing_sizes_stocks ? parseClothingSizeTypeStocks(product.clothing_sizes_stocks) : [],
    images: [],
    stock: product.stock,
    isSuggested: product.is_suggested,
    showInList: product.show_in_list,
    minimumAmount: product.minimum_amount,
    estimatedPreparationTime: product.estimated_preparation_time ?? undefined,
    updatedAt: product.updated_at,
    clothingSizeType: Boolean(product.is_clothing)
      ? { id: parseInt(product.clothing_size.id), description: product.clothing_size.description }
      : undefined,
  };
};

const parseCategory = (category: any): ProductCategory => {
  return {
    id: category.id,
    description: category.description,
    requiresIdentification: category.requires_identification === 1,
    image: category.path,
  };
};

export const parseOrderDetailProduct = (product: any): Product => ({
  id: product.id,
  name: product.name,
  description: product.description,
  price: product.price,
  isClothing: product.is_clothing === 1,
  clothingSizeStocks: product.clothing_sizes_stocks ? parseClothingSizeTypeStocks(product.clothing_sizes_stocks) : [],
  isSuggested: product.is_suggested,
  showInList: product.show_in_list,
  minimumAmount: product.minimum_amount,
  stock: product.stock,
  images: parseImages(product.images),
  estimatedPreparationTime: product.estimated_preparation_time ?? undefined,
  updatedAt: product.updated_at,
});

const parseClothingSizeTypeStocks = (clothingSizeStocks: any[]): ClothingSizeTypeStock[] => {
  return clothingSizeStocks
    .filter((clothingSizeStock) => clothingSizeStock.stock !== 0)
    .map((clothingSizeStock) => ({
      id: clothingSizeStock.clothing_size_type_id,
      description: clothingSizeStock.clothing_size_type.description,
      stock: clothingSizeStock.stock,
    }));
};
