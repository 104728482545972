import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  separator: {
    width: '100%',
    marginBottom: 15,
    marginTop: 15,
    opacity: 0.5,
    borderStyle: 'solid',
    borderColor: '#979797',
    borderWidth: 0.5,
  },
  buttonsContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    marginTop: 50,
  },
  button: {
    marginTop: 30,
    justifyContent: 'center',
    width: 470,
    height: 106,
    borderRadius: 100,
  },
  confirmPaymentBtnText: {
    color: '#FFF',
    fontSize: 18,
  },
  buttonDisabled: {
    backgroundColor: '#FFFFFF66',
  },
});
