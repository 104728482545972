import * as yup from 'yup';

export default yup.object().shape({
  holderName: yup
    .string()
    .required('El nombre es requerido')
    .max(26, 'Longitud máxima alcanzada')
    .matches(/^[aA-zZ\s]+$/, 'Solamente se permiten letras'),
  cardNumber: yup.string().required('El número de la tarjeta es requerido'),
  expiration: yup.string().required('La fecha de expliración es requerida'),
  cvv: yup
    .string()
    .required('El cvv es requerido')
    .min(3, 'El cvv debe tener un mínimo de 3 dígitos')
    .max(4, 'El cvv debe tener un máximo de 4 dígitos'),
});
