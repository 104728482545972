import React from 'react';
import {
  View,
  Text,
  ScrollView,
  TextInput,
  TouchableOpacity,
  Platform,
  NativeScrollSize,
  NativeScrollPoint,
  Image,
} from 'react-native';
import { useFocusEffect } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';
import { Picker, PickerIOS } from '@react-native-picker/picker';
import moment from 'moment-timezone';
import { SuccessModal } from '@double_point/rn-components';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import useIcon from 'hooks/useIcon';
import useChangeShoppingCart from 'hooks/useChangeShoppingCart';
import useChangeOrder from 'hooks/useChangeOrder';
import useLocalOrders from 'hooks/useLocalOrders';
import useChangePayment from 'hooks/useChangePayment';
import OrderDetailList from 'components/OrderDetailList';
import PaymentMethodList from 'components/PaymentMethodList';
import DeliveryMethod from 'components/DeliveryMethod';
import GuestMailInput from 'components/GuestMailInput';
import OrderResponseModal from 'components/OrderResponseModal';
import SuggestedProductsModal from 'components/SuggestedProductsModal';
import ShoppingCartScreenProps from 'types/screens/ShoppingCartScreenProps';
import PaymentProviderTypes from 'types/enums/PaymentProviderTypes';
import DeliveryType from 'types/models/DeliveryType';
import BenefitType from 'types/enums/BenefitType';
import BenefitImageTypes from 'types/enums/BenefitImageTypes';
import Product from 'types/models/Product';
import Order from 'types/models/Order';
import { calculateTotalAmount } from 'utils/orders';
import regexConstants from 'constants/Regex';
import {
  storeOrder,
  checkProductsStock,
  getBenefitByUser,
  saveBuyersBenefit,
  getUserBalance,
  getSuggestedProducts,
} from 'services/ordersService';
import { styles } from 'styles/screens/shoppingCartScreen';
import { greenColor } from 'styles/global';
import DetailItem from 'types/models/DetailItem';
import ShoppingCart from 'types/models/ShoppingCart';
import ChatIcon from 'assets/images/image-chat.png';

export default ({ navigation }: ShoppingCartScreenProps): JSX.Element => {
  const { t } = useTranslation();
  const Icon = useIcon();
  const [buttonWasPressed, setButtonWasPressed] = React.useState<boolean>(false);
  const {
    modalizeData,
    currentAuthData,
    currentVenue,
    setIsLoading,
    setModalizeData,
    userBalance,
    setUserBalance,
    setCurrentAuthData,
    cloverConnected,
    setOrderModalResponseData,
  } = React.useContext(AppContext);
  const {
    currentCommerce,
    currentShoppingCart,
    currentOrder,
    setCurrentShoppingCart,
    setCurrentOrder,
    resultPayment,
    selectedPayment,
    setSelectedPayment,
    currentBenefit,
    setCurrentBenefit,
    setResultPayment,
  } = React.useContext(OrderContext);
  const { storeCurrentShoppingCart, deleteCurrentShoppingCart } = useChangeShoppingCart();
  const { storeLocalOrder } = useLocalOrders();
  const { deleteCurrentOrder } = useChangeOrder();
  const { changeResultOrder } = useChangePayment();
  const scrollContainerRef = React.useRef<ScrollView>(null);
  const fieldDataRefs = React.useRef<any>({});
  const [emailIntputValue, setEmailIntputValue] = React.useState<string>(
    (currentOrder?.guestEmail || currentShoppingCart?.firstUserEmail) ?? '',
  );
  const [suggestedProductsModal, setSuggestedProductsModal] = React.useState<true | false>(false);
  const [suggestedItems, setSuggestedItems] = React.useState<Product[]>([]);
  const [selectedDelivery, setSelectedDelivery] = React.useState<DeliveryType | null>(null);
  const [phone, setPhone] = React.useState<string>(currentShoppingCart?.phone ?? '');
  const [identification, setIdentification] = React.useState<string>(
    currentShoppingCart?.identification?.toString() ?? '',
  );
  const [selectedEventForOrder, setSelectedEventForOrder] = React.useState<string | null>(null);
  const [enableStickyConfirmButton, setEnableStickyConfirmButton] = React.useState<boolean>(false);
  const [firstFieldDataIncorrect, setFirstFieldDataIncorrect] = React.useState<string | null>(null);
  const maxIdentification = 45000000;
  const minIdentification = 91000000;
  const currentOrderTotalAmount =
    currentShoppingCart && currentShoppingCart.detail.length > 0
      ? calculateTotalAmount(currentShoppingCart.detail)
      : null;

  const onChangeQuantity = (id: string, quantity: number, clothingSizeId?: number) => {
    if (!currentShoppingCart) return;
    const detailItem = currentShoppingCart.detail.find((item) => {
      const itsSameProduct = item.product.id === id;
      if (item.product.isClothing) {
        // si es de tipo indumentaria debo buscar el producto que coincida en id y talle
        return itsSameProduct && item.clothingSize?.id === clothingSizeId;
      } else {
        // Si no es indumentaria entonces solo busco que coincida en el id
        return itsSameProduct;
      }
    });
    if (detailItem) {
      detailItem.quantity = quantity;
    }
    setCurrentShoppingCart({ ...currentShoppingCart });
  };

  const onDeleteProduct = (id: string, clothingSizeId?: number) => {
    if (currentShoppingCart) {
      currentShoppingCart.detail = currentShoppingCart.detail.filter((item) => {
        const itsNotSameProduct = item.product.id !== id;
        if (item.product.isClothing) {
          // si es de tipo indumentaria debo buscar el producto que coincida en id y talle y excluirlo
          return !(item.product.id === id && item.clothingSize?.id === clothingSizeId);
        } else {
          // Si no es indumentaria entonces solo busco que no coincida en el id
          return itsNotSameProduct;
        }
      });
      setCurrentShoppingCart({ ...currentShoppingCart });
    }
  };

  const getEmailForOrderBenefit = (): string | undefined => {
    if (currentBenefit) {
      if (currentAuthData?.user.email) {
        return currentAuthData?.user.email;
      } else if (emailIntputValue && regexConstants.emailForBenefits.test(emailIntputValue)) {
        return emailIntputValue;
      }
    }
  };

  const isAllDataComplete = (): boolean => {
    if (selectedDelivery?.id === 2 && !currentShoppingCart?.delivery) {
      setFirstFieldDataIncorrect('delivery');
      return false;
    }

    if (currentVenue?.events && currentVenue.events.length > 0 && !selectedEventForOrder) {
      setFirstFieldDataIncorrect('events');
      return false;
    }

    if (!currentAuthData?.user.email && !regexConstants.email.test(emailIntputValue)) {
      setFirstFieldDataIncorrect('email');
      return false;
    }

    if (someProductRequiresIdentification()) {
      if (!identification) {
        setFirstFieldDataIncorrect('identification');
        return false;
      }
      if (parseInt(identification) > maxIdentification && parseInt(identification) < minIdentification) {
        setFirstFieldDataIncorrect('identification');
        return false;
      }
    }

    if (selectedDelivery?.id !== 1) {
      if (!phone || !regexConstants.phone.test(phone)) {
        setFirstFieldDataIncorrect('phone');
        return false;
      }
    }

    return true;
  };

  const inputIsInvalid = (input: string): boolean => {
    switch (input) {
      case 'phone':
        if (!phone || !regexConstants.phone.test(phone)) {
          return true;
        }
        break;
      case 'identification':
        if (someProductRequiresIdentification()) {
          if (!identification) return true;
          if (parseInt(identification) > maxIdentification && parseInt(identification) < minIdentification) return true;
        }
        break;
      case 'guestEmail':
        if (!currentAuthData?.user.email && !regexConstants.email.test(emailIntputValue)) {
          return true;
        }
        break;
      case 'event':
        if (currentVenue?.events && currentVenue.events.length > 0 && !selectedEventForOrder) {
          return true;
        }
      default:
        if (selectedDelivery?.id === 2 && !currentShoppingCart?.delivery) {
          return true;
        }
        break;
    }

    return false;
  };

  /*const buttonDisabled = (): boolean => {
    // El telefono es requerido
    if (selectedDelivery?.id !== 1 && !phone) return true;
    // Controla que sea un número de telefono con formato internacional válido.
    if (selectedDelivery?.id !== 1 && !regexConstants.phone.test(phone)) return true;
    // Si al menos un producto requiere DNI se controla que se haya ingresado el DNI y
    // que el mismo sea menor a maxIdentification o mayor a minIdentification
    if (someProductRequiresIdentification()) {
      if (!identification) return true;
      if (parseInt(identification) > maxIdentification && parseInt(identification) < minIdentification) return true;
    }
    // El tipo de pago es requerido
    if (!selectedPayment) return true;
    // La orden este procesada es requerido
    if (currentShoppingCart?.processed && currentShoppingCart?.isCash) return true;
    // La orden este paga es requerido
    if (currentShoppingCart?.paid) return true;
    // El email es requerido
    if (!currentAuthData?.user.email && !regexConstants.email.test(emailIntputValue)) return true;
    // El delivery es requerido
    if (selectedDelivery?.id === 2 && !currentShoppingCart?.delivery) return true;
    // Los datos para la orden son correctos
    return false;
  };*/

  const someProductRequiresIdentification = (): boolean | undefined => {
    return currentShoppingCart?.detail.some((detail) => detail.product?.category?.requiresIdentification);
  };

  const calculatePreparationTime = (): number | void => {
    if (!currentShoppingCart || !currentShoppingCart.detail) return;

    const filterEstimatedPreparationTimes = (): number[] => {
      const filteredEstimationTimes = currentShoppingCart?.detail.filter(
        (detailItem) => detailItem.product.estimatedPreparationTime,
      );
      return filteredEstimationTimes.map((detailItem) => {
        return Number(detailItem.product.estimatedPreparationTime);
      });
    };

    const maxPreparationTime =
      filterEstimatedPreparationTimes().length > 0 ? Math.max(...filterEstimatedPreparationTimes()) : 0;
    return maxPreparationTime;
  };

  const getBenefit = async () => {
    if (!currentCommerce) return;
    setIsLoading(true);
    const benefitByUser = await getBenefitByUser(emailIntputValue, currentCommerce.id);
    setIsLoading(false);
    if (benefitByUser) {
      if (benefitByUser.benefitType === BenefitType.ProductBenefit && benefitByUser.product) {
        benefitByUser.product.price = 0;
        if (currentShoppingCart && currentShoppingCart.detail.length > 0) {
          const productDetailItem = currentShoppingCart.detail.find((item) => {
            if (item.product.id === benefitByUser.product?.id && item.product.price === benefitByUser.product?.price) {
              return true;
            }
          });
          if (!productDetailItem) {
            currentShoppingCart?.detail.push({ product: benefitByUser.product, quantity: 1 });
            setCurrentShoppingCart({ ...currentShoppingCart });
          }
        }
      }
      setCurrentBenefit(benefitByUser);
      const benefitMessage = benefitByUser.benefitDescription;
      const benefitImageByUser = benefitByUser?.images?.find(
        (image) => image.benefitImageTypeId === BenefitImageTypes.banner,
      )?.path;
      setModalizeData({
        content: (
          <SuccessModal
            isSuccess={true}
            title={t('title-benefit-success')}
            mainColor={currentVenue?.appColor ?? greenColor}
            message={benefitMessage}
            buttonText={t('close-benefit-modal-button')}
            onConfirm={() => {
              setModalizeData(null);
            }}
            isBenefit={true}
            benefitImage={benefitImageByUser}
          />
        ),
        closeOnOverlayTap: true,
      });
    }
  };

  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          if (currentShoppingCart.processed) {
            if (currentShoppingCart.paid) {
              await deleteCurrentOrder();
              setTimeout(() => {
                navigation.reset({
                  index: 0,
                  routes: [{ name: 'HomeScreen' }],
                });
              }, 500);
            }
          }
        }
      };
      onFocus();

      return () => {
        setButtonWasPressed(false);
      };
    }, []),
  );

  useFocusEffect(
    React.useCallback(() => {
      const onFocus = async () => {
        if (currentShoppingCart) {
          await storeCurrentShoppingCart(currentShoppingCart);
        }
      };
      onFocus();
    }, [currentShoppingCart]),
  );

  useFocusEffect(
    React.useCallback(() => {
      if (currentAuthData && currentAuthData.user.email) {
        getBenefit();
      }
    }, []),
  );

  React.useEffect(() => {
    const getBalanceByCurrentUser = async (): Promise<void> => {
      if (
        currentAuthData &&
        currentVenue?.paymentConfig?.balanceEnabled &&
        currentVenue.paymentConfig.priorityOverCommerce
      ) {
        const userBalance = await getUserBalance(currentAuthData.user.id, currentVenue.id);
        if (userBalance) {
          setUserBalance(userBalance);
        }
      }
    };
    getBalanceByCurrentUser();
  }, [currentAuthData]);

  const modalMessageContent = (message: string, paymentError: boolean): JSX.Element => (
    <OrderResponseModal
      success={false}
      message={message}
      onClose={() => setOrderModalResponseData(null)}
      navigation={navigation}
      redirectToPayment={paymentError ? () => redirectPayment() : undefined}
    />
  );

  const openErrorModal = (message: string, errorFromPayment: boolean) => {
    setOrderModalResponseData({
      content: modalMessageContent(message, errorFromPayment),
    });
  };

  const redirectPayment = () => {
    switch (selectedPayment?.paymentProvider) {
      case PaymentProviderTypes.firstData:
        navigation.reset({
          index: 1,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'PaymentFDScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                      paymentMethod: 'first-data',
                    }
                  : undefined,
            },
          ],
        });
        break;
      case PaymentProviderTypes.mercadoPago:
        navigation.reset({
          index: 1,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'AddCardScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                      paymentMethod: 'mercado-pago',
                    }
                  : undefined,
            },
          ],
        });
        break;
      case PaymentProviderTypes.mercadoPagoPoint:
        navigation.reset({
          index: 1,
          routes: [{ name: 'HomeScreen' }, { name: 'PaymentMPPointScreen' }],
        });
        break;
      case PaymentProviderTypes.clover:
        navigation.reset({
          index: 1,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'PaymentCloverScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                      paymentMethod: 'clover-point',
                    }
                  : undefined,
            },
          ],
        });
        break;
      case PaymentProviderTypes.izipay:
        navigation.reset({
          index: 1,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'PaymentIzipayScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                      paymentMethod: 'izipay',
                    }
                  : undefined,
            },
          ],
        });
        break;
      case PaymentProviderTypes.MercadoPagoCheckout:
        navigation.reset({
          index: 1,
          routes: [
            { name: 'HomeScreen' },
            {
              name: 'PaymentMPCheckoutScreen',
              params:
                Platform.OS === 'web'
                  ? {
                      venueCode: currentVenue?.code,
                      commerceCode: currentCommerce?.code,
                      paymentMethod: 'mercado-pago-checkout',
                    }
                  : undefined,
            },
          ],
        });
        break;
      default:
        break;
    }
  };

  const handleProductsWithoutStock = (productsWithoutStock: Product[]) => {
    setIsLoading(false);
    let messageMaximumUnitsAvailable = '';
    productsWithoutStock.map((product, index) => {
      if (product.isClothing) {
        const detailItem = currentShoppingCart?.detail.find(
          (detailItem) =>
            detailItem.product.id === product.id && detailItem.clothingSize?.id === product.clothingSizeType?.id,
        );
        const stock = product.clothingSizeStocks.find(({ id }) => id === detailItem?.clothingSize?.id)?.stock;
        const word = stock === 0 ? 'disponible' : 'suficiente';
        messageMaximumUnitsAvailable += `No hay stock ${word} de ${product.name} en talle ${detailItem?.clothingSize?.description}. \n`;
      } else {
        if (productsWithoutStock.length > 1 && index === productsWithoutStock.length - 1) {
          messageMaximumUnitsAvailable += ` y no hay suficiente de ${product.name}`;
        } else if (productsWithoutStock.length > 2 && index !== productsWithoutStock.length - 2) {
          messageMaximumUnitsAvailable += `No hay stock suficiente de ${product.name}, `;
        } else if (product.stock === 0) {
          messageMaximumUnitsAvailable += `No hay stock disponible de ${product.name}`;
        } else if (
          product.stock === 0 &&
          productsWithoutStock.length > 1 &&
          index === productsWithoutStock.length - 1
        ) {
          messageMaximumUnitsAvailable += ` y no hay disponible de ${product.name}`;
        } else if (
          product.stock === 0 &&
          productsWithoutStock.length > 2 &&
          index !== productsWithoutStock.length - 2
        ) {
          messageMaximumUnitsAvailable += `No hay stock disponible de ${product.name}, `;
        } else if (index > 0 && index !== productsWithoutStock.length - 1) {
          messageMaximumUnitsAvailable += ` no hay stock suficiente de ${product.name}`;
        } else if (product.stock === 0 && index > 0 && index !== productsWithoutStock.length - 1) {
          messageMaximumUnitsAvailable += ` no hay stock disponible de ${product.name}`;
        } else {
          messageMaximumUnitsAvailable += `No hay stock suficiente de ${product.name}`;
        }
      }
    });
    messageMaximumUnitsAvailable += '.';
    openErrorModal(messageMaximumUnitsAvailable, false);
  };

  React.useEffect(() => {
    if (resultPayment) {
      if (!resultPayment.success) {
        if (cloverConnected) {
          setResultPayment(null);
        }
        if (resultPayment.response) {
          console.log('hola');
          openErrorModal(resultPayment.response, true);
        }
      }
    }
  }, [resultPayment]);

  React.useEffect(() => {
    if (!currentCommerce || currentShoppingCart?.detail.length === 0) return;
    const getCartIds = (details: DetailItem[]): string[] => {
      return details.map((detail: DetailItem) => detail.product.id);
    };
    const ids = getCartIds(currentShoppingCart?.detail ?? []);

    const suggestedProducts = async () => {
      const suggestedProducts = await getSuggestedProducts(
        currentCommerce?.id,
        currentOrderTotalAmount === null ? 0 : currentOrderTotalAmount,
        ids,
      );
      if (!suggestedProducts) return;
      if (suggestedProducts?.length === 0) {
        setSuggestedProductsModal(false);
      } else {
        setSuggestedItems(suggestedProducts);
        setSuggestedProductsModal(true);
      }
    };
    try {
      suggestedProducts();
    } catch (error) {
      console.log('error en suggestedProducts', error);
    }
  }, []);

  React.useEffect(() => {
    if (regexConstants.emailForBenefits.test(emailIntputValue)) {
      getBenefit();
    }
  }, [emailIntputValue]);

  React.useEffect(() => {
    if (firstFieldDataIncorrect && scrollContainerRef?.current) {
      fieldDataRefs.current[firstFieldDataIncorrect].measureLayout(
        scrollContainerRef.current.getInnerViewNode(),
        (x: number, y: number) => {
          scrollContainerRef?.current?.scrollTo({
            x: 0,
            y: y,
            animated: true,
          });
        },
        () => {
          console.error('measurement failed');
        },
      );
    }

    return () => {
      setFirstFieldDataIncorrect(null);
    };
  }, [firstFieldDataIncorrect]);

  let paymentMethods = <></>;
  if (currentVenue?.paymentConfig) {
    let paymentConfig;
    const venuePaymentConfig = currentVenue.paymentConfig;
    if (venuePaymentConfig.priorityOverCommerce) {
      paymentConfig = venuePaymentConfig;
    } else {
      if (currentCommerce?.paymentConfig) {
        paymentConfig = currentCommerce.paymentConfig;
      }
    }
    if (paymentConfig) {
      paymentMethods = (
        <PaymentMethodList
          paymentConfig={paymentConfig}
          setSelectedPayment={setSelectedPayment}
          userBalance={userBalance}
          currentOrderTotalAmount={currentOrderTotalAmount}
        />
      );
    }
  }

  let deliveryMethods = <></>;
  if (currentVenue?.deliveryConfig) {
    let deliveryConfig;
    const venueDeliveryConfig = currentVenue.deliveryConfig;
    if (venueDeliveryConfig.priorityOverCommerce) {
      deliveryConfig = venueDeliveryConfig;
    } else {
      if (currentCommerce?.deliveryConfig) {
        deliveryConfig = currentCommerce.deliveryConfig;
      }
    }
    if (deliveryConfig && currentShoppingCart) {
      deliveryMethods = (
        <DeliveryMethod
          selectedDelivery={selectedDelivery}
          setSelectedDelivery={setSelectedDelivery}
          deliveryConfig={deliveryConfig}
          editable={!currentShoppingCart.processed}
          buttonWasPressed={buttonWasPressed}
          inputEmpty={inputIsInvalid('delivery')}
        />
      );
    }
  }

  const PaymentButton = (currentShoppingCart: ShoppingCart | null): JSX.Element => {
    if (!currentShoppingCart) return <></>;
    return (
      <TouchableOpacity
        style={[
          enableStickyConfirmButton ? styles.confirmPaymentFloatingBtnContainer : styles.confirmPaymentBtnContainer,
          { backgroundColor: currentVenue?.appColor ?? greenColor },
          // buttonDisabled() && styles.confirmPaymentBtnDisabled,
          { outlineStyle: 'none' },
        ]}
        //disabled={buttonDisabled()}
        onPress={async () => {
          setButtonWasPressed(true);
          if (!isAllDataComplete()) return;

          setIsLoading(true);
          let redirectToPayment = false;
          const productsWithoutStock = await checkProductsStock(currentShoppingCart.detail);
          if (productsWithoutStock.length > 0) {
            handleProductsWithoutStock(productsWithoutStock);
          } else {
            if (currentShoppingCart.processed && !currentShoppingCart.paid && currentOrder) {
              // Reintentar el pago
              redirectToPayment = true;
              redirectPayment();
              setIsLoading(false);
            } else {
              // Crear la orden
              if (!currentVenue) return;
              try {
                const shoppingCart = currentShoppingCart;
                if (emailIntputValue && currentAuthData && !currentAuthData.user.email) {
                  shoppingCart.firstUserEmail = emailIntputValue;
                } else if (emailIntputValue) {
                  shoppingCart.guestEmail = emailIntputValue;
                }
                if (phone)
                  shoppingCart.phone = `${
                    selectedPayment?.paymentProvider === PaymentProviderTypes.izipay ? '51' : '549'
                  }${phone}`;
                if (identification) shoppingCart.identification = parseInt(identification);
                setCurrentShoppingCart(shoppingCart);
                const isCash = selectedPayment?.isCash ?? false;
                const isBalance = selectedPayment?.isBalance ?? false;
                let order: Order | null;
                if (currentBenefit) {
                  order = await storeOrder(
                    currentShoppingCart,
                    currentVenue.id,
                    isCash,
                    isBalance,
                    false,
                    currentVenue.localDateTimeIdentifier,
                    selectedEventForOrder ?? undefined,
                    currentBenefit.id,
                    getEmailForOrderBenefit(),
                  );
                } else {
                  order = await storeOrder(
                    currentShoppingCart,
                    currentVenue.id,
                    isCash,
                    isBalance,
                    false,
                    currentVenue.localDateTimeIdentifier,
                    selectedEventForOrder ?? undefined,
                  );
                }
                if (order) {
                  if (!order.buyer) await storeLocalOrder(order);
                  redirectToPayment = true;
                  setCurrentShoppingCart({ ...currentShoppingCart, processed: true, isCash });
                  setCurrentOrder(order);
                  if (isCash) {
                    // Payment with cash
                    if (currentBenefit) {
                      const email = currentAuthData?.user.email ?? shoppingCart.guestEmail;
                      if (email) {
                        await saveBuyersBenefit(email, currentBenefit.id, order.id);
                      }
                    }
                    changeResultOrder(order, true);
                  } else if (isBalance) {
                    // Payment with balance
                    if (currentBenefit) {
                      const email = currentAuthData?.user.email ?? shoppingCart.guestEmail;
                      if (email) {
                        await saveBuyersBenefit(email, currentBenefit.id, order.id);
                      }
                    }
                    changeResultOrder(order, false);
                  } else {
                    // Payment with provider
                    redirectPayment();
                  }
                }
                if (shoppingCart.firstUserEmail) {
                  if (currentAuthData) {
                    const currentUser = { ...currentAuthData.user, email: shoppingCart.firstUserEmail };
                    const updatedUserData = { ...currentAuthData, user: currentUser };
                    setCurrentAuthData(updatedUserData);
                  }
                }
              } catch (error) {
                console.log(error);
              } finally {
                setIsLoading(false);
              }
            }
            if (!redirectToPayment) {
              setIsLoading(false);
              openErrorModal(t('error-no-received-order'), false);
            }
          }
        }}
      >
        <View style={styles.confirmPaymentBtnTextContainer}>
          <Text style={[styles.confirmPaymentBtnText, enableStickyConfirmButton && styles.stickyBtnText]}>
            {currentShoppingCart.processed ? t('finalize-purchase') : t('order-confirm')}
          </Text>
        </View>
        <View style={styles.confirmPaymentBtnTotalTextContainer}>
          <Text style={[styles.confirmPaymentBtnText, enableStickyConfirmButton && styles.stickyBtnText]}>
            ${calculateTotalAmount(currentShoppingCart.detail)}
          </Text>
        </View>
      </TouchableOpacity>
    );
  };

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      if (currentCommerce) {
        navigation.reset({
          index: 1,
          routes: [{ name: 'HomeScreen' }, { name: 'CommerceScreen' }],
        });
      } else {
        navigation.reset({
          index: 0,
          routes: [{ name: 'HomeScreen' }],
        });
      }
    }
  };

  const isCloseToBottom = (
    layoutMeasurement: NativeScrollSize,
    contentOffset: NativeScrollPoint,
    contentSize: NativeScrollSize,
  ) => {
    const paddingToBottom = 225;
    return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
  };

  return (
    <View style={styles.container}>
      <ScrollView
        scrollEnabled={!modalizeData}
        persistentScrollbar={true}
        onScroll={({ nativeEvent }) => {
          const closeToBottom = isCloseToBottom(
            nativeEvent.layoutMeasurement,
            nativeEvent.contentOffset,
            nativeEvent.contentSize,
          );
          if (!closeToBottom) {
            setEnableStickyConfirmButton(true);
          } else {
            setEnableStickyConfirmButton(false);
          }
        }}
        scrollEventThrottle={400}
        ref={scrollContainerRef}
      >
        <View style={styles.header}>
          <View style={styles.headerTitle}>
            {!currentShoppingCart?.processed && (
              <Icon
                name="arrow-left"
                style={[styles.headerBackIcon]}
                color={currentVenue?.appSecondaryColor ? currentVenue?.appColor : 'black'}
                onPress={goBack}
              />
            )}
            <Text
              style={[
                styles.headerTitleText,
                { color: currentVenue?.appSecondaryColor ? currentVenue?.appColor : 'black' },
              ]}
            >
              {t('my-cart')}
            </Text>
          </View>
          {currentVenue?.useDifferentIconSet ? (
            <Image
              source={ChatIcon}
              style={[styles.headerCommentChatIcon, { tintColor: currentVenue?.appColor ?? greenColor }]}
            />
          ) : (
            <Icon
              name="comment-question"
              style={[styles.headerCommentQuestionIcon, { color: currentVenue?.appColor ?? greenColor }]}
            />
          )}
        </View>
        <View style={styles.content}>
          {currentShoppingCart && currentShoppingCart.detail.length > 0 ? (
            <>
              <OrderDetailList
                detail={currentShoppingCart.detail}
                showControlQuantity={!currentShoppingCart?.processed}
                onChangeQuantity={onChangeQuantity}
                onDeleteProduct={onDeleteProduct}
              />
              {currentBenefit && currentBenefit.benefitType === BenefitType.DiscountBenefit && (
                <>
                  <Text style={styles.dataOrder}>
                    {t('discount-benefit')}
                    <Text style={{ color: currentVenue?.appColor ?? greenColor }}>
                      {' '}
                      - ${currentBenefit.discountAmount}
                    </Text>
                  </Text>
                  <Text style={styles.dataOrder}>
                    {t('order-total')}
                    <Text style={{ color: currentVenue?.appColor ?? greenColor }}>
                      {' '}
                      {currentBenefit.discountAmount &&
                        `$${calculateTotalAmount(currentShoppingCart.detail, currentBenefit.discountAmount)}`}
                    </Text>
                  </Text>
                </>
              )}
              {!!calculatePreparationTime() && (
                <Text style={styles.dataOrder}>
                  {t('estimated-preparation-time-text')}
                  <Text
                    style={{ color: currentVenue?.appColor ?? greenColor }}
                  >{`${calculatePreparationTime()}min`}</Text>
                </Text>
              )}

              {currentVenue?.deliveryConfig && (
                <>
                  <View
                    style={styles.separator}
                    id={'delivery'}
                    ref={(ref) => {
                      if (ref) fieldDataRefs.current.delivery = ref;
                    }}
                  ></View>
                  <Text style={styles.subTitle}>
                    {currentCommerce?.type.description === 'Parking'
                      ? t('complete-vehicle-data')
                      : t('select-the-type-of-delivery')}
                  </Text>
                  {deliveryMethods}
                </>
              )}
              <>
                <View style={styles.separator}></View>
                {currentVenue?.events && currentVenue.events.length > 0 && (
                  <View
                    style={styles.pickerContainer}
                    id={'events'}
                    ref={(ref) => {
                      if (ref) fieldDataRefs.current.events = ref;
                    }}
                  >
                    <Text style={styles.sectionTitle}>
                      {t('select-event-for-this-order')} <Text style={styles.importantInputs}>*</Text>
                    </Text>
                    {Platform.OS !== 'ios' ? (
                      <Picker
                        style={[
                          styles.picker,
                          { outlineStyle: 'none' },
                          buttonWasPressed && inputIsInvalid('event') && styles.inputHasError,
                          buttonWasPressed &&
                            inputIsInvalid('event') &&
                            !selectedEventForOrder &&
                            styles.inputTextError,
                        ]}
                        selectedValue={selectedEventForOrder}
                        onValueChange={(value) => {
                          setSelectedEventForOrder(value);
                        }}
                      >
                        {!selectedEventForOrder && <Picker.Item label={t('select-event')} value={null} />}
                        {currentVenue?.events?.map((event) => (
                          <Picker.Item
                            key={event.id}
                            value={event.id}
                            label={`${event.name} - ${moment(event.eventStart).format('DD/MM/YY - HH:mm')}`}
                          />
                        ))}
                      </Picker>
                    ) : (
                      <PickerIOS
                        style={[
                          styles.pickerIos,
                          { outlineStyle: 'none' },
                          buttonWasPressed && inputIsInvalid('event') && styles.inputHasError,
                          buttonWasPressed &&
                            inputIsInvalid('event') &&
                            !selectedEventForOrder &&
                            styles.inputTextError,
                        ]}
                        selectedValue={selectedEventForOrder ?? undefined}
                        onValueChange={(value) => {
                          setSelectedEventForOrder(value.toString());
                        }}
                        itemStyle={{ height: 80, padding: 10 }}
                      >
                        {!selectedEventForOrder && <Picker.Item label={t('select-event')} value={null} />}
                        {currentVenue?.events?.map((event) => (
                          <Picker.Item
                            key={event.id}
                            value={event.id}
                            label={`${event.name} - ${moment(event.eventStart).format('DD/MM/YY - HH:mm')}`}
                          />
                        ))}
                      </PickerIOS>
                    )}
                    {buttonWasPressed && inputIsInvalid('event') && (
                      <Text style={{ color: '#ff0000', fontSize: 16, marginTop: 5 }}>{t('needs-event-selected')}</Text>
                    )}
                  </View>
                )}
                <Text style={styles.sectionTitle}>{t('personal-data')}</Text>
                {/* <Text style={styles.importantInputs}>{`${t('fields-required')} *`}</Text> */}
                {(!currentAuthData || !currentAuthData.user.email) && (
                  <GuestMailInput
                    label={t('guest-email-shopping-label')}
                    value={emailIntputValue}
                    onChangeValue={setEmailIntputValue}
                    editable={!currentShoppingCart.processed}
                    inputHasError={buttonWasPressed && inputIsInvalid('guestEmail')}
                    scrollContainerRef={fieldDataRefs}
                  />
                )}
                {someProductRequiresIdentification() && (
                  <View
                    id={'identification'}
                    ref={(ref) => {
                      if (ref) fieldDataRefs.current.identification = ref;
                    }}
                  >
                    <Text style={styles.subTitle}>
                      {t('enter-identification')} <Text style={styles.importantInputs}>*</Text>
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      value={identification}
                      onChangeText={setIdentification}
                      style={[
                        styles.phoneInput,
                        buttonWasPressed && inputIsInvalid('identification') && styles.requiredInput,
                        currentShoppingCart.processed && !currentShoppingCart.paid && styles.disabledPhoneInput,
                        { outlineStyle: 'none' },
                      ]}
                      placeholder={t('enter-identification-placeholder')}
                      placeholderTextColor={buttonWasPressed && inputIsInvalid('phone') ? '#f00' : '#C4C4C4'}
                      editable={!currentShoppingCart.processed}
                    />
                    <Text style={styles.invalidatedIdentification}>
                      {identification !== '' &&
                        parseInt(identification) > maxIdentification &&
                        parseInt(identification) < minIdentification &&
                        t('minimum-age')}
                    </Text>
                  </View>
                )}
                {selectedDelivery?.id !== 1 && (
                  <View
                    id={'phone'}
                    style={styles.inputPhoneContainer}
                    ref={(ref) => {
                      if (ref) fieldDataRefs.current.phone = ref;
                    }}
                  >
                    <Text style={styles.subTitle}>
                      {t('enter-phone-number')} <Text style={styles.importantInputs}>*</Text>
                    </Text>
                    <TextInput
                      keyboardType="number-pad"
                      value={phone}
                      onChangeText={setPhone}
                      style={[
                        styles.phoneInput,
                        buttonWasPressed && inputIsInvalid('phone') && styles.requiredInput,
                        currentShoppingCart.processed && !currentShoppingCart.paid && styles.disabledPhoneInput,
                        { outlineStyle: 'none' },
                      ]}
                      placeholder={t('example-phone-number')}
                      placeholderTextColor={buttonWasPressed && inputIsInvalid('phone') ? '#f00' : '#C4C4C4'}
                      editable={!currentShoppingCart.processed}
                    />
                    {buttonWasPressed && inputIsInvalid('phone') && (
                      <Text style={{ color: '#ff0000', fontSize: 16, marginTop: 5 }}>{t('phone-input-error')}</Text>
                    )}
                    <Text style={styles.phoneNumberContact}>{t('phone-number-contact')}</Text>
                  </View>
                )}
              </>
              <View style={styles.separator} />
              {currentBenefit &&
              currentBenefit.benefitType === BenefitType.DiscountBenefit &&
              calculateTotalAmount(currentShoppingCart.detail, currentBenefit?.discountAmount) <= 0 ? (
                <Text style={styles.subTitle}>{t('discount-benefit-superior-to-order-total')}</Text>
              ) : (
                paymentMethods
              )}

              <>
                <View style={[styles.separator]}></View>
                <View>
                  <Text
                    style={{
                      fontStyle: 'normal',
                      fontWeight: '500',
                      fontSize: 16,
                      lineHeight: 20,
                    }}
                  >
                    {t('order-resume')}
                  </Text>
                  <View style={{ marginTop: 15 }}>
                    {currentShoppingCart.detail.map((item, index) => (
                      <View key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <Text
                          style={{
                            flex: 5,
                            paddingLeft: 7,
                          }}
                        >
                          {item.product.name}
                        </Text>
                        <Text style={{ flex: 1 }}>${item.quantity * item.product.price}</Text>
                      </View>
                    ))}

                    <View style={{ display: 'flex', flexDirection: 'row', marginTop: 18 }}>
                      <Text
                        style={{
                          paddingLeft: 7,
                          flex: 5,
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: 14,
                          lineHeight: 16,
                        }}
                      >
                        Total
                      </Text>
                      <Text
                        style={{
                          flex: 1,
                          fontStyle: 'normal',
                          fontWeight: '700',
                          fontSize: 14,
                          lineHeight: 16,
                        }}
                      >
                        ${calculateTotalAmount(currentShoppingCart.detail)}
                      </Text>
                    </View>
                  </View>
                </View>
              </>
              <View style={styles.separator}></View>
              <View style={styles.buttonsContainer}>
                {!enableStickyConfirmButton && PaymentButton(currentShoppingCart)}
                {!currentShoppingCart.processed && currentCommerce !== null && (
                  <TouchableOpacity
                    style={{ outlineStyle: 'none' }}
                    onPress={() => {
                      navigation.reset({
                        index: 1,
                        routes: [
                          { name: 'HomeScreen' },
                          {
                            name: 'CommerceScreen',
                            params:
                              Platform.OS === 'web'
                                ? {
                                    venueCode: currentVenue?.code,
                                    commerceCode: currentCommerce.code,
                                  }
                                : undefined,
                          },
                        ],
                      });
                    }}
                  >
                    <Text style={styles.shoppingCartActionsText}>
                      {`${t('continue-shopping-at')} ${currentShoppingCart.commerce.name}`}
                    </Text>
                  </TouchableOpacity>
                )}
                <TouchableOpacity
                  style={{ outlineStyle: 'none' }}
                  onPress={async () => {
                    setCurrentShoppingCart(null);
                    await deleteCurrentShoppingCart();
                  }}
                >
                  <Text style={styles.shoppingCartActionsText}>{t('cancel-order')}</Text>
                </TouchableOpacity>
              </View>
            </>
          ) : (
            <View style={styles.returnAdviceContainer}>
              <Text style={[styles.noProducts, { color: currentVenue?.appColor ?? greenColor }]}>
                {t('no-products-shopping-cart')}
              </Text>
              <TouchableOpacity
                style={[
                  styles.returnButtonContainer,
                  { backgroundColor: currentVenue?.appColor ?? '#E50303' },
                  { outlineStyle: 'none' },
                ]}
                onPress={() => {
                  goBack();
                }}
              >
                <View style={styles.returnTextContainer}>
                  <Text style={styles.returnText}>Volver</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
        {currentShoppingCart?.commerce && suggestedProductsModal && (
          <SuggestedProductsModal
            onClose={() => setSuggestedProductsModal(false)}
            items={suggestedItems}
            onChangeQuantity={onChangeQuantity}
            onDeleteProduct={onDeleteProduct}
          />
        )}
      </ScrollView>
      {enableStickyConfirmButton && PaymentButton(currentShoppingCart)}
    </View>
  );
};
