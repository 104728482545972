import React from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import constantsStorage from 'constants/Storage';
import { CommerceScreenParams } from 'types/navigation/bottomTabNavigator';
import useRedirectParams from 'types/hooks/useRedirectParams';
import AppContext from 'contexts/AppContext';

export default (): useRedirectParams => {
  const { setRedirectionItems } = React.useContext(AppContext);

  const storeRedirectCommerceParams = async (params: CommerceScreenParams): Promise<void> => {
    try {
      await AsyncStorage.setItem(constantsStorage.commerceRedirectParams, JSON.stringify(params));
      setRedirectionItems(params);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRedirectCommerceParams = async (): Promise<void> => {
    try {
      await AsyncStorage.removeItem(constantsStorage.commerceRedirectParams);
      if (setRedirectionItems) setRedirectionItems(null);
    } catch (error) {
      console.log(error);
    }
  };

  return { storeRedirectCommerceParams, deleteRedirectCommerceParams };
};
