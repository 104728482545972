import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  flatlistContainer: {
    paddingLeft: 10,
    marginTop: 50,
  },
  buttonsContainer: {
    width: '100%',
    top: 0,
    alignItems: 'center',
  },
  confirmVenueBtn: {
    width: 250,
    height: 50,
    borderRadius: 12,
    marginBottom: 20,
    backgroundColor: '#FFF',
  },
  confirmVenueBtnText: {
    fontSize: 18,
  },
  btnDisabled: {
    backgroundColor: '#FFFFFF66',
  },
  selectLaterBtn: {
    width: 300,
    marginBottom: 20,
    fontSize: 16,
  },
  selectLaterText: {
    fontSize: 16,
    color: '#FFFFFF',
  },
  noVenues: {
    color: '#FFF',
    fontSize: 20,
    marginTop: 20,
    padding: 60,
  },
});
