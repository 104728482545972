import React from 'react';
import { View, Text } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import AppContext from 'contexts/AppContext';
import OrderContext from 'contexts/OrderContext';
import useIcon from 'hooks/useIcon';
import { styles } from 'styles/components/navBarForTotem';
import { greenColor, disabledColorForTabsTotem } from 'styles/global';

export default (): JSX.Element => {
  const Icon = useIcon();
  const { t } = useTranslation();
  const { currentVenue } = React.useContext(AppContext);
  const { currentOrder, currentShoppingCart, currentCommerce } = React.useContext(OrderContext);
  const navigation = useNavigation();
  const navigationState = navigation.getState();
  const lastScreenOnRoutes = navigationState.routes[navigationState.routes.length - 1].name;
  const homeStackScreens = ['CommerceScreen', 'ProductDetailsScreen'];
  const shoppingCartStackScreens = ['ShoppingCartScreen', 'PaymentResumeScreen', 'PaymentCloverScreen'];
  const findLastScreenOnHomeStack = homeStackScreens.find((homeStackScreen) => homeStackScreen === lastScreenOnRoutes);
  const findLastScreenOnShoppingCartStack = shoppingCartStackScreens.find(
    (shoppingCartStackScreen) => shoppingCartStackScreen === lastScreenOnRoutes,
  );

  const totalAmountOfAllProductsInShoppingCart = currentShoppingCart?.detail.reduce((acc, detailItem) => {
    return acc + detailItem.quantity;
  }, 0);

  const isCommerceScreenNotInDisplay = navigation.canGoBack() && findLastScreenOnHomeStack !== 'CommerceScreen';

  return (
    <View
      style={{
        backgroundColor: '#F9F9F9',
        bottom: 35,
        borderRadius: 15,
        height: 171,
        marginTop: 150,

        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,

        elevation: 5,
        width: isCommerceScreenNotInDisplay ? 978 : 489,
        alignSelf: 'center',

        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'absolute',
        paddingVertical: 20,
        paddingHorizontal: 60,
        alignItems: 'center',
      }}
    >
      {isCommerceScreenNotInDisplay && (
        <View>
          <Icon
            name="arrow-left"
            style={{ color: disabledColorForTabsTotem, fontSize: 75 }}
            onPress={() => {
              if (currentOrder) return;
              if (isCommerceScreenNotInDisplay) {
                navigation.goBack();
              }
            }}
          />
          <Text
            style={{
              lineHeight: 20,
              fontSize: 24,
              fontWeight: '500',
              textAlign: 'center',
              color: disabledColorForTabsTotem,
              letterSpacing: 0.14,
              marginTop: 15,
            }}
          >
            {t('go-back')}
          </Text>
        </View>
      )}
      <View>
        <Icon
          name="home-alt"
          style={{
            color: findLastScreenOnHomeStack ? currentVenue?.appColor ?? greenColor : disabledColorForTabsTotem,
            fontSize: 75,
          }}
          onPress={() => {
            if (currentOrder) return;
            navigation.navigate('CommerceScreen');
          }}
        />
        <Text
          style={{
            lineHeight: 20,
            fontSize: 24,
            fontWeight: '500',
            textAlign: 'center',
            color: findLastScreenOnHomeStack ? currentVenue?.appColor ?? greenColor : disabledColorForTabsTotem,
            letterSpacing: 0.14,
            marginTop: 15,
          }}
        >
          {t('home')}
        </Text>
      </View>
      <TouchableOpacity
        onPress={() => {
          if (currentOrder) return;
          navigation.navigate('ShoppingCartScreen', {
            venueCode: currentVenue?.code,
            commerceCode: currentCommerce?.code,
          });
        }}
      >
        {currentShoppingCart && currentShoppingCart.detail.length > 0 && totalAmountOfAllProductsInShoppingCart && (
          <View
            style={[
              styles.shoppingCartLength,
              {
                backgroundColor: currentVenue?.appColor ?? greenColor,
                borderColor: currentVenue?.appColor ?? greenColor,
              },
            ]}
          >
            <Text style={styles.shoppingCartLengthNumber}>{totalAmountOfAllProductsInShoppingCart}</Text>
          </View>
        )}
        <Icon
          name="shopping-cart"
          style={{
            color: findLastScreenOnShoppingCartStack ? currentVenue?.appColor ?? greenColor : disabledColorForTabsTotem,
            fontSize: 75,
          }}
        />
        <Text
          style={{
            lineHeight: 20,
            fontSize: 24,
            fontWeight: '500',
            textAlign: 'center',
            color: findLastScreenOnShoppingCartStack ? currentVenue?.appColor ?? greenColor : disabledColorForTabsTotem,
            letterSpacing: 0.14,
            marginTop: 15,
          }}
        >
          {t('my-cart')}
        </Text>
      </TouchableOpacity>
    </View>
  );
};
