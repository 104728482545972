import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as clover from "remote-pay-cloud";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useFonts } from 'expo-font';
import { StatusBar } from 'expo-status-bar';
import * as SplashScreen from 'expo-splash-screen';
import './src/translation/config';
import { Loading } from '@double_point/rn-components';

import useCachedResources from 'hooks/useCachedResources';
import useChangeVenue from 'hooks/useChangeVenue';
import Navigation from 'navigation/Navigation';
import AppContext from 'contexts/AppContext';
import ModalizeData from 'types/models/ModalizeData';
import AuthData from 'types/models/AuthData';
import Venue from 'types/models/Venue';
import Order from 'types/models/Order';
import CreditCard from 'types/models/CreditCard';
import CloverPoint from 'types/models/CloverPoint';
import CloverPointConfig from 'types/models/CloverPointConfig';
import OrderModalResponseData from 'types/models/OrderModalResponseData';
import User from 'types/models/User';
import { CommerceScreenParams, HomeScreenParams } from 'types/navigation/bottomTabNavigator';
import constantsStorage from 'constants/Storage';
import { getVenue } from 'services/venuesServices';
import { getUser } from 'services/authService';
import { getCurrentPairedPoint } from 'services/paymentsService';

SplashScreen.preventAutoHideAsync();

export default function App(): JSX.Element | null {
  const { i18n } = useTranslation();
  const isCachedResourcesLoadingComplete = useCachedResources();
  const { deleteCurrentVenue } = useChangeVenue();
  const [fontsLoaded] = useFonts({ IcoMoon: require('assets/icomoon/billetera-fan.ttf') });
  const [initialLoad, setInitialLoad] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [modalizeData, setModalizeData] = React.useState<ModalizeData | null>(null);
  const [currentAuthData, setCurrentAuthData] = React.useState<AuthData | null>(null);
  const [selectedOrder, setSelectedOrder] = React.useState<Order | null>(null);
  const [newlyCreatedOrder, setNewlyCreatedOrder] = React.useState<boolean>(false);
  const [showVenues, setShowVenues] = React.useState<boolean>(false);
  const [currentVenue, setCurrentVenue] = React.useState<Venue | null>(null);
  const [showVenueNotSelected, setShowVenueNotSelected] = React.useState<boolean>(false);
  const [creditCards, setCreditCards] = React.useState<CreditCard[] | null>(null);
  const [tabHeight, setTabHeight] = React.useState<number>(0);
  const [redirectionItems, setRedirectionItems] = React.useState<CommerceScreenParams | null>(null);
  const [redirectionHomeItems, setRedirectionHomeItems] = React.useState<HomeScreenParams | null>(null);
  const [pairedWithClover, setPairedWithClover] = React.useState<boolean>(false);
  const [cloverConnected, setCloverConnected] = React.useState<clover.remotepay.ICloverConnector | null>(null);
  const [currentPairedCloverPoint, setCurrentPairedCloverPoint] = React.useState<CloverPoint | null>(null);
  const [currentCloverConfig, setCurrentCloverConfig] = React.useState<CloverPointConfig | null>(null);
  const [currentCloverPairingCode, setCurrentCloverPairingCode] = React.useState<string | null>(null);
  const [successfullyConnectedToCloverDevice, setSuccessfullyConnectedToCloverDevice] = React.useState<boolean>(false)
  const [userBalance, setUserBalance] = React.useState<number | null>(null);
  const [orderModalResponseData, setOrderModalResponseData] = React.useState<OrderModalResponseData | null>(null);
  const [showWelcomeView, setShowWelcomeView] = React.useState<boolean>(false);
  const [wasVenueSelectedManually,setWasVenueSelectedManually] = React.useState<boolean>(false);
  const [previousRouteName, setPreviousRouteName] = React.useState<string | null>(null);
  const [currentRouteName, setCurrentRouteName] = React.useState<string | null>(null);
  const [venueLoad, setVenueLoad] = React.useState<boolean>(false);
  const [checkedForDifferentVenueCode, setCheckedForDifferentVenueCode] = React.useState<boolean>(false);

  const setStorageAuthData = async (): Promise<void> => {
    const currentAuthDataInStorage = await AsyncStorage.getItem(constantsStorage.authData);
    const storeCurrentVenue = await AsyncStorage.getItem(constantsStorage.currentVenue);
    if (currentAuthDataInStorage && storeCurrentVenue) {
      const localAuthData: AuthData = JSON.parse(currentAuthDataInStorage);
      const remoteUser: User | null = await getUser(localAuthData.user.id, JSON.parse(storeCurrentVenue)?.id);
      const localAuthdata = { ...localAuthData };
      if (remoteUser && localAuthData.user.updatedAt === remoteUser.updatedAt) {
        localAuthdata.user = localAuthData.user;
        setCurrentAuthData(localAuthdata);
      } else if (remoteUser && localAuthData.user.updatedAt !== remoteUser.updatedAt) {
        localAuthData.user = remoteUser;
        setCurrentAuthData(localAuthData)
      }
    }
  };

  const setStorageVenue = async (): Promise<void> => {
    const storeCurrentVenue = await AsyncStorage.getItem(constantsStorage.currentVenue);
    if (storeCurrentVenue) {
      const localVenue: Venue = JSON.parse(storeCurrentVenue);
      i18n.changeLanguage(localVenue?.language);
      const remoteVenue: Venue | null = await getVenue(localVenue.id);
      if (remoteVenue) {
        if (localVenue.updatedAt === remoteVenue.updatedAt) {
          if (someCommerceOrderChanged(localVenue, remoteVenue)) {
            localVenue.commerces = remoteVenue.commerces;
          }
        } else {
          setCurrentVenue(remoteVenue);
          return;
        }
        setCurrentVenue(localVenue);
      } else {
        await deleteCurrentVenue();
      }
    }
  };

  const setStorageHomeScreenParams = async (): Promise<void> => {
    const params = await AsyncStorage.getItem(constantsStorage.homeRedirectParams);
    if (params) setRedirectionHomeItems(JSON.parse(params));
  }

  const someCommerceOrderChanged = (localVenue: Venue, remoteVenue: Venue | null) => {
    let someChanged = false;

    localVenue.commerces.forEach((commerce) => {
      const findCommerceRemote = remoteVenue?.commerces.find(({ id }) => id === commerce.id);
      if (findCommerceRemote) {
        if (findCommerceRemote.order !== commerce.order) {
          someChanged = true;
        }
      }
    });

    return someChanged;
  }

  const setStoragePairedWithClover = async (): Promise<void> => {
    const storeCurrentPairedCloverPoint = await AsyncStorage.getItem(constantsStorage.currentPairedCloverPoint);
    if (storeCurrentPairedCloverPoint) {
      const parsedCurrentPairedCloverPoint = JSON.parse(storeCurrentPairedCloverPoint);
      const currentPairedRemoteCloverPoint = await getCurrentPairedPoint(parsedCurrentPairedCloverPoint.id);

      if (currentPairedRemoteCloverPoint && parsedCurrentPairedCloverPoint) {
        if (currentPairedRemoteCloverPoint.deviceAddress !== parsedCurrentPairedCloverPoint.deviceAddress && currentPairedRemoteCloverPoint.isPaired) {
          setPairedWithClover(true)
        } else if (!currentPairedRemoteCloverPoint.isPaired) {
          setPairedWithClover(false);
        }
      }
    }
  }

  const setStorageCloverPoint = async (): Promise<void> => {
    const storeCurrentPairedCloverPoint = await AsyncStorage.getItem(constantsStorage.currentPairedCloverPoint);
    if (storeCurrentPairedCloverPoint) {
      const parsedCurrentPairedCloverPoint = JSON.parse(storeCurrentPairedCloverPoint);
      const currentPairedRemoteCloverPoint = await getCurrentPairedPoint(parsedCurrentPairedCloverPoint.id);
      if (currentPairedRemoteCloverPoint && parsedCurrentPairedCloverPoint) {
        if (currentPairedRemoteCloverPoint.deviceAddress !== parsedCurrentPairedCloverPoint.deviceAddress && currentPairedRemoteCloverPoint.isPaired) {
          setCurrentPairedCloverPoint(currentPairedRemoteCloverPoint)
          await AsyncStorage.setItem(constantsStorage.currentPairedCloverPoint, JSON.stringify(currentPairedRemoteCloverPoint));
        } else if (!currentPairedRemoteCloverPoint.isPaired) {
          setCurrentPairedCloverPoint(null);
          await AsyncStorage.removeItem(constantsStorage.currentPairedCloverPoint);
        }
      }
    }
  };

  const setStorageCloverConfig = async (): Promise<void> => {
    const storeCurrentCloverConfig = await AsyncStorage.getItem(constantsStorage.currentCloverConfig);
    if (storeCurrentCloverConfig) setCurrentCloverConfig(JSON.parse(storeCurrentCloverConfig));
  }

  const loadInitalData = React.useCallback(async () => {
    if (!initialLoad) {
      await setStorageHomeScreenParams();
      await setStorageVenue();
      await setStorageAuthData();
      await setStorageCloverConfig();
      await setStoragePairedWithClover();
      await setStorageCloverPoint();
      setInitialLoad(true);
    }
  }, [initialLoad]);

  React.useEffect(() => {
    loadInitalData();
  }, []);

  React.useEffect(() => {
    if (initialLoad) {
      SplashScreen.hideAsync();
    }
  }, [initialLoad])

  React.useEffect(() => {
    if (showVenueNotSelected && currentVenue) {
      setShowVenueNotSelected(false);
    }
  }, [currentVenue]);

  if (!isCachedResourcesLoadingComplete && !fontsLoaded && !initialLoad) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <AppContext.Provider
          value={{
            isLoading,
            setIsLoading,
            modalizeData,
            setModalizeData,
            currentAuthData,
            setCurrentAuthData,
            selectedOrder,
            setSelectedOrder,
            newlyCreatedOrder,
            setNewlyCreatedOrder,
            showVenues,
            setShowVenues,
            currentVenue,
            setCurrentVenue,
            setShowVenueNotSelected,
            creditCards,
            setCreditCards,
            tabHeight,
            setTabHeight,
            redirectionItems,
            setRedirectionItems,
            pairedWithClover,
            setPairedWithClover,
            cloverConnected,
            setCloverConnected,
            currentPairedCloverPoint,
            setCurrentPairedCloverPoint,
            currentCloverConfig,
            setCurrentCloverConfig,
            currentCloverPairingCode,
            setCurrentCloverPairingCode,
            successfullyConnectedToCloverDevice,
            setSuccessfullyConnectedToCloverDevice,
            userBalance,
            setUserBalance,
            redirectionHomeItems,
            setRedirectionHomeItems,
            orderModalResponseData,
            setOrderModalResponseData,
            showWelcomeView, 
            setShowWelcomeView,
            wasVenueSelectedManually,
            setWasVenueSelectedManually,
            previousRouteName,
            setPreviousRouteName,
            currentRouteName,
            setCurrentRouteName,
            venueLoad, 
            setVenueLoad,
            checkedForDifferentVenueCode,
            setCheckedForDifferentVenueCode
          }}
        >
          {isLoading && <Loading />}
          <Navigation />
          <StatusBar style="light" />
        </AppContext.Provider>
      </SafeAreaProvider>
    );
  }
}
